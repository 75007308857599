import React from "react";
import { all, takeEvery, put, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import { Toast } from "../../utils/helper";
import {
  GET_ORDERS_LIST,
  GET_PRODUCT_LIST,
  GET_PLANS_AND_PRICING,
  GET_PLAN_SERVICES,
  ADD_PLAN_SERVICES,
  DELETE_PLAN_SERVICES,
  GET_PLAN_ADDONS,
  GET_PRODUCT_HIGHLIGHT,
  ADD_PRODUCT_HIGHLIGHT,
  DELETE_PRODUCT_HIGHLIGHT,
  ADD_EDIT_PRODUCT,
  DELETE_PRODUCT,
  DELETE_PLAN,
  CHANGE_PLAN_STATUS,
  ADD_EDIT_PLAN,
  GET_CUSTOM_PLAN,
  UPDATE_PRODUCT_VISIBILITY_FOR_USER,
  GET_SUBSCRIPTION_LIST,
  GET_ALL_SUBSCRIPTION_LIST,
  ADD_SUBSCRIPTION,
  CHANGE_PLAN_ADDONS_STATUS,
  ADD_EDIT_PLAN_ADDONS,
  GET_ADDON_SERVICE,
  GET_EMPLOYER_ADDON_LIST,
  GET_EMPLOYER_ACTIVE_PRODUCT_LIST,
  GET_ADDON_TITLE_LIST,
  DELETE_EMPLOYER_ADDON,
  UPDATE_EMPLOYER_ADDON,
  EDIT_SUBSCRIPTION_CREDITS,
  SHOW_OR_HIDE_PRODUCT_PLAN_FOR_EMPLOYER,
  GET_PLANS_AND_PRICING_WITH_USER,
  FETCH_SUBSCRIPTION_DATA,
  ADD_EDIT_CREDITS,
} from "../action/types";
import {
  getOrderListSuccess,
  getOrderListFailure,
  getProductListSuccess,
  getProductListFailure,
  getPlansAndPricingListSuccess,
  getPlansAndPricingListFailure,
  getPlanServicesSuccess,
  getPlanServicesFailure,
  addPlanServicesSuccess,
  addPlanServicesFailure,
  deletePlanServicesSuccess,
  deletePlanServicesFailure,
  getPlanAddonsSuccess,
  getPlanAddonsFailure,
  getProductHighlightSuccess,
  getProductHighlightFailure,
  addProductHighlightSuccess,
  addProductHighlightFailure,
  deleteProductHighlightSuccess,
  deleteProductHighlightFailure,
  addOrEditProductSuccess,
  addOrEditProductFailure,
  deleteProductSuccess,
  deleteProductFailure,
  deletePlanSuccess,
  deletePlanFailure,
  changePlanStatusSuccess,
  changePlanStatusFailure,
  addOrEditPlanSuccess,
  addOrEditPlanFailure,
  getCustomPlanSuccess,
  getCustomPlanFailure,
  updateProductVisibilityForUserSuccess,
  updateProductVisibilityForUserFailure,
  getAllSubscriptionListFailure,
  getAllSubscriptionListSuccess,
  getSubscriptionListSuccess,
  getSubscriptionListFailure,
  postSubscriptionSuccess,
  postSubscriptionFailure,
  changePlanAddOnsStatusSuccess,
  changePlanAddOnsStatusFailure,
  addOrEditPlanAddonsSuccess,
  addOrEditPlanAddonsFailure,
  getAddonServicesSuccess,
  getAddonServicesFailure,
  getEmployerAddonListSuccess,
  getEmployerAddonListFailure,
  getEmployerActiveProductListSuccess,
  getEmployerActiveProductListFailure,
  getAddonTitleListSuccess,
  getAddonTitleListFailure,
  deleteEmployerAddonSuccess,
  deleteEmployerAddonFailure,
  updateEmployerAddonSuccess,
  updateEmployerAddonFailure,
  editSubscriptionCreditSuccess,
  editSubscriptionCreditFailure,
  showOrHideProductPlanForEmployerSuccess,
  showOrHideProductPlanForEmployerFailure,
  getPlansAndPricingWithUserSuccess,
  getPlansAndPricingWithUserFailure,
  fetchSubscriptionDataSuccess,
  fetchSubscriptionDataFailure,
  addEditCreditsSuccess,
  addEditCreditsFailure,
} from "../action";
import SUBSCRIPTION from "../../utils/api/subscription";

function* editSubscriptionRequest(action) {
  try {
    const res = yield SUBSCRIPTION.put(
      `admin/subscriptions/increaseCredits/subscription/${action?.payload?.subscriptionId}`,
      action.payload?.data
    );
    if (res.status === 200 || res.status === 201) {
      yield put(editSubscriptionCreditSuccess());
      yield toast.success(<Toast msg="Subscription Edited Successfully" />);
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(editSubscriptionCreditFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* getEmployerActiveProductListRequest(action) {
  try {
    const res = yield SUBSCRIPTION.get(
      `admin/products/activeProductList/user/${action.payload.user}`
    );
    if (res.status === 200) {
      yield put(getEmployerActiveProductListSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getEmployerActiveProductListFailure());
  }
}

function* getSubscriptionListRequest(action) {
  try {
    const res = yield SUBSCRIPTION.get(
      `admin/subscriptions/employer/user/${action.payload}`
    );
    if (res.status === 200) {
      yield put(getSubscriptionListSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getSubscriptionListFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* getAllSubscriptionListRequest(action) {
  const isSearch = action.payload.search
    ? `${
        action.payload.page && action.payload.pageSize ? "&search" : "?search"
      }=${encodeURIComponent(action.payload.search)}`
    : "";
  const isPageAndPageSize =
    action.payload.page && action.payload.pageSize
      ? `?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
      : "";
  const isVerified =
    action.payload.isVerified !== undefined
      ? `&isVerified=${action.payload.isVerified}`
      : "";
  const productId = action.payload.productId
    ? `&productId=${action.payload.productId}`
    : "";
  const active = action.payload.active
    ? `&active=${action.payload.active}`
    : "";
  const deleted = action.payload.deleted
    ? `&deleted=${action.payload.deleted}`
    : "";
  const suspended = action.payload.suspended
    ? `&suspended=${action.payload.suspended}`
    : "";
  const inactive = action.payload.inactive
    ? `&inactive=${action.payload.inactive}`
    : "";
  try {
    const res = yield SUBSCRIPTION.get(
      `admin/subscriptions/allsubscriptionlist${isPageAndPageSize}${isSearch}${isVerified}${productId}${active}${inactive}${deleted}${suspended}`
    );
    if (res.status === 200) {
      yield put(getAllSubscriptionListSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getAllSubscriptionListFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* addSubscriptionRequest(action) {
  const id = action?.payload?.data?.userId;
  delete action?.payload?.data?.userId;
  try {
    const res = yield SUBSCRIPTION.post(
      `admin/orders/subscription/${id}`,
      action.payload?.data
    );
    if (res.status === 200 || res.status === 201) {
      yield put(postSubscriptionSuccess());
      yield toast.success(<Toast msg="Subscription Added successfully" />);
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(postSubscriptionFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* getOrderList(action) {
  const isPageAndPageSize =
    action.payload.page && action.payload.pageSize
      ? `?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
      : "";
  const isSort = action.payload.sort ? `&sort=${action.payload.sort}` : "";
  const isUserId =
    action.payload.userId && action.payload.userId
      ? `&user=${action.payload.userId}`
      : "";
  try {
    const res = yield SUBSCRIPTION.get(
      `admin/orders${isPageAndPageSize}${isSort}${isUserId}`
    );
    if (res.status === 200) {
      yield put(getOrderListSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getOrderListFailure());
    yield e?.response?.data?.message &&
      toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
// PRODUCT
function* getProductListRequest() {
  try {
    const res = yield SUBSCRIPTION.get("admin/products");
    if (res.status === 200) {
      yield put(getProductListSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getProductListFailure());
  }
}
function* updateProductVisibilityForUserRequest(action) {
  try {
    let visibility = action.payload.visibility === true ? "active" : "inactive";
    const res = yield SUBSCRIPTION.patch(
      `admin/products/${action.payload.productId}/visibility/${visibility}/user/${action.payload.userId}`
    );
    if (res.status === 200) {
      yield put(updateProductVisibilityForUserSuccess());
    }
  } catch (e) {
    yield put(updateProductVisibilityForUserFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* getPlanServicesRequest() {
  try {
    const res = yield SUBSCRIPTION.get("admin/plans/planServices");
    if (res.status === 200) {
      yield put(getPlanServicesSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getPlanServicesFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* getAddonServicesRequest() {
  try {
    const res = yield SUBSCRIPTION.get("admin/plans/addonService");
    if (res.status === 200) {
      yield put(getAddonServicesSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getAddonServicesFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* addPlanServicesRequest(action) {
  try {
    const res = yield SUBSCRIPTION.post("admin/plans/planServices", {
      name: action.payload.name,
    });
    if (res.status === 201) {
      yield put(addPlanServicesSuccess());
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(addPlanServicesFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* deletePlanServicesRequest(action) {
  try {
    const res = yield SUBSCRIPTION.delete(
      `admin/plans/planServices/${action.payload.deleteId}`
    );
    if (res.status === 204) {
      yield put(deletePlanServicesSuccess());
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(deletePlanServicesFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* getPlanAddonsRequest(action) {
  try {
    const isSearch = action?.payload?.search
      ? `${
          action.payload.page && action.payload.pageSize ? "&search" : "?search"
        }=${encodeURIComponent(action.payload.search)}`
      : "";
    const isSort = action?.payload?.sort ? `&sort=${action.payload.sort}` : "";
    const isPageAndPageSize =
      action?.payload?.page && action?.payload?.pageSize
        ? `?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
        : `?page=1&pageSize=1000`; // this is number is added to fetch all the addons in the add plan and add custom plan page
    const res = yield SUBSCRIPTION.get(
      `admin/plans/addOns${isPageAndPageSize}${isSort}${isSearch}`
    );
    if (res.status === 200) {
      yield put(getPlanAddonsSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getPlanAddonsFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* getEmployerAddonListRequest(action) {
  try {
    const res = yield SUBSCRIPTION.get(
      `admin/subscriptions/user/${action?.payload?.id}`
    );
    if (res.status === 200) {
      yield put(getEmployerAddonListSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getEmployerAddonListFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* getPlansAndPricingListRequest(action) {
  const productId = action.payload.productId
    ? `/${action.payload.productId}`
    : "";
  const userId = action.payload.userId ? `/${action.payload.userId}` : "";
  try {
    const res = yield SUBSCRIPTION.get(`admin/plans${productId}${userId}`);
    if (res.status === 200) {
      yield put(getPlansAndPricingListSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getPlansAndPricingListFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* getPlansAndPricingWithUserListRequest(action) {
  const productId = action.payload.productId
    ? `/${action.payload.productId}`
    : "";
  const userId = action.payload.userId ? `/${action.payload.userId}` : "";
  try {
    const res = yield SUBSCRIPTION.get(`admin/plans${productId}/user${userId}`);
    if (res.status === 200) {
      yield put(getPlansAndPricingWithUserSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getPlansAndPricingWithUserFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* showOrHideProductPlanForEmployerRequest(action) {
  try {
    const res = yield SUBSCRIPTION.put(
      `admin/plans/visibility/${action.payload.visibility}/${action.payload.planId}/${action.payload.userId}`
    );
    if (res.status === 200 || res.status === 201) {
      yield put(showOrHideProductPlanForEmployerSuccess());
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(showOrHideProductPlanForEmployerFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* getProductHighlightRequest() {
  try {
    const res = yield SUBSCRIPTION.get(`admin/products/productHighlight`);
    if (res.status === 200) {
      yield put(getProductHighlightSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getProductHighlightFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* addProductHighlightRequest(action) {
  try {
    const res = yield SUBSCRIPTION.post(`admin/products/productHighlight`, {
      name: action.payload.name,
    });
    if (res.status === 201) {
      yield put(addProductHighlightSuccess());
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(addProductHighlightFailure());
  }
}
function* deleteProductHighlightRequest(action) {
  try {
    const res = yield SUBSCRIPTION.delete(
      `admin/products/productHighlight/${action.payload.deleteId}`
    );
    if (res.status === 204) {
      yield put(deleteProductHighlightSuccess());
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(deleteProductHighlightFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* addOrEditProductRequest(action) {
  const formData = new FormData();
  formData.append("name", action.payload.data.name);
  formData.append("visibility", action.payload.data.visibility);
  formData.append("isContactUsEnabled", action.payload.data.isContactUsEnabled);
  formData.append("price", action.payload.data.price);
  formData.append("label", action.payload.data.label);
  formData.append("icon", action.payload.data.icon);
  formData.append("status", action.payload.data.status ? "active" : "inactive");
  formData.append("subdomain", action.payload.data.subdomain);
  formData.append("plansVisibility", action.payload.data.plansVisibility);
  action.payload.data.productFeatureIds.map((itm) =>
    formData.append("productHighlightIds", itm)
  );
  try {
    let res;
    if (action?.payload?.id) {
      res = yield SUBSCRIPTION.put(
        `admin/products/${action?.payload?.id}`,
        formData
      );
    } else {
      res = yield SUBSCRIPTION.post(`admin/products`, formData);
    }
    if (res.status === 201 || res.status === 200) {
      yield put(addOrEditProductSuccess());
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(addOrEditProductFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* deleteProductRequest(action) {
  try {
    const res = yield SUBSCRIPTION.delete(
      `admin/products/${action.payload.id}`
    );
    if (res.status === 204) {
      yield put(deleteProductSuccess());
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(deleteProductFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* deletePlanRequest(action) {
  try {
    const res = yield SUBSCRIPTION.delete(
      `admin/plans/${action.payload.productId}/${action.payload.planId}`
    );
    if (res.status === 204) {
      yield put(deletePlanSuccess());
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(deletePlanFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* changePlanStatusRequest(action) {
  try {
    const res = yield SUBSCRIPTION.patch(
      `admin/plans/${action.payload.productId}/${action.payload.planId}/status/${action.payload.status}`
    );
    if (res.status === 201) {
      yield put(changePlanStatusSuccess());
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(changePlanStatusFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* changePlanAddOnsStatusRequest(action) {
  try {
    const res = yield SUBSCRIPTION.patch(
      `admin/plans/addOns/status/${action.payload.id}/${action.payload.status}`
    );
    if (res.status === 201) {
      yield put(changePlanAddOnsStatusSuccess());
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(changePlanAddOnsStatusFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* addEditPlanRequest(action) {
  try {
    let res;
    const formData = new FormData();
    const { invoice, ...jasonData } = action.payload.data;
    let myJasonData = { ...jasonData };
    Object.keys(myJasonData).forEach((key) => {
      if (myJasonData[key].length === 0 && key !== "addons") {
        delete myJasonData[key];
      }
    });
    formData.append("json_data", JSON.stringify(myJasonData));
    if (invoice) formData.append("invoice", invoice);
    if (action.payload.planId) {
      res = yield SUBSCRIPTION.put(
        `admin/plans/${action.payload?.data?.product}/${action.payload.planId}`,
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      );
    } else {
      res = yield SUBSCRIPTION.post(
        `admin/plans/${action.payload.data.product}`,
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      );
    }
    if (res.status === 201 || res.status === 200) {
      yield put(addOrEditPlanSuccess());
      if (action.payload.isCustom === false) {
        if (action.payload.planId) {
          yield toast.success(<Toast msg="Plan Edited successfully" />);
        } else {
          yield toast.success(<Toast msg="Plan Added successfully" />);
        }
      } else if (action.payload.isCustom === true) {
        if (action.payload.productId !== false) {
          yield toast.success(<Toast msg="Custom Plan Edited successfully" />);
        } else {
          yield toast.success(<Toast msg="Custom Plan Added successfully" />);
        }
      }
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(addOrEditPlanFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* addEditPlanAddonsRequest(action) {
  try {
    let res = yield SUBSCRIPTION.post(
      `admin/plans/addOns`,
      action.payload.data
    );
    if (res.status === 201 || res.status === 200) {
      yield put(addOrEditPlanAddonsSuccess());
      if (action.payload.data?.id) {
        yield toast.success(<Toast msg="Addons Edited successfully" />);
      } else {
        yield toast.success(<Toast msg="Addons Added successfully" />);
      }
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(addOrEditPlanAddonsFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* getCustomPlanRequest(action) {
  const isPageAndPageSize =
    action.payload.page && action.payload.pageSize
      ? `?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
      : "";
  const isSort = action.payload.sort ? `&sort=${action.payload.sort}` : "";
  const isSearch =
    action.payload.search && action.payload.search
      ? `&search=${encodeURIComponent(action.payload.search)}`
      : "";
  try {
    const res = yield SUBSCRIPTION.get(
      `admin/plans/all/custom${isPageAndPageSize}${isSort}${isSearch}`
    );
    if (res.status === 200 || res.status === 201) {
      yield put(getCustomPlanSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getCustomPlanFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* getAddonTitleListRequest(action) {
  try {
    const res = yield SUBSCRIPTION.get(`admin/plans/addOns/activeList`);
    if (res.status === 200 || res.status === 201) {
      yield put(getAddonTitleListSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getAddonTitleListFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* deleteEmployerAddonRequest(action) {
  try {
    const res = yield SUBSCRIPTION.delete(
      `admin/orders/addOns/delete/${action.payload.orderId}/${action.payload.addonsId}`
    );
    if (res.status === 204) {
      yield put(deleteEmployerAddonSuccess());
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(deleteEmployerAddonFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* updateEmployerAddonRequest(action) {
  try {
    const res = yield SUBSCRIPTION.put(`admin/orders/addOns`, {
      orderId: action.payload.subscriptions,
      addOns: action.payload.addOn,
    });
    if (res.status === 204) {
      yield put(updateEmployerAddonSuccess());
      yield toast.success(<Toast msg="Addons added successfully" />);
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(updateEmployerAddonFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* fetchSubscriptionDataRequest(action) {
  const subscriptionId = action?.payload?.id;

  if (subscriptionId) {
    try {
      const res = yield SUBSCRIPTION.get(
        `admin/subscriptions/getSubscription/${subscriptionId}`
      );
      if (res.status === 200) {
        yield put(fetchSubscriptionDataSuccess(res.data.result));
      }
    } catch (e) {
      yield put(fetchSubscriptionDataFailure());
      yield toast.error(<Toast msg={e?.response?.data?.message} />);
    }
  }
}

function* addEditCreditsRequest(action) {
  try {
    let res;
    const formData = new FormData();
    const creditId = action.payload.creditId ?? "";
    const subscriptionId = action.payload.subscriptionId ?? "";
    const { invoice, ...jasonData } = action.payload.data;
    let myJasonData = { ...jasonData };
    Object.keys(myJasonData).forEach((key) => {
      if (myJasonData[key].length === 0) {
        delete myJasonData[key];
      }
    });
    formData.append("json_data", JSON.stringify(myJasonData));
    if (invoice) formData.append("invoice", invoice);
    if (creditId) {
      res = yield SUBSCRIPTION.put(
        `admin/subscriptions/updateCredits/${creditId}/${subscriptionId}`,
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      );
    } else {
      res = yield SUBSCRIPTION.post(
        `admin/subscriptions/addCredits/${subscriptionId}`,
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      );
    }
    if (res.status === 201 || res.status === 200) {
      yield put(addEditCreditsSuccess());
      if (creditId) {
        yield toast.success(<Toast msg="Credits updated successfully" />);
      } else {
        yield toast.success(<Toast msg="Credits added successfully" />);
      }
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(addEditCreditsFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

export function* watchAddEditCreditsAPI() {
  yield takeEvery(ADD_EDIT_CREDITS, addEditCreditsRequest);
}

export function* watchFetchSubscriptionDataAPI() {
  yield takeEvery(FETCH_SUBSCRIPTION_DATA, fetchSubscriptionDataRequest);
}

export function* watchEditSubscriptionCreditAPI() {
  yield takeEvery(EDIT_SUBSCRIPTION_CREDITS, editSubscriptionRequest);
}
export function* watchGetEmployerActiveProductListAPI() {
  yield takeEvery(
    GET_EMPLOYER_ACTIVE_PRODUCT_LIST,
    getEmployerActiveProductListRequest
  );
}
export function* getOrderListAPI() {
  yield takeEvery(GET_ORDERS_LIST, getOrderList);
}

export function* watchGetAllSubscriptionListAPI() {
  yield takeEvery(GET_ALL_SUBSCRIPTION_LIST, getAllSubscriptionListRequest);
}

export function* watchAddSubscriptionAPI() {
  yield takeEvery(ADD_SUBSCRIPTION, addSubscriptionRequest);
}

export function* watchGetProductListAPI() {
  yield takeEvery(GET_PRODUCT_LIST, getProductListRequest);
}
export function* watchGetPlansAndPricingListAPI() {
  yield takeEvery(GET_PLANS_AND_PRICING, getPlansAndPricingListRequest);
}

export function* watchGetPlansAndPricingWithUserListRequestAPI() {
  yield takeEvery(
    GET_PLANS_AND_PRICING_WITH_USER,
    getPlansAndPricingWithUserListRequest
  );
}
export function* watchShowOrHideProductPlanForEmployerAPI() {
  yield takeEvery(
    SHOW_OR_HIDE_PRODUCT_PLAN_FOR_EMPLOYER,
    showOrHideProductPlanForEmployerRequest
  );
}
export function* watchGetPlanServicesAPI() {
  yield takeEvery(GET_PLAN_SERVICES, getPlanServicesRequest);
}
export function* watchGetAddonServicesAPI() {
  yield takeEvery(GET_ADDON_SERVICE, getAddonServicesRequest);
}
export function* watchAddPlanServicesAPI() {
  yield takeEvery(ADD_PLAN_SERVICES, addPlanServicesRequest);
}
export function* watchDeletePlanServicesAPI() {
  yield takeEvery(DELETE_PLAN_SERVICES, deletePlanServicesRequest);
}
export function* watchGetPlanAddonsAPI() {
  yield takeEvery(GET_PLAN_ADDONS, getPlanAddonsRequest);
}
export function* watchGetProductHighlightAPI() {
  yield takeEvery(GET_PRODUCT_HIGHLIGHT, getProductHighlightRequest);
}
export function* watchAddProductHighlightAPI() {
  yield takeEvery(ADD_PRODUCT_HIGHLIGHT, addProductHighlightRequest);
}
export function* watchDeleteProductHighlightAPI() {
  yield takeEvery(DELETE_PRODUCT_HIGHLIGHT, deleteProductHighlightRequest);
}
export function* watchAddOrEditProductAPI() {
  yield takeEvery(ADD_EDIT_PRODUCT, addOrEditProductRequest);
}
export function* watchUpdateProductVisibilityForUserAPI() {
  yield takeEvery(
    UPDATE_PRODUCT_VISIBILITY_FOR_USER,
    updateProductVisibilityForUserRequest
  );
}
export function* watchDeleteProductAPI() {
  yield takeEvery(DELETE_PRODUCT, deleteProductRequest);
}
export function* watchDeletePlanAPI() {
  yield takeEvery(DELETE_PLAN, deletePlanRequest);
}
export function* watchChangePlanStatusAPI() {
  yield takeEvery(CHANGE_PLAN_STATUS, changePlanStatusRequest);
}
export function* watchChangePlanAddonsStatusAPI() {
  yield takeEvery(CHANGE_PLAN_ADDONS_STATUS, changePlanAddOnsStatusRequest);
}
export function* watchAddEditPlanAPI() {
  yield takeEvery(ADD_EDIT_PLAN, addEditPlanRequest);
}
export function* watchAddEditPlanAddonsAPI() {
  yield takeEvery(ADD_EDIT_PLAN_ADDONS, addEditPlanAddonsRequest);
}
export function* watchGetCustomPlanAPI() {
  yield takeEvery(GET_CUSTOM_PLAN, getCustomPlanRequest);
}
export function* watchGetSubscriptionListAPI() {
  yield takeEvery(GET_SUBSCRIPTION_LIST, getSubscriptionListRequest);
}
export function* watchGetEmployerAddonListAPI() {
  yield takeEvery(GET_EMPLOYER_ADDON_LIST, getEmployerAddonListRequest);
}
export function* watchGetAddonTitleListAPI() {
  yield takeEvery(GET_ADDON_TITLE_LIST, getAddonTitleListRequest);
}
export function* watchDeleteEmployerAddonAPI() {
  yield takeEvery(DELETE_EMPLOYER_ADDON, deleteEmployerAddonRequest);
}
export function* watchUpdateEmployerAddonAPI() {
  yield takeEvery(UPDATE_EMPLOYER_ADDON, updateEmployerAddonRequest);
}

export default function* rootSaga() {
  yield all([
    watchGetEmployerActiveProductListAPI(),
    watchGetAllSubscriptionListAPI(),
    watchAddSubscriptionAPI(),
    getOrderListAPI(),
    watchGetProductListAPI(),
    watchGetPlansAndPricingListAPI(),
    watchGetPlanServicesAPI(),
    watchAddPlanServicesAPI(),
    watchDeletePlanServicesAPI(),
    watchGetPlanAddonsAPI(),
    watchGetProductHighlightAPI(),
    watchAddProductHighlightAPI(),
    watchDeleteProductHighlightAPI(),
    watchAddOrEditProductAPI(),
    watchDeleteProductAPI(),
    watchDeletePlanAPI(),
    watchChangePlanStatusAPI(),
    watchAddEditPlanAPI(),
    watchGetCustomPlanAPI(),
    watchUpdateProductVisibilityForUserAPI(),
    watchGetSubscriptionListAPI(),
    watchChangePlanAddonsStatusAPI(),
    watchAddEditPlanAddonsAPI(),
    watchGetAddonServicesAPI(),
    watchGetEmployerAddonListAPI(),
    watchGetAddonTitleListAPI(),
    watchDeleteEmployerAddonAPI(),
    watchUpdateEmployerAddonAPI(),
    watchEditSubscriptionCreditAPI(),
    watchShowOrHideProductPlanForEmployerAPI(),
    watchGetPlansAndPricingWithUserListRequestAPI(),
    watchGetPlanServicesAPI(),
    watchFetchSubscriptionDataAPI(),
    watchAddEditCreditsAPI(),
  ]);
}
