import React from "react";
import { all, takeEvery, put, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import { Toast } from "../../utils/helper";
import {
  GET_CAREERS_LIST,
  GET_CAREERS_POSITION,
  CREATE_CAREERS_POSITION,
  UPDATE_CAREERS_POSITION,
  DELETE_CAREERS,
  DELETE_CAREERS_LIST,
} from "../action/types";
import {
  getCareersListSuccess,
  getCareersListFailure,
  getCareersPositionSuccess,
  getCareersPositionFailure,
  createCareersPositionSuccess,
  createCareersPositionFailure,
  updateCareersPositionSuccess,
  updateCareersPositionFailure,
  deleteCareersSuccess,
  deleteCareersFailure,
  deleteCareersListSuccess,
  deleteCareersListFailure,
} from "../action";
import CANDIDATE from "../../utils/api/candidate";

//CAREER
function* getCareersListRequest(action) {
  const isPageAndPageSize =
    action.payload.page && action.payload.pageSize
      ? `?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
      : "";
  const isId = action?.payload?.id ? action?.payload?.id : "";
  const isSort = action?.payload?.sort ? `&sort=${action?.payload?.sort}` : "";
  const isSearch = action.payload.search
    ? `&search=${encodeURIComponent(action.payload.search)}`
    : "";
  try {
    const res = yield CANDIDATE.get(
      `admin/careers${isPageAndPageSize}&careerPosition=${isId}${isSearch}${isSort}`
    );
    if (res.status === 200) {
      yield put(getCareersListSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getCareersListFailure());
  }
}

function* getCareersPositionRequest(action) {
  const isFilter = action.payload.filters
    ? (action.payload.filters.from
        ? `&from=${action.payload.filters.from}T00:00:00`
        : "") +
      (action.payload.filters.to
        ? `&to=${action.payload.filters.to}T23:59:59`
        : "")
    : "";
  const isSort = action.payload.sort ? `&sort=${action.payload.sort}` : "";
  const isSearch = action.payload.search
    ? `&search=${encodeURIComponent(action.payload.search)}`
    : "";
  const isPageAndPageSize =
    action.payload.page && action.payload.pageSize
      ? `?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
      : "";
  try {
    const res = yield CANDIDATE.get(
      `admin/careerPositions${isPageAndPageSize}${isFilter}${isSearch}${isSort}`
    );
    if (res.status === 200) {
      yield put(getCareersPositionSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getCareersPositionFailure());
  }
}
function* createCareersPositionRequest(action) {
  try {
    const res = yield CANDIDATE.post(
      "admin/careerPositions",
      action.payload.data
    );
    if (res.status === 201 || res.status === 200) {
      yield put(createCareersPositionSuccess());
      yield call(action.payload.callback);
      yield toast.success(
        <Toast msg="Career position created successfully." />
      );
    }
  } catch (e) {
    const isT = '"responsibilities" is not allowed to be empty';
    yield put(createCareersPositionFailure());
    yield toast.error(
      <Toast
        msg={
          e?.response?.data?.message === isT
            ? "Job Description can't be empty"
            : e?.response?.data?.message
        }
      />
    );
  }
}
function* updateCareersPositionRequest(action) {
  try {
    const res = yield CANDIDATE.put(
      `admin/careerPositions/${action?.payload?.id}`,
      action.payload.data
    );
    if (res.status === 201 || res.status === 200) {
      yield put(updateCareersPositionSuccess());
      yield call(action.payload.callback);
      yield toast.success(
        <Toast msg="Career position updated successfully." />
      );
    }
  } catch (e) {
    yield put(updateCareersPositionFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* deleteCareersRequest(action) {
  try {
    const res = yield CANDIDATE.put(
      `admin/careerPositions/bulkDelete`,
      action.payload
    );
    if (res.status === 201 || res.status === 200) {
      yield put(deleteCareersSuccess());
      yield call(action.payload.callback);
      yield toast.success(
        <Toast msg="Career position deleted successfully." />
      );
    }
  } catch (e) {
    yield put(deleteCareersFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* deleteCareersListRequest(action) {
  try {
    const res = yield CANDIDATE.put(`admin/careers/bulkDelete`, action.payload);
    if (res.status === 201 || res.status === 200) {
      yield put(deleteCareersListSuccess());
      yield call(action.payload.callback);
      yield toast.success(
        <Toast msg="Career position deleted successfully." />
      );
    }
  } catch (e) {
    yield put(deleteCareersListFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
export function* watchGetCareersAPI() {
  yield takeEvery(GET_CAREERS_LIST, getCareersListRequest);
}

export function* watchGetCareersPositionAPI() {
  yield takeEvery(GET_CAREERS_POSITION, getCareersPositionRequest);
}
export function* watchCreateCareersPositionAPI() {
  yield takeEvery(CREATE_CAREERS_POSITION, createCareersPositionRequest);
}
export function* watchUpdateCareersPositionAPI() {
  yield takeEvery(UPDATE_CAREERS_POSITION, updateCareersPositionRequest);
}
export function* watchDeleteCareersAPI() {
  yield takeEvery(DELETE_CAREERS, deleteCareersRequest);
}
export function* watchDeleteCareersListAPI() {
  yield takeEvery(DELETE_CAREERS_LIST, deleteCareersListRequest);
}
export default function* rootSaga() {
  yield all([
    watchGetCareersAPI(),
    watchGetCareersPositionAPI(),
    watchCreateCareersPositionAPI(),
    watchUpdateCareersPositionAPI(),
    watchDeleteCareersAPI(),
    watchDeleteCareersListAPI(),
  ]);
}
