/* eslint-disable import/no-anonymous-default-export */
import {
  GET_ORDERS_LIST,
  GET_ORDERS_LIST_SUCCESS,
  GET_ORDERS_LIST_FAILURE,
  GET_PRODUCT_ID,
  GET_PRODUCT_LIST,
  GET_PRODUCT_LIST_SUCCESS,
  GET_PRODUCT_LIST_FAILURE,
  GET_PLANS_AND_PRICING,
  GET_PLANS_AND_PRICING_SUCCESS,
  GET_PLANS_AND_PRICING_FAILURE,
  GET_PRODUCT_HIGHLIGHT,
  GET_PRODUCT_HIGHLIGHT_SUCCESS,
  GET_PRODUCT_HIGHLIGHT_FAILURE,
  ADD_PRODUCT_HIGHLIGHT,
  ADD_PRODUCT_HIGHLIGHT_SUCCESS,
  ADD_PRODUCT_HIGHLIGHT_FAILURE,
  DELETE_PRODUCT_HIGHLIGHT,
  DELETE_PRODUCT_HIGHLIGHT_SUCCESS,
  DELETE_PRODUCT_HIGHLIGHT_FAILURE,
  ADD_EDIT_PRODUCT,
  ADD_EDIT_PRODUCT_SUCCESS,
  ADD_EDIT_PRODUCT_FAILURE,
  DELETE_PRODUCT,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAILURE,
  GET_PRODUCTS_PLANS,
  DELETE_PLAN,
  DELETE_PLAN_SUCCESS,
  DELETE_PLAN_FAILURE,
  CHANGE_PLAN_STATUS,
  CHANGE_PLAN_STATUS_SUCCESS,
  CHANGE_PLAN_STATUS_FAILURE,
  ADD_EDIT_PLAN,
  ADD_EDIT_PLAN_SUCCESS,
  ADD_EDIT_PLAN_FAILURE,
  GET_CUSTOM_PLAN,
  GET_CUSTOM_PLAN_SUCCESS,
  GET_CUSTOM_PLAN_FAILURE,
  GET_PLAN_SERVICES,
  GET_PLAN_SERVICES_SUCCESS,
  GET_PLAN_SERVICES_FAILURE,
  GET_PLAN_ADDONS,
  GET_PLAN_ADDONS_SUCCESS,
  GET_PLAN_ADDONS_FAILURE,
  ADD_PLAN_SERVICES,
  ADD_PLAN_SERVICES_SUCCESS,
  ADD_PLAN_SERVICES_FAILURE,
  DELETE_PLAN_SERVICES,
  DELETE_PLAN_SERVICES_SUCCESS,
  DELETE_PLAN_SERVICES_FAILURE,
  UPDATE_PRODUCT_VISIBILITY_FOR_USER,
  UPDATE_PRODUCT_VISIBILITY_FOR_USER_SUCCESS,
  UPDATE_PRODUCT_VISIBILITY_FOR_USER_FAILURE,
  GET_ALL_SUBSCRIPTION_LIST,
  GET_ALL_SUBSCRIPTION_LIST_SUCCESS,
  GET_ALL_SUBSCRIPTION_LIST_FAILURE,
  GET_SUBSCRIPTION_LIST,
  GET_SUBSCRIPTION_LIST_SUCCESS,
  GET_SUBSCRIPTION_LIST_FAILURE,
  ADD_SUBSCRIPTION,
  ADD_SUBSCRIPTION_SUCCESS,
  ADD_SUBSCRIPTION_FAILURE,
  CHANGE_PLAN_ADDONS_STATUS,
  CHANGE_PLAN_ADDONS_STATUS_SUCCESS,
  CHANGE_PLAN_ADDONS_STATUS_FAILURE,
  ADD_EDIT_PLAN_ADDONS,
  ADD_EDIT_PLAN_ADDONS_SUCCESS,
  ADD_EDIT_PLAN_ADDONS_FAILURE,
  GET_ADDON_SERVICE,
  GET_ADDON_SERVICE_SUCCESS,
  GET_ADDON_SERVICE_FAILURE,
  GET_EMPLOYER_ADDON_LIST,
  GET_EMPLOYER_ADDON_LIST_SUCCESS,
  GET_EMPLOYER_ADDON_LIST_FAILURE,
  GET_EMPLOYER_ACTIVE_PRODUCT_LIST,
  GET_EMPLOYER_ACTIVE_PRODUCT_LIST_SUCCESS,
  GET_EMPLOYER_ACTIVE_PRODUCT_LIST_FAILURE,
  GET_ADDON_TITLE_LIST,
  GET_ADDON_TITLE_LIST_SUCCESS,
  GET_ADDON_TITLE_LIST_FAILURE,
  DELETE_EMPLOYER_ADDON,
  DELETE_EMPLOYER_ADDON_SUCCESS,
  DELETE_EMPLOYER_ADDON_FAILURE,
  UPDATE_EMPLOYER_ADDON,
  UPDATE_EMPLOYER_ADDON_SUCCESS,
  UPDATE_EMPLOYER_ADDON_FAILURE,
  EDIT_SUBSCRIPTION_CREDITS,
  EDIT_SUBSCRIPTION_CREDITS_SUCCESS,
  EDIT_SUBSCRIPTION_CREDITS_FAILURE,
  SHOW_OR_HIDE_PRODUCT_PLAN_FOR_EMPLOYER,
  SHOW_OR_HIDE_PRODUCT_PLAN_FOR_EMPLOYER_SUCCESS,
  SHOW_OR_HIDE_PRODUCT_PLAN_FOR_EMPLOYER_FAILURE,
  GET_PLANS_AND_PRICING_WITH_USER,
  GET_PLANS_AND_PRICING_WITH_USER_SUCCESS,
  GET_PLANS_AND_PRICING_WITH_USER_FAILURE,
  FETCH_SUBSCRIPTION_DATA,
  FETCH_SUBSCRIPTION_DATA_SUCCESS,
  FETCH_SUBSCRIPTION_DATA_FAILURE,
  ADD_EDIT_CREDITS,
  ADD_EDIT_CREDITS_SUCCESS,
  ADD_EDIT_CREDITS_FAILURE,
} from "../action/types";

const INIT_STATE = {
  loading: false,
  productInfo: null,
  productData: null,
  plansAndPricingData: null,
  productHighlight: null,
  products: null,
  productsPlans: null,
  customPlan: null,
  services: null,
  addons: null,
  addonsTitleList: null,
  orderList: null,
  subscriptionsList: null,
  subscriptionList: null,
  addonsServices: null,
  productDetailList: null,
  timeStamp: null,
  addOns: null,
  plansAndPricingWithUserData: null,
  subscriptionData: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case EDIT_SUBSCRIPTION_CREDITS:
      return { ...state, loading: true };
    case EDIT_SUBSCRIPTION_CREDITS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case EDIT_SUBSCRIPTION_CREDITS_FAILURE:
      return { ...state, loading: false };
    case GET_ADDON_TITLE_LIST:
      return { ...state, loading: true };
    case GET_ADDON_TITLE_LIST_SUCCESS:
      return { ...state, loading: false, addonsTitleList: action.payload };
    case GET_ADDON_TITLE_LIST_FAILURE:
      return { ...state, loading: false };
    case UPDATE_EMPLOYER_ADDON:
      return { ...state, loading: true };
    case UPDATE_EMPLOYER_ADDON_SUCCESS:
      return { ...state, loading: false };
    case UPDATE_EMPLOYER_ADDON_FAILURE:
      return { ...state, loading: false };
    case DELETE_EMPLOYER_ADDON:
      return { ...state, loading: true };
    case DELETE_EMPLOYER_ADDON_SUCCESS:
      return { ...state, loading: false };
    case DELETE_EMPLOYER_ADDON_FAILURE:
      return { ...state, loading: false };
    case GET_EMPLOYER_ACTIVE_PRODUCT_LIST:
      return { ...state, loading: true };
    case GET_EMPLOYER_ACTIVE_PRODUCT_LIST_SUCCESS:
      return { ...state, loading: false, productDetailList: action.payload };
    case GET_EMPLOYER_ACTIVE_PRODUCT_LIST_FAILURE:
      return { ...state, loading: false };
    case GET_ORDERS_LIST:
      return { ...state, loading: true };
    case GET_ORDERS_LIST_SUCCESS:
      return { ...state, loading: false, orderList: action.payload };
    case GET_ORDERS_LIST_FAILURE:
      return { ...state, loading: false };
    case UPDATE_PRODUCT_VISIBILITY_FOR_USER:
      return { ...state, loading: true };
    case UPDATE_PRODUCT_VISIBILITY_FOR_USER_SUCCESS:
      return { ...state, loading: false };
    case UPDATE_PRODUCT_VISIBILITY_FOR_USER_FAILURE:
      return { ...state, loading: false };
    case GET_PRODUCT_ID:
      return { ...state, productInfo: action.payload };
    case GET_PRODUCT_LIST:
      return { ...state, loading: true };
    case GET_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        productData: action.payload,
      };
    case GET_PRODUCT_LIST_FAILURE:
      return { ...state, loading: false };
    case GET_PLANS_AND_PRICING:
      return { ...state, loading: true };
    case GET_PLANS_AND_PRICING_SUCCESS:
      return {
        ...state,
        loading: false,
        plansAndPricingData: action.payload,
      };
    case GET_PLANS_AND_PRICING_FAILURE:
      return { ...state, loading: false };
    case GET_PLANS_AND_PRICING_WITH_USER:
      return { ...state, loading: true };
    case GET_PLANS_AND_PRICING_WITH_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        plansAndPricingWithUserData: action.payload,
      };
    case GET_PLANS_AND_PRICING_WITH_USER_FAILURE:
      return { ...state, loading: false };
    case GET_PLAN_SERVICES:
      return { ...state, loading: true };
    case GET_PLAN_SERVICES_SUCCESS:
      return {
        ...state,
        loading: false,
        services: action.payload,
      };
    case GET_PLAN_SERVICES_FAILURE:
      return { ...state, loading: false };
    case GET_ADDON_SERVICE:
      return { ...state, loading: true };
    case GET_ADDON_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        timeStamp:
          state.timeStamp > action.payload.timeStamp
            ? state.timeStamp
            : action.payload.timeStamp,
        addonsServices:
          state.timeStamp > action.payload.timeStamp
            ? state.addonsServices
            : action.payload,
      };
    case GET_ADDON_SERVICE_FAILURE:
      return { ...state, loading: false };
    case ADD_PLAN_SERVICES:
      return { ...state, loading: true };
    case ADD_PLAN_SERVICES_SUCCESS:
      return { ...state, loading: false };
    case ADD_PLAN_SERVICES_FAILURE:
      return { ...state, loading: false };
    case DELETE_PLAN_SERVICES:
      return { ...state, loading: true };
    case DELETE_PLAN_SERVICES_SUCCESS:
      return { ...state, loading: false };
    case DELETE_PLAN_SERVICES_FAILURE:
      return { ...state, loading: false };
    case GET_PLAN_ADDONS:
      return { ...state, loading: true };
    case GET_PLAN_ADDONS_SUCCESS:
      return {
        ...state,
        loading: false,
        addOns: action.payload,
      };
    case GET_PLAN_ADDONS_FAILURE:
      return { ...state, loading: false };
    case GET_PRODUCT_HIGHLIGHT:
      return { ...state, loading: true };
    case GET_PRODUCT_HIGHLIGHT_SUCCESS:
      return {
        ...state,
        loading: false,
        productHighlight: action.payload,
      };
    case GET_PRODUCT_HIGHLIGHT_FAILURE:
      return { ...state, loading: false };
    case ADD_PRODUCT_HIGHLIGHT:
      return { ...state, loading: true };
    case ADD_PRODUCT_HIGHLIGHT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_PRODUCT_HIGHLIGHT_FAILURE:
      return { ...state, loading: false };
    case DELETE_PRODUCT_HIGHLIGHT:
      return { ...state, loading: true };
    case DELETE_PRODUCT_HIGHLIGHT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_PRODUCT_HIGHLIGHT_FAILURE:
      return { ...state, loading: false };
    case ADD_EDIT_PRODUCT:
      return { ...state, loading: true };
    case ADD_EDIT_PRODUCT_SUCCESS:
      return { ...state, loading: false };
    case ADD_EDIT_PRODUCT_FAILURE:
      return { ...state, loading: false };
    case DELETE_PRODUCT:
      return { ...state, loading: true };
    case DELETE_PRODUCT_SUCCESS:
      return { ...state, loading: false };
    case DELETE_PRODUCT_FAILURE:
      return { ...state, loading: false };
    case GET_PRODUCTS_PLANS:
      return { ...state, loading: false, productsPlans: action.payload };
    case DELETE_PLAN:
      return { ...state, loading: true };
    case DELETE_PLAN_SUCCESS:
      return { ...state, loading: false };
    case DELETE_PLAN_FAILURE:
      return { ...state, loading: false };
    case CHANGE_PLAN_STATUS:
      return { ...state, loading: true };
    case CHANGE_PLAN_STATUS_SUCCESS:
      return { ...state, loading: false };
    case CHANGE_PLAN_STATUS_FAILURE:
      return { ...state, loading: false };
    case CHANGE_PLAN_ADDONS_STATUS:
      return { ...state, loading: true };
    case CHANGE_PLAN_ADDONS_STATUS_SUCCESS:
      return { ...state, loading: false };
    case CHANGE_PLAN_ADDONS_STATUS_FAILURE:
      return { ...state, loading: false };

    case ADD_EDIT_PLAN:
      return { ...state, loading: true };
    case ADD_EDIT_PLAN_SUCCESS:
      return { ...state, loading: false };
    case ADD_EDIT_PLAN_FAILURE:
      return { ...state, loading: false };
    case ADD_EDIT_PLAN_ADDONS:
      return { ...state, loading: true };
    case ADD_EDIT_PLAN_ADDONS_SUCCESS:
      return { ...state, loading: false };
    case ADD_EDIT_PLAN_ADDONS_FAILURE:
      return { ...state, loading: false };
    case GET_CUSTOM_PLAN:
      return { ...state, loading: true };
    case GET_CUSTOM_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        timeStamp:
          state.timeStamp > action.payload.timeStamp
            ? state.timeStamp
            : action.payload.timeStamp,
        customPlan:
          state.timeStamp > action.payload.timeStamp
            ? state.customPlan
            : action.payload,
      };
    case GET_CUSTOM_PLAN_FAILURE:
      return { ...state, loading: false };
    case GET_ALL_SUBSCRIPTION_LIST: {
      return { ...state, loading: true };
    }
    case GET_ALL_SUBSCRIPTION_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        timeStamp:
          state.timeStamp > action.payload.timeStamp
            ? state.timeStamp
            : action.payload.timeStamp,
        subscriptionsList:
          state.timeStamp > action.payload.timeStamp
            ? state.subscriptionsList
            : action.payload,
      };
    }
    case GET_ALL_SUBSCRIPTION_LIST_FAILURE: {
      return { ...state, loading: false };
    }
    case GET_SUBSCRIPTION_LIST:
      return { ...state, loading: true };
    case GET_SUBSCRIPTION_LIST_SUCCESS:
      return { ...state, loading: false, subscriptionList: action.payload };
    case GET_SUBSCRIPTION_LIST_FAILURE:
      return { ...state, loading: false };
    case ADD_SUBSCRIPTION:
      return { ...state, loading: true };
    case ADD_SUBSCRIPTION_SUCCESS:
      return { ...state, loading: false };
    case ADD_SUBSCRIPTION_FAILURE:
      return { ...state, loading: false };
    case GET_EMPLOYER_ADDON_LIST:
      return { ...state, loading: true };
    case GET_EMPLOYER_ADDON_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        addons: action.payload,
      };
    case GET_EMPLOYER_ADDON_LIST_FAILURE:
      return { ...state, loading: false };

    case SHOW_OR_HIDE_PRODUCT_PLAN_FOR_EMPLOYER:
      return { ...state, loading: true };
    case SHOW_OR_HIDE_PRODUCT_PLAN_FOR_EMPLOYER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case SHOW_OR_HIDE_PRODUCT_PLAN_FOR_EMPLOYER_FAILURE:
      return { ...state, loading: false };

    case FETCH_SUBSCRIPTION_DATA:
      return { ...state, loading: true };
    case FETCH_SUBSCRIPTION_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        subscriptionData: action.payload,
      };
    case FETCH_SUBSCRIPTION_DATA_FAILURE:
      return { ...state, loading: false };
    case ADD_EDIT_CREDITS:
      return { ...state, loading: true };
    case ADD_EDIT_CREDITS_SUCCESS:
      return { ...state, loading: false };
    case ADD_EDIT_CREDITS_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};
