import React from "react";
import { all, takeEvery, put, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import { Toast } from "../../utils/helper";
import {
  GET_DOMAIN,
  GET_SCREENING_QUESTIONS,
  GET_MANDATORY_SKILL,
  GET_OPTIONAL_SKILL,
  GET_QUESTIONS,
  GET_QUESTION_SKILLS,
  ADD_OR_EDIT_QUESTIONS,
  DELETE_QUESTIONS,
  DELETE_BULK_QUESTIONS,
  POST_QUESTIONS,
  GET_SKILLS,
  GET_NEWS_ARTICLE_LIST,
  ADD_OR_EDIT_NEWS_ARTICLE,
  DELETE_NEWS_ARTICLE,
} from "../action/types";
import {
  getDomainSuccess,
  getDomainFailure,
  getScreeningQuestionsSuccess,
  getScreeningQuestionsFailure,
  getQuestionSkillsFailure,
  getQuestionSkillsSuccess,
  getMandatorySkillSuccess,
  getMandatorySkillFailure,
  getOptionalSkillSuccess,
  getOptionalSkillFailure,
  getQuestionsSuccess,
  getQuestionsFailure,
  addOrEditQuestionsSuccess,
  addOrEditQuestionsFailure,
  deleteQuestionsSuccess,
  deleteQuestionsFailure,
  deleteBulkQuestionsSuccess,
  deleteBulkQuestionsFailure,
  postQuestionsSuccess,
  postQuestionsFailure,
  getSkillsSuccess,
  getSkillsFailure,
  getNewsArticleListSuccess,
  getNewsArticleListFailure,
  addOrEditNewsArticleSuccess,
  addOrEditNewsArticleFailure,
  deleteNewsArticleSuccess,
  deleteNewsArticleFailure,
  addOrEditNewsArticle,
  deleteNewsArticle,
} from "../action";
import SKILLS from "../../utils/api/skills";

// DOMAIN
function* getDomainRequest() {
  try {
    const res = yield SKILLS.get("admin/domains");
    if (res.status === 200) {
      yield put(getDomainSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getDomainFailure());
  }
}

// SKILLS
function* getSkillsRequest() {
  try {
    const res = yield SKILLS.get("list/skills");
    if (res.status === 200) {
      yield put(getSkillsSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getSkillsFailure());
  }
}

// SCREENING QUESTIONS
function* getScreeningQuestionRequest() {
  try {
    const res = yield SKILLS.get("admin/screeningquestions");
    if (res.status === 200) {
      yield put(getScreeningQuestionsSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getScreeningQuestionsFailure());
  }
}
//SKILLS
function* getMandatorySkillRequest() {
  try {
    const res = yield SKILLS.get("admin/skills?type=mandatory");
    if (res.status === 200) {
      yield put(getMandatorySkillSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getMandatorySkillFailure());
  }
}
function* getOptionalSkillRequest() {
  try {
    const res = yield SKILLS.get("admin/skills?type=optional");
    if (res.status === 200) {
      yield put(getOptionalSkillSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getOptionalSkillFailure());
  }
}
function* getQuestionsRequest(action) {
  const isPageAndPageSize =
    action?.payload?.page && action?.payload?.pageSize
      ? `?page=${action?.payload?.page}&pageSize=${action?.payload?.pageSize}`
      : "";
  const isSearch = action?.payload?.search
    ? `&search=${encodeURIComponent(action?.payload?.search)}`
    : "";
  const isSort = action?.payload?.sort ? `&sort=${action?.payload?.sort}` : "";
  const isDifficultyLevel = action?.payload?.difficultyLevel
    ? `&difficultyLevel=${action?.payload?.difficultyLevel}`
    : "";
  const isQuestionType = action?.payload?.questionType
    ? `&questionType=${action?.payload?.questionType}`
    : "";
  const isLanguage = action?.payload?.language
    ? `&language=${action?.payload?.language}`
    : "";
  try {
    const res = yield SKILLS.get(
      `admin/skills/questionBank/list${isPageAndPageSize}${isSearch}${isSort}${isDifficultyLevel}${isQuestionType}${isLanguage}`
    );
    if (res.status === 200) {
      yield put(getQuestionsSuccess(res?.data?.result));
    }
  } catch (e) {
    yield put(getQuestionsFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* addOrEditQuestionsRequest(action) {
  try {
    let res;
    if (action?.payload?.data?.id) {
      res = yield SKILLS.put(
        `admin/skills/questionBank/${action?.payload?.data?.id}`,
        action?.payload?.data
      );
    } else {
      res = yield SKILLS.post(
        "admin/skills/questionBank",
        action?.payload?.data
      );
    }
    if (res.status === 200 || res.status === 201) {
      yield put(addOrEditQuestionsSuccess());
      yield toast.success(
        <Toast
          msg={`Question ${
            action?.payload?.data?.id ? "Edited" : "Added"
          } Successfully`}
        />
      );
      yield call(action?.payload?.callback);
    }
  } catch (e) {
    yield put(addOrEditQuestionsFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* deleteQuestionsRequest(action) {
  try {
    const res = yield SKILLS.delete(
      `admin/skills/questionBank/${action?.payload?.id}`
    );
    if (res.status === 204) {
      yield call(action?.payload?.callback);
      yield put(deleteQuestionsSuccess());
      yield toast.success(<Toast msg={`Question Deleted Successfully`} />);
    }
  } catch (e) {
    yield put(deleteQuestionsFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* deleteBulkQuestionsRequest(action) {
  try {
    const res = yield SKILLS.put(
      `admin/skills/questionBank/bulkDelete`,
      action.payload
    );
    if (res.status === 200) {
      yield call(action?.payload?.callback);
      yield put(deleteBulkQuestionsSuccess());
      yield toast.success(
        <Toast msg={`Bulk Questions Deleted Successfully`} />
      );
    }
  } catch (e) {
    yield put(deleteBulkQuestionsFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* getQuestionSkillsRequest() {
  try {
    const res = yield SKILLS.get(`admin/skills/questionBank/language`);
    if (res.status === 200) {
      yield put(getQuestionSkillsSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getQuestionSkillsFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* postQuestionsRequest(action) {
  try {
    const formData = new FormData();
    formData.append("questionBank", action.payload.file);
    const res = yield SKILLS.post(
      `admin/skills/questionBank/bulkUpload`,
      formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
    if (res.status === 201) {
      yield call(action?.payload?.callback);
      yield put(postQuestionsSuccess(res.data.result));
      yield toast.success(
        <Toast msg={`Bulk question uploaded Successfully`} />
      );
    }
  } catch (e) {
    yield put(postQuestionsFailure());
  }
}

function* getNewsArticleRequest(action) {
  const isSearch = action?.payload?.search
    ? `&search=${encodeURIComponent(action?.payload?.search)}`
    : "";
  const isPageAndPageSize =
    action.payload.page && action.payload.pageSize
      ? `?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
      : "";
  const isSort = action.payload.sort ? `&sort=${action.payload.sort}` : "";
  try {
    const res = yield SKILLS.get(
      `admin/article/getArticles${isPageAndPageSize}${isSort}${isSearch}`
    );
    if (res.status === 200) {
      yield put(getNewsArticleListSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getNewsArticleListFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* addOrEditNewsArticleRequest(action) {
  try {
    let res;
    const formData = new FormData();
    formData.append("title", action?.payload?.data?.title);
    formData.append("bannerImage", action?.payload?.data?.bannerImage);
    formData.append("link", action?.payload?.data?.link);
    formData.append("logoImage", action?.payload?.data?.logoImage);
    if (action?.payload?.id) {
      res = yield SKILLS.put(
        `admin/article/update/${action?.payload?.id}`,
        formData
      );
    } else {
      res = yield SKILLS.post(`admin/article/create`, formData);
    }
    if (res.status === 201 || res.status === 200) {
      yield put(addOrEditNewsArticleSuccess(res.data.result));
      yield call(action.payload.callback);
      yield toast.success(
        <Toast
          msg={`News article ${
            action.payload.id ? "edited" : "added"
          } successfuly`}
        />
      );
    }
  } catch (e) {
    yield put(addOrEditNewsArticleFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* deleteNewsArticleRequest(action) {
  try {
    let res;
    if (action.payload.id) {
      res = yield SKILLS.patch(
        `admin/article/changeStatus/${action.payload.id}/${action.payload.status}`
      );
    }
    if (res.status === 204 || res.status === 201) {
      yield put(deleteNewsArticleSuccess(res.data.result));
      yield call(action.payload.callback);
      yield toast.success(<Toast msg={`News article deleted successfuly`} />);
    }
  } catch (e) {
    yield put(deleteNewsArticleFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

export function* watchGetSkillsAPI() {
  yield takeEvery(GET_SKILLS, getSkillsRequest);
}
export function* watchGetScreeningQuestionAPI() {
  yield takeEvery(GET_SCREENING_QUESTIONS, getScreeningQuestionRequest);
}
export function* watchGetQuestionsAPI() {
  yield takeEvery(GET_QUESTIONS, getQuestionsRequest);
}
export function* watchGetQuestionSkillsAPI() {
  yield takeEvery(GET_QUESTION_SKILLS, getQuestionSkillsRequest);
}
export function* watchAddOrEditQuestionsAPI() {
  yield takeEvery(ADD_OR_EDIT_QUESTIONS, addOrEditQuestionsRequest);
}
export function* watchDeleteQuestionsAPI() {
  yield takeEvery(DELETE_QUESTIONS, deleteQuestionsRequest);
}
export function* watchDeleteBulkQuestionsAPI() {
  yield takeEvery(DELETE_BULK_QUESTIONS, deleteBulkQuestionsRequest);
}
export function* watchPostQuestionsAPI() {
  yield takeEvery(POST_QUESTIONS, postQuestionsRequest);
}
export function* watchGetMandatorySkillAPI() {
  yield takeEvery(GET_MANDATORY_SKILL, getMandatorySkillRequest);
}
export function* watchGetOptionalSkillAPI() {
  yield takeEvery(GET_OPTIONAL_SKILL, getOptionalSkillRequest);
}
export function* watchGetDomainAPI() {
  yield takeEvery(GET_DOMAIN, getDomainRequest);
}

export function* watchGetNewsArticleListRequestAPI() {
  yield takeEvery(GET_NEWS_ARTICLE_LIST, getNewsArticleRequest);
}
export function* watchAddOrEditNewsArticleRequestAPI() {
  yield takeEvery(ADD_OR_EDIT_NEWS_ARTICLE, addOrEditNewsArticleRequest);
}
export function* watchDeleteNewsArticleRequestAPI() {
  yield takeEvery(DELETE_NEWS_ARTICLE, deleteNewsArticleRequest);
}

export default function* rootSaga() {
  yield all([
    watchDeleteNewsArticleRequestAPI(),
    watchGetNewsArticleListRequestAPI(),
    watchAddOrEditNewsArticleRequestAPI(),
    watchGetQuestionsAPI(),
    watchGetQuestionSkillsAPI(),
    watchAddOrEditQuestionsAPI(),
    watchDeleteQuestionsAPI(),
    watchDeleteBulkQuestionsAPI(),
    watchPostQuestionsAPI(),
    watchGetScreeningQuestionAPI(),
    watchGetMandatorySkillAPI(),
    watchGetOptionalSkillAPI(),
    watchGetDomainAPI(),
    watchGetSkillsAPI(),
  ]);
}
