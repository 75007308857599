import React from "react";
import { Button } from "../commonComponent";
import deleteIcon from "../../assets/admin Images/delete-icon.svg";

const ConfirmDeletePopUp = ({
  icon,
  title,
  firstFunction,
  secondFunction,
  firstButtonName,
  secondButtonName,
  firstButtonClass,
  secondButtonClass,
  Message,
}) => {
  return (
    <div className="delete-popup">
      <div className="confirm-delete-box rounded d-flex flex-column align-items-center">
        <div className="title w-100 d-flex align-items-center justify-content-end">
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={firstFunction}
          ></button>
        </div>
        <img
          src={icon ? icon : deleteIcon}
          alt="icon"
          className="accept_icon"
        />
        <div className="text_container mt-2">
          <span className="title f-sem-bol">{title}</span>
          <p className="text f-reg mt-1">{Message}</p>
        </div>
        <div className="delete-box-buttons d-flex justify-content-evenly w-100 mt-2 f-med">
          <Button
            text={firstButtonName}
            className={`delete-box-btn rounded ${firstButtonClass}`}
            onClick={firstFunction}
          />
          <Button
            text={secondButtonName}
            className={`delete-box-btn rounded ${secondButtonClass}`}
            onClick={secondFunction}
          />
        </div>
      </div>
    </div>
  );
};

export default ConfirmDeletePopUp;
