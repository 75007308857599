import React, { useEffect, useState, memo, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import doubleArrow from "../assets/admin Images/2arrows.svg";
import { Tooltip } from "react-tooltip";
import _debounce from "lodash/debounce";
import {
  Loader,
  Pagination,
  RangeDropdown,
  SearchBar,
  NoRecordFound,
  ExcelBtn,
} from "../component/commonComponent";
import { exportToCSV } from "../utils/helper";
import { getEventsList } from "../redux/action";
import moment from "moment";
import EventTrackingFilter from "../component/commonComponent/EventTrackingFilter";

const EventTracking = () => {
  const [popUp, setPopup] = useState(false);
  const [order, setOrder] = useState(true);
  const [sort, setSort] = useState();
  const [error, setError] = useState();
  const [inputWord, setInputWord] = useState("");
  const [entriesLimit, setEntriesLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [isFilterBtnData, setIsFilterBtnData] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.UserAuth.loading);
  const EventsList = useSelector((state) => state.Interview.eventsList);

  const [filterStates, setFilterStates] = useState({
    from: "",
    to: "",
    user: "",
  });

  useEffect(() => {
    dispatch(
      getEventsList({
        sort: sort ? `${sort} ${order ? "asc" : "desc"}` : "",
        page: currentPage,
        pageSize: entriesLimit,
        search: inputWord,
        filterStates: filterStates,
      })
    );
    if (!showFilter) {
      setFilterStates("");
    }
  }, [
    dispatch,
    entriesLimit,
    currentPage,
    inputWord,
    sort,
    order,
    showFilter,
    filterStates,
  ]);

  const headerContent = [
    {
      name: "#",
      width: "w-15",
      sort: "code",
    },
    {
      name: "User",
      width: "w-35",
      sort: "user",
    },
    {
      name: "Employer Name",
      width: "w-35",
      sort: "employer",
    },
    {
      name: "Event",
      width: "w-35",
      sort: "event",
    },
    {
      name: "Action",
      width: "w-25",
      sort: "action",
    },
    {
      name: "IP",
      width: "w-35",
      sort: "ip",
    },
    {
      name: "Country",
      width: "w-25",
      sort: "country",
    },
    {
      name: "OS",
      width: "w-25",
      sort: "os",
    },
    {
      name: "Browser",
      width: "w-35",
      sort: "browser",
    },
    {
      name: "Referrer",
      width: "w-35",
      sort: "referrer",
    },
    {
      name: "Device",
      width: "w-25",
      sort: "device",
    },
    {
      name: "Date Time",
      width: "w-50",
      sort: "date time",
    },
  ];

  const handleEntriesLimit = (limit) => {
    setEntriesLimit(limit);
    setCurrentPage(1);
  };
  const filterBySearch = (e) => {
    const value = e.target.value;
    const isValidValue = /^[a-zA-Z0-9!.@#$%^&*_+\-={};':"\s*]*$/.test(value);
    if (isValidValue) {
      setInputWord(value);
      setCurrentPage(1);
    }
    // setInputWord(e.target.value);

    setCurrentPage(1);
  };
  const handleChangeSort = (sort) => {
    setSort(sort);
    setOrder(!order);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleExcelClick = _debounce(() => {
    dispatch(
      getEventsList({
        sort: sort ? `${sort} ${order ? "asc" : "desc"}` : "",
        page: "all",
        pageSize: "all",
        search: inputWord,
        filterStates: filterStates,
        callback: (list) => {
          const tmpArray = [];
          list &&
            list.data &&
            list.data.length > 0 &&
            list.data.forEach((element) => {
              let dataValue = {
                User: element?.user?.name ? element?.user?.name : "N/A",
                "Employer Name": element?.employer?.company?.name
                  ? element?.employer?.company?.name
                  : "N/A",
                Event: element?.event ? element?.event : "N/A",
                Action: element?.action ? element?.action : "N/A",
                Ip: element?.ip ? element?.ip : "N/A",
                Country: element?.country ? element?.country : "N/A",
                Domain: element?.domain ? element?.domain : "N/A",
                Os: element?.os ? element?.os : "N/A",
                Browser: element?.browser ? element?.browser : "N/A",
                Referrer: element?.referrer ? element?.referrer : "N/A",
                Device: element?.device ? element?.device : "N/A",
                "Date Time":
                  moment(element?.createdAt).format("Do MMM, YYYY h:mm A") ??
                  "N/A",
              };
              tmpArray.push(dataValue);
            });
          exportToCSV(tmpArray, "");
        },
      })
    );
  }, 300);

  return (
    <>
      {loading && <Loader />}
      <div className="page-container eep">
        <div className="mpl-title-container d-flex justify-content-between align-items-center rounded-3">
          <div className="text-theme-blue f-sem-bol f-20">Event Tracking</div>
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <RangeDropdown handleEntriesLimit={handleEntriesLimit} />
          <div className="d-flex align-items-center">
            <SearchBar
              onChange={(e) => filterBySearch(e)}
              value={inputWord}
              containerClass={"search-bar-container-type1"}
              inputClass={"search-input-type1"}
            />
            <EventTrackingFilter
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              setFilterStates={setFilterStates}
              filterStates={filterStates}
            />
            {EventsList?.data?.length > 0 ? (
              <ExcelBtn onClick={() => handleExcelClick()} />
            ) : (
              <ExcelBtn excelBtnClassName="excel-grey-btn cursor-na" />
            )}
          </div>
        </div>
        <div className="sp-list">
          <div className="header d-flex f-12 f-sem-bol rounded-3">
            {headerContent.map((data, index) => (
              <div className={`header-content ${data.width}`} key={index}>
                <span>{data.name}</span>
                {data.sort && (
                  <img
                    src={doubleArrow}
                    alt="double"
                    className="img-fluid"
                    onClick={() => handleChangeSort(data?.sort)}
                  />
                )}
              </div>
            ))}
          </div>
          <div className="row-container">
            {EventsList?.data?.length > 0 ? (
              EventsList?.data?.map((data, index) => (
                <div
                  className="support-row d-flex f-12 f-sem-bol rounded-3"
                  key={index}
                >
                  <div className="row-content w-15 no-wrap">
                    <span>{index + 1}</span>
                  </div>
                  <div className="row-content w-35">
                    <span className="t-clip word-break">
                      {data?.user?.name ? data?.user?.name : "N/A"}
                    </span>
                  </div>

                  <div className="row-content w-35 d-flex flex-wrap">
                    <span className="word-break">
                      {data?.employer?.company?.name
                        ? data?.employer?.company?.name
                        : "N/A"}
                    </span>
                  </div>

                  <div className="row-content w-35">
                    <span className="word-break">
                      {" "}
                      {data?.event ? data?.event : "N/A"}
                    </span>
                  </div>
                  <div className="row-content w-25">
                    <span className="word-break">
                      {" "}
                      {data?.action ? data?.action : "N/A"}
                    </span>
                  </div>
                  <div className="row-content w-35">
                    <span className="word-break">
                      {" "}
                      {data?.ip ? data?.ip : "N/A"}
                    </span>
                  </div>
                  <div className="row-content w-25">
                    <span className="word-break">
                      {data?.country ? data?.country : "N/A"}
                    </span>
                  </div>
                  <div className="row-content w-25">
                    <span className="word-break">
                      {data?.os ? data?.os : "N/A"}
                    </span>
                  </div>
                  <div className="row-content w-35">
                    <span className="word-break">
                      {data?.browser ? data?.browser : "N/A"}
                    </span>
                  </div>
                  <div className="row-content w-35 ">
                    <span
                      className="ellipsis-event-tracking word-break"
                      title={`${data?.referrer}`}
                    >
                      {data?.referrer ? data?.referrer : "N/A"}
                    </span>
                  </div>
                  <div className="row-content w-25">
                    <span>{data?.device ? data?.device : "N/A"}</span>
                  </div>
                  <div className="row-content w-50">
                    <span className="word-break">
                      {" "}
                      {moment(data?.createdAt).format(
                        "Do MMM, YYYY h:mm A"
                      )}{" "}
                    </span>
                  </div>
                </div>
              ))
            ) : (
              <NoRecordFound />
            )}
          </div>
        </div>
        {EventsList?.data?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalCount={EventsList?.total}
            pageSize={entriesLimit}
            onPageChange={handlePageChange}
          />
        )}
      </div>
    </>
  );
};

export default EventTracking;
