import React, { memo } from "react";
import { Link } from "react-router-dom";
import { downloadFile, localStorageEncrypt } from "../../utils/helper";
import downloadVector from "../../assets/admin Images/download-vector.svg";

const FeedbackButton = memo(
  ({ className, name, image, onClick, productSubDomain, data }) => {
    return (
      <>
        {data?.calendarInviteDetails &&
          (data?.feedbackId ? (
            <>
              <Link
                to="/feedback-report"
                target="_blank"
                rel="noopener noreferrer"
              >
                <a
                  className={`d-flex justify-content-evenly align-items-center ${className}`}
                  onClick={() => {
                    localStorageEncrypt("interviewData", JSON.stringify(data));
                  }}
                >
                  {name}&nbsp;
                  <img src={image} className="img-fluid" alt={name} />
                </a>
              </Link>
            </>
          ) : data?.feedback ? (
            <>
              <Link
                to="/feedback-report"
                target="_blank"
                rel="noopener noreferrer"
              >
                <a
                  className={`d-flex justify-content-evenly align-items-center ${className}`}
                  onClick={() => {
                    localStorageEncrypt("interviewData", JSON.stringify(data));
                  }}
                >
                  {name}&nbsp;
                  <img src={image} className="img-fluid" alt={name} />
                </a>
              </Link>
            </>
          ) : data?.calendarInviteDetails?.feedback_url ? (
            <>
              <a
                href={data?.calendarInviteDetails?.feedback_url}
                target="_blank"
                rel="noreferrer"
                className={`d-flex justify-content-evenly align-items-center ${className}`}
              >
                {name}&nbsp;
                <img src={image} className="img-fluid" alt={name} />
              </a>
            </>
          ) : (
            <div className="d-flex text-align-center">
              No Report
              <br /> Available
            </div>
          ))}
        {data?.carfeedback && (
          <span
            onClick={() =>
              downloadFile(
                data?.carfeedback?.carFeedbackUrl,
                `${data?.carfeedback?.carFeedbackFilename}`
              )
            }
            className={`d-flex justify-content-evenly align-items-center download-btn`}
          >
            {"Download"}&nbsp;
            <img src={downloadVector} className="img-fluid" alt={name} />
          </span>
        )}
      </>
    );
  }
);

export default FeedbackButton;
