import {
  GET_CAREERS_LIST,
  GET_CAREERS_LIST_SUCCESS,
  GET_CAREERS_LIST_FAILURE,
  GET_CAREERS_POSITION,
  GET_CAREERS_POSITION_SUCCESS,
  GET_CAREERS_POSITION_FAILURE,
  CREATE_CAREERS_POSITION,
  CREATE_CAREERS_POSITION_SUCCESS,
  CREATE_CAREERS_POSITION_FAILURE,
  UPDATE_CAREERS_POSITION,
  UPDATE_CAREERS_POSITION_SUCCESS,
  UPDATE_CAREERS_POSITION_FAILURE,
  DELETE_CAREERS,
  DELETE_CAREERS_SUCCESS,
  DELETE_CAREERS_FAILURE,
  DELETE_CAREERS_LIST,
  DELETE_CAREERS_LIST_SUCCESS,
  DELETE_CAREERS_LIST_FAILURE,
} from "../action/types";

const INIT_STATE = {
  loading: false,
  careersList: null,
  careersPositionList: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    //CAREER
    case GET_CAREERS_LIST:
      return { ...state, loading: true };
    case GET_CAREERS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        careersList: action.payload,
      };
    case GET_CAREERS_LIST_FAILURE:
      return { ...state, loading: false };
    case GET_CAREERS_POSITION:
      return { ...state, loading: true };
    case GET_CAREERS_POSITION_SUCCESS:
      return {
        ...state,
        loading: false,
        careersPositionList: action.payload,
      };
    case GET_CAREERS_POSITION_FAILURE:
      return { ...state, loading: false };
    case CREATE_CAREERS_POSITION:
      return { ...state, loading: true };
    case CREATE_CAREERS_POSITION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CREATE_CAREERS_POSITION_FAILURE:
      return { ...state, loading: false };
    case UPDATE_CAREERS_POSITION:
      return { ...state, loading: true };
    case UPDATE_CAREERS_POSITION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_CAREERS_POSITION_FAILURE:
      return { ...state, loading: false };
    case DELETE_CAREERS:
      return { ...state, loading: true };
    case DELETE_CAREERS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_CAREERS_FAILURE:
      return { ...state, loading: false };
    case DELETE_CAREERS_LIST:
      return { ...state, loading: true };
    case DELETE_CAREERS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_CAREERS_LIST_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};
