import React, { memo } from "react";
import threeDot from "../../assets/admin Images/threedot.svg";

const CommonMenu = memo(({ options }) => {
  return (
    <div className="dropdown">
      <button
        className="common-menu-btn"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <img src={threeDot} alt="threeDot" className="threedot-img" />
      </button>
      <ul
        className="dropdown-menu shadow-6 threedot-menu rounded f-sem-bol"
        aria-labelledby="dropdownMenuButton1"
      >
        {options
          .filter((data) => data !== null)
          .map((data, index) => (
            <div
              key={index}
              className={`${
                data?.hasAccess
                  ? "threedot-menu-content"
                  : "disabled-threedot-menu-content"
              } ${data?.showRedOption && "text-theme-red"}`}
              onClick={() => {
                data?.action();
              }}
            >
              {data?.name}
            </div>
          ))}
      </ul>
    </div>
  );
});
export default CommonMenu;
