import React, { memo } from "react";

const ClientInfoCard = memo(
  ({
    companyName = "Incruiter",
    userName = "User",
    profilePic = "https://incruiter-media.s3.ap-south-1.amazonaws.com/client1/emailTemplates/file-1658493924237-289737689.jpeg",
    id = "1234567890",
  }) => {
    return (
      <div className="d-flex d-center">
        <div className="cic-info">
          <div className="cic-name f-sem-bol f-18">{companyName}</div>
          <div className="cic-person f-reg text-end f-12">{userName}</div>
          <div className="cic-person f-reg text-end f-12">{id}</div>
        </div>
        <div className="cic-profile-pic d-center f-sem-bol">
          <img src={profilePic} alt="company-profile-pic" className="cic-img" />
          {/* {companyName.charAt(0).toUpperCase()} */}
        </div>
      </div>
    );
  }
);

export default ClientInfoCard;
