import React, { useState } from "react";
import CreatableSelect from "react-select/creatable";
import { skillChecker } from "../../utils/helper";

const SkillsSelect = ({
  name,
  value,
  onChange,
  labelClass,
  inputClass,
  errorClass,
  isDisabled,
  error,
  label,
  isRequired,
  options,
  isMulti,
  placeholder,
  onMenuOpen,
  onInputChange,
}) => {
  const colorStyles = {
    control: (provided) => ({
      ...provided,
      border: "none",
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "1.2em !important",
    }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#8780ab",
      };
    },
  };
  return (
    <>
      {label && (
        <label htmlFor={name} className={`c-label ${labelClass}`}>
          {label} {isRequired && <span className="asterisk f-12">&#42;</span>}
        </label>
      )}
      <div className={`position-relative`}>
        <CreatableSelect
          className={`${inputClass} ${error ? "error" : ""}`}
          isMulti={isMulti}
          // classNamePrefix="select"
          isSearchable={true}
          options={options}
          name={name}
          isDisabled={isDisabled}
          value={value}
          onChange={onChange}
          createOptionPosition="first"
          placeholder={placeholder}
          maxMenuHeight={200}
          onMenuOpen={onMenuOpen}
          styles={colorStyles}
          onInputChange={onInputChange}
          menuPlacement="auto"
        />
      </div>
      {error && <div className={`invalid ${errorClass}`}>{error}</div>}
    </>
  );
};

export default SkillsSelect;
