import React, { memo } from "react";
import search from "../../assets/images/search-bar.svg";
import { capitalizeAll } from "../../utils/helper";

const SearchBar = memo(
  ({ value, onChange, containerClass, inputClass, placeHolderText, name }) => {
    return (
      <div
        className={`d-flex align-items-center justify-content-between px-2 rounded bg-white ${containerClass} border`}
      >
        <input
          type="text"
          placeholder={"You're looking for..."}
          className={`f-12 f-med me-2 ${inputClass}`}
          value={value}
          onChange={onChange}
          name={name}
        />
        <img
          src={search}
          alt="search-bar"
          className="search-img img-fluid ms-2"
        />
      </div>
    );
  }
);

export default SearchBar;
