import {
  DASHBOARD_STATS,
  DASHBOARD_STATS_SUCCESS,
  DASHBOARD_STATS_FAILURE,
  GET_INTERVIEW,
  GET_INTERVIEW_SUCCESS,
  GET_INTERVIEW_FAILURE,
  GET_FEEDBACK_REPORT,
  GET_FEEDBACK_REPORT_SUCCESS,
  GET_FEEDBACK_REPORT_FAILURE,
  CREATE_OR_UPDATE_POSITION,
  CREATE_OR_UPDATE_POSITION_SUCCESS,
  CREATE_OR_UPDATE_POSITION_FAILURE,
  GET_CALENDAR_EVENT,
  GET_CALENDAR_EVENT_SUCCESS,
  GET_CALENDAR_EVENT_FAILURE,
  GET_POSITION,
  GET_POSITION_SUCCESS,
  GET_POSITION_FAILURE,
  DELETE_POSITION,
  DELETE_POSITION_SUCCESS,
  DELETE_POSITION_FAILURE,
  GET_POSITION_LIST,
  GET_POSITION_LIST_SUCCESS,
  GET_POSITION_LIST_FAILURE,
  UPDATE_POSITION_LIST,
  UPDATE_POSITION_LIST_SUCCESS,
  UPDATE_POSITION_LIST_FAILURE,
  GET_PREDEFINED_POSITION_LIST,
  GET_PREDEFINED_POSITION_LIST_FAILURE,
  GET_PREDEFINED_POSITION_LIST_SUCCESS,
  GET_PANELIST,
  GET_PANELIST_SUCCESS,
  GET_PANELIST_FAILURE,
  NOTIFY_PANELIST,
  NOTIFY_PANELIST_SUCCESS,
  NOTIFY_PANELIST_FAILURE,
  UPDATE_PANELIST,
  UPDATE_PANELIST_SUCCESS,
  UPDATE_PANELIST_FAILURE,
  GET_INTERVIEWER_LIST,
  GET_INTERVIEWER_LIST_FAILURE,
  GET_INTERVIEWER_LIST_SUCCESS,
  GET_INTERVIEWER_ACCOUNT_DETAILS,
  GET_INTERVIEWER_ACCOUNT_DETAILS_SUCCESS,
  GET_INTERVIEWER_ACCOUNT_DETAILS_FAILURE,
  ADD_OR_EDIT_INTERVIEWER_ACCOUNT_DETAILS,
  ADD_OR_EDIT_INTERVIEWER_ACCOUNT_DETAILS_SUCCESS,
  ADD_OR_EDIT_INTERVIEWER_ACCOUNT_DETAILS_FAILURE,
  GET_INTERVIEWER_BANK_NAME_LIST,
  GET_INTERVIEWER_BANK_NAME_LIST_SUCCESS,
  GET_INTERVIEWER_BANK_NAME_LIST_FAILURE,
  GET_INTERVIEWER_USER_INFORMATION,
  GET_INTERVIEWER_USER_INFORMATION_SUCCESS,
  GET_INTERVIEWER_USER_INFORMATION_FAILURE,
  UPDATE_INTERVIEWER_USER_INFORMATION,
  UPDATE_INTERVIEWER_USER_INFORMATION_SUCCESS,
  UPDATE_INTERVIEWER_USER_INFORMATION_FAILURE,
  UPDATE_INTERVIEWER_EDU_AND_SKILL_SET,
  UPDATE_INTERVIEWER_EDU_AND_SKILL_SET_SUCCESS,
  UPDATE_INTERVIEWER_EDU_AND_SKILL_SET_FAILURE,
  GET_INTERVIEWER_ADMIN_REMARKS_INFO,
  GET_INTERVIEWER_ADMIN_REMARKS_INFO_SUCCESS,
  GET_INTERVIEWER_ADMIN_REMARKS_INFO_FAILURE,
  GET_INCSERVE_POSITIONS,
  GET_INCSERVE_POSITIONS_SUCCESS,
  GET_INCSERVE_POSITIONS_FAILURE,
  GET_INCSERVE_REQUIREMENTS,
  GET_INCSERVE_REQUIREMENTS_SUCCESS,
  GET_INCSERVE_REQUIREMENTS_FAILURE,
  RESEND_EMAIL_VERIFICATION,
  RESEND_EMAIL_VERIFICATION_SUCCESS,
  RESEND_EMAIL_VERIFICATION_FAILURE,
  CHANGE_INTERVIEWER_STATUS,
  CHANGE_INTERVIEWER_STATUS_SUCCESS,
  CHANGE_INTERVIEWER_STATUS_FAILURE,
  ALLOW_INTERVIEWER,
  ALLOW_INTERVIEWER_SUCCESS,
  ALLOW_INTERVIEWER_FAILURE,
  VERIFY_INTERVIEWER,
  VERIFY_INTERVIEWER_SUCCESS,
  VERIFY_INTERVIEWER_FAILURE,
  CREATE_OR_UPDATE_INCSERVE_POSITIONS,
  CREATE_OR_UPDATE_INCSERVE_POSITIONS_SUCCESS,
  CREATE_OR_UPDATE_INCSERVE_POSITIONS_FAILURE,
  DELETE_INCSERVE_POSITIONS,
  DELETE_INCSERVE_POSITIONS_SUCCESS,
  DELETE_INCSERVE_POSITIONS_FAILURE,
  GET_POSITIONS_DROPDOWN,
  GET_POSITIONS_DROPDOWN_SUCCESS,
  GET_POSITIONS_DROPDOWN_FAILURE,
  GET_INCSERVE_INTERVIEWS,
  GET_INCSERVE_INTERVIEWS_SUCCESS,
  GET_INCSERVE_INTERVIEWS_FAILURE,
  GET_INCSERVE_INTERVIEWS_BY_ID,
  GET_INCSERVE_INTERVIEWS_BY_ID_SUCCESS,
  GET_INCSERVE_INTERVIEWS_BY_ID_FAILURE,
  CREATE_OR_UPDATE_INCSERVE_INTERVIEWS,
  CREATE_OR_UPDATE_INCSERVE_INTERVIEWS_SUCCESS,
  CREATE_OR_UPDATE_INCSERVE_INTERVIEWS_FAILURE,
  GET_INCSERVE_POSITIONS_BY_ID,
  GET_INCSERVE_POSITIONS_BY_ID_SUCCESS,
  GET_INCSERVE_POSITIONS_BY_ID_FAILURE,
  GET_EMPLOYER_INTERVIEW_DETAILS,
  GET_EMPLOYER_INTERVIEW_DETAILS_SUCCESS,
  GET_EMPLOYER_INTERVIEW_DETAILS_FAILURE,
  GET_EMPLOYER_POSITION_DETAILS,
  GET_EMPLOYER_POSITION_DETAILS_SUCCESS,
  GET_EMPLOYER_POSITION_DETAILS_FAILURE,
  GET_INCEXIT_INTERVIEW,
  GET_INCEXIT_INTERVIEW_SUCCESS,
  GET_INCEXIT_INTERVIEW_FAILURE,
  GET_ALL_INTERVIEWERS_SUCCESS,
  GET_ALL_INTERVIEWERS_FAILURE,
  GET_ALL_INTERVIEWERS,
  ACCEPT_INCEXIT_INTERVIEW,
  ACCEPT_INCEXIT_INTERVIEW_SUCCESS,
  ACCEPT_INCEXIT_INTERVIEW_FAILURE,
  PREVIOUSLY_ALLOTED_INTERVIEWER_LIST,
  PREVIOUSLY_ALLOTED_INTERVIEWER_LIST_SUCCESS,
  PREVIOUSLY_ALLOTED_INTERVIEWER_LIST_FAILURE,
  DESIGNATION_LIST,
  DESIGNATION_LIST_SUCCESS,
  DESIGNATION_LIST_FAILURE,
  UPDATE_INCEXIT_INTERVIEW,
  UPDATE_INCEXIT_INTERVIEW_SUCCESS,
  UPDATE_INCEXIT_INTERVIEW_FAILURE,
  CREATE_INCEXIT_INTERVIEW,
  CREATE_INCEXIT_INTERVIEW_SUCCESS,
  CREATE_INCEXIT_INTERVIEW_FAILURE,
  INCSERVE_APPROVE_FEEDBACK,
  INCSERVE_APPROVE_FEEDBACK_SUCCESS,
  INCSERVE_APPROVE_FEEDBACK_FAILURE,
  INCSERVE_CANCEL_INTERVIEW,
  INCSERVE_CANCEL_INTERVIEW_SUCCESS,
  INCSERVE_CANCEL_INTERVIEW_FAILURE,
  INCSERVE_UPDATE_PAY_PER_INTERVIEW,
  INCSERVE_UPDATE_PAY_PER_INTERVIEW_SUCCESS,
  INCSERVE_UPDATE_PAY_PER_INTERVIEW_FAILURE,
  INCSERVE_ACCEPT_INTERVIEW,
  INCSERVE_ACCEPT_INTERVIEW_SUCCESS,
  INCSERVE_ACCEPT_INTERVIEW_FAILURE,
  INCSERVE_RESCHEDULE_INTERVIEW,
  INCSERVE_RESCHEDULE_INTERVIEW_SUCCESS,
  INCSERVE_RESCHEDULE_INTERVIEW_FAILURE,
  GET_INCSERVE_INTERVIEWS_SEARCH_LIST,
  GET_INCSERVE_INTERVIEWS_SEARCH_LIST_SUCCESS,
  GET_INCSERVE_INTERVIEWS_SEARCH_LIST_FAILURE,
  CANCEL_INCEXIT_INTERVIEW,
  CANCEL_INCEXIT_INTERVIEW_SUCCESS,
  CANCEL_INCEXIT_INTERVIEW_FAILURE,
  UPDATE_INTERVIEWER_ADMIN_REMARKS_INFO,
  UPDATE_INTERVIEWER_ADMIN_REMARKS_INFO_SUCCESS,
  UPDATE_INTERVIEWER_ADMIN_REMARKS_INFO_FAILURE,
  GET_INTERVIEWER_RATINGS,
  GET_INTERVIEWER_RATINGS_SUCCESS,
  GET_INTERVIEWER_RATINGS_FAILURE,
  UPDATE_INTERVIEWER_RATINGS,
  UPDATE_INTERVIEWER_RATINGS_SUCCESS,
  UPDATE_INTERVIEWER_RATINGS_FAILURE,
  CALL_INTERVIEWER_AND_CANDIDATE,
  CALL_INTERVIEWER_AND_CANDIDATE_SUCCESS,
  CALL_INTERVIEWER_AND_CANDIDATE_FAILURE,
  GET_SIMILAR_WEBSITE_LIST,
  GET_SIMILAR_WEBSITE_LIST_SUCCESS,
  GET_SIMILAR_WEBSITE_LIST_FAILURE,
  CHANGE_PLATFORM,
  CHANGE_PLATFORM_SUCCESS,
  CHANGE_PLATFORM_FAILURE,
  ALLOT_AND_REALLOT_INTERVIEWER,
  ALLOT_AND_REALLOT_INTERVIEWER_SUCCESS,
  ALLOT_AND_REALLOT_INTERVIEWER_FAILURE,
  GET_INVOICE_DETAILS,
  GET_INVOICE_DETAILS_SUCCESS,
  GET_INVOICE_DETAILS_FAILURE,
  ALLOT_INCEXIT_INTERVIEWER,
  ALLOT_INCEXIT_INTERVIEWER_SUCCESS,
  ALLOT_INCEXIT_INTERVIEWER_FAILURE,
  SCHEDULE_INCEXIT_INTERVIEW,
  SCHEDULE_INCEXIT_INTERVIEW_SUCCESS,
  SCHEDULE_INCEXIT_INTERVIEW_FAILURE,
  GET_INCEXIT_INTERVIEWS_SEARCH_LIST,
  GET_INCEXIT_INTERVIEWS_SEARCH_LIST_SUCCESS,
  GET_INCEXIT_INTERVIEWS_SEARCH_LIST_FAILURE,
  BULK_INCEXIT_INTERVIEW,
  BULK_INCEXIT_INTERVIEW_SUCCESS,
  BULK_INCEXIT_INTERVIEW_FAILURE,
  ACCEPT_BULK_INCEXIT_INTERVIEW,
  ACCEPT_BULK_INCEXIT_INTERVIEW_SUCCESS,
  ACCEPT_BULK_INCEXIT_INTERVIEW_FAILURE,
  INCSERVE_RESCHEDULED_ACCEPT_REQUEST,
  INCSERVE_RESCHEDULED_ACCEPT_REQUEST_SUCCESS,
  INCSERVE_RESCHEDULED_ACCEPT_REQUEST_FAILURE,
  INCSERVE_POST_REVIEW_REQUEST,
  INCSERVE_POST_REVIEW_REQUEST_SUCCESS,
  INCSERVE_POST_REVIEW_REQUEST_FAILURE,
  INCSERVE_GIVE_FEEDBACK,
  INCSERVE_GIVE_FEEDBACK_SUCCESS,
  INCSERVE_GIVE_FEEDBACK_FAILURE,
  GET_INCSERVE_SINGLE_DELETE_LIST,
  GET_INCSERVE_SINGLE_DELETE_LIST_SUCCESS,
  GET_INCSERVE_SINGLE_DELETE_LIST_FAILURE,
  GET_INCSERVE_BULK_DELETE_LIST,
  GET_INCSERVE_BULK_DELETE_LIST_SUCCESS,
  GET_INCSERVE_BULK_DELETE_LIST_FAILURE,
  BULK_INCSERVE_REQUIREMENT,
  BULK_INCSERVE_REQUIREMENT_SUCCESS,
  BULK_INCSERVE_REQUIREMENT_FAILURE,
  ACCEPT_BULK_INCSERVE_REQUIREMENT,
  ACCEPT_BULK_INCSERVE_REQUIREMENT_SUCCESS,
  ACCEPT_BULK_INCSERVE_REQUIREMENT_FAILURE,
  ADD_INCEXIT_FEEDBACK,
  ADD_INCEXIT_FEEDBACK_SUCCESS,
  ADD_INCEXIT_FEEDBACK_FAILURE,
  CLOSE_BULK_INCEXIT_INTERVIEW,
  CLOSE_BULK_INCEXIT_INTERVIEW_SUCCESS,
  CLOSE_BULK_INCEXIT_INTERVIEW_FAILURE,
  RESCHEDULE_INCEXIT_INTERVIEW,
  RESCHEDULE_INCEXIT_INTERVIEW_SUCCESS,
  RESCHEDULE_INCEXIT_INTERVIEW_FAILURE,
  CLOSE_BULK_INCSERVE_REQUIREMENT,
  CLOSE_BULK_INCSERVE_REQUIREMENT_SUCCESS,
  CLOSE_BULK_INCSERVE_REQUIREMENT_FAILURE,
  ADD_UPDATE_BULK_INCSERVE_REQUIREMENT,
  ADD_UPDATE_BULK_INCSERVE_REQUIREMENT_SUCCESS,
  ADD_UPDATE_BULK_INCSERVE_REQUIREMENT_FAILURE,
  UPDATE_BULK_INCEXIT_INTERVIEW,
  UPDATE_BULK_INCEXIT_INTERVIEW_SUCCESS,
  UPDATE_BULK_INCEXIT_INTERVIEW_FAILURE,
  MAPPED_INTERVIEWERS,
  MAPPED_INTERVIEWERS_SUCCESS,
  MAPPED_INTERVIEWERS_FAILURE,
  APPROVE_INCEXIT_FEEDBACK,
  APPROVE_INCEXIT_FEEDBACK_SUCCESS,
  APPROVE_INCEXIT_FEEDBACK_FAILURE,
  UPDATE_INTERVIEW_FEEDBACK_RATING,
  UPDATE_INTERVIEW_FEEDBACK_RATING_FAILURE,
  UPDATE_INTERVIEW_FEEDBACK_RATING_SUCCESS,
  RESPOND_RESCHEDULE_INCEXIT_INTERVIEW,
  RESPOND_RESCHEDULE_INCEXIT_INTERVIEW_SUCCESS,
  RESPOND_RESCHEDULE_INCEXIT_INTERVIEW_FAILURE,
  INCSERVE_INTERVIEWER_DETAILS,
  INCSERVE_INTERVIEWER_DETAILS_SUCCESS,
  INCSERVE_INTERVIEWER_DETAILS_FAILURE,
  INCSERVE_CALENDAR_INVITE,
  INCSERVE_CALENDAR_INVITE_SUCCESS,
  INCSERVE_CALENDAR_INVITE_FAILURE,
  INCEXIT_CALENDAR_INVITE,
  INCEXIT_CALENDAR_INVITE_SUCCESS,
  INCEXIT_CALENDAR_INVITE_FAILURE,
  INCSERVE_REQUEST_FOR_RESCHEDULED,
  INCSERVE_REQUEST_FOR_RESCHEDULED_SUCCESS,
  INCSERVE_REQUEST_FOR_RESCHEDULED_FAILURE,
  ADD_CUSTOM_FILE,
  ADD_CUSTOM_FILE_SUCCESS,
  ADD_CUSTOM_FILE_FAILURE,
  GET_INTERVIEWS_COUNT,
  GET_INTERVIEWS_COUNT_SUCCESS,
  GET_INTERVIEWS_COUNT_FAILURE,
  GET_INVOICE_DETAILS_BY_MONTH,
  GET_INVOICE_DETAILS_BY_MONTH_SUCCESS,
  GET_INVOICE_DETAILS_BY_MONTH_FAILURE,
  GET_INTERVIEWER_AUDIO_LOGS,
  GET_INTERVIEWER_AUDIO_LOGS_SUCCESS,
  GET_INTERVIEWER_AUDIO_LOGS_FAILURE,
  CREATE_EMPLOYER_POSITION,
  CREATE_EMPLOYER_POSITION_SUCCESS,
  CREATE_EMPLOYER_POSITION_FAILURE,
  INCSERVE_INCEXIT_PREVIOUSLY_ALLOTED_INTERVIEWER_LIST,
  INCSERVE_INCEXIT_PREVIOUSLY_ALLOTED_INTERVIEWER_LIST_SUCCESS,
  INCSERVE_INCEXIT_PREVIOUSLY_ALLOTED_INTERVIEWER_LIST_FAILURE,
  GET_CALL_LOGS,
  GET_CALL_LOGS_SUCCESS,
  GET_CALL_LOGS_FAILURE,
  UPDATE_ADD_QUESTION,
  UPDATE_ADD_QUESTION_SUCCESS,
  UPDATE_ADD_QUESTION_FAILURE,
  UPDATE_INCSERVE_ADD_QUESTION,
  UPDATE_INCSERVE_ADD_QUESTION_SUCCESS,
  UPDATE_INCSERVE_ADD_QUESTION_FAILURE,
  GET_INTERVIEWER_CALL_LOGS,
  GET_INTERVIEWER_CALL_LOGS_SUCCESS,
  GET_INTERVIEWER_CALL_LOGS_FAILURE,
  VERIFY_LINKEDIN_URL,
  VERIFY_LINKEDIN_URL_SUCCESS,
  VERIFY_LINKEDIN_URL_FAILURE,
  SEND_CONVERSION_REPORT,
  SEND_CONVERSION_REPORT_SUCCESS,
  SEND_CONVERSION_REPORT_FAILURE,
  GET_INCBOT_INTERVIEWS_SEARCH_LIST,
  GET_INCBOT_INTERVIEWS_SEARCH_LIST_SUCCESS,
  GET_INCBOT_INTERVIEWS_SEARCH_LIST_FAILURE,
  GET_INCBOT_POSITIONS,
  GET_INCBOT_POSITIONS_SUCCESS,
  GET_INCBOT_POSITIONS_FAILURE,
  GET_INCBOT_INTERVIEWS,
  GET_INCBOT_INTERVIEWS_SUCCESS,
  GET_INCBOT_INTERVIEWS_FAILURE,
  INTERVIEWER_ACCOUNT_DETAILS_LIST,
  INTERVIEWER_ACCOUNT_DETAILS_LIST_SUCCESS,
  INTERVIEWER_ACCOUNT_DETAILS_LIST_FAILURE,
  GET_ACTIVE_INCBOT_EMPLOYER,
  GET_ACTIVE_INCBOT_EMPLOYER_SUCCESS,
  GET_ACTIVE_INCBOT_EMPLOYER_FAILURE,
  INCSERVE_HOLD_INTERVIEW,
  INCSERVE_HOLD_INTERVIEW_SUCCESS,
  INCSERVE_HOLD_INTERVIEW_FAILURE,
  UPDATE_CANDIDATE_RATINGS,
  UPDATE_CANDIDATE_RATINGS_SUCCESS,
  UPDATE_CANDIDATE_RATINGS_FAILURE,
  PUT_INTERVIEWER_MOCK_REMARKS_INFO,
  PUT_INTERVIEWER_MOCK_REMARKS_INFO_SUCCESS,
  PUT_INTERVIEWER_MOCK_REMARKS_INFO_FAILURE,
  GET_API_CALL_LOGS,
  GET_API_CALL_LOGS_SUCCESS,
  GET_API_CALL_LOGS_FAILURE,
  GET_MEETING_DETAILS_SUCCESS,
  GET_MEETING_DETAILS_FAILURE,
  GET_MEETING_DETAILS,
  UPDATE_API_CALL_LOGS,
  UPDATE_API_CALL_LOGS_SUCCESS,
  UPDATE_API_CALL_LOGS_FAILURE,
  POST_MEETING_DETAILS,
  POST_MEETING_DETAILS_SUCCESS,
  POST_MEETING_DETAILS_FAILURE,
  GET_EVENTS_LIST,
  GET_EVENTS_LIST_SUCCESS,
  GET_EVENTS_LIST_FAILURE,
  FEEDBACK_APPROVAL,
  FEEDBACK_APPROVAL_SUCCESS,
  FEEDBACK_APPROVAL_FAILURE,
  UPDATE_FEEDBACK,
  UPDATE_FEEDBACK_SUCCESS,
  UPDATE_FEEDBACK_FAILURE,
  FETCH_RESUME_SUMMARY,
  FETCH_RESUME_SUMMARY_SUCCESS,
  FETCH_RESUME_SUMMARY_FAILURE,
  FETCH_TRANSCRIPT,
  FETCH_TRANSCRIPT_SUCCESS,
  FETCH_TRANSCRIPT_FAILURE,
  FETCH_AI_COMMENT,
  FETCH_AI_COMMENT_SUCCESS,
  FETCH_AI_COMMENT_FAILURE,
  FETCH_FINAL_REMARKS,
  FETCH_FINAL_REMARKS_SUCCESS,
  FETCH_FINAL_REMARKS_FAILURE,
  FETCH_AI_COMMENT_FOR_CODE,
  FETCH_AI_COMMENT_FOR_CODE_SUCCESS,
  FETCH_AI_COMMENT_FOR_CODE_FAILURE,
  SET_NETWORK_DISCONNECTED,
  SET_NETWORK_DISCONNECTED_SUCCESS,
  SET_NETWORK_DISCONNECTED_FAILURE,
} from "./types";

// INCSERVE INTERVIEWER LIST
export const feedbackApproval = (payload) => ({
  type: FEEDBACK_APPROVAL,
  payload,
});
export const feedbackApprovalSuccess = () => ({
  type: FEEDBACK_APPROVAL_SUCCESS,
});
export const feedbackApprovalFailure = () => ({
  type: FEEDBACK_APPROVAL_FAILURE,
});

export const createEmployerPosition = (payload) => ({
  type: CREATE_EMPLOYER_POSITION,
  payload,
});
export const createEmployerPositionSuccess = () => ({
  type: CREATE_EMPLOYER_POSITION_SUCCESS,
});
export const createEmployerPositionFailure = () => ({
  type: CREATE_EMPLOYER_POSITION_FAILURE,
});

export const verifyInterviewer = (payload) => ({
  type: VERIFY_INTERVIEWER,
  payload,
});
export const verifyInterviewerSuccess = () => ({
  type: VERIFY_INTERVIEWER_SUCCESS,
});
export const verifyInterviewerFailure = () => ({
  type: VERIFY_INTERVIEWER_FAILURE,
});

export const allowInterviewer = (payload) => ({
  type: ALLOW_INTERVIEWER,
  payload,
});
export const allowInterviewerSuccess = () => ({
  type: ALLOW_INTERVIEWER_SUCCESS,
});
export const allowInterviewerFailure = () => ({
  type: ALLOW_INTERVIEWER_FAILURE,
});

export const changePlatform = (payload) => ({
  type: CHANGE_PLATFORM,
  payload,
});
export const changePlatformSuccess = (payload) => ({
  type: CHANGE_PLATFORM_SUCCESS,
  payload,
});
export const changePlatformFailure = (payload) => ({
  type: CHANGE_PLATFORM_FAILURE,
  payload,
});

export const callInterviewerAndCandidate = (payload) => ({
  type: CALL_INTERVIEWER_AND_CANDIDATE,
  payload,
});
export const callInterviewerAndCandidateSuccess = () => ({
  type: CALL_INTERVIEWER_AND_CANDIDATE_SUCCESS,
});
export const callInterviewerAndCandidateFailure = () => ({
  type: CALL_INTERVIEWER_AND_CANDIDATE_FAILURE,
});
export const changeInterviewerStatus = (payload) => ({
  type: CHANGE_INTERVIEWER_STATUS,
  payload,
});
export const changeInterviewerStatusSuccess = () => ({
  type: CHANGE_INTERVIEWER_STATUS_SUCCESS,
});
export const changeInterviewerStatusFailure = () => ({
  type: CHANGE_INTERVIEWER_STATUS_FAILURE,
});

export const updateCandidateRatings = (payload) => ({
  type: UPDATE_CANDIDATE_RATINGS,
  payload,
});
export const updateCandidateRatingsSuccess = () => ({
  type: UPDATE_CANDIDATE_RATINGS_SUCCESS,
});
export const updateCandidateRatingsFailure = () => ({
  type: UPDATE_CANDIDATE_RATINGS_FAILURE,
});

// INCSERVE POSITIONS

export const getIncservePositions = (payload) => ({
  type: GET_INCSERVE_POSITIONS,
  payload,
});
export const getIncservePositionsSuccess = (payload) => ({
  type: GET_INCSERVE_POSITIONS_SUCCESS,
  payload,
});
export const getIncservePositionsFailure = () => ({
  type: GET_INCSERVE_POSITIONS_FAILURE,
});

// INCBOT POSITIONS

export const getIncbotPositions = (payload) => ({
  type: GET_INCBOT_POSITIONS,
  payload,
});
export const getIncbotPositionsSuccess = (payload) => ({
  type: GET_INCBOT_POSITIONS_SUCCESS,
  payload,
});
export const getIncbotPositionsFailure = () => ({
  type: GET_INCBOT_POSITIONS_FAILURE,
});

export const getActiveIncbotEmployers = (payload) => ({
  type: GET_ACTIVE_INCBOT_EMPLOYER,
  payload,
});
export const getActiveIncbotEmployersSuccess = (payload) => ({
  type: GET_ACTIVE_INCBOT_EMPLOYER_SUCCESS,
  payload,
});
export const getActiveIncbotEmployersFailure = () => ({
  type: GET_ACTIVE_INCBOT_EMPLOYER_FAILURE,
});

// INCBOT Interviews

export const getIncbotInterviews = (payload) => ({
  type: GET_INCBOT_INTERVIEWS,
  payload,
});
export const getIncbotInterviewsSuccess = (payload) => ({
  type: GET_INCBOT_INTERVIEWS_SUCCESS,
  payload,
});
export const getIncbotInterviewsFailure = () => ({
  type: GET_INCBOT_INTERVIEWS_FAILURE,
});

export const createOrUpdateIncservePosition = (payload) => ({
  type: CREATE_OR_UPDATE_INCSERVE_POSITIONS,
  payload,
});
export const createOrUpdateIncservePositionSuccess = () => ({
  type: CREATE_OR_UPDATE_INCSERVE_POSITIONS_SUCCESS,
});
export const createOrUpdateIncservePositionFailure = () => ({
  type: CREATE_OR_UPDATE_INCSERVE_POSITIONS_FAILURE,
});

export const mappedInterviewers = (payload) => ({
  type: MAPPED_INTERVIEWERS,
  payload,
});
export const mappedInterviewersSuccess = () => ({
  type: MAPPED_INTERVIEWERS_SUCCESS,
});
export const mappedInterviewersFailure = () => ({
  type: MAPPED_INTERVIEWERS_FAILURE,
});

export const deleteIncservePosition = (payload) => ({
  type: DELETE_INCSERVE_POSITIONS,
  payload,
});
export const deleteIncservePositionSuccess = () => ({
  type: DELETE_INCSERVE_POSITIONS_SUCCESS,
});
export const deleteIncservePositionFailure = () => ({
  type: DELETE_INCSERVE_POSITIONS_FAILURE,
});

export const getIncservePositionById = (payload) => ({
  type: GET_INCSERVE_POSITIONS_BY_ID,
  payload,
});
export const getIncservePositionByIdSuccess = (payload) => ({
  type: GET_INCSERVE_POSITIONS_BY_ID_SUCCESS,
  payload,
});
export const getIncservePositionByIdFailure = () => ({
  type: GET_INCSERVE_POSITIONS_BY_ID_FAILURE,
});

// INCSERVE REQUIREMENTS

export const getIncserveRequirements = (payload) => ({
  type: GET_INCSERVE_REQUIREMENTS,
  payload,
});
export const getIncserveRequirementsSuccess = (payload) => ({
  type: GET_INCSERVE_REQUIREMENTS_SUCCESS,
  payload,
});
export const getIncserveRequirementsFailure = () => ({
  type: GET_INCSERVE_REQUIREMENTS_FAILURE,
});

export const getIncserveInterviewersDetails = (payload) => ({
  type: INCSERVE_INTERVIEWER_DETAILS,
  payload,
});
export const getIncserveInterviewersDetailsSuccess = (payload) => ({
  type: INCSERVE_INTERVIEWER_DETAILS_SUCCESS,
  payload,
});
export const getIncserveInterviewersDetailsFailure = () => ({
  type: INCSERVE_INTERVIEWER_DETAILS_FAILURE,
});

export const getIncserveSingleDeleteRequirement = (payload) => ({
  type: GET_INCSERVE_SINGLE_DELETE_LIST,
  payload,
});
export const getIncserveSingleDeleteRequirementSuccess = (payload) => ({
  type: GET_INCSERVE_SINGLE_DELETE_LIST_SUCCESS,
  payload,
});
export const getIncserveSingleDeleteRequirementFailure = () => ({
  type: GET_INCSERVE_SINGLE_DELETE_LIST_FAILURE,
});

export const getIncserveBulkDeleteRequirement = (payload) => ({
  type: GET_INCSERVE_BULK_DELETE_LIST,
  payload,
});
export const getIncserveBulkDeleteRequirementSuccess = (payload) => ({
  type: GET_INCSERVE_BULK_DELETE_LIST_SUCCESS,
  payload,
});
export const getIncserveBulkDeleteRequirementFailure = () => ({
  type: GET_INCSERVE_BULK_DELETE_LIST_FAILURE,
});

export const incserveRescheduleRequest = (payload) => ({
  type: INCSERVE_RESCHEDULED_ACCEPT_REQUEST,
  payload,
});
export const incserveRescheduleRequestSuccess = (payload) => ({
  type: INCSERVE_RESCHEDULED_ACCEPT_REQUEST_SUCCESS,
  payload,
});
export const incserveRescheduleRequestFailure = () => ({
  type: INCSERVE_RESCHEDULED_ACCEPT_REQUEST_FAILURE,
});

export const incserveRequestForRescheduled = (payload) => ({
  type: INCSERVE_REQUEST_FOR_RESCHEDULED,
  payload,
});
export const incserveRequestForRescheduledSuccess = (payload) => ({
  type: INCSERVE_REQUEST_FOR_RESCHEDULED_SUCCESS,
  payload,
});
export const incserveRequestForRescheduledFailure = () => ({
  type: INCSERVE_REQUEST_FOR_RESCHEDULED_FAILURE,
});

export const incserveInterviewFeedbackRatingRequest = (payload) => ({
  type: UPDATE_INTERVIEW_FEEDBACK_RATING,
  payload,
});
export const incserveInterviewFeedbackRatingRequestSuccess = (payload) => ({
  type: UPDATE_INTERVIEW_FEEDBACK_RATING_SUCCESS,
  payload,
});
export const incserveInterviewFeedbackRatingRequestFailure = () => ({
  type: UPDATE_INTERVIEW_FEEDBACK_RATING_FAILURE,
});

export const incserveApproveFeedback = (payload) => ({
  type: INCSERVE_APPROVE_FEEDBACK,
  payload,
});
export const incserveApproveFeedbackSuccess = (payload) => ({
  type: INCSERVE_APPROVE_FEEDBACK_SUCCESS,
  payload,
});
export const incserveApproveFeedbackFailure = () => ({
  type: INCSERVE_APPROVE_FEEDBACK_FAILURE,
});

export const incserveGiveFeedback = (payload) => ({
  type: INCSERVE_GIVE_FEEDBACK,
  payload,
});
export const incserveGiveFeedbackSuccess = (payload) => ({
  type: INCSERVE_GIVE_FEEDBACK_SUCCESS,
  payload,
});
export const incserveGiveFeedbackFailure = () => ({
  type: INCSERVE_GIVE_FEEDBACK_FAILURE,
});

export const incservePostReviewRequest = (payload) => ({
  type: INCSERVE_POST_REVIEW_REQUEST,
  payload,
});
export const incservePostReviewRequestSuccess = (payload) => ({
  type: INCSERVE_POST_REVIEW_REQUEST_SUCCESS,
  payload,
});
export const incservePostReviewRequestFailure = () => ({
  type: INCSERVE_POST_REVIEW_REQUEST_FAILURE,
});

export const incserveCancelInterview = (payload) => ({
  type: INCSERVE_CANCEL_INTERVIEW,
  payload,
});
export const incserveCancelInterviewSuccess = (payload) => ({
  type: INCSERVE_CANCEL_INTERVIEW_SUCCESS,
  payload,
});
export const incserveCancelInterviewFailure = () => ({
  type: INCSERVE_CANCEL_INTERVIEW_FAILURE,
});

export const incserveHoldInterview = (payload) => ({
  type: INCSERVE_HOLD_INTERVIEW,
  payload,
});
export const incserveHoldInterviewSuccess = (payload) => ({
  type: INCSERVE_HOLD_INTERVIEW_SUCCESS,
  payload,
});
export const incserveHoldInterviewFailure = () => ({
  type: INCSERVE_HOLD_INTERVIEW_FAILURE,
});

export const incserveRescheduleInterview = (payload) => ({
  type: INCSERVE_RESCHEDULE_INTERVIEW,
  payload,
});
export const incserveRescheduleInterviewSuccess = (payload) => ({
  type: INCSERVE_RESCHEDULE_INTERVIEW_SUCCESS,
  payload,
});
export const incserveRescheduleInterviewFailure = () => ({
  type: INCSERVE_RESCHEDULE_INTERVIEW_FAILURE,
});

export const incserveUpdatePayPerInterview = (payload) => ({
  type: INCSERVE_UPDATE_PAY_PER_INTERVIEW,
  payload,
});
export const incserveUpdatePayPerInterviewSuccess = (payload) => ({
  type: INCSERVE_UPDATE_PAY_PER_INTERVIEW_SUCCESS,
  payload,
});
export const incserveUpdatePayPerInterviewFailure = () => ({
  type: INCSERVE_UPDATE_PAY_PER_INTERVIEW_FAILURE,
});

export const incserveAcceptInterview = (payload) => ({
  type: INCSERVE_ACCEPT_INTERVIEW,
  payload,
});
export const incserveAcceptInterviewSuccess = (payload) => ({
  type: INCSERVE_ACCEPT_INTERVIEW_SUCCESS,
  payload,
});
export const incserveAcceptInterviewFailure = () => ({
  type: INCSERVE_ACCEPT_INTERVIEW_FAILURE,
});

export const incserveAllotAndReallotInterviewer = (payload) => ({
  type: ALLOT_AND_REALLOT_INTERVIEWER,
  payload,
});
export const incserveAllotAndReallotInterviewerSuccess = (payload) => ({
  type: ALLOT_AND_REALLOT_INTERVIEWER_SUCCESS,
  payload,
});
export const incserveAllotAndReallotInterviewerFailure = () => ({
  type: ALLOT_AND_REALLOT_INTERVIEWER_FAILURE,
});

//INCSREVE INTERVIEWS

export const getIncserveInterviews = (payload) => ({
  type: GET_INCSERVE_INTERVIEWS,
  payload,
});
export const getIncserveInterviewsSuccess = (payload) => ({
  type: GET_INCSERVE_INTERVIEWS_SUCCESS,
  payload,
});
export const getIncserveInterviewsFailure = () => ({
  type: GET_INCSERVE_INTERVIEWS_FAILURE,
});

export const getIncserveInterviewsSearchList = (payload) => ({
  type: GET_INCSERVE_INTERVIEWS_SEARCH_LIST,
  payload,
});
export const getIncserveInterviewsSearchListSuccess = (payload) => ({
  type: GET_INCSERVE_INTERVIEWS_SEARCH_LIST_SUCCESS,
  payload,
});
export const getIncserveInterviewsSearchListFailure = () => ({
  type: GET_INCSERVE_INTERVIEWS_SEARCH_LIST_FAILURE,
});

export const getIncbotInterviewsSearchList = (payload) => ({
  type: GET_INCBOT_INTERVIEWS_SEARCH_LIST,
  payload,
});
export const getIncbotInterviewsSearchListSuccess = (payload) => ({
  type: GET_INCBOT_INTERVIEWS_SEARCH_LIST_SUCCESS,
  payload,
});
export const getIncbotInterviewsSearchListFailure = () => ({
  type: GET_INCBOT_INTERVIEWS_SEARCH_LIST_FAILURE,
});

export const getIncexitInterviewsSearchList = (payload) => ({
  type: GET_INCEXIT_INTERVIEWS_SEARCH_LIST,
  payload,
});
export const getIncexitInterviewsSearchListSuccess = (payload) => ({
  type: GET_INCEXIT_INTERVIEWS_SEARCH_LIST_SUCCESS,
  payload,
});
export const getIncexitInterviewsSearchListFailure = () => ({
  type: GET_INCEXIT_INTERVIEWS_SEARCH_LIST_FAILURE,
});

export const getIncserveInterviewersDropdown = (payload) => ({
  type: GET_ALL_INTERVIEWERS,
  payload,
});
export const getIncserveInterviewersDropdownSuccess = (payload) => ({
  type: GET_ALL_INTERVIEWERS_SUCCESS,
  payload,
});
export const getIncserveInterviewersDropdownFailure = () => ({
  type: GET_ALL_INTERVIEWERS_FAILURE,
});

export const getIncserveInterviewById = (payload) => ({
  type: GET_INCSERVE_INTERVIEWS_BY_ID,
  payload,
});
export const getIncserveInterviewByIdSuccess = (payload) => ({
  type: GET_INCSERVE_INTERVIEWS_BY_ID_SUCCESS,
  payload,
});
export const getIncserveInterviewByIdFailure = () => ({
  type: GET_INCSERVE_INTERVIEWS_BY_ID_FAILURE,
});

export const createOrUpdateIncserveInterview = (payload) => ({
  type: CREATE_OR_UPDATE_INCSERVE_INTERVIEWS,
  payload,
});
export const createOrUpdateIncserveInterviewSuccess = (payload) => ({
  type: CREATE_OR_UPDATE_INCSERVE_INTERVIEWS_SUCCESS,
  payload,
});
export const createOrUpdateIncserveInterviewFailure = () => ({
  type: CREATE_OR_UPDATE_INCSERVE_INTERVIEWS_FAILURE,
});

//
export const resendEmailVerification = (payload) => ({
  type: RESEND_EMAIL_VERIFICATION,
  payload,
});
export const resendEmailVerificationSuccess = () => ({
  type: RESEND_EMAIL_VERIFICATION_SUCCESS,
});
export const resendEmailVerificationFailure = () => ({
  type: RESEND_EMAIL_VERIFICATION_FAILURE,
});

export const getInterviewerAdminRemarksInfo = (payload) => ({
  type: GET_INTERVIEWER_ADMIN_REMARKS_INFO,
  payload,
});
export const getInterviewerAdminRemarksInfoSuccess = (payload) => ({
  type: GET_INTERVIEWER_ADMIN_REMARKS_INFO_SUCCESS,
  payload,
});
export const getInterviewerAdminRemarksInfoFailure = () => ({
  type: GET_INTERVIEWER_ADMIN_REMARKS_INFO_FAILURE,
});

export const putInterviewerMockRemarksInfo = (payload) => ({
  type: PUT_INTERVIEWER_MOCK_REMARKS_INFO,
  payload,
});
export const putInterviewerMockRemarksInfoSuccess = (payload) => ({
  type: PUT_INTERVIEWER_MOCK_REMARKS_INFO_SUCCESS,
  payload,
});
export const putInterviewerMockRemarksInfoFailure = () => ({
  type: PUT_INTERVIEWER_MOCK_REMARKS_INFO_FAILURE,
});

export const getInterviewerAudioLogs = (payload) => ({
  type: GET_INTERVIEWER_AUDIO_LOGS,
  payload,
});
export const getInterviewerAudioLogsSuccess = (payload) => ({
  type: GET_INTERVIEWER_AUDIO_LOGS_SUCCESS,
  payload,
});
export const getInterviewerAudioLogsFailure = () => ({
  type: GET_INTERVIEWER_AUDIO_LOGS_FAILURE,
});

export const updateInterviewerAdminRemarksInfo = (payload) => ({
  type: UPDATE_INTERVIEWER_ADMIN_REMARKS_INFO,
  payload,
});
export const updateInterviewerAdminRemarksInfoSuccess = (payload) => ({
  type: UPDATE_INTERVIEWER_ADMIN_REMARKS_INFO_SUCCESS,
  payload,
});
export const updateInterviewerAdminRemarksInfoFailure = () => ({
  type: UPDATE_INTERVIEWER_ADMIN_REMARKS_INFO_FAILURE,
});

export const getInterviewerRatings = (payload) => ({
  type: GET_INTERVIEWER_RATINGS,
  payload,
});
export const getInterviewerRatingsSuccess = (payload) => ({
  type: GET_INTERVIEWER_RATINGS_SUCCESS,
  payload,
});
export const getInterviewerRatingsFailure = () => ({
  type: GET_INTERVIEWER_RATINGS_FAILURE,
});

export const updateInterviewerRatings = (payload) => ({
  type: UPDATE_INTERVIEWER_RATINGS,
  payload,
});
export const updateInterviewerRatingsSuccess = (payload) => ({
  type: UPDATE_INTERVIEWER_RATINGS_SUCCESS,
  payload,
});
export const updateInterviewerRatingsFailure = () => ({
  type: UPDATE_INTERVIEWER_RATINGS_FAILURE,
});

export const updateInterviewerEduAndSkillSet = (payload) => ({
  type: UPDATE_INTERVIEWER_EDU_AND_SKILL_SET,
  payload,
});
export const updateInterviewerEduAndSkillSetSuccess = (payload) => ({
  type: UPDATE_INTERVIEWER_EDU_AND_SKILL_SET_SUCCESS,
  payload,
});
export const updateInterviewerEduAndSkillSetFailure = () => ({
  type: UPDATE_INTERVIEWER_EDU_AND_SKILL_SET_FAILURE,
});

export const getInterviewerUserInformation = (payload) => ({
  type: GET_INTERVIEWER_USER_INFORMATION,
  payload,
});
export const getInterviewerUserInformationSuccess = (payload) => ({
  type: GET_INTERVIEWER_USER_INFORMATION_SUCCESS,
  payload,
});
export const getInterviewerUserInformationFailure = () => ({
  type: GET_INTERVIEWER_USER_INFORMATION_FAILURE,
});

export const getSimilarWebsiteList = (payload) => ({
  type: GET_SIMILAR_WEBSITE_LIST,
  payload,
});
export const getSimilarWebsiteListSuccess = (payload) => ({
  type: GET_SIMILAR_WEBSITE_LIST_SUCCESS,
  payload,
});
export const getSimilarWebsiteListFailure = () => ({
  type: GET_SIMILAR_WEBSITE_LIST_FAILURE,
});

export const bulkIncserveRequirement = (payload) => ({
  type: BULK_INCSERVE_REQUIREMENT,
  payload,
});
export const bulkIncserveRequirementSuccess = (payload) => ({
  type: BULK_INCSERVE_REQUIREMENT_SUCCESS,
  payload,
});
export const bulkIncserveRequirementFailure = (payload) => ({
  type: BULK_INCSERVE_REQUIREMENT_FAILURE,
  payload,
});

export const acceptBulkIncserveRequirements = (payload) => ({
  type: ACCEPT_BULK_INCSERVE_REQUIREMENT,
  payload,
});
export const acceptBulkIncserveRequirementsSuccess = (payload) => ({
  type: ACCEPT_BULK_INCSERVE_REQUIREMENT_SUCCESS,
  payload,
});
export const acceptBulkIncserveRequirementsFailure = (payload) => ({
  type: ACCEPT_BULK_INCSERVE_REQUIREMENT_FAILURE,
  payload,
});

export const addCustomFile = (payload) => ({
  type: ADD_CUSTOM_FILE,
  payload,
});
export const addCustomFileSuccess = (payload) => ({
  type: ADD_CUSTOM_FILE_SUCCESS,
  payload,
});
export const addCustomFileFailure = (payload) => ({
  type: ADD_CUSTOM_FILE_FAILURE,
  payload,
});

export const getInterviewsCount = (payload) => ({
  type: GET_INTERVIEWS_COUNT,
  payload,
});
export const getInterviewsCountSuccess = (payload) => ({
  type: GET_INTERVIEWS_COUNT_SUCCESS,
  payload,
});
export const getInterviewsCountFailure = (payload) => ({
  type: GET_INTERVIEWS_COUNT_FAILURE,
  payload,
});

export const addUpdateBulkIncserveRequirements = (payload) => ({
  type: ADD_UPDATE_BULK_INCSERVE_REQUIREMENT,
  payload,
});
export const addUpdateBulkIncserveRequirementsSuccess = (payload) => ({
  type: ADD_UPDATE_BULK_INCSERVE_REQUIREMENT_SUCCESS,
  payload,
});
export const addUpdateBulkIncserveRequirementsFailure = (payload) => ({
  type: ADD_UPDATE_BULK_INCSERVE_REQUIREMENT_FAILURE,
  payload,
});

export const closeBulkIncserveRequirements = (payload) => ({
  type: CLOSE_BULK_INCSERVE_REQUIREMENT,
  payload,
});
export const closeBulkIncserveRequirementsSuccess = (payload) => ({
  type: CLOSE_BULK_INCSERVE_REQUIREMENT_SUCCESS,
  payload,
});
export const closeBulkIncserveRequirementsFailure = (payload) => ({
  type: CLOSE_BULK_INCSERVE_REQUIREMENT_FAILURE,
  payload,
});

export const updateInterviewerUserInformation = (payload) => ({
  type: UPDATE_INTERVIEWER_USER_INFORMATION,
  payload,
});
export const updateInterviewerUserInformationSuccess = () => ({
  type: UPDATE_INTERVIEWER_USER_INFORMATION_SUCCESS,
});
export const updateInterviewerUserInformationFailure = () => ({
  type: UPDATE_INTERVIEWER_USER_INFORMATION_FAILURE,
});
export const getInterviewerBankNameList = (payload) => ({
  type: GET_INTERVIEWER_BANK_NAME_LIST,
  payload,
});
export const getInterviewerBankNameListSuccess = (payload) => ({
  type: GET_INTERVIEWER_BANK_NAME_LIST_SUCCESS,
  payload,
});
export const getInterviewerBankNameListFailure = (payload) => ({
  type: GET_INTERVIEWER_BANK_NAME_LIST_FAILURE,
  payload,
});

export const addOrEditInterviewerAccountDetail = (payload) => ({
  type: ADD_OR_EDIT_INTERVIEWER_ACCOUNT_DETAILS,
  payload,
});
export const addOrEditInterviewerAccountDetailSuccess = (payload) => ({
  type: ADD_OR_EDIT_INTERVIEWER_ACCOUNT_DETAILS_SUCCESS,
  payload,
});
export const addOrEditInterviewerAccountDetailFailure = () => ({
  type: ADD_OR_EDIT_INTERVIEWER_ACCOUNT_DETAILS_FAILURE,
});

export const getInterviewerAccountDetail = (payload) => ({
  type: GET_INTERVIEWER_ACCOUNT_DETAILS,
  payload,
});
export const getInterviewerAccountDetailSuccess = (payload) => ({
  type: GET_INTERVIEWER_ACCOUNT_DETAILS_SUCCESS,
  payload,
});

export const getInterviewerAccountDetailFailure = (payload) => ({
  type: GET_INTERVIEWER_ACCOUNT_DETAILS_FAILURE,
  payload,
});

export const getInterviewerList = (payload) => ({
  type: GET_INTERVIEWER_LIST,
  payload,
});
export const getInterviewerListSuccess = (payload) => ({
  type: GET_INTERVIEWER_LIST_SUCCESS,
  payload,
});
export const getInterviewerListFailure = (payload) => ({
  type: GET_INTERVIEWER_LIST_FAILURE,
  payload,
});

export const getInvoiceDetails = (payload) => ({
  type: GET_INVOICE_DETAILS,
  payload,
});
export const getInvoiceDetailsSuccess = (payload) => ({
  type: GET_INVOICE_DETAILS_SUCCESS,
  payload,
});
export const getInvoiceDetailsFailure = (payload) => ({
  type: GET_INVOICE_DETAILS_FAILURE,
  payload,
});

export const getInvoiceDetailsByMonth = (payload) => ({
  type: GET_INVOICE_DETAILS_BY_MONTH,
  payload,
});
export const getInvoiceDetailsByMonthSuccess = (payload) => ({
  type: GET_INVOICE_DETAILS_BY_MONTH_SUCCESS,
  payload,
});
export const getInvoiceDetailsByMonthFailure = (payload) => ({
  type: GET_INVOICE_DETAILS_BY_MONTH_FAILURE,
  payload,
});

// Active Interviewer Accounts
export const interviewAccountDetailsList = (payload) => ({
  type: INTERVIEWER_ACCOUNT_DETAILS_LIST,
  payload,
});
export const interviewAccountDetailsListSuccess = (payload) => ({
  type: INTERVIEWER_ACCOUNT_DETAILS_LIST_SUCCESS,
  payload,
});

export const interviewAccountDetailsListFailure = (payload) => ({
  type: INTERVIEWER_ACCOUNT_DETAILS_LIST_FAILURE,
  payload,
});

// INTERVIEWS
export const getDashboardStats = (payload) => ({
  type: DASHBOARD_STATS,
  payload,
});
export const getDashboardStatsSuccess = (payload) => ({
  type: DASHBOARD_STATS_SUCCESS,
  payload,
});
export const getDashboardStatsFailure = () => ({
  type: DASHBOARD_STATS_FAILURE,
});
export const getInterview = (payload) => ({
  type: GET_INTERVIEW,
  payload,
});
export const getInterviewSuccess = (payload) => ({
  type: GET_INTERVIEW_SUCCESS,
  payload,
});
export const getInterviewFailure = () => ({
  type: GET_INTERVIEW_FAILURE,
});
export const getFeedbackReport = (payload) => ({
  type: GET_FEEDBACK_REPORT,
  payload,
});
export const getFeedbackReportSuccess = (payload) => ({
  type: GET_FEEDBACK_REPORT_SUCCESS,
  payload,
});
export const getFeedbackReportFailure = (payload) => ({
  type: GET_FEEDBACK_REPORT_FAILURE,
  payload,
});

// PANELIST
export const getPanelist = (payload) => ({
  type: GET_PANELIST,
  payload,
});
export const getPanelistSuccess = (payload) => ({
  type: GET_PANELIST_SUCCESS,
  payload,
});
export const getPanelistFailure = (payload) => ({
  type: GET_PANELIST_FAILURE,
  payload,
});
export const notifyPanelist = (payload) => ({
  type: NOTIFY_PANELIST,
  payload,
});
export const notifyPanelistSuccess = (payload) => ({
  type: NOTIFY_PANELIST_SUCCESS,
  payload,
});
export const notifyPanelistFailure = (payload) => ({
  type: NOTIFY_PANELIST_FAILURE,
  payload,
});
export const updatePanelist = (payload) => ({
  type: UPDATE_PANELIST,
  payload,
});
export const updatePanelistSuccess = (payload) => ({
  type: UPDATE_PANELIST_SUCCESS,
  payload,
});
export const updatePanelistFailure = (payload) => ({
  type: UPDATE_PANELIST_FAILURE,
  payload,
});
// Positions
export const createOrUpdatePosition = (payload) => ({
  type: CREATE_OR_UPDATE_POSITION,
  payload,
});
export const createOrUpdatePositionSuccess = () => ({
  type: CREATE_OR_UPDATE_POSITION_SUCCESS,
});
export const createOrUpdatePositionFailure = () => ({
  type: CREATE_OR_UPDATE_POSITION_FAILURE,
});
export const getPosition = (payload) => ({
  type: GET_POSITION,
  payload,
});
export const getPositionSuccess = (payload) => ({
  type: GET_POSITION_SUCCESS,
  payload,
});
export const getPositionFailure = () => ({
  type: GET_POSITION_FAILURE,
});
export const deletePosition = (payload) => ({
  type: DELETE_POSITION,
  payload,
});
export const deletePositionSuccess = () => ({
  type: DELETE_POSITION_SUCCESS,
});
export const deletePositionFailure = () => ({
  type: DELETE_POSITION_FAILURE,
});
export const getPositionList = (payload) => ({
  type: GET_POSITION_LIST,
  payload,
});
export const getPositionListSuccess = (payload) => ({
  type: GET_POSITION_LIST_SUCCESS,
  payload,
});
export const getPositionListFailure = () => ({
  type: GET_POSITION_LIST_FAILURE,
});

export const getDropdownPositionList = (payload) => ({
  type: GET_POSITIONS_DROPDOWN,
  payload,
});
export const getDropdownPositionListSuccess = (payload) => ({
  type: GET_POSITIONS_DROPDOWN_SUCCESS,
  payload,
});
export const getDropdownPositionListFailure = () => ({
  type: GET_POSITIONS_DROPDOWN_FAILURE,
});

export const getPredefinedPositionList = (payload) => ({
  type: GET_PREDEFINED_POSITION_LIST,
  payload,
});
export const getPredefinedPositionListSuccess = (payload) => ({
  type: GET_PREDEFINED_POSITION_LIST_SUCCESS,
  payload,
});
export const getPredefinedPositionListFailure = () => ({
  type: GET_PREDEFINED_POSITION_LIST_FAILURE,
});
export const updatePositionList = (payload) => ({
  type: UPDATE_POSITION_LIST,
  payload,
});
export const updatePositionListSuccess = (payload) => ({
  type: UPDATE_POSITION_LIST_SUCCESS,
  payload,
});
export const updatePositionListFailure = () => ({
  type: UPDATE_POSITION_LIST_FAILURE,
});

export const getEmployerInterviewDetails = (payload) => ({
  type: GET_EMPLOYER_INTERVIEW_DETAILS,
  payload,
});

export const getEmployerInterviewDetailsSuccess = (payload) => ({
  type: GET_EMPLOYER_INTERVIEW_DETAILS_SUCCESS,
  payload,
});

export const getEmployerInterviewDetailsFailure = () => ({
  type: GET_EMPLOYER_INTERVIEW_DETAILS_FAILURE,
});

export const getEmployerPositionDetails = (payload) => ({
  type: GET_EMPLOYER_POSITION_DETAILS,
  payload,
});

export const getEmployerPositionDetailsSuccess = (payload) => ({
  type: GET_EMPLOYER_POSITION_DETAILS_SUCCESS,
  payload,
});

export const getEmployerPositionDetailsFailure = () => ({
  type: GET_EMPLOYER_POSITION_DETAILS_FAILURE,
});

// INCEXIT

export const getIncExitInterviews = (payload) => ({
  type: GET_INCEXIT_INTERVIEW,
  payload,
});

export const getIncExitInterviewsSuccess = (payload) => ({
  type: GET_INCEXIT_INTERVIEW_SUCCESS,
  payload,
});

export const getIncExitInterviewsFailure = () => ({
  type: GET_INCEXIT_INTERVIEW_FAILURE,
});

export const acceptIncExitInterviews = (payload) => ({
  type: ACCEPT_INCEXIT_INTERVIEW,
  payload,
});

export const acceptIncExitInterviewsSuccess = (payload) => ({
  type: ACCEPT_INCEXIT_INTERVIEW_SUCCESS,
  payload,
});

export const acceptIncExitInterviewsFailure = () => ({
  type: ACCEPT_INCEXIT_INTERVIEW_FAILURE,
});

export const previouslyAllotedInterviewerList = (payload) => ({
  type: PREVIOUSLY_ALLOTED_INTERVIEWER_LIST,
  payload,
});

export const previouslyAllotedInterviewerListSuccess = (payload) => ({
  type: PREVIOUSLY_ALLOTED_INTERVIEWER_LIST_SUCCESS,
  payload,
});

export const previouslyAllotedInterviewerListFailure = () => ({
  type: PREVIOUSLY_ALLOTED_INTERVIEWER_LIST_FAILURE,
});

export const incserveIncexitPreviouslyAllotedInterviewerList = (payload) => ({
  type: INCSERVE_INCEXIT_PREVIOUSLY_ALLOTED_INTERVIEWER_LIST,
  payload,
});

export const incserveIncexitPreviouslyAllotedInterviewerListSuccess = (
  payload
) => ({
  type: INCSERVE_INCEXIT_PREVIOUSLY_ALLOTED_INTERVIEWER_LIST_SUCCESS,
  payload,
});

export const incserveIncexitPreviouslyAllotedInterviewerListFailure = () => ({
  type: INCSERVE_INCEXIT_PREVIOUSLY_ALLOTED_INTERVIEWER_LIST_FAILURE,
});

export const getDesignationList = (payload) => ({
  type: DESIGNATION_LIST,
  payload,
});
export const getDesignationListSuccess = (payload) => ({
  type: DESIGNATION_LIST_SUCCESS,
  payload,
});
export const getDesignationListFailure = (payload) => ({
  type: DESIGNATION_LIST_FAILURE,
});

export const updateIncexitInterview = (payload) => ({
  type: UPDATE_INCEXIT_INTERVIEW,
  payload,
});
export const updateIncexitInterviewSucees = (payload) => ({
  type: UPDATE_INCEXIT_INTERVIEW_SUCCESS,
  payload,
});
export const updateIncexitInterviewFailure = (payload) => ({
  type: UPDATE_INCEXIT_INTERVIEW_FAILURE,
  payload,
});
export const getCallLogs = (payload) => ({
  type: GET_CALL_LOGS,
  payload,
});
export const getCallLogsSuccess = (payload) => ({
  type: GET_CALL_LOGS_SUCCESS,
  payload,
});
export const getCallLogsFailure = (payload) => ({
  type: GET_CALL_LOGS_FAILURE,
  payload,
});

export const getInterviewerCallLogs = (payload) => ({
  type: GET_INTERVIEWER_CALL_LOGS,
  payload,
});
export const getInterviewerCallLogsSuccess = (payload) => ({
  type: GET_INTERVIEWER_CALL_LOGS_SUCCESS,
  payload,
});
export const getInterviewerCallLogsFailure = (payload) => ({
  type: GET_INTERVIEWER_CALL_LOGS_FAILURE,
  payload,
});

export const createIncexitInterview = (payload) => ({
  type: CREATE_INCEXIT_INTERVIEW,
  payload,
});
export const createIncexitInterviewSucees = (payload) => ({
  type: CREATE_INCEXIT_INTERVIEW_SUCCESS,
  payload,
});
export const createIncexitInterviewFailure = (payload) => ({
  type: CREATE_INCEXIT_INTERVIEW_FAILURE,
  payload,
});
export const cancelIncexitInterview = (payload) => ({
  type: CANCEL_INCEXIT_INTERVIEW,
  payload,
});
export const cancelIncexitInterviewSuccess = (payload) => ({
  type: CANCEL_INCEXIT_INTERVIEW_SUCCESS,
  payload,
});
export const cancelIncexitInterviewFailure = (payload) => ({
  type: CANCEL_INCEXIT_INTERVIEW_FAILURE,
  payload,
});
export const allotIncexitInterviewer = (payload) => ({
  type: ALLOT_INCEXIT_INTERVIEWER,
  payload,
});
export const allotIncexitInterviewerSuccess = (payload) => ({
  type: ALLOT_INCEXIT_INTERVIEWER_SUCCESS,
  payload,
});
export const allotIncexitInterviewerFailure = (payload) => ({
  type: ALLOT_INCEXIT_INTERVIEWER_FAILURE,
  payload,
});

export const scheduleInterview = (payload) => ({
  type: SCHEDULE_INCEXIT_INTERVIEW,
  payload,
});
export const scheduleInterviewSuccess = (payload) => ({
  type: SCHEDULE_INCEXIT_INTERVIEW_SUCCESS,
  payload,
});
export const scheduleInterviewFailure = (payload) => ({
  type: SCHEDULE_INCEXIT_INTERVIEW_FAILURE,
  payload,
});
export const reScheduleInterview = (payload) => ({
  type: RESCHEDULE_INCEXIT_INTERVIEW,
  payload,
});
export const reScheduleInterviewSuccess = (payload) => ({
  type: RESCHEDULE_INCEXIT_INTERVIEW_SUCCESS,
  payload,
});
export const reScheduleInterviewFailure = (payload) => ({
  type: RESCHEDULE_INCEXIT_INTERVIEW_FAILURE,
  payload,
});

export const respondReScheduleInterview = (payload) => ({
  type: RESPOND_RESCHEDULE_INCEXIT_INTERVIEW,
  payload,
});
export const respondReScheduleInterviewSuccess = (payload) => ({
  type: RESPOND_RESCHEDULE_INCEXIT_INTERVIEW_SUCCESS,
  payload,
});
export const respondReScheduleInterviewFailure = (payload) => ({
  type: RESPOND_RESCHEDULE_INCEXIT_INTERVIEW_FAILURE,
  payload,
});

export const bulkIncExitInterview = (payload) => ({
  type: BULK_INCEXIT_INTERVIEW,
  payload,
});
export const bulkIncExitInterviewSuccess = (payload) => ({
  type: BULK_INCEXIT_INTERVIEW_SUCCESS,
  payload,
});
export const bulkIncExitInterviewFailure = (payload) => ({
  type: BULK_INCEXIT_INTERVIEW_FAILURE,
  payload,
});

export const updateBulkIncExitInterview = (payload) => ({
  type: UPDATE_BULK_INCEXIT_INTERVIEW,
  payload,
});
export const updateBulkIncExitInterviewSuccess = (payload) => ({
  type: UPDATE_BULK_INCEXIT_INTERVIEW_SUCCESS,
  payload,
});
export const updateBulkIncExitInterviewFailure = (payload) => ({
  type: UPDATE_BULK_INCEXIT_INTERVIEW_FAILURE,
  payload,
});

export const acceptBulkIncExitInterview = (payload) => ({
  type: ACCEPT_BULK_INCEXIT_INTERVIEW,
  payload,
});
export const acceptBulkIncExitInterviewSuccess = (payload) => ({
  type: ACCEPT_BULK_INCEXIT_INTERVIEW_SUCCESS,
  payload,
});
export const acceptBulkIncExitInterviewFailure = (payload) => ({
  type: ACCEPT_BULK_INCEXIT_INTERVIEW_FAILURE,
  payload,
});
export const closeBulkIncExitInterview = (payload) => ({
  type: CLOSE_BULK_INCEXIT_INTERVIEW,
  payload,
});
export const closeBulkIncExitInterviewSuccess = (payload) => ({
  type: CLOSE_BULK_INCEXIT_INTERVIEW_SUCCESS,
  payload,
});
export const closeBulkIncExitInterviewFailure = (payload) => ({
  type: CLOSE_BULK_INCEXIT_INTERVIEW_FAILURE,
  payload,
});
export const addIncexitFeddback = (payload) => ({
  type: ADD_INCEXIT_FEEDBACK,
  payload,
});
export const addIncexitFeddbackSuccess = (payload) => ({
  type: ADD_INCEXIT_FEEDBACK_SUCCESS,
  payload,
});
export const addIncexitFeddbackFailure = (payload) => ({
  type: ADD_INCEXIT_FEEDBACK_FAILURE,
  payload,
});
export const approveIncExitFeedback = (payload) => ({
  type: APPROVE_INCEXIT_FEEDBACK,
  payload,
});
export const approveIncExitFeedbackSuccess = (payload) => ({
  type: APPROVE_INCEXIT_FEEDBACK_SUCCESS,
  payload,
});
export const approveIncExitFeedbackFailure = (payload) => ({
  type: APPROVE_INCEXIT_FEEDBACK_FAILURE,
  payload,
});

export const incserveCalendarInvite = (payload) => ({
  type: INCSERVE_CALENDAR_INVITE,
  payload,
});
export const incserveCalendarInviteSuccess = () => ({
  type: INCSERVE_CALENDAR_INVITE_SUCCESS,
});
export const incserveCalendarInviteFailure = () => ({
  type: INCSERVE_CALENDAR_INVITE_FAILURE,
});

export const incexitCalendarInvite = (payload) => ({
  type: INCEXIT_CALENDAR_INVITE,
  payload,
});
export const incexitCalendarInviteSuccess = () => ({
  type: INCEXIT_CALENDAR_INVITE_SUCCESS,
});
export const incexitCalendarInviteFailure = () => ({
  type: INCEXIT_CALENDAR_INVITE_FAILURE,
});

export const getCalendarEvent = (payload) => ({
  type: GET_CALENDAR_EVENT,
  payload,
});
export const getCalendarEventSuccess = (payload) => ({
  type: GET_CALENDAR_EVENT_SUCCESS,
  payload,
});
export const getCalendarEventFailure = () => ({
  type: GET_CALENDAR_EVENT_FAILURE,
});

export const updateAddQuestion = (payload) => ({
  type: UPDATE_ADD_QUESTION,
  payload,
});
export const updateAddQuestionSuccess = (payload) => ({
  type: UPDATE_ADD_QUESTION_SUCCESS,
  payload,
});
export const updateAddQuestionFailure = () => ({
  type: UPDATE_ADD_QUESTION_FAILURE,
});

export const verifyLinkedinUrl = (payload) => ({
  type: VERIFY_LINKEDIN_URL,
  payload,
});
export const verifyLinkedinUrlSuccess = (payload) => ({
  type: VERIFY_LINKEDIN_URL_SUCCESS,
  payload,
});
export const verifyLinkedinUrlFailure = () => ({
  type: VERIFY_LINKEDIN_URL_FAILURE,
});

export const sendConversionReport = (payload) => ({
  type: SEND_CONVERSION_REPORT,
  payload,
});
export const sendConversionReportSuccess = (payload) => ({
  type: SEND_CONVERSION_REPORT_SUCCESS,
  payload,
});
export const sendConversionReportFailure = () => ({
  type: SEND_CONVERSION_REPORT_FAILURE,
});

export const updateIncserveAddQuestion = (payload) => ({
  type: UPDATE_INCSERVE_ADD_QUESTION,
  payload,
});
export const updateIncserveAddQuestionSuccess = (payload) => ({
  type: UPDATE_INCSERVE_ADD_QUESTION_SUCCESS,
  payload,
});
export const updateIncserveAddQuestionFailure = () => ({
  type: UPDATE_INCSERVE_ADD_QUESTION_FAILURE,
});

export const getApiCallLogs = (payload) => ({
  type: GET_API_CALL_LOGS,
  payload,
});
export const getApiCallLogsSuccess = (payload) => ({
  type: GET_API_CALL_LOGS_SUCCESS,
  payload,
});

export const getApiCallLogsFailure = () => ({
  type: GET_API_CALL_LOGS_FAILURE,
});

export const updateApiCallLogs = (payload) => ({
  type: UPDATE_API_CALL_LOGS,
  payload,
});
export const updateApiCallLogsSuccess = (payload) => ({
  type: UPDATE_API_CALL_LOGS_SUCCESS,
  payload,
});

export const updateApiCallLogsFailure = () => ({
  type: UPDATE_API_CALL_LOGS_FAILURE,
});

export const getMeetingDetails = (payload) => ({
  type: GET_MEETING_DETAILS,
  payload,
});
export const getMeetingDetailsSuccess = (payload) => ({
  type: GET_MEETING_DETAILS_SUCCESS,
  payload,
});
export const getMeetingDetailsFailure = () => ({
  type: GET_MEETING_DETAILS_FAILURE,
});

export const postMeetingDetails = (payload) => ({
  type: POST_MEETING_DETAILS,
  payload,
});
export const postMeetingDetailsSuccess = (payload) => ({
  type: POST_MEETING_DETAILS_SUCCESS,
  payload,
});
export const postMeetingDetailsFailure = () => ({
  type: POST_MEETING_DETAILS_FAILURE,
});

export const getEventsList = (payload) => ({
  type: GET_EVENTS_LIST,
  payload,
});
export const getEventsListSuccess = (payload) => ({
  type: GET_EVENTS_LIST_SUCCESS,
  payload,
});
export const getEventsListFailure = () => ({
  type: GET_EVENTS_LIST_FAILURE,
});

export const fetchResumeSummary = (payload) => ({
  type: FETCH_RESUME_SUMMARY,
  payload,
});

export const fetchResumeSummarySuccess = () => ({
  type: FETCH_RESUME_SUMMARY_SUCCESS,
});

export const fetchResumeSummaryFailure = () => ({
  type: FETCH_RESUME_SUMMARY_FAILURE,
});

export const fetchTranscript = (payload) => ({
  type: FETCH_TRANSCRIPT,
  payload,
});

export const fetchTranscriptSuccess = () => ({
  type: FETCH_TRANSCRIPT_SUCCESS,
});

export const fetchTranscriptFailure = () => ({
  type: FETCH_TRANSCRIPT_FAILURE,
});

export const fetchAiComment = (payload) => ({
  type: FETCH_AI_COMMENT,
  payload,
});

export const fetchAiCommentSuccess = () => ({
  type: FETCH_AI_COMMENT_SUCCESS,
});

export const fetchAiCommentFailure = () => ({
  type: FETCH_AI_COMMENT_FAILURE,
});

export const fetchAiCommentForCode = (payload) => ({
  type: FETCH_AI_COMMENT_FOR_CODE,
  payload,
});

export const fetchAiCommentForCodeSuccess = () => ({
  type: FETCH_AI_COMMENT_FOR_CODE_SUCCESS,
});

export const fetchAiCommentForCodeFailure = () => ({
  type: FETCH_AI_COMMENT_FOR_CODE_FAILURE,
});

export const fetchFinalRemarks = (payload) => ({
  type: FETCH_FINAL_REMARKS,
  payload,
});

export const fetchFinalRemarksSuccess = () => ({
  type: FETCH_FINAL_REMARKS_SUCCESS,
});

export const fetchFinalRemarksFailure = () => ({
  type: FETCH_FINAL_REMARKS_FAILURE,
});

export const updateFeedback = (payload) => ({
  type: UPDATE_FEEDBACK,
  payload,
});

export const updateFeedbackSuccess = () => ({
  type: UPDATE_FEEDBACK_SUCCESS,
});

export const updateFeedbackFailure = () => ({
  type: UPDATE_FEEDBACK_FAILURE,
});

export const setNetworkDisconnected = (payload) => ({
  type: SET_NETWORK_DISCONNECTED,
  payload,
});

export const setNetworkDisconnectedSuccess = () => ({
  type: SET_NETWORK_DISCONNECTED_SUCCESS,
});

export const setNetworkDisconnectedFailure = () => ({
  type: SET_NETWORK_DISCONNECTED_FAILURE,
});
