import React, { memo } from "react";

const Button = memo(
  ({
    title,
    type,
    className,
    onClick,
    isImage,
    image,
    text,
    disabled,
    style,
    imgStyle,
    label,
  }) => {
    return (
      <>
        <button
          title={title}
          type={type}
          className={`${className}`}
          onClick={onClick}
          disabled={disabled}
          style={style}
          aria-label={label}
        >
          {isImage && (
            <img
              src={image}
              className="img-fluid"
              alt={text}
              style={imgStyle}
            />
          )}
          {text && <span className="mx-1">{text}</span>}
        </button>
      </>
    );
  }
);

export default Button;
