import {
  GET_CAREERS_LIST,
  GET_CAREERS_LIST_SUCCESS,
  GET_CAREERS_LIST_FAILURE,
  GET_CAREERS_POSITION,
  GET_CAREERS_POSITION_SUCCESS,
  GET_CAREERS_POSITION_FAILURE,
  CREATE_CAREERS_POSITION,
  CREATE_CAREERS_POSITION_SUCCESS,
  CREATE_CAREERS_POSITION_FAILURE,
  UPDATE_CAREERS_POSITION,
  UPDATE_CAREERS_POSITION_SUCCESS,
  UPDATE_CAREERS_POSITION_FAILURE,
  DELETE_CAREERS,
  DELETE_CAREERS_SUCCESS,
  DELETE_CAREERS_FAILURE,
  DELETE_CAREERS_LIST,
  DELETE_CAREERS_LIST_SUCCESS,
  DELETE_CAREERS_LIST_FAILURE,
} from "./types";

// CAREER
export const getCareersList = (payload) => ({
  type: GET_CAREERS_LIST,
  payload,
});
export const getCareersListSuccess = (payload) => ({
  type: GET_CAREERS_LIST_SUCCESS,
  payload,
});
export const getCareersListFailure = () => ({
  type: GET_CAREERS_LIST_FAILURE,
});
export const getCareersPosition = (payload) => ({
  type: GET_CAREERS_POSITION,
  payload,
});
export const getCareersPositionSuccess = (payload) => ({
  type: GET_CAREERS_POSITION_SUCCESS,
  payload,
});
export const getCareersPositionFailure = () => ({
  type: GET_CAREERS_POSITION_FAILURE,
});
export const createCareersPosition = (payload) => ({
  type: CREATE_CAREERS_POSITION,
  payload,
});
export const createCareersPositionSuccess = () => ({
  type: CREATE_CAREERS_POSITION_SUCCESS,
});
export const createCareersPositionFailure = () => ({
  type: CREATE_CAREERS_POSITION_FAILURE,
});
export const updateCareersPosition = (payload) => ({
  type: UPDATE_CAREERS_POSITION,
  payload,
});
export const updateCareersPositionSuccess = () => ({
  type: UPDATE_CAREERS_POSITION_SUCCESS,
});
export const updateCareersPositionFailure = () => ({
  type: UPDATE_CAREERS_POSITION_FAILURE,
});
export const deleteCareers = (payload) => ({
  type: DELETE_CAREERS,
  payload,
});
export const deleteCareersSuccess = () => ({
  type: DELETE_CAREERS_SUCCESS,
});
export const deleteCareersFailure = () => ({
  type: DELETE_CAREERS_FAILURE,
});
export const deleteCareersList = (payload) => ({
  type: DELETE_CAREERS_LIST,
  payload,
});
export const deleteCareersListSuccess = () => ({
  type: DELETE_CAREERS_LIST_SUCCESS,
});
export const deleteCareersListFailure = () => ({
  type: DELETE_CAREERS_LIST_FAILURE,
});
