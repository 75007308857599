import { lazy } from "react";
// import { checkPermission } from "../utils/helper";
// import Tracker from "../container/Product/InterviewManage/InCServe/IncserveInterviewManage/Tracker";
import Tracker from "../component/IncServe/ManageInterviews/Tracker";
import { checkPermission, checkPermissions } from "../utils/helper";
import InterviewerInvoices from "../container/Product/IncServe/InterviewerInvoices";
import Meetings from "../container/Meetings";
import Countries from "../container/Locations/Countries";
import States from "../container/Locations/States";
import Cities from "../container/Locations/Cities";
import EventTracking from "../container/EventTracking";
// import { checkPermission } from "../utils/constant";

const Login = lazy(() => import("../container/login"));
const SubscriptionManage = lazy(() =>
  import("../container/EmployerList/SubscriptionManage")
);
const ResetPassword = lazy(() => import("../container/ResetPassword"));
const MainDashboard = lazy(() =>
  import("../container/Dashboard/MainDashboard")
);
const ProductDashboard = lazy(() =>
  import("../container/Dashboard/ProductDashboard")
);
const EmployerEmailPage = lazy(() =>
  import("../container/EmployerList/EmployerEmailPage")
);
const EmployerEmailPendingPage = lazy(() =>
  import("../container/EmployerList/EmployerEmailPendingPage")
);
const EmployerProfileManagement = lazy(() =>
  import("../container/EmployerList/EmployerProfileManagement")
);
const CompanyProfile = lazy(() =>
  import("../container/EmployerList/EmployerProfile/CompanyProfile")
);
const BulkRequirementsDetails = lazy(() =>
  import("../component/IncExit/BulkRequirementsDetails")
);
const InterviewCallRecords = lazy(() => import("../component/AudioLogs"));
const IncserveBulkRequirementsDetails = lazy(() =>
  import("../component/IncServe/ManageInterviews/BulkRequirementDetails")
);
const IncserveBulkRequirementsDetailsEdit = lazy(() =>
  import("../component/IncServe/ManageInterviews/EditBulkRequirement")
);
const IncExitNewRequirementsDetailsEdit = lazy(() =>
  import("../component/IncExit/IncExitNewRequirementsEdit")
);
const InterviewerCompanyProfile = lazy(() =>
  import("../component/IncServe/InterviewerManage/IncservePanelistProfile")
);

const Invoice = lazy(() =>
  import("../container/EmployerList/EmployerProfile/InvoiceForDownload")
);
const InCExitCompletedInterviews = lazy(() =>
  import(
    "../container/Product/IncExit/ManageInterviews/IncExitCompletedInterview"
  )
);
const InCExitDeletedInterviews = lazy(() =>
  import("../container/Product/IncExit/ManageInterviews/DeletedInterviews")
);
const EmployerDeletedPage = lazy(() =>
  import("../container/EmployerList/EmployerDeletedPage")
);
const EmployerSuspendedPage = lazy(() =>
  import("../container/EmployerList/EmployerSuspendedPage")
);
const SubscribedList = lazy(() =>
  import("../container/EmployerList/SubscribedList")
);
const EmployerSubusers = lazy(() =>
  import("../container/EmployerList/EmployerSubusers")
);
const Roles = lazy(() => import("../container/RolesAndSubusers/Roles"));
const Subusers = lazy(() => import("../container/RolesAndSubusers/Subusers"));
const DeleteSubUsers = lazy(() =>
  import("../container/RolesAndSubusers/DeleteSubusers")
);

const AddSubuser = lazy(() =>
  import("../container/RolesAndSubusers/AddSubuser")
);
const Product = lazy(() => import("../container/ProductsAndPlan/Product"));
const Plan = lazy(() => import("../container/ProductsAndPlan/Plan"));
const Support = lazy(() => import("../container/Support/Support"));
const ContactInquiries = lazy(() =>
  import("../container/ContactInquiries/ContactInquiries")
);
const UsageDashboard = lazy(() =>
  import("../container/EmployerList/UsageDashboard")
);
const CustomPlan = lazy(() =>
  import("../container/ProductsAndPlan/CustomPlan")
);
const AnnouncementList = lazy(() =>
  import("../container/Announcement/AnnouncementList")
);
const Announcement = lazy(() =>
  import("../container/Announcement/Announcement")
);
const PositionsList = lazy(() => import("../container/Positions/Positions"));

const PositionsTable = lazy(() =>
  import("../container/Careers/PositionsTable")
);

const IncServeInterviewSearch = lazy(() =>
  import("../container/Product/IncServe/InterviewSearch/InterviewSearch")
);
const InCBotInterviewSearch = lazy(() =>
  import("../container/Product/IncBot/Search")
);
const Interviews = lazy(() =>
  import("../container/Product/InterviewManage/Interviews")
);
const AllInterviews = lazy(() =>
  import("../component/IncServe/ManageInterviews/EditBulkRequirement")
);

const Positions = lazy(() =>
  import("../container/Product/InterviewManage/Positions")
);
const PositionManage = lazy(() =>
  import("../container/Product/IncServe/ManagePosition/PositionManage")
);
const IncBotPositionManage = lazy(() =>
  import("../container/Product/IncBot/ManagePosition/PositionManage")
);
const IncBotInterviewManage = lazy(() =>
  import("../container/Product/IncBot/ManageInterviews/InterviewManage")
);
const AllRequirements = lazy(() =>
  import("../container/Product/IncServe/ManageInterviews/AllRequirements")
);
const EditRequirements = lazy(() =>
  import("../component/IncServe/ManageInterviews/EditRequirement")
);
const CreatePosition = lazy(() =>
  import("../container/EmployerList/CreatePosition")
);

const NewsArticle = lazy(() => import("../container/NewsArticle"));
const ApiCallLogs = lazy(() => import("../container/ApiCallLogs"));
const MockInterviews = lazy(() => import("../container/MockInterviews"));

const TwoFactorAuthentication = lazy(() =>
  import("../container/TwoFactorAuthentication")
);

const ScheduledInterviews = lazy(() =>
  import("../container/Product/IncServe/ManageInterviews/ScheduledInterviews")
);
const CompletedInterviews = lazy(() =>
  import("../container/Product/IncServe/ManageInterviews/CompletedInterviews")
);
const DeletedInterviews = lazy(() =>
  import("../container/Product/IncServe/ManageInterviews/DeletedInterviews")
);

const IncserveTracker = lazy(() =>
  import("../component/IncServe/ManageInterviews/Tracker")
);
const Panelists = lazy(() => import("../container/Product/Panelists"));
const OnboardedClients = lazy(() =>
  import("../container/Product/OnboardedClients")
);
const InterviewerPendingProfile = lazy(() =>
  import("../container/Product/IncServe/Interviewer/PendingProfile")
);
const InterviewerApprovedProfile = lazy(() =>
  import("../container/Product/IncServe/Interviewer/ApprovedProfile")
);
const InterviewerApprovalProfile = lazy(() =>
  import("../container/Product/IncServe/Interviewer/ApprovalProfile")
);
const InterviewerActiveProfile = lazy(() =>
  import("../container/Product/IncServe/Interviewer/ActiveProfile")
);
const InterviewerBlacklistedProfile = lazy(() =>
  import("../container/Product/IncServe/Interviewer/BlacklistedProfile")
);
const InterviewerHoldProfile = lazy(() =>
  import("../container/Product/IncServe/Interviewer/HoldProfile")
);
const InterviewerDeactivatedProfile = lazy(() =>
  import("../container/Product/IncServe/Interviewer/DeactivatedProfile")
);
const InterviewerDeletedProfile = lazy(() =>
  import("../container/Product/IncServe/Interviewer/DeletedProfile")
);
const ReferNEarn = lazy(() => import("../container/ReferNEarn"));
const QuestionBanks = lazy(() => import("../container/QuestionBank"));
const AddOns = lazy(() => import("../container/ProductsAndPlan/AddOns"));
const PlanUsageDashboard = lazy(() =>
  import("../container/Dashboard/PlanUsageDashboard")
);
const Report = lazy(() => import("../container/Report"));
const OnboardedClientProfile = lazy(() =>
  import("../container/Product/OnboardedClientProfile")
);

const FaqPage = lazy(() => import("../container/FaqPage"));
const Notification = lazy(() => import("../container/Notifications"));
const FeedbackReport = lazy(() =>
  import("../component/MyInterviewComponents/FeedbackReport")
);
const MailReport = lazy(() =>
  import("../component/commonComponent/MailReports")
);

const InterviewSearch = lazy(() =>
  import("../container/Product/IncExit/Search/InterviewSearch")
);

const IncExitNewRequirements = lazy(() =>
  import("../container/Product/IncExit/ManageInterviews/IncExitNewRequirements")
);

const IncExitScheduledInterview = lazy(() =>
  import(
    "../container/Product/IncExit/ManageInterviews/IncExitScheduledInterview"
  )
);

const RoutesFile = [
  {
    path: "/login",
    exact: true,
    name: "Login",
    component: Login,
    resource: "Login",
    private: false,
    mainResource: "",
    resource: "",
    action: "View",
  },
  {
    path: "/reset-password",
    exact: true,
    name: "ResetPassword",
    resource: "ResetPassword",
    component: ResetPassword,
    private: false,
    mainResource: "",
    resource: "",
    action: "View",
  },
  {
    path: "/notification",
    exact: true,
    name: "Notification",
    component: Notification,
    private: true,
    mainResource: "Common Permission",
    resource: "Notification",
    action: "View",
  },
  {
    path: "/incexit/product-dashboard",
    exact: true,
    name: "InCExitProductDashboard",
    component: ProductDashboard,
    private: true,
    mainResource: "IncExit",
    resource: "InCExit Product Dashboard",
    action: "View",
  },
  {
    path: "/incserve/product-dashboard",
    exact: true,
    name: "InCServeProductDashboard",
    component: ProductDashboard,
    private: true,
    mainResource: "IncServe",
    resource: "InCServe Product Dashboard",
    action: "View",
    // permission: checkPermission("InCServe Product Dashboard"),
  },

  {
    path: "/incexit/onboarded-client-list",
    exact: true,
    name: "InCExitOnboardedClients",
    component: OnboardedClients,
    private: true,
    action: "View",
    mainResource: "IncExit",
    resource: "InCExit Onboarded Clients",
  },

  {
    path: "/interviewer/pending-profile",
    exact: true,
    name: "InterviewerPendingProfile",
    component: InterviewerPendingProfile,
    private: true,
    mainResource: "IncServe",
    resource: "Interviewer Pending Profile",
    action: "View",
  },
  {
    path: "/interviewer/approval-pending",
    exact: true,
    name: "InterviewerApprovalProfile",
    component: InterviewerApprovalProfile,
    private: true,
    mainResource: "IncServe",
    resource: "Interviewer Approval Pending",
    action: "View",
    // permission: checkPermission("Interviewer Approval Profile"),
  },
  {
    path: "/interviewer/invoices/:interviewerId/:month/:year",
    exact: true,
    name: "InterviewerApprovalProfile",
    component: InterviewerInvoices,
    private: false,
    mainResource: "IncServe",
    resource: "Interviewer Invoices",
    action: "View",
    // permission: checkPermission("Interviewer Approval Profile"),
  },
  {
    path: "/interviewer/approved-profile",
    exact: true,
    name: "InterviewerApprovedProfile",
    component: InterviewerApprovedProfile,
    private: true,
    mainResource: "IncServe",
    resource: "Interviewer Approved Profile",
    action: "View",
    // permission: checkPermission("Interviewer Approved Profile"),
  },
  {
    path: "/interviewer/active-profile",
    exact: true,
    name: "InterviewerActiveProfile",
    component: InterviewerActiveProfile,
    private: true,
    mainResource: "IncServe",
    resource: "Interviewer Active Profile",
    action: "View",
    // permission: checkPermission("Interviewer Active Profile"),
  },
  {
    path: "/interviewer/blacklisted-profile",
    exact: true,
    name: "InterviewerBlacklistedProfile",
    component: InterviewerBlacklistedProfile,
    private: true,
    mainResource: "IncServe",
    resource: "Interviewer Blacklisted Profile",
    action: "View",
    // permission: checkPermission("Interviewer Blacklisted Profile"),
  },
  {
    path: "/interviewer/hold-interviewer",
    exact: true,
    name: "InterviewerHoldProfile",
    component: InterviewerHoldProfile,
    private: true,
    mainResource: "IncServe",
    resource: "Interviewer Hold Profile",
    action: "View",
    // permission: checkPermission("Interviewer Hold Profile"),
  },

  {
    path: "/interviewer/deactivated-profile",
    exact: true,
    name: "InterviewerDeactivatedProfile",
    component: InterviewerDeactivatedProfile,
    private: true,
    mainResource: "IncServe",
    resource: "Interviewer Deactivated Profile",
    action: "View",
    // permission: checkPermission("Interviewer Deactivated Profile"),
  },
  {
    path: "/interviewer/deleted-profile",
    exact: true,
    name: "InterviewerDeletedProfile",
    component: InterviewerDeletedProfile,
    private: true,
    mainResource: "IncServe",
    resource: "Interviewer Deleted Profile",
    action: "View",
    // permission: checkPermission("Interviewer Deleted Profile"),
  },

  {
    path: "/incexit/all-requirements",
    exact: true,
    name: "InCExitAllRequirements",
    component: AllRequirements,
    private: true,
    mainResource: "IncExit",
    resource: "New Requirements",
    action: "View",
    // permission: checkPermission("InCExit New Requirements"),
  },

  {
    path: "/incexit/manage-interviews/completed",
    exact: true,
    name: "InCExitCompletedInterviews",
    component: InCExitCompletedInterviews,
    private: true,
    mainResource: "IncExit",
    action: "View",
    resource: "Completed",
  },
  {
    path: "/incexit/manage-interviews/deleted",
    exact: true,
    name: "InCExitDeletedInterviews",
    component: InCExitDeletedInterviews,
    private: true,
    mainResource: "IncExit",
    resource: "Deleted",
    action: "View",
  },
  {
    path: "/incserve/search",
    exact: true,
    name: "InCServeInterviewSearch",
    component: IncServeInterviewSearch,
    mainResource: "IncServe",
    resource: "Search",
    private: true,
    action: "View",
  },
  {
    path: "/incbot/search",
    exact: true,
    name: "InCBotInterviewSearch",
    component: InCBotInterviewSearch,
    mainResource: "IncExit",
    resource: "InCBot Search",
    private: true,
    action: "View",
  },
  {
    path: "/incserve/manage-positions",
    exact: true,
    name: "InCServePositions",
    component: PositionManage,
    private: true,
    mainResource: "IncServe",
    resource: "Positions",
    action: "View",
  },
  {
    path: "/incbot/position/",
    exact: true,
    name: "IncBotPositionManage",
    component: IncBotPositionManage,
    private: true,
    mainResource: "incBot",
    resource: "positions",
    action: "View",
  },
  {
    path: "/incbot/interviews/",
    exact: true,
    name: "IncBotInterviewManage",
    component: IncBotInterviewManage,
    private: true,
    mainResource: "incBot",
    resource: "interviewManagement",
    action: "View",
  },
  {
    path: "/incserve/manage-interviews/requirements",
    exact: true,
    name: "InCServeAllRequirements",
    component: AllRequirements,
    private: true,
    mainResource: "IncServe",
    resource: "New Requirements",
    action: "View",
  },
  {
    path: "/incserve/invoice",
    exact: true,
    name: "InCServeAllRequirements",
    component: Invoice,
    private: true,
    mainResource: "IncServe",
    resource: "Invoice",
    action: "View",
    // permission: checkPermission("InCServe Invoice"),
  },

  {
    path: "/incserve/manage-interviews/edit-requirements",
    exact: true,
    name: "InCServeEditRequirements",
    component: EditRequirements,
    private: true,
    mainResource: "IncServe",
    resource: "New Requirement",
    action: "View",
  },

  {
    path: "/incserve/manage-interviews/scheduled",
    exact: true,
    name: "InCServeScheduledInterviews",
    component: ScheduledInterviews,
    private: true,
    mainResource: "IncServe",
    resource: "Scheduled",
    action: "View",
  },
  {
    path: "/incserve/manage-interviews/completed",
    exact: true,
    name: "InCServeCompletedInterviews",
    component: CompletedInterviews,
    private: true,
    mainResource: "IncServe",
    resource: "Completed",
    action: "View",
  },
  {
    path: "/incserve/manage-interviews/deleted",
    exact: true,
    name: "InCServeDeletedInterviews",
    component: DeletedInterviews,
    private: true,
    mainResource: "IncServe",
    resource: "Deleted",
    action: "View",
  },

  {
    path: "/",
    exact: true,
    name: "MainDashboard",
    component: MainDashboard,
    mainResource: "",
    resource: "",
    private: true,
  },
  {
    path: "/employer/subscription-manage",
    exact: true,
    name: "SubscriptionManage",
    component: SubscriptionManage,
    mainResource: "commonPermission",
    resource: "clientManagement",
    subResource: "subscribedClients",
    private: true,
    action: "View",
  },
  {
    path: "/employer/email",
    exact: true,
    name: "EmployerEmailPage",
    component: EmployerEmailPage,
    mainResource: "commonPermission",
    resource: "clientManagement",
    subResource: "pendingProfile",
    private: true,
    action: "View",
  },
  {
    path: "/incexit/search",
    exact: true,
    name: "IncExitSearchPage",
    component: InterviewSearch,
    private: true,
    mainResource: "IncExit",
    resource: "Search",
    action: "View",
  },
  {
    path: "/incexit/manage-interviews/requirements",
    exact: true,
    name: "IncExitNewRequirements",
    component: IncExitNewRequirements,
    private: true,
    mainResource: "IncServe",
    resource: "New Requirements",
    action: "View",
  },
  {
    path: "/incexit/manage-interviews/requirements-details-edit",
    exact: true,
    name: "IncExitNewRequirements",
    component: IncExitNewRequirementsDetailsEdit,
    private: true,
    mainResource: "IncExit",
    resource: "New Requirements",
    action: "View",
  },
  {
    path: "/bulk-requiremenet-details",
    exact: true,
    name: "IncExitNewRequirements",
    component: BulkRequirementsDetails,
    private: true,
    mainResource: "IncExit",
    resource: "Bulk Requirements",
    action: "View",
  },
  {
    path: "/interview-call-records",
    exact: true,
    name: "InterviewCallRecords",
    component: InterviewCallRecords,
    private: true,
    mainResource: "IncExit",
    resource: "Call Records",
    action: "View",
  },
  {
    path: "/incserve/bulk-requiremenet-details",
    exact: true,
    name: "IncServeBulkRequirements",
    component: IncserveBulkRequirementsDetails,
    private: true,
    mainResource: "IncExit",
    resource: "New Requirement",
    action: "View",
  },
  {
    path: "/incserve/bulk-requiremenet-details-edit",
    exact: true,
    name: "IncServeBulkRequirements",
    component: IncserveBulkRequirementsDetailsEdit,
    private: true,
    mainResource: "IncServe",
    resource: "Bulk Requirement",
    action: "View",
  },
  {
    path: "/incexit/manage-interviews/scheduled",
    exact: true,
    name: "IncExitScheduledInterview",
    component: IncExitScheduledInterview,
    private: true,
    mainResource: "IncExit",
    resource: "Scheduled",
    action: "View",
  },
  {
    path: "/employer/profile-management",
    exact: true,
    name: "EmployerProfileManagement",
    component: EmployerProfileManagement,
    private: true,
    mainResource: "commonPermission",
    resource: "clientManagement",
    subResource: "approvedProfile",
    action: "View",
  },
  {
    path: "/employer/suspended-page",
    exact: true,
    name: "EmployerSuspendedPage",
    component: EmployerSuspendedPage,
    private: true,
    mainResource: "commonPermission",
    resource: "clientManagement",
    subResource: "suspendedClients",
    action: "View",
  },
  {
    path: "/employer/deleted-page",
    exact: true,
    name: "EmployerDeletedPage",
    component: EmployerDeletedPage,
    private: true,
    mainResource: "commonPermission",
    resource: "clientManagement",
    subResource: "deletedClients",
    action: "View",
  },
  {
    path: "/employer/subscriber-list",
    exact: true,
    name: "SubscribedList",
    component: SubscribedList,
    private: true,
    mainResource: "commonPermission",
    resource: "clientManagement",
    subResource: "subscribedClients",
    action: "View",
  },
  {
    path: "/employer/subusers",
    exact: true,
    name: "EmployerSubusers",
    component: EmployerSubusers,
    private: true,
    mainResource: "Common Permission",
    resource: "Suspended Clients",
    action: "View",
  },
  {
    path: "/products",
    exact: true,
    name: "Product",
    component: Product,
    private: true,
    mainResource: "commonPermission",
    resource: "manageProductsPlans",
    action: "View",
  },
  {
    path: "/custom-plans",
    exact: true,
    name: "CustomPlan",
    component: CustomPlan,
    private: true,
    mainResource: "Common Permission",
    resource: "Manage Products",
    action: "View",
  },

  {
    path: "/admin-roles",
    exact: true,
    name: "Roles",
    component: Roles,
    private: true,
    mainResource: "commonPermission",
    resource: "otherConfigurations",
    subResource: "rolesSubusers",
    action: "View",
  },
  {
    path: "/admin-subusers",
    exact: true,
    name: "Subusers",
    component: Subusers,
    private: true,
    mainResource: "commonPermission",
    resource: "otherConfigurations",
    subResource: "rolesSubusers",
    action: "View",
  },
  {
    path: "/admin-deleteusers",
    exact: true,
    name: "DeleteSubUsers",
    component: DeleteSubUsers,
    private: true,
    mainResource: "",
    resource: "",
    action: "View",
  },
  {
    path: "/positions-list",
    exact: true,
    name: "PositionsList",
    component: PositionsList,
    private: true,
    mainResource: "IncServe",
    resource: "Position",
    action: "View",
  },
  {
    path: "/contact",
    exact: true,
    name: "ContactInquiries",
    component: ContactInquiries,
    private: true,
    mainResource: "Common Permission",
    resource: "Contact Inquiries",
    action: "View",
  },
  {
    path: "/announcement-list",
    exact: true,
    name: "AnnouncementList",
    component: AnnouncementList,
    private: true,
    mainResource: "commonPermission",
    resource: "otherConfigurations",
    subResource: "announcements",
    action: "View",
  },
  {
    path: "/support",
    exact: true,
    name: "Support",
    component: Support,
    private: true,
    mainResource: "commonPermission",
    resource: "otherConfigurations",
    subResource: "support",
    action: "View",
  },
  {
    path: "/careers",
    exact: true,
    name: "PositionsTable",
    component: PositionsTable,
    private: true,
    mainResource: "commonPermission",
    resource: "otherConfigurations",
    subResource: "careers",
    action: "View",
  },

  {
    path: "/question-banks",
    exact: true,
    name: "QuestionBanks",
    component: QuestionBanks,
    private: true,
    mainResource: "Common Permission",
    resource: "Question Bank",
    action: "View",
  },
  {
    path: "/employer-email-pending",
    exact: true,
    name: "EmployerEmailPendingPage",
    component: EmployerEmailPendingPage,
    private: true,
    mainResource: "Common Permission",
    resource: "Pending",
    action: "View",
  },
  {
    path: "/employer-company-profile",
    exact: true,
    name: "CompanyProfile",
    component: CompanyProfile,
    private: true,
    mainResource: "",
    resource: "",
    action: "View",
  },
  {
    path: "/interviewer-company-profile",
    exact: true,
    name: "CompanyProfile",
    component: InterviewerCompanyProfile,
    private: true,
    mainResource: "IncServe",
    resource: "IncServe",
    action: "View",
  },

  {
    path: "incserve/onboarded-client-profile",
    exact: true,
    name: "OnboardedClientProfile",
    component: OnboardedClientProfile,
    private: true,
    mainResource: "Common Permission",
    resource: "Onboarded Client Profile",
    action: "View",
  },
  {
    path: "incexit/onboarded-client-profile",
    exact: true,
    name: "OnboardedClientProfile",
    component: OnboardedClientProfile,
    private: true,
    mainResource: "Common Permission",
    resource: "Onboarded Client Profile",
    action: "View",
  },
  {
    path: "/admin-add-subuser",
    exact: true,
    name: "AddSubuser",
    component: AddSubuser,
    private: true,
    mainResource: "Common Permission",
    resource: "Subuser",
    action: "View",
  },
  {
    path: "/products/plans",
    exact: true,
    name: "Plan",
    component: Plan,
    private: true,
    mainResource: "commonPermission",
    resource: "manageProductsPlans",
    action: "View",
  },
  {
    path: "/usage-dashboard",
    exact: true,
    name: "UsageDashboard",
    component: UsageDashboard,
    private: true,
    permission: true,
    mainResource: "",
    resource: "",
    action: "View",
  },
  {
    path: "/announcement",
    exact: true,
    name: "Announcement",
    component: Announcement,
    private: true,
    mainResource: "Common Permission",
    resource: "Announcements",
    action: "View",
  },
  {
    path: "/feedback-report",
    exact: true,
    name: "FeedbackReport",
    component: FeedbackReport,
    private: false,
    resource: "FeedbackReport",
    mainResource: "Common Permission",
    permission: true,
    action: "View",
  },

  {
    path: "/faq",
    exact: true,
    name: "FaqPage",
    component: FaqPage,
    private: true,
    mainResource: "commonPermission",
    resource: "otherConfigurations",
    subResource: "faq",
    action: "View",
  },
  {
    path: "/create-position",
    exact: true,
    name: "CreatePosition",
    component: CreatePosition,
    private: true,
    resource: "Panelists",
    action: "Page",
    mainResource: "Common Permission",
    permission: true,
    action: "View",
    redirect: false,
  },
  {
    path: "/news-article",
    exact: true,
    name: "NewsArticle",
    component: NewsArticle,
    private: true,
    action: "Page",
    permission: true,
    action: "View",
    redirect: false,
    mainResource: "commonPermission",
    resource: "otherConfigurations",
    subResource: "websiteNews",
  },
  {
    path: "/two-factor-authentication",
    exact: true,
    name: "twoFactorAuthentication",
    component: TwoFactorAuthentication,
    private: true,
    action: "Page",
    permission: true,
    action: "View",
    redirect: false,
    mainResource: "commonPermission",
    resource: "otherConfigurations",
    subResource: "twoFactorSecurity",
  },
  {
    path: "/api-call-Logs",
    exact: true,
    name: "apiCallLogs",
    component: ApiCallLogs,
    private: true,
    action: "Page",
    permission: true,
    action: "View",
    redirect: false,
    mainResource: "commonPermission",
    resource: "otherConfigurations",
    subResource: "apiCallLogs",
  },
  {
    path: "/mock-interviews",
    exact: true,
    name: "mockInterviews",
    component: MockInterviews,
    private: true,
    resource: "",
    action: "Page",
    mainResource: "",
    permission: true,
    action: "View",
    redirect: false,
  },

  {
    path: "/meetings",
    exact: true,
    name: "Meetings",
    component: Meetings,
    private: true,
    action: "Page",
    permission: true,
    action: "View",
    redirect: false,
    mainResource: "commonPermission",
    resource: "otherConfigurations",
    subResource: "meetings",
  },
  // {
  //   path: "/locations/countries",
  //   exact: true,
  //   name: "Countries",
  //   component: Countries,
  //   mainResource: "Common Permission",
  //   resource: "Locations",
  //   private: true,
  //   action: "View",
  // },
  // {
  //   path: "/locations/states",
  //   exact: true,
  //   name: "States",
  //   component: States,
  //   resource: "Locations",
  //   mainResource: "Common Permission",
  //   private: true,
  //   action: "View",
  // },
  // {
  //   path: "/locations/cities",
  //   exact: true,
  //   name: "Cities",
  //   component: Cities,
  //   mainResource: "Common Permission",
  //   resource: "Locations",
  //   private: true,
  //   action: "View",
  // },

  {
    path: "/event-tracking",
    exact: true,
    name: "EventTracking",
    component: EventTracking,
    private: true,
    action: "Page",
    permission: true,
    action: "View",
    redirect: false,
    mainResource: "commonPermission",
    resource: "otherConfigurations",
    subResource: "meetings",
  },
];

export default RoutesFile;
