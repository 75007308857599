import axios from "axios";
import { generateUrl, getToken, removeToken } from "../helper";

const calendar = axios.create({
  baseURL: generateUrl("calendar_backend"),
});
calendar.interceptors.request.use(
  function (config) {
    let token = getToken();
    if (token && token !== null) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
calendar.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      removeToken();
      window.location.href = "/login";
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);
export default calendar;
