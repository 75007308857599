import React, { memo } from "react";
import ToggleSwitch from "./ToggleSwitch";

const SwitchCard = memo(({ swCard, cardName, checked, onChange }) => {
  return (
    <div className={`input-shadow ${swCard}`}>
      <div className="sw_cardName">{cardName}</div>
      <ToggleSwitch className={"f-13"} checked={checked} onChange={onChange} />
    </div>
  );
});

export default SwitchCard;
