import React, { memo } from "react";

const ToggleSwitch = memo(
  ({ className, checked, onChange, disabled = false }) => {
    return (
      <label
        className={`${
          disabled ? "toggle-switch-disabled" : "toggle-switch"
        } ${className}`}
      >
        <input
          type="checkbox"
          checked={checked}
          onChange={onChange}
          disabled={disabled}
        />
        <span className="switch" />
      </label>
    );
  }
);
export default ToggleSwitch;
