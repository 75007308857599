import {
  GET_SCREENING_QUESTIONS,
  GET_SCREENING_QUESTIONS_SUCCESS,
  GET_SCREENING_QUESTIONS_FAILURE,
  GET_QUESTIONS,
  GET_QUESTIONS_SUCCESS,
  GET_QUESTIONS_FAILURE,
  ADD_OR_EDIT_QUESTIONS,
  ADD_OR_EDIT_QUESTIONS_SUCCESS,
  ADD_OR_EDIT_QUESTIONS_FAILURE,
  DELETE_QUESTIONS,
  DELETE_QUESTIONS_SUCCESS,
  DELETE_QUESTIONS_FAILURE,
  POST_QUESTIONS,
  POST_QUESTIONS_SUCCESS,
  POST_QUESTIONS_FAILURE,
  GET_QUESTION_SKILLS,
  GET_QUESTION_SKILLS_SUCCESS,
  GET_QUESTION_SKILLS_FAILURE,
  GET_MANDATORY_SKILL,
  GET_MANDATORY_SKILL_SUCCESS,
  GET_MANDATORY_SKILL_FAILURE,
  GET_OPTIONAL_SKILL,
  GET_OPTIONAL_SKILL_SUCCESS,
  GET_OPTIONAL_SKILL_FAILURE,
  GET_DOMAIN,
  GET_DOMAIN_SUCCESS,
  GET_DOMAIN_FAILURE,
  GET_INDUSTRY_TYPE,
  GET_INDUSTRY_TYPE_SUCCESS,
  GET_INDUSTRY_TYPE_FAILURE,
  GET_SKILLS,
  GET_SKILLS_SUCCESS,
  GET_SKILLS_FAILURE,
  GET_SKILLS_EXPERTISE,
  GET_SKILLS_EXPERTISE_SUCCESS,
  GET_SKILLS_EXPERTISE_FAILURE,
  DELETE_BULK_QUESTIONS,
  DELETE_BULK_QUESTIONS_SUCCESS,
  DELETE_BULK_QUESTIONS_FAILURE,
  CREATE_JD,
  CREATE_JD_SUCCESS,
  CREATE_JD_FAILURE,
  GET_NEWS_ARTICLE_LIST,
  GET_NEWS_ARTICLE_LIST_SUCCESS,
  GET_NEWS_ARTICLE_LIST_FAILURE,
  ADD_OR_EDIT_NEWS_ARTICLE_FAILURE,
  ADD_OR_EDIT_NEWS_ARTICLE_SUCCESS,
  ADD_OR_EDIT_NEWS_ARTICLE,
  DELETE_NEWS_ARTICLE,
  DELETE_NEWS_ARTICLE_SUCCESS,
  DELETE_NEWS_ARTICLE_FAILURE,
} from "./types";

//DOMAIN
export const getDomain = (payload) => ({
  type: GET_DOMAIN,
  payload,
});
export const getDomainSuccess = (payload) => ({
  type: GET_DOMAIN_SUCCESS,
  payload,
});
export const getDomainFailure = () => ({
  type: GET_DOMAIN_FAILURE,
});

// SKILLS

export const getSkills = (payload) => ({
  type: GET_SKILLS,
  payload,
});
export const getSkillsSuccess = (payload) => ({
  type: GET_SKILLS_SUCCESS,
  payload,
});
export const getSkillsFailure = () => ({
  type: GET_SKILLS_FAILURE,
});

// SCREENING QUESTION
export const getScreeningQuestions = (payload) => ({
  type: GET_SCREENING_QUESTIONS,
  payload,
});
export const getScreeningQuestionsSuccess = (payload) => ({
  type: GET_SCREENING_QUESTIONS_SUCCESS,
  payload,
});
export const getScreeningQuestionsFailure = () => ({
  type: GET_SCREENING_QUESTIONS_FAILURE,
});

//SKILLS
export const getMandatorySkill = (payload) => ({
  type: GET_MANDATORY_SKILL,
  payload,
});
export const getMandatorySkillSuccess = (payload) => ({
  type: GET_MANDATORY_SKILL_SUCCESS,
  payload,
});
export const getMandatorySkillFailure = () => ({
  type: GET_MANDATORY_SKILL_FAILURE,
});
export const getOptionalSkill = (payload) => ({
  type: GET_OPTIONAL_SKILL,
  payload,
});
export const getOptionalSkillSuccess = (payload) => ({
  type: GET_OPTIONAL_SKILL_SUCCESS,
  payload,
});
export const getOptionalSkillFailure = () => ({
  type: GET_OPTIONAL_SKILL_FAILURE,
});

// QUESTION BANKS
export const getQuestions = (payload) => ({
  type: GET_QUESTIONS,
  payload,
});
export const getQuestionsSuccess = (payload) => ({
  type: GET_QUESTIONS_SUCCESS,
  payload,
});
export const getQuestionsFailure = () => ({
  type: GET_QUESTIONS_FAILURE,
});
export const addOrEditQuestions = (payload) => ({
  type: ADD_OR_EDIT_QUESTIONS,
  payload,
});
export const addOrEditQuestionsSuccess = () => ({
  type: ADD_OR_EDIT_QUESTIONS_SUCCESS,
});
export const addOrEditQuestionsFailure = () => ({
  type: ADD_OR_EDIT_QUESTIONS_FAILURE,
});
export const getQuestionSkills = () => ({
  type: GET_QUESTION_SKILLS,
});
export const getQuestionSkillsSuccess = (payload) => ({
  type: GET_QUESTION_SKILLS_SUCCESS,
  payload,
});
export const getQuestionSkillsFailure = () => ({
  type: GET_QUESTION_SKILLS_FAILURE,
});
export const deleteQuestions = (payload) => ({
  type: DELETE_QUESTIONS,
  payload,
});
export const deleteQuestionsSuccess = () => ({
  type: DELETE_QUESTIONS_SUCCESS,
});
export const deleteQuestionsFailure = () => ({
  type: DELETE_QUESTIONS_FAILURE,
});
export const deleteBulkQuestions = (payload) => ({
  type: DELETE_BULK_QUESTIONS,
  payload,
});
export const deleteBulkQuestionsSuccess = () => ({
  type: DELETE_BULK_QUESTIONS_SUCCESS,
});
export const deleteBulkQuestionsFailure = () => ({
  type: DELETE_BULK_QUESTIONS_FAILURE,
});
export const postQuestions = (payload) => ({
  type: POST_QUESTIONS,
  payload,
});
export const postQuestionsSuccess = () => ({
  type: POST_QUESTIONS_SUCCESS,
});
export const postQuestionsFailure = () => ({
  type: POST_QUESTIONS_FAILURE,
});
export const getIndustryType = () => ({
  type: GET_INDUSTRY_TYPE,
});
export const getIndustryTypeSuccess = (payload) => ({
  type: GET_INDUSTRY_TYPE_SUCCESS,
  payload,
});
export const getIndustryTypeFailure = () => ({
  type: GET_INDUSTRY_TYPE_FAILURE,
});
export const createJD = (payload) => ({
  type: CREATE_JD,
  payload,
});
export const createJDSuccess = (payload) => ({
  type: CREATE_JD_SUCCESS,
  payload,
});
export const createJDFailure = () => ({
  type: CREATE_JD_FAILURE,
});

export const getNewsArticleList = (payload) => ({
  type: GET_NEWS_ARTICLE_LIST,
  payload,
});
export const getNewsArticleListSuccess = (payload) => ({
  type: GET_NEWS_ARTICLE_LIST_SUCCESS,
  payload,
});
export const getNewsArticleListFailure = () => ({
  type: GET_NEWS_ARTICLE_LIST_FAILURE,
});

export const addOrEditNewsArticle = (payload) => ({
  type: ADD_OR_EDIT_NEWS_ARTICLE,
  payload,
});
export const addOrEditNewsArticleSuccess = (payload) => ({
  type: ADD_OR_EDIT_NEWS_ARTICLE_SUCCESS,
  payload,
});
export const addOrEditNewsArticleFailure = () => ({
  type: ADD_OR_EDIT_NEWS_ARTICLE_FAILURE,
});
export const deleteNewsArticle = (payload) => ({
  type: DELETE_NEWS_ARTICLE,
  payload,
});
export const deleteNewsArticleSuccess = () => ({
  type: DELETE_NEWS_ARTICLE_SUCCESS,
});
export const deleteNewsArticleFailure = () => ({
  type: DELETE_NEWS_ARTICLE_FAILURE,
});
