import React, { useEffect, useState } from "react";
import { Page, Document, View, Text, Image } from "@react-pdf/renderer";
import { generateUrl } from "../../utils/helper";

const PrintContent = React.forwardRef(({ value, JDTitle, company, logo }) => {
  const styles = {
    page: {
      border: "10px solid #E6E9F8",
      flexDirection: "row",
      padding: "16px",
    },
    // container: {
    //   background: "#ffffff",
    //   padding: "20px",
    //   height: "100%",
    // },
    section: {
      margin: "10px",
      padding: "10px",
      flexGrow: "1px",
    },
    title: {
      fontSize: "18px",
      fontWeight: 600,
      marginBottom: "10px",
      color: "#191919",
    },
    companyName: {
      fontSize: "12px",
      fontWeight: "400",
      marginBottom: "5px",
    },
    content: {
      fontStyle: "normal",
      fontWeight: "100",
      lineHeight: "2px",
      color: "#171717",
      // fontFamily: "Manrope-Regular",
      fontSize: "10px",
    },
    logo: {
      width: "40px",
      height: "auto",
      position: "absolute",
      right: "30px",
      top: "20px",
    },
  };
  const [imageBase64, setImageBase64] = useState(null);
  const whiteLabel = generateUrl("admin") + "/logos/" + logo;
  useEffect(() => {
    const convertToDataURI = async () => {
      try {
        const response = await fetch(whiteLabel);
        const blob = await response.blob();
        const reader = new FileReader();
        reader.onloadend = () => {
          const dataURI = reader.result;
          setImageBase64(dataURI);
        };
        reader.readAsDataURL(blob);
      } catch (error) {
        console.error("Error converting image to data URI:", error);
      }
    };
    convertToDataURI();
  }, [whiteLabel]);

  return (
    <>
      <Document>
        <Page style={styles.page}>
          <View style={styles.container}>
            <Text style={styles.title}>{JDTitle}</Text>
            <Text style={styles.companyName}>{company}</Text>
            <Image style={styles.logo} src={imageBase64} />
            <Text style={styles.content}>{value.replace(/\\n/g, "\n")}</Text>
          </View>
        </Page>
      </Document>
    </>
  );
});
export default PrintContent;
