import React, { memo, useEffect, useMemo, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
  BackButton,
  Button,
  CreatableSelect,
  SearchSelect,
  Textarea,
} from "../../../component/commonComponent";
import removeField from "../../..//assets/images/remove-field.svg";
import Input from "../../../component/commonComponent/Input";
import file from "../../../assets/images/file.svg";
import AddQuestions from "../../../component/commonComponent/AddQuestions";
import {
  addCustomFile,
  createOrUpdateIncservePosition,
  createOrUpdatePosition,
  getActiveEmployers,
  getDomain,
  getDropdownPositionList,
  getIncservePositions,
  getMandatorySkill,
  getOptionalSkill,
  getScreeningQuestions,
} from "../../../redux/action";
import {
  ArrayForExpRange,
  Toast,
  capitalize,
  capitalizeAll,
  checkFileValidation,
  generateUrl,
  localStorageJsonParseDecrypt,
  numberChecker,
  skillChecker,
} from "../../../utils/helper";
import validateEditIncservePosition from "../../../validation/edit-incserve-position";
import CreateJD from "./CreateJD";
import CustomInput from "../../../component/commonComponent/CustomInput";
import ExperienceSearchSelect from "../../../component/commonComponent/ExperienceSearchSelect";
import SkillsSelect from "../../commonComponent/SkillsSelect";

export const EditPosition = memo(
  ({
    editableData,
    setIsEditable,
    currentPage,
    entriesLimit,
    sortBy,
    search,
  }) => {
    const dispatch = useDispatch();
    const refsArray = useRef([]);
    const hiddenFileInputRef = useRef(null);
    const [newQuestions, setNewQuestions] = useState([]);
    const [isScreeningQuestion, setIsScreeningQuestion] = useState(false);
    const [error, setError] = useState();
    const [isCreatedJD, setIsCreatedJD] = useState(true);
    const [addField, setAddField] = useState(false);
    const [jdData, setJdData] = useState("");
    const [isCreatedJDVisible, setIsCreatedJDVisible] = useState(false);
    const [isJdUploaded, setIsJdUploaded] = useState(false);
    const [customMandatorySkill, setCustomMandatorySkill] = useState([]);
    const [customOptionalSkill, setCustomOptionalSkill] = useState([]);
    const [isDownloadJdButtonVisible, setIsDownloadJdButtonVisible] =
      useState(false);
    const [customScreeningQuestionLists, setCustomScreeningQuestionLists] =
      useState([]);
    const [question, setQuestion] = useState("");
    const [selectedPositionTitle, setSelectedPositionTitle] = useState(null);
    const [masterMandatorySkill, setMasterMandatorySkill] = useState([]);
    const [masterOptionalSkill, setMasterOptionalSkill] = useState([]);
    const [minExpRange, setMinExpRange] = useState(ArrayForExpRange());
    const [maxExpRange, setMaxExpRange] = useState(ArrayForExpRange());
    const [selectedPosition, setSelectedPosition] = useState({});
    const [aiScreeningQuestions, setAiScreeningQuestions] = useState(null);
    const [screeningQsLoader, setScreeningQsLoader] = useState(false);
    const [screeningQuestionsList, setScreeningQuestionsList] = useState([]);
    const [allAIQuestions, setAllAIQuestions] = useState([]);
    const [employerList, setEmployerList] = useState([]);
    const [directUploadQuestion, setDirectUploadQuestion] = useState(false);
    const domainList = useSelector((state) => state.Skills.domain);
    const [inputQuestion, setInputQuestion] = useState({
      question: "",
      error: "",
    });

    const customFileDetails = useSelector(
      (state) => state.Interview.customFileDetails
    );

    const positionList = useSelector(
      (state) => state.Interview.dropdownPositionList
    );

    const allEmployerList = useSelector(
      (state) => state.UserAuth.activeEmployerList
    );
    let screeningQuestion = useSelector(
      (state) => state.Skills.screeningQuestions
    );

    const hiddenFileInput = useRef(null);
    const mandatorySkill = useSelector((state) => state.Skills.mandatorySkill);
    const optionalSkill = useSelector((state) => state.Skills.optionalSkill);
    const [newFieldData, setNewFieldData] = useState({
      fieldName: "",
      fieldValue: "",
      fieldType: "",
      interviewerVisibility: false,
      fieldPlaceHolder: "Enter Value Here",
      fieldMode: "interview",
      required: true,
    });
    const [form, setForm] = useState({
      title: "",
      domain: "",
      minExp: "",
      maxExp: "",
      jobDescription: "",
      mandatorySkills: [],
      optionalSkills: [],
      customMandatorySkills: [],
      customOptionalSkills: [],
      screeningQuestions: [],
      customField: [],
      customScreeningQuestions: [],
      feedbackType: "",
      clientName: "",
      feedbackSystem: "",
      remarks: "",
      feedbackFile: "",
      positionType: "",
      user: "",
    });

    useEffect(() => {
      dispatch(getDomain());
      dispatch(getMandatorySkill());
      dispatch(getOptionalSkill());
      dispatch(getActiveEmployers());
      dispatch(getScreeningQuestions());
      dispatch(getDropdownPositionList({ _id: editableData?.user }));
    }, [dispatch]);

    const MandatorySkill = useMemo(() => {
      const updatedMandatorySkill = [];
      if (mandatorySkill && mandatorySkill.length > 0) {
        mandatorySkill.forEach((itm) => {
          updatedMandatorySkill.push({
            value: itm._id,
            label: itm.name,
          });
        });
      }
      return updatedMandatorySkill;
    }, [mandatorySkill]);

    const OptionalSkill = useMemo(() => {
      const updatedOptionalSkill = [];
      if (optionalSkill && optionalSkill.length > 0) {
        optionalSkill.forEach((itm) => {
          updatedOptionalSkill.push({
            value: itm._id,
            label: itm.name,
          });
        });
      }
      return updatedOptionalSkill;
    }, [optionalSkill]);

    const DomainList = useMemo(() => {
      const updatedDomainList = [
        {
          value: "",
          label: "Select Domain",
        },
      ];
      if (domainList && domainList.length > 0) {
        domainList.forEach((itm) => {
          updatedDomainList.push({
            value: itm._id,
            label: itm.name,
          });
        });
      }
      return updatedDomainList;
    }, [domainList]);

    useEffect(() => {
      const tmpArray = [
        {
          value: "",
          label: "Select Employer",
        },
      ];
      allEmployerList &&
        allEmployerList.length > 0 &&
        allEmployerList.map((element) => {
          tmpArray.push({
            value: element?._id,
            label: element?.employer,
          });
        });
      setEmployerList(tmpArray);
    }, [allEmployerList]);

    let PositionList = useMemo(() => {
      const updatedPositionList = [
        {
          value: "",
          label: "Select Job Title",
        },
      ];
      if (positionList && positionList?.data?.length > 0) {
        positionList?.data?.forEach((itm) => {
          updatedPositionList.push({
            value: itm.title,
            label: itm.title,
          });
        });
      }

      let positionFilter =
        editableData &&
        updatedPositionList &&
        updatedPositionList.length > 0 &&
        updatedPositionList.filter((itm) => itm.value === editableData?.title);

      positionFilter &&
        positionFilter.length === 0 &&
        editableData.title &&
        updatedPositionList.push({
          value: editableData?.title,
          label: editableData?.title,
        });
      editableData &&
        editableData?.title &&
        setSelectedPositionTitle({
          value: editableData?.title,
          label: editableData?.title,
        });
      return updatedPositionList;
    }, [positionList, editableData]);

    useEffect(() => {
      if (form?.title !== "") {
        setIsCreatedJD(false);
      }
    }, [form]);

    const handleCreateJD = async () => {
      if (form?.title !== "") {
        setIsCreatedJD(true);
        const data = {
          position: form?.title,
          experience: form?.minExp ? form?.minExp : 0,
        };
        try {
          let url =
            generateUrl("interview_backend") +
            `/interviews/createJobDescription`;
          const response = await fetch(url, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${localStorageJsonParseDecrypt("token")}`,
              "content-type": "application/json",
            },
            body: JSON.stringify(data),
          });
          const reader = response.body.getReader();
          let receivedData = "";
          function processChunk({ done, value }) {
            if (done) {
              setIsCreatedJD(false);
              setIsDownloadJdButtonVisible(true);
              return;
            }
            const text = new TextDecoder().decode(value);
            // Append the received chunk to the existing data
            receivedData += text;
            // Update the state with the new data
            setJdData(receivedData.toString());
            // Read the next chunk
            return reader.read().then(processChunk);
          }
          // Start reading the chunks
          reader.read().then(processChunk);
        } catch (error) {
          console.error("Error:", error);
        }
        setIsCreatedJDVisible(true);
        setIsScreeningQuestion(false);
      } else {
        toast.error(<Toast msg="Please Select Position" />);
      }
    };

    useEffect(() => {
      if (selectedPosition && selectedPosition?.title) {
        setForm((prevState) => ({
          ...prevState,
          // mandatorySkills:
          //   selectedPosition?.mandatorySkills &&
          //   selectedPosition?.mandatorySkills?.length > 0
          //     ? selectedPosition?.mandatorySkills.map((data) => ({
          //         label: data?.name,
          //         value: data?._id,
          //       }))
          //     : [],
          mandatorySkills:
            selectedPosition?.mandatorySkills &&
            selectedPosition?.mandatorySkills?.length > 0
              ? selectedPosition?.mandatorySkills
                  .slice(0, 4) // Take the first 4 elements
                  .map((data) => ({
                    label: data?.name,
                    value: data?._id,
                  }))
              : [],

          optionalSkills:
            selectedPosition?.optionalSkills &&
            selectedPosition?.optionalSkills?.length > 0
              ? selectedPosition?.optionalSkills.slice(0, 4).map((data) => ({
                  label: data?.name,
                  value: data?._id,
                }))
              : [],
          screeningQuestions:
            (selectedPosition?.screeningQuestions &&
              selectedPosition?.screeningQuestions?.map((itm) => itm._id)) ||
            [],
        }));
        setMasterMandatorySkill(
          MandatorySkill.filter((itm) =>
            selectedPosition?.mandatorySkills
              .map((item) => item._id)
              .includes(itm.value)
          )
        );
        setMasterOptionalSkill(
          OptionalSkill.filter((itm) =>
            selectedPosition?.optionalSkills
              .map((item) => item._id)
              .includes(itm.value)
          )
        );
        setError((prevState) => ({
          ...prevState,
          mandatorySkills: "",
          optionalSkills: "",
        }));
      }
    }, [selectedPosition, MandatorySkill, OptionalSkill]);

    useEffect(() => {
      const tmpMandatoryArray = [];
      const tmpOptionalArray = [];
      if (editableData) {
        if (editableData.name) {
          setForm((prevState) => ({
            ...prevState,
            title: editableData?.name,
          }));
          setSelectedPositionTitle({
            label: editableData?.name,
            value: editableData?.name,
          });
          PositionList.push({
            label: editableData?.name,
            value: editableData?.name,
          });
          setSelectedPosition(
            positionList?.data.find((itm) => itm.title === editableData?.name)
          );
        } else {
          setForm((prevState) => ({
            ...prevState,
            domain: editableData?.domain?._id ? editableData?.domain?._id : "",
            clientName:
              editableData?.positionType === "Internal Hiring"
                ? ""
                : editableData?.clientName,
            title: editableData?.title ? editableData?.title : "",
            minExp: editableData?.expRange?.minYears
              ? editableData?.expRange?.minYears
              : 0,
            maxExp: editableData?.expRange?.maxYears
              ? editableData?.expRange?.maxYears
              : "",
            mandatorySkills:
              editableData?.mandatorySkills &&
              editableData?.mandatorySkills?.length > 0
                ? editableData?.mandatorySkills.map((data) => ({
                    label: data?.name,
                    value: data?._id,
                  }))
                : [],
            optionalSkills:
              editableData?.optionalSkills &&
              editableData?.optionalSkills?.length > 0
                ? editableData?.optionalSkills.map((data) => ({
                    label: data?.name,
                    value: data?._id,
                  }))
                : [],
            screeningQuestions:
              (editableData?.screeningQuestions &&
                editableData?.screeningQuestions?.map((itm) => itm._id)) ||
              [],
            remarks: editableData?.remarks
              ? editableData?.remarks
              : editableData?.position?.remarks
              ? editableData?.position?.remarks
              : "",
            positionType: editableData?.positionType
              ? editableData?.positionType
              : "",
            jobDescription: editableData?.jobDescription
              ? editableData?.jobDescription
              : "",
            feedbackType: editableData?.feedbackType
              ? editableData?.feedbackType
              : "",
            feedbackFile: editableData?.feedbackFileUrl
              ? editableData?.feedbackFileUrl
              : "",
            user: editableData?.employer?.user
              ? editableData?.employer?.user
              : "",
            customField: editableData.customField?.map((itm) => itm) || [],
          }));
          let tempScreeningQuestions =
            editableData?.screeningQuestions &&
            editableData?.screeningQuestions.length > 0
              ? editableData?.screeningQuestions.map((data) => data)
              : [];
          setScreeningQuestionsList(tempScreeningQuestions);
          if (MandatorySkill && MandatorySkill.length > 0) {
            MandatorySkill.forEach((element) => {
              if (
                editableData &&
                editableData?.mandatorySkills &&
                editableData?.mandatorySkills.find(
                  (itm) => itm._id === element.value
                )
              ) {
                tmpMandatoryArray.push(element.value);
              }
            });
            setMasterMandatorySkill(
              editableData &&
                editableData?.mandatorySkills &&
                editableData?.mandatorySkills.map((itm) => {
                  return {
                    label: itm.name,
                    value: itm._id,
                  };
                })
            );
          }
          if (OptionalSkill && OptionalSkill.length > 0) {
            OptionalSkill.forEach((element) => {
              if (
                editableData &&
                editableData?.optionalSkills &&
                editableData?.optionalSkills.find(
                  (itm) => itm._id === element.value
                )
              ) {
                tmpOptionalArray.push(element.value);
              }
            });
            setMasterOptionalSkill(
              editableData &&
                editableData?.optionalSkills &&
                editableData?.optionalSkills.map((itm) => {
                  return {
                    label: itm.name,
                    value: itm._id,
                  };
                })
            );
          }
          const tmpMaxArray = ArrayForExpRange();
          const newMaxArray = tmpMaxArray.map((itm) => {
            if (itm.value <= editableData?.expRange?.minYears) {
              itm.isDisabled = true;
            } else {
              itm.isDisabled = false;
            }
            return itm;
          });
          const tmpMinArray = ArrayForExpRange();
          const newMinArray = tmpMinArray.map((itm) => {
            if (itm.value >= editableData?.expRange?.maxYears) {
              itm.isDisabled = true;
            } else {
              itm.isDisabled = false;
            }
            return itm;
          });
          setMaxExpRange(newMaxArray);
          setMinExpRange(newMinArray);
        }
      } else {
        setForm({
          title: "",
          domain: "",
          minExp: "",
          maxExp: "",
          jobDescription: "",
          mandatorySkills: [],
          optionalSkills: [],
          customField: [],
          screeningQuestions: [],
          customScreeningQuestions: [],
          positionType: "",
          feedbackType: "",
          clientName: "",
          feedbackSystem: "",
          remarks: "",
          feedbackFile: "",
          user: "",
        });
        setIsScreeningQuestion(false);
        setMasterMandatorySkill([]);
        setMasterOptionalSkill([]);
      }
    }, [editableData, OptionalSkill, MandatorySkill]);

    const handleFileChange = (e) => {
      if (!e.target.files || e.target.files.length === 0) {
        setIsJdUploaded(false);
        return;
      }
      if (checkFileValidation(e.target.files[0])) {
        setForm({
          ...form,
          [e.target.name]: e.target.files[0],
        });
        setError({
          ...error,
          [e.target.name]: "",
        });
        setIsJdUploaded(true);
        setError((prevState) => ({
          ...prevState,
          [e.target.name]: "",
        }));
      } else {
        e.target.value = null;
        toast.error(
          <Toast msg="The uploaded file is not a valid file type. Only DOC, DOCX, and PDF files are allowed." />
        );
      }
    };

    const handleSelectChange = (e, name) => {
      if (name === "minExp") {
        const tmpMaxArray = [...maxExpRange];
        const newMaxArray = tmpMaxArray.map((itm) => {
          if (itm.value <= e.value) {
            itm.isDisabled = true;
          } else {
            itm.isDisabled = false;
          }
          return itm;
        });
        setMaxExpRange(newMaxArray);
      } else if (name === "maxExp") {
        const tmpMinArray = [...minExpRange];
        const newMinArray = tmpMinArray.map((itm) => {
          if (itm.value >= e.value) {
            itm.isDisabled = true;
          } else {
            itm.isDisabled = false;
          }
          return itm;
        });
        setMinExpRange(newMinArray);
      } else if (name === "positionType") {
        setError((prevState) => ({
          ...prevState,
          [name]: "",
        }));
        setForm((prevState) => ({
          ...prevState,
          [name]: e.value,
        }));
      }
      setError((prevState) => ({
        ...prevState,
        [name]: "",
      }));
      setForm((prevState) => ({
        ...prevState,
        [name]: e.value,
      }));
    };

    const handleSkillsSelect = (data, type) => {
      if (type.action === "create-option") {
        if (type.name === "mandatorySkills") {
          let skills = type.option.value.split(",");
          skills =
            skills.length > 0 &&
            skills.map((skill) => skill.trim()).filter((skill) => skill !== "");
          if (
            form?.customMandatorySkills.length +
              form?.mandatorySkills.length +
              skills.length >
            4
          ) {
            toast.error(
              <Toast msg={"Mandatory skills cannot exceed more than 4."} />
            );
          } else if (
            form?.customMandatorySkills
              .concat(skills)
              .some((element, index) => {
                return (
                  form?.customMandatorySkills
                    .concat(skills)
                    .indexOf(element) !== index
                );
              })
          ) {
            setError((prev) => ({
              ...prev,
              mandatorySkills: "Duplicate skills are not allowed.",
            }));
          } else {
            let tempSkills = [...form?.customMandatorySkills];
            skills.forEach((skill) => {
              tempSkills.push({
                label: skill,
                value: skill,
              });
            });
            setForm((prev) => ({
              ...prev,
              customMandatorySkills: tempSkills,
            }));
            setError((prev) => ({
              ...prev,
              mandatorySkills: "",
            }));
          }
        } else {
          let skills = type.option.value.split(",");
          skills =
            skills.length > 0 &&
            skills.map((skill) => skill.trim()).filter((skill) => skill !== "");
          if (
            form?.customOptionalSkills.length +
              form?.optionalSkills.length +
              skills.length >
            4
          ) {
            toast.error(
              <Toast msg={"Optional skills cannot exceed more than 4."} />
            );
          } else if (
            form?.customOptionalSkills.concat(skills).some((element, index) => {
              return (
                form?.customOptionalSkills.concat(skills).indexOf(element) !==
                index
              );
            })
          ) {
            setError((prev) => ({
              ...prev,
              optionalSkills: "Duplicate skills are not allowed.",
            }));
          } else {
            let tempSkills = [...form?.customOptionalSkills];
            skills.forEach((skill) => {
              tempSkills.push({
                label: skill,
                value: skill,
              });
            });
            setForm((prev) => ({
              ...prev,
              customOptionalSkills: tempSkills,
            }));
            setError((prev) => ({
              ...prev,
              optionalSkills: "",
            }));
          }
        }
      } else if (type.action === "select-option") {
        if (type.name === "mandatorySkills") {
          let skill = type.option;
          if (
            form?.customMandatorySkills.length + form?.mandatorySkills.length >=
            4
          ) {
            toast.error(
              <Toast msg={"Mandatory skills cannot exceed more than 4."} />
            );
          } else {
            let tempSkills = [...form?.mandatorySkills];
            tempSkills.push(skill);
            setForm((prev) => ({
              ...prev,
              mandatorySkills: tempSkills,
            }));
            setError((prev) => ({
              ...prev,
              mandatorySkills: "",
            }));
          }
        } else {
          let skill = type.option;
          if (
            form?.customOptionalSkills.length + form?.optionalSkills.length >=
            4
          ) {
            toast.error(
              <Toast msg={"Optional skills cannot exceed more than 4."} />
            );
          } else {
            let tempSkills = [...form?.optionalSkills];
            tempSkills.push(skill);
            setForm((prev) => ({
              ...prev,
              optionalSkills: tempSkills,
            }));
            setError((prev) => ({
              ...prev,
              optionalSkills: "",
            }));
          }
        }
      } else if (type.action === "clear") {
        if (type.name === "mandatorySkills") {
          setForm({
            ...form,
            mandatorySkills: [],
            customMandatorySkills: [],
          });
        } else {
          setForm({
            ...form,
            optionalSkills: [],
            customOptionalSkills: [],
          });
        }
      } else {
        if (type.name === "mandatorySkills") {
          const filteredMandatorySkill = form?.mandatorySkills.filter(
            (skill) => skill?.value !== type.removedValue.value
          );
          const filteredCustomMandatorySkill =
            form?.customMandatorySkills.filter(
              (skill) => skill?.value !== type.removedValue.value
            );
          setForm({
            ...form,
            mandatorySkills: filteredMandatorySkill,
            customMandatorySkills: filteredCustomMandatorySkill,
          });
          setError((prev) => ({
            ...prev,
            mandatorySkills: "",
          }));
        } else {
          const filteredOptionalSkill = form?.optionalSkills.filter(
            (skill) => skill?.value !== type.removedValue.value
          );
          const filteredCustomOptionalSkill = form?.customOptionalSkills.filter(
            (skill) => skill?.value !== type.removedValue.value
          );
          setForm({
            ...form,
            optionalSkills: filteredOptionalSkill,
            customOptionalSkills: filteredCustomOptionalSkill,
          });
          setError({
            ...error,
            optionalSkills: "",
          });
        }
      }
    };

    const handleCreatableSelectChange = (data, type) => {
      const tmpForm = { ...form };
      if (type.action === "create-option") {
        if (type.name === "title") {
          if (data.label) {
            setForm({
              ...form,
              title: data.label,
            });
            setError({
              ...error,
              title: "",
            });
            setSelectedPositionTitle({ label: data.label, value: data.value });
            PositionList.push({ label: data.label, value: data.value });
            setSelectedPosition(
              positionList?.data.find((itm) => itm.title === data.value)
            );
          } else {
            setError({
              ...error,
              title: "Special Character is not allowed",
            });
          }
        }
      } else if (type.action === "select-option") {
        if (type.name === "title") {
          setForm({
            ...form,
            title: data.value,
          });
          setError({
            ...error,
            [type.name]: "",
          });
          setSelectedPositionTitle({ label: data.label, value: data.value });
          setSelectedPosition(
            positionList.data.find((itm) => itm.title === data.value)
          );
        }
      }
    };

    const handleChange = (e) => {
      setError((prevState) => ({
        ...prevState,
        [e.target.name]: "",
      }));
      if (e.target.name === "noOfPositions") {
        setForm((prev) => {
          return {
            ...prev,
            noOfPositions: numberChecker(e.target.value).replace(/  /g, " "),
          };
        });
      } else {
        setForm((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value.replace(/  /g, " "),
        }));
      }
    };

    const handleGenrateAiQs = async () => {
      setScreeningQsLoader(true);
      if (form?.title === "") {
        setScreeningQsLoader(false);
        toast.error(<Toast msg="Please add a position" />);
      } else if (form?.mandatorySkills.length === 0) {
        setScreeningQsLoader(false);
        toast.error(<Toast msg="Please add mandatory skills." />);
      } else {
        // getting the label of selected mandatory skill id
        const selectedSkillLabels = form?.mandatorySkills.map((skillId) => {
          const selectedSkill = MandatorySkill.find(
            (skill) => skill.value === skillId
          );
          return selectedSkill ? selectedSkill.label : null;
        });
        try {
          let promptData;
          if (aiScreeningQuestions === "") {
            promptData = `Act as hiring manager and generate screening questions for the evaluation of the candidate for the role of ${
              form?.title
            } which requires following skill set ${[
              selectedSkillLabels,
              ...form?.optionalSkills,
            ]}`;
          } else {
            promptData = `Act as hiring manager and generate screening questions for the evaluation of the candidate for the role of ${
              form?.title
            } which requires following skill set ${[
              selectedSkillLabels,
              ...form?.optionalSkills,
            ]} here are some sample screening question for the given ${
              form?.title
            } and the ${[
              selectedSkillLabels,
              ...form?.optionalSkills,
            ]} that i have provided, from which you can take reference from ${
              aiScreeningQuestions && aiScreeningQuestions.replaceAll("\r", "")
            }.`;
          }

          let formData = {
            title: form?.title,
            skills: selectedSkillLabels,
            prompt: promptData,
          };
          let url =
            generateUrl("interview_backend") +
            `/admin/interviews/generate-screening-questions`;
          const response = await fetch(url, {
            method: "POST",
            body: JSON.stringify(formData),
            headers: {
              Authorization: `Bearer ${localStorageJsonParseDecrypt("token")}`,
              "Content-Type": "application/json",
            },
          });
          setScreeningQsLoader(false);
          const reader = response.body.getReader();
          let receivedData = "";
          function processChunk({ done, value }) {
            const text = new TextDecoder().decode(value);
            // Append the received chunk to the existing data
            receivedData += text;
            // Update the state with the new data
            setAiScreeningQuestions(receivedData);
            // Read the next chunk
            if (done) {
              return;
            }
            return reader.read().then(processChunk);
          }
          // Start reading the chunks
          reader.read().then(processChunk);
        } catch (error) {
          console.error("Error:", error);
        }
      }
    };

    //BELOW FUNCTION IS USED TO CHANGE CUSTOM INPUTS
    const handleCustomFieldChange = (e, name) => {
      if (name === "fieldValue2") {
        setNewFieldData((prev) => {
          return {
            ...prev,
            fieldValue: e.target.value,
          };
        });
      } else if (name === "fieldValue") {
        setNewFieldData((prev) => {
          return {
            ...prev,
            fieldValue: e.target.value,
          };
        });
      } else if (e.target.name === "fieldName") {
        setNewFieldData((prev) => {
          return {
            ...prev,
            fieldName: e.target.value.replace(/  /g, " "),
          };
        });
      } else {
        setNewFieldData((prev) => ({
          ...prev,
          [e.target.name]: e.target.value.replace(/  /g, " "),
        }));
      }
    };

    useEffect(() => {
      if (customFileDetails?.file) {
        if (form?.customField.length > 0 && customFileDetails?.index > -1) {
          let tmpList = form?.customField;
          tmpList[customFileDetails?.index]["fieldValue"] =
            customFileDetails?.file;
          setForm((prevState) => ({
            ...prevState,
            customField: tmpList,
          }));
        } else if (newFieldData.fieldType === "file") {
          setNewFieldData((prevState) => ({
            ...prevState,
            fieldValue: customFileDetails?.file,
          }));
        }
      }
    }, [customFileDetails]);

    //BELOW FUNCTION IS USED TO CHANGE CUSTOM FILE INPUTS
    const handleCustomFileChange = (e, index, name) => {
      if (e.target.name === "fieldValue") {
        if (checkFileValidation(e.target.files[0])) {
          dispatch(
            addCustomFile({
              file: e.target.files[0],
              index: index,
            })
          );
        } else {
          e.target.value = null;
          toast.error(
            <Toast msg="Invalid File Format: Please upload a valid document file. Only files with the .doc, .docx, or .pdf extensions are allowed." />
          );
        }
      }
    };

    //BELOW FUNCTION IS USED TO DELETE CUSTOM FIELD FROM FORM DATA
    const handleDeleteCustomAttributes = (index) => {
      let tempCustomAttributes = [...form?.customField];
      tempCustomAttributes.splice(index, 1);
      setForm((prev) => {
        return { ...prev, customField: tempCustomAttributes };
      });
    };

    const handleCustomSelectChange = (e, name) => {
      setNewFieldData((prevState) => ({
        ...prevState,
        [name]: e.value,
      }));
    };

    const handleChangeCustomAttributes = (e, index, type) => {
      let tempCustomAttributes = [...form?.customField];
      if (type === "number") {
        tempCustomAttributes[index].fieldValue = e.target.value.replace(
          /\D/g,
          ""
        );
        setForm((prev) => {
          return { ...prev, customField: tempCustomAttributes };
        });
      } else {
        tempCustomAttributes[index].fieldValue = e.target.value;
        setForm((prev) => {
          return { ...prev, customField: tempCustomAttributes };
        });
      }
    };

    const handleAddFieldButton = () => {
      setAddField(true);
      if (addField) {
        toast.error(
          <Toast msg={"Please save field before adding new field."} />
        );
      }
    };

    const handleAddNewField = () => {
      if (newFieldData?.fieldName === "") {
        toast.error(<Toast msg={"Field name cannot be empty."} />);
      } else if (newFieldData?.fieldType === "") {
        toast.error(<Toast msg={"Field type cannot be empty."} />);
      } else {
        let newValueList = [newFieldData];
        setForm((prev) => {
          return {
            ...prev,
            customField: [...form?.customField, ...newValueList],
          };
        });
        setAddField(false);
        setNewFieldData({
          fieldName: "",
          fieldValue: "",
          fieldType: "",
          required: true,
          interviewerVisibility: false,
          fieldPlaceHolder: "Enter Value Here",
        });
      }
    };
    const handleSubmit = (e) => {
      e.preventDefault();
      const { errors, isValid } = validateEditIncservePosition({
        form,
        customMandatorySkill,
        customOptionalSkill,
      });
      const {
        title,
        domain,
        minExp,
        maxExp,
        jobDescription,
        screeningQuestions,
        customScreeningQuestions,
        feedbackType,
        clientName,
        positionType,
        feedbackSystem,
        mandatorySkills,
        customMandatorySkills,
        optionalSkills,
        customOptionalSkills,
        remarks,
        feedbackFile,
        customField,
        user,
      } = form;
      if (isValid) {
        const data = {
          title,
          domain,
          expRange: { minYears: minExp, maxYears: maxExp },
          jobDescription,
          // skills: {
          //   mandatory: mandatorySkills.filter((skill) => skill !== ""),
          //   optional: optionalSkills.filter((skill) => skill !== ""),
          // },
          // customSkills: {
          //   mandatory: customMandatorySkill.filter((skill) => skill !== ""),
          //   optional: customOptionalSkill.filter((skill) => skill !== ""),
          // },
          skills: {
            mandatory:
              mandatorySkills.length > 0
                ? mandatorySkills.map((skill) => skill?.value)
                : [],
            optional:
              optionalSkills.length > 0
                ? optionalSkills.map((skill) => skill?.value)
                : [],
          },
          customSkills: {
            mandatory:
              customMandatorySkills.length > 0
                ? customMandatorySkills.map((skill) => skill?.value)
                : [],
            optional:
              customOptionalSkills.length > 0
                ? customOptionalSkills.map((skill) => skill?.value)
                : [],
          },
          screeningQuestions: screeningQuestions.filter((qs) => qs !== ""),
          customScreeningQuestions: customScreeningQuestions.filter(
            (qs) => qs !== ""
          ),
          clientName,
          feedbackType: form?.feedbackFile ? "automation" : "manual",
          positionType,
          remarks,
          feedbackFile,
          user,
          customField,
        };
        dispatch(
          createOrUpdateIncservePosition({
            id: editableData?._id,
            data,
            callback: () => {
              dispatch(
                getIncservePositions({
                  sort: sortBy,
                  search: search,
                  page: currentPage,
                  pageSize: entriesLimit,
                })
              );
              setIsEditable(false);
            },
          })
        );
      } else {
        setError(errors);
      }
    };

    const positionTypeList = [
      {
        value: "",
        label: "Select Position For",
      },
      {
        value: "Internal Hiring",
        label: "Internal Hiring",
      },
      {
        value: "Client Hiring",
        label: "Client Hiring",
      },
    ];
    const customFieldTypeOptions = [
      {
        value: "",
        label: "Select Field Type",
      },
      {
        label: "Text",
        value: "alphanumeric",
      },
      {
        label: "File",
        value: "file",
      },
      {
        label: "Number",
        value: "number",
      },
    ];

    return (
      <div className="page-container eep">
        <div className="mpl-title-container">
          <div className="text-theme-blue f-sem-bol f-20 d-flex align-items-center">
            <BackButton
              onClick={() => {
                setIsEditable(false);
              }}
            />
            <span className="ms-3" />
          </div>
          <div className="f-sem-bol f-20 d-flex align-items-center">
            {editableData?.code}
            {"  "}
            {editableData?.employerName}
          </div>
        </div>
        <div className="cpp-data d-flex flex-wrap justify-content-between w-100">
          <div className="cpp-data d-flex flex-wrap justify-content-between">
            <div className="cpp-input-container mb-2">
              <CreatableSelect
                name="title"
                value={selectedPositionTitle}
                error={error?.title}
                label="Job Title"
                isRequired={true}
                onChange={handleCreatableSelectChange}
                options={PositionList}
                labelClass="my-1 f-l f-sem-bol"
                inputClass="f-l f-reg rounded input-shadow top"
                placeholder={"Enter job title"}
                onMenuOpen={() => {
                  setForm((prev) => ({ ...prev, title: "" }));
                  setSelectedPositionTitle(null);
                  setSelectedPosition({});
                }}
              />
            </div>
            <div className="cpp-input-container mb-2">
              <SearchSelect
                name="domain"
                value={form?.domain}
                error={error?.domain}
                label="Domain"
                placeholder={"Please select domain"}
                isRequired={true}
                onChange={(e) => handleSelectChange(e, "domain")}
                options={DomainList}
                labelClass="my-1 f-l f-sem-bol"
                inputClass="f-l f-reg rounded input-shadow top"
              />
            </div>
            <div className="cpp-input-container cstm-select-box mb-2">
              <SearchSelect
                name="positionType"
                value={form.positionType}
                error={error?.positionType}
                label="Position For"
                isRequired={false}
                onChange={(e) => handleSelectChange(e, "positionType")}
                options={positionTypeList}
                labelClass="my-1 f-l f-sem-bol"
                inputClass="f-l f-reg rounded input-shadow top"
              />
            </div>
            <div className="cpp-input-container mb-2">
              <Input
                label="Client Name"
                labelClass="my-1 f-l f-sem-bol"
                inputClass="f-l f-reg rounded input-shadow"
                className={"common-input"}
                name="clientName"
                type="text"
                disabled={
                  form?.positionType === "Internal Hiring" ? true : false
                }
                value={form?.clientName}
                error={error?.clientName}
                onChange={handleChange}
                placeholder="Enter client name"
                isRequired={
                  form?.positionType === "Internal Hiring" ? false : true
                }
                borderNone
              />
            </div>
            <div className="d-flex justify-content-between w-48 mb-2">
              <div className="cpp-input-container w-48">
                <ExperienceSearchSelect
                  name="minExp"
                  value={form?.minExp}
                  error={error?.minExp}
                  label="Experience From"
                  isRequired={true}
                  onChange={(e) => handleSelectChange(e, "minExp")}
                  options={minExpRange}
                  labelClass="my-1 f-l f-sem-bol"
                  inputClass="f-l f-reg rounded input-shadow"
                />
              </div>
              <div className="cpp-input-container w-48">
                <ExperienceSearchSelect
                  name="maxExp"
                  value={form?.maxExp}
                  error={error?.maxExp}
                  label="Experience To"
                  isRequired={true}
                  onChange={(e) => handleSelectChange(e, "maxExp")}
                  options={maxExpRange}
                  labelClass="my-1 f-l f-sem-bol"
                  inputClass="f-l f-reg rounded input-shadow"
                />
              </div>
            </div>
            <div className="d-flex justify-content-between w-48 mb-2">
              <div className="cpp-input-container w-65">
                <label
                  htmlFor={"JobDescription"}
                  className={`c-label my-1 f-l f-sem-bol`}>
                  Job Description
                  <span className="asterisk f-12">&#42;</span>
                </label>
                <div
                  className={`position-relative file-input bg-white d-flex align-items-center justify-content-end rounded`}>
                  <input
                    id="fileInput"
                    type={"file"}
                    className={`${
                      error ? "error" : ""
                    } f-l f-reg rounded input-shadow position-relative real-file-input cursor-pointer ${
                      form?.jobDescription &&
                      !form?.jobDescription?.name &&
                      "hidden"
                    }`}
                    name={"jobDescription"}
                    onChange={handleFileChange}
                    ref={hiddenFileInput}
                  />
                  <div
                    className={`cursor-pointer f-l f-reg rounded input-shadow real-file-input position-relative ${
                      (!form?.jobDescription || form?.jobDescription?.name) &&
                      "hidden"
                    }`}
                    onClick={() => {
                      hiddenFileInput.current.click();
                    }}>
                    {form?.jobDescription?.name
                      ? form?.jobDescription?.name
                      : form?.jobDescription}
                  </div>
                  <img
                    src={file}
                    alt="file"
                    className="file h-75 position-absolute img-fluid"
                  />
                </div>
                <span className="upload-type f-8 f-reg">
                  *supported file types are .pdf, .docx, .doc
                </span>
                {error?.jobDescription && (
                  <div className={`invalid`}>{error?.jobDescription}</div>
                )}
              </div>
              <div className="w-40 mt-1_7">
                {!isCreatedJD ? (
                  <Button
                    text={"Create JD"}
                    className={`common-btn create-jd-btn f-12 f-sem-bol`}
                    onClick={() => handleCreateJD()}
                  />
                ) : (
                  <Button
                    text={"Create JD"}
                    className={`common-btn create-jd-btn-2 f-12 f-sem-bol`}
                  />
                )}
              </div>
            </div>
            <div className="cpp-input-container cstm-select-box mb-2">
              <SkillsSelect
                name="mandatorySkills"
                value={form?.mandatorySkills.concat(
                  form?.customMandatorySkills
                )}
                // error={error.mandatorySkills}
                label="Mandatory Skills"
                isRequired={true}
                isMulti={true}
                onChange={handleSkillsSelect}
                options={MandatorySkill}
                labelClass="my-1 f-l f-sem-bol"
                inputClass="f-l f-reg rounded input-shadow"
                onInputChange={(e) => skillChecker(e)}
                placeholder={"Add or select skill(s)"}
              />
            </div>
            <div className="cpp-input-container cstm-select-box w-48 mb-2">
              <SkillsSelect
                name="optionalSkills"
                value={form?.optionalSkills.concat(form?.customOptionalSkills)}
                // error={error.optionalSkills}
                label="Good to have Skills"
                isMulti={true}
                onChange={handleSkillsSelect}
                options={OptionalSkill}
                labelClass="my-1 f-l f-sem-bol"
                inputClass="f-l f-reg rounded input-shadow"
                onInputChange={(e) => skillChecker(e)}
                placeholder={"Add or select skill(s)"}
              />
            </div>
            <div className="d-flex justify-content-between w-48">
              <div className="cpp-input-container w-100">
                <label
                  htmlFor={"feedbackFile"}
                  className={`c-label my-1 f-l f-sem-bol`}>
                  Feedback Format
                </label>
                <div
                  className={`position-relative file-input bg-white d-flex align-items-center justify-content-end rounded`}>
                  <input
                    id="fileInput"
                    type={"file"}
                    className={`${
                      error ? "error" : ""
                    } f-l f-reg rounded input-shadow position-relative real-file-input cursor-pointer ${
                      form?.feedbackFile &&
                      !form?.feedbackFile?.name &&
                      "hidden"
                    }`}
                    name={"feedbackFile"}
                    onChange={handleFileChange}
                    ref={hiddenFileInputRef}
                  />
                  <div
                    className={`cursor-pointer f-l f-reg rounded input-shadow real-file-input position-relative ${
                      (!form?.feedbackFile || form?.feedbackFile?.name) &&
                      "hidden"
                    }`}
                    onClick={() => {
                      hiddenFileInputRef.current.click();
                    }}>
                    {form?.feedbackFile?.name
                      ? form?.feedbackFile?.name
                      : form?.feedbackFile}
                  </div>
                  <img
                    src={file}
                    alt="file"
                    className="file h-75 position-absolute img-fluid"
                  />
                </div>
                <span className="upload-type f-8 f-reg">
                  *supported file types are .pdf, .docx, .doc
                </span>
                {error?.feedbackFile && (
                  <div className={`invalid`}>{error?.feedbackFile}</div>
                )}
              </div>
            </div>
            <div className="d-flex flex-wrap justify-content-between w-100">
              {/* BELOW CODE FOR TO SHOW ALLREADY ADDED CUSTOM FIELDS */}
              {form?.customField &&
                form?.customField.length > 0 &&
                form?.customField.map((data, index) => (
                  <>
                    {data?.fieldType === "file" ? (
                      <div className="cpp-input-container">
                        <div className="d-flex align-items-center justify-content-between">
                          <div>
                            <label
                              htmlFor={"fieldValue"}
                              className={`c-label my-1 f-l f-sem-bol`}>
                              {capitalizeAll(data?.fieldName)}
                            </label>
                            {data?.required ? (
                              <span className="asterisk f-12">&#42;</span>
                            ) : (
                              ""
                            )}
                          </div>
                          <img
                            src={removeField}
                            alt="delete custom field"
                            className="remove-field cursor-pointer"
                            onClick={() => handleDeleteCustomAttributes(index)}
                            title="remove field"
                          />
                        </div>
                        <div
                          className={`position-relative file-input bg-white d-flex align-items-center justify-content-end rounded`}>
                          <input
                            id="fileInput"
                            type={"file"}
                            className={`${
                              error ? "error" : ""
                            } f-l f-reg rounded input-shadow position-relative real-file-input cursor-pointer ${
                              data?.fieldValue && "hidden"
                            }`}
                            name={"fieldValue"}
                            onChange={(e) =>
                              handleCustomFileChange(e, index, data?.fieldName)
                            }
                            ref={(el) => (refsArray.current[index] = el)}
                          />
                          <div
                            className={`f-l f-reg rounded input-shadow real-file-input position-relative cursor-pointer ${
                              !data?.fieldValue && "hidden"
                            }`}
                            onClick={() => {
                              refsArray.current[index].click();
                            }}>
                            {data?.fieldValue
                              ? data?.fieldValue
                              : data?.fieldValue}
                          </div>
                          <img
                            src={file}
                            alt="file"
                            className="file h-75 position-absolute img-fluid"
                          />
                        </div>
                        <span className="upload-type f-ph f-reg">
                          *supported file types are .pdf, .docx, .doc
                        </span>
                      </div>
                    ) : data.fieldType === "number" ? (
                      <div className="cpp-input-container" key={index}>
                        <CustomInput
                          label={capitalize(data?.fieldName)}
                          value={data?.fieldValue}
                          onChange={(e) => {
                            handleChangeCustomAttributes(e, index, "number");
                          }}
                          deleteField={() =>
                            handleDeleteCustomAttributes(index)
                          }
                          isRequired={data?.required}
                          placeholder={"New custom field"}
                          type={"text"}
                          labelClass={"my-1 f-l f-sem-bol"}
                          inputClass={"f-l f-reg rounded input-shadow"}
                          className={"common-input"}
                          borderNone
                        />
                      </div>
                    ) : (
                      <div className="cpp-input-container" key={index}>
                        <CustomInput
                          label={capitalize(data?.fieldName)}
                          value={data?.fieldValue}
                          onChange={(e) =>
                            handleChangeCustomAttributes(e, index)
                          }
                          deleteField={() =>
                            handleDeleteCustomAttributes(index)
                          }
                          isRequired={data?.required}
                          placeholder={"New custom field"}
                          type={"text"}
                          labelClass={"my-1 f-l f-sem-bol"}
                          inputClass={"f-l f-reg rounded input-shadow"}
                          className={"common-input"}
                          borderNone
                        />
                      </div>
                    )}
                  </>
                ))}
              {/* BELOW CODE FOR TO ADD CUSTOM FIELDS */}
              {addField && (
                <>
                  <div className="mt-4 w-100">
                    <div className="f-sem-bol text-theme-blue f-14 single-candidate-subtitle">
                      Setup Custom Field
                    </div>
                    <div className="d-flex flex-wrap justify-content-between">
                      <div className="custom-container w-100">
                        <div className="d-flex justify-content-end">
                          <Button
                            type="button"
                            className={"btn-close f-10"}
                            label={"Close"}
                            onClick={() => setAddField(false)}
                          />
                        </div>
                        <div className="d-flex flex-wrap justify-content-between">
                          <div className="d-flex justify-content-between w-50">
                            <span className="cpp-input-container w-100">
                              <Input
                                label="Field Name"
                                name="fieldName"
                                onChange={(e) =>
                                  handleCustomFieldChange(e, "fieldName")
                                }
                                placeholder="Enter field name"
                                labelClass={"my-1 f-l f-sem-bol"}
                                inputClass={"f-l f-reg rounded input-shadow"}
                                className={"common-input"}
                                borderNone
                                isRequired={true}
                              />
                            </span>
                          </div>
                          <div className="cpp-input-container mb-2">
                            <SearchSelect
                              name="fieldType"
                              label="Field Type"
                              isMulti={false}
                              removeSearch={true}
                              options={customFieldTypeOptions}
                              onChange={(e) =>
                                handleCustomSelectChange(e, "fieldType")
                              }
                              labelClass="my-1 f-l f-sem-bol"
                              inputClass="f-l f-reg rounded input-shadow"
                              placeholder={"Select field type"}
                              isRequired={true}
                            />
                          </div>
                          <div className="cpp-input-container">
                            <ExperienceSearchSelect
                              name="interviewerVisibility"
                              label="Send To Interviewer ?"
                              isMulti={false}
                              isSearchable={false}
                              defaultValue={{
                                value: false,
                                label: "No",
                              }}
                              options={[
                                {
                                  value: true,
                                  label: "Yes",
                                },
                                {
                                  value: false,
                                  label: "No",
                                },
                              ]}
                              onChange={(e) =>
                                handleCustomSelectChange(
                                  e,
                                  "interviewerVisibility"
                                )
                              }
                              labelClass="my-1 f-l f-sem-bol"
                              inputClass="f-l f-reg rounded input-shadow"
                              placeholder={"Select field type"}
                            />
                          </div>
                          <div className="cpp-input-container">
                            <Button
                              text={"Save Field"}
                              className={`f-l cpp-btn f-sem-bol save-field`}
                              onClick={() => handleAddNewField()}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className="d-flex flex-column justify-content-center add-custom-field-box w-100 mt-1">
                <div
                  className="add-qs f-sem-bol my-1 f-ph text-primary cursor-pointer"
                  onClick={() => handleAddFieldButton()}>
                  + Add Custom Field
                </div>
              </div>
            </div>
            <div className="cpp-input-container w-100">
              <Textarea
                name="remarks"
                value={form?.remarks}
                placeholder={"Please enter interview instructions"}
                labelClass={"my-2 f-12 f-sem-bol"}
                textClass={"f-12 rounded input-shadow"}
                label={"Interview Instructions"}
                onChange={handleChange}
              />
            </div>
            <div
              className="add-qs f-sem-bol my-1 f-ph text-primary cursor-pointer"
              onClick={() => {
                setIsScreeningQuestion(true);
                setIsCreatedJDVisible(false);
              }}>
              + Add Screening Questions (optional)
            </div>
            <div className="ac-btn-container w-100 mt-2">
              <Button
                text={"Submit"}
                className={"common-btn f-12 f-sem-bol mx-auto"}
                onClick={handleSubmit}
              />
            </div>
          </div>
          <AddQuestions
            screeningQuestion={screeningQuestion}
            aiScreeningQuestions={aiScreeningQuestions}
            isScreeningQuestion={isScreeningQuestion}
            setIsScreeningQuestion={setIsScreeningQuestion}
            customScreeningQuestionLists={customScreeningQuestionLists}
            setCustomScreeningQuestionLists={setCustomScreeningQuestionLists}
            setForm={setForm}
            screeningQsLoader={screeningQsLoader}
            form={form}
            setQuestion={setQuestion}
            handleGenrateAiQs={handleGenrateAiQs}
            setNewQuestions={setNewQuestions}
            question={question}
            newQuestions={newQuestions}
            isJdUploaded={isJdUploaded}
            inputQuestion={inputQuestion}
            setInputQuestion={setInputQuestion}
            screeningQuestionsList={screeningQuestionsList}
            setScreeningQuestionsList={setScreeningQuestionsList}
            allAIQuestions={allAIQuestions}
            setAllAIQuestions={setAllAIQuestions}
            directUploadQuestion={directUploadQuestion}
            setDirectUploadQuestion={setDirectUploadQuestion}
          />
          <CreateJD
            jDData={jdData}
            isCreatedJD={isCreatedJDVisible}
            setIsCreatedJD={setIsCreatedJDVisible}
            JDTitle={capitalizeAll(selectedPositionTitle?.label)}
            isDownloadJdButtonVisible={isDownloadJdButtonVisible}
          />
        </div>
      </div>
    );
  }
);
