import { all, put, takeEvery } from "redux-saga/effects";
import userAuth from "../../utils/api/userAuth";
import {
  getCreditDescriptionFailure,
  getCreditDescriptionSuccess,
} from "../action/productPermissionActions";
import { toast } from "react-toastify";
import { Toast } from "../../utils/helper";
import { GET_CREDIT_DESCRIPTION } from "../action/types";

function* getCreditDescriptionRequest(action) {
  const productId = action.payload.productId;
  const userId = action.payload.userId;
  try {
    const res = yield userAuth.get(
      `/admin/permissions/creditIncfeedIncvid-stats?productId=${productId}&user=${userId}
        `
    );
    if (res.status === 200) {
      yield put(getCreditDescriptionSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getCreditDescriptionFailure());
    yield e?.response?.data?.message &&
      toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

export function* watchGetCreditDescriptionRequestAPI() {
  yield takeEvery(GET_CREDIT_DESCRIPTION, getCreditDescriptionRequest);
}

export default function* rootSaga() {
  yield all([watchGetCreditDescriptionRequestAPI()]);
}
