import CloseButton from "./CloseButton";
import NoRecordFound from "./NoRecordFound";
import {
  Toast,
  capitalize,
  capitalizeAll,
  checkPermissions,
  downloadFile,
  downloadImage,
  localStorageJsonParseDecrypt,
  useOutsideAlerter,
} from "../../utils/helper";
import downloadImg from "../../assets/images/download_icon.svg";
import CALL_ICON from "../../assets/images/call_icon.svg";
import { useEffect, useState } from "react";
import eye from "../../assets/images/blue-eye.svg";
import requestedReason from "../../assets/images/requestedReason.svg";
import greenTick from "../../assets/images/green-tick-circle.svg";
import redTick from "../../assets/images/red-tick.svg";
import video from "../../assets/admin Images/video-cam-blue.svg";

import { useDispatch, useSelector } from "react-redux";
import {
  changeInterviewerStatus,
  getDomain,
  getIncserveInterviewersDetails,
  getSimilarWebsiteList,
  getSkills,
  getInterviewerList,
  updateInterviewerAdminRemarksInfo,
  putInterviewerMockRemarksInfo,
} from "../../redux/action";
import { Rating } from "react-simple-star-rating";
import {
  Button,
  CreatableSelect,
  Input,
  SearchSelect,
  Textarea,
} from "../../component/commonComponent";
import { useMemo } from "react";
import ExperienceSearchSelect from "./ExperienceSearchSelect";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useRef } from "react";
import { Tooltip } from "react-tooltip";
import MeetingLinkBtn from "./MeetingLinkBtn";

const InterviewerInfoTable = ({
  currentPage,
  entriesLimit,
  inputWord,
  interviewerId,
  setAudioLogs,
  setInterviewerId,
  setIsEditable,
  status,
  setVerifyLinkdin,
  type,
  setInterviewerIDForLogs,
  callbackDispatch,
  setCallbackDispatch,
}) => {
  const infoRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const interviewerDetails = useSelector(
    (state) => state.Interview.incserveInterviewersDetails
  );

  const role = localStorageJsonParseDecrypt("role");

  const skillsList = useSelector((state) => state.Skills.skills);
  const domains = useSelector((state) => state.Skills.domain);
  const similarWebsiteList = useSelector(
    (state) => state.Interview.similarWebsiteList
  );
  const interviewerLogs = useSelector(
    (state) => state.UserAuth.employerAccountLogs
  );
  const [adminRemarksForm, setAdminRemarksForm] = useState({
    companyType: interviewerDetails?.adminRemarks?.companyType
      ? interviewerDetails?.adminRemarks?.companyType
      : "",
    shiftTiming: interviewerDetails?.adminRemarks?.shiftTiming
      ? interviewerDetails?.adminRemarks?.shiftTiming
      : "",
    alternatePhone: interviewerDetails?.adminRemarks?.alternatePhone
      ? interviewerDetails?.adminRemarks?.alternatePhone
      : "",
    totalExperience: interviewerDetails?.adminRemarks?.totalExperience
      ? interviewerDetails?.adminRemarks?.totalExperience
      : "",
    relevantExperience: interviewerDetails?.adminRemarks?.relevantExperience
      ? interviewerDetails?.adminRemarks?.relevantExperience
      : "",
    domain: interviewerDetails?.domain?._id
      ? interviewerDetails?.domain?._id
      : "",
    skills:
      interviewerDetails?.adminRemarks?.expertSkills &&
      interviewerDetails?.adminRemarks?.expertSkills.length > 0
        ? interviewerDetails?.adminRemarks?.expertSkills.map((skill) => ({
            label: skill?.name,
            value: skill?._id,
          }))
        : [],
    customSkills: [],
    coreExperience: interviewerDetails?.adminRemarks?.coreExperience
      ? interviewerDetails?.adminRemarks?.coreExperience
      : "",
    approxInterviews: interviewerDetails?.adminRemarks?.approxInterviews
      ? interviewerDetails?.adminRemarks?.approxInterviews
      : { number: "", type: "" },
    candidateExperience: interviewerDetails?.adminRemarks?.candidateExperience
      ? interviewerDetails?.adminRemarks?.candidateExperience
      : { min: "", max: "" },
    telephonic_or_videoConf: interviewerDetails?.adminRemarks
      ?.telephonic_or_videoConf
      ? interviewerDetails?.adminRemarks?.telephonic_or_videoConf
      : false,
    actualInterviews: interviewerDetails?.adminRemarks?.actualInterviews
      ? interviewerDetails?.adminRemarks?.actualInterviews
      : { number: "", type: "" },
    preferredWeekdayTimings: interviewerDetails?.adminRemarks
      ?.preferredWeekdayTimings
      ? interviewerDetails?.adminRemarks?.preferredWeekdayTimings
      : { start: "", end: "" },
    preferredWeekendTimings: interviewerDetails?.adminRemarks
      ?.preferredWeekendTimings
      ? interviewerDetails?.adminRemarks?.preferredWeekendTimings
      : { start: "", end: "" },
    currentCTC: interviewerDetails?.adminRemarks?.currentCTC
      ? interviewerDetails?.adminRemarks?.currentCTC
      : {
          value: "",
          currency: "INR",
        },
    payPerInterview: interviewerDetails?.adminRemarks?.payPerInterview
      ? interviewerDetails?.adminRemarks?.payPerInterview
      : {
          value: "",
          currency: "INR",
        },
    similarRegisteredWebsites:
      interviewerDetails?.adminRemarks?.similarRegisteredWebsites &&
      interviewerDetails?.adminRemarks?.similarRegisteredWebsites.length > 0
        ? interviewerDetails?.adminRemarks?.similarRegisteredWebsites.map(
            (data) => ({ label: data?.name, value: data?._id })
          )
        : [],
    customSimilarRegisteredWebsites: [],
    remarks: interviewerDetails?.adminRemarks?.remarks
      ? interviewerDetails?.adminRemarks?.remarks
      : "",
    communicationSkills: interviewerDetails?.ratings?.communicationSkills
      ? interviewerDetails?.ratings?.communicationSkills
      : {
          score: "",
          total: 10,
        },
    jobKnowledge: interviewerDetails?.ratings?.jobKnowledge
      ? interviewerDetails?.ratings?.jobKnowledge
      : {
          score: "",
          total: 10,
        },
    confidence: interviewerDetails?.ratings?.confidence
      ? interviewerDetails?.ratings?.confidence
      : {
          score: "",
          total: 10,
        },
    interviewExperience: interviewerDetails?.ratings?.interviewExperience
      ? interviewerDetails?.ratings?.interviewExperience
      : {
          score: "",
          total: 10,
        },
  });
  const [mockForm, setMockForm] = useState({
    remarks: "",
  });
  const ratingOptions = [
    {
      label: "0",
      value: 0,
    },
    {
      label: "1",
      value: 1,
    },
    {
      label: "2",
      value: 2,
    },
    {
      label: "3",
      value: 3,
    },
    {
      label: "4",
      value: 4,
    },
    {
      label: "5",
      value: 5,
    },
    {
      label: "6",
      value: 6,
    },
    {
      label: "7",
      value: 7,
    },
    {
      label: "8",
      value: 8,
    },
    {
      label: "9",
      value: 9,
    },
    {
      label: "10",
      value: 10,
    },
  ];
  // useEffect(() => {
  //   if (sideSectionData) {
  //     dispatch(getEmployerAccountLogs({ user: sideSectionData?._id }));
  //   }
  // }, [dispatch, sideSectionData]);

  useOutsideAlerter(infoRef, () => setInterviewerId(null));
  useEffect(() => {
    dispatch(
      getIncserveInterviewersDetails({
        interviewerId: interviewerId,
      })
      // getEmployerAccountLogs({ interviewerId: interviewerId })
    );
  }, [interviewerId, dispatch]);

  useEffect(() => {
    dispatch(getSkills());
    dispatch(getDomain());
    dispatch(getSimilarWebsiteList());
  }, [dispatch]);

  const SkillsList = useMemo(() => {
    let list = [];
    if (skillsList && skillsList.length > 0) {
      skillsList.forEach((skill) => {
        list.push({
          label: skill?.name,
          value: skill?._id,
        });
      });
    }
    return list;
  }, [skillsList]);

  const DomainList = useMemo(() => {
    let list = [];
    if (domains && domains.length > 0) {
      domains.forEach((domain) => {
        list.push({
          label: domain?.name,
          value: domain?._id,
        });
      });
    }
    return list;
  }, [domains]);

  const WebsiteList = useMemo(() => {
    let list = [];
    if (similarWebsiteList && similarWebsiteList.length > 0) {
      similarWebsiteList.forEach((data) => {
        list.push({
          label: data?.name,
          value: data?._id,
        });
      });
    }
    return list;
  }, [similarWebsiteList]);

  const handleCreatableSelectChange = (x, y, name) => {
    if (name === "skills") {
      if (y.action === "select-option") {
        let tempList = adminRemarksForm?.skills;
        tempList.push({ label: y.option.label, value: y.option.value });
        setAdminRemarksForm((prev) => ({ ...prev, skills: tempList }));
      } else if (y.action === "create-option") {
        let tempList = adminRemarksForm?.customSkills;
        tempList.push({ label: y.option.label, value: y.option.value });
        SkillsList.push({ label: y.option.label, value: y.option.value });
        setAdminRemarksForm((prev) => ({ ...prev, customSkills: tempList }));
      } else if (y.action === "remove-value") {
        let tempSkillsList = adminRemarksForm?.skills;
        tempSkillsList = tempSkillsList.filter(
          (data) => data?.value !== y.removedValue.value
        );
        let tempCustSkillsList = adminRemarksForm?.customSkills;
        tempCustSkillsList = tempCustSkillsList.filter(
          (data) => data?.value !== y.removedValue.value
        );
        setAdminRemarksForm((prev) => ({
          ...prev,
          skills: tempSkillsList,
          customSkills: tempCustSkillsList,
        }));
      } else if (y.action === "clear") {
        setAdminRemarksForm((prev) => ({
          ...prev,
          skills: [],
          customSkills: [],
        }));
      }
    } else if (name === "websites") {
      if (y.action === "select-option") {
        let tempList = adminRemarksForm?.similarRegisteredWebsites;
        tempList.push({ label: y.option.label, value: y.option.value });
        setAdminRemarksForm((prev) => ({
          ...prev,
          similarRegisteredWebsites: tempList,
        }));
      } else if (y.action === "create-option") {
        let tempList = adminRemarksForm?.customSimilarRegisteredWebsites;
        tempList.push({ label: y.option.label, value: y.option.value });
        WebsiteList.push({ label: y.option.label, value: y.option.value });
        setAdminRemarksForm((prev) => ({
          ...prev,
          customSimilarRegisteredWebsites: tempList,
        }));
      } else if (y.action === "remove-value") {
        let tempSkillsList = adminRemarksForm?.similarRegisteredWebsites;
        tempSkillsList = tempSkillsList.filter(
          (data) => data?.value !== y.removedValue.value
        );
        let tempCustSkillsList =
          adminRemarksForm?.customSimilarRegisteredWebsites;
        tempCustSkillsList = tempCustSkillsList.filter(
          (data) => data?.value !== y.removedValue.value
        );
        setAdminRemarksForm((prev) => ({
          ...prev,
          similarRegisteredWebsites: tempSkillsList,
          customSimilarRegisteredWebsites: tempCustSkillsList,
        }));
      } else if (y.action === "clear") {
        setAdminRemarksForm((prev) => ({
          ...prev,
          similarRegisteredWebsites: [],
          customSimilarRegisteredWebsites: [],
        }));
      }
    }
  };

  const handleSaveChanges = () => {
    let data = {
      alternatePhone: adminRemarksForm?.alternatePhone,
      totalExperience: adminRemarksForm?.totalExperience,
      relevantExperience: adminRemarksForm?.relevantExperience,
      domain: adminRemarksForm?.domain,
      skills:
        adminRemarksForm?.skills && adminRemarksForm?.skills.length > 0
          ? adminRemarksForm?.skills.map((skill) => skill?.value)
          : [],
      customSkills:
        adminRemarksForm?.customSkills &&
        adminRemarksForm?.customSkills.length > 0
          ? adminRemarksForm?.customSkills.map((skill) => skill?.value)
          : [],
      coreExperience: adminRemarksForm?.coreExperience,
      candidateExperience: adminRemarksForm?.candidateExperience,
      approxInterviews: adminRemarksForm?.approxInterviews,
      actualInterviews: adminRemarksForm?.actualInterviews,
      telephonic_or_videoConf: adminRemarksForm?.telephonic_or_videoConf,
      preferredWeekdayTimings: adminRemarksForm?.preferredWeekdayTimings,
      preferredWeekendTimings: adminRemarksForm?.preferredWeekendTimings,
      currentCTC: adminRemarksForm?.currentCTC,
      payPerInterview: adminRemarksForm?.payPerInterview,
      similarRegisteredWebsites:
        adminRemarksForm?.similarRegisteredWebsites &&
        adminRemarksForm?.similarRegisteredWebsites.length > 0
          ? adminRemarksForm?.similarRegisteredWebsites.map(
              (data) => data?.value
            )
          : [],
      customSimilarRegisteredWebsites:
        adminRemarksForm?.customSimilarRegisteredWebsites &&
        adminRemarksForm?.customSimilarRegisteredWebsites.length > 0
          ? adminRemarksForm?.customSimilarRegisteredWebsites.map(
              (data) => data?.value
            )
          : [],
      // companyType: adminRemarksForm?.companyType,
      // shiftTiming: adminRemarksForm?.shiftTiming,
      remarks: adminRemarksForm?.remarks,
      ratings: {
        communicationSkills: adminRemarksForm?.communicationSkills,
        jobKnowledge: adminRemarksForm?.jobKnowledge,
        confidence: adminRemarksForm?.confidence,
        interviewExperience: adminRemarksForm?.interviewExperience,
      },
    };
    dispatch(
      updateInterviewerAdminRemarksInfo({
        data,
        interviewerId: interviewerDetails?._id,
        callback: () => {
          dispatch(
            getIncserveInterviewersDetails({
              interviewerId: interviewerId,
            })
          );
          setCallbackDispatch(!callbackDispatch);
        },
      })
    );
  };

  const handleChangeStatus = ({ id, status }) => {
    const data = {
      id: id,
      status: status,
    };
    if (data) {
      dispatch(
        changeInterviewerStatus({
          data: data,
          callback: () => {
            dispatch(
              getInterviewerList({
                page: currentPage,
                pageSize: entriesLimit,
                search: inputWord,
                status: status,
                // status: status === "hold" ? "approved" : "hold",
              })
            );
          },
        })
      );
    }
  };

  const handleApproveInterviewer = ({}) => {
    if (mockForm?.remarks === "") {
      toast.error(<Toast msg={"Please add remarks."} />);
    } else {
      dispatch(
        putInterviewerMockRemarksInfo({
          interviewerDetails,
          mockForm,
        })
      );
      toast.success(<Toast msg={"Mock remarks added successfully."} />);
      setInterviewerId(null);
      setMockForm("");
    }
  };

  return (
    <div className="info-container pt-5 px-2" ref={infoRef}>
      <CloseButton
        onClick={() => {
          setInterviewerId(null);
        }}
      />
      <div className="list">
        <div className="position-table-container">
          {interviewerDetails ? (
            <>
              <table className="table-list f-l f-reg">
                {interviewerDetails?.code && (
                  <tr className="info-tabel">
                    <td>ID</td>
                    <td>
                      {interviewerDetails?.code
                        ? interviewerDetails?.code
                        : "Not Available"}
                    </td>
                  </tr>
                )}
                {interviewerDetails?.name && (
                  <tr className="info-tabel">
                    <td>Full Name</td>
                    <td>
                      {interviewerDetails?.name
                        ? interviewerDetails?.name
                        : "Not Available"}
                      {interviewerDetails?.isLinkedinVerified && (
                        <span>
                          <img
                            src={greenTick}
                            alt="verified"
                            className="cancel-reason-img ms-2"
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content={"Verified"}
                            data-tooltip-place="top"
                          />
                        </span>
                      )}
                    </td>
                  </tr>
                )}
                {interviewerDetails?.photoURL && (
                  <tr className="info-tabel">
                    <td>Profile Photo</td>
                    <td>
                      <div className="d-flex flex-column align-items-center">
                        {interviewerDetails?.photoURL ? (
                          <div className="profile-pic-container">
                            <img
                              src={interviewerDetails?.photoURL}
                              alt="profile"
                              className="profile-pic"
                            />
                          </div>
                        ) : (
                          <img
                            src="https://incruiter-media.s3.ap-south-1.amazonaws.co…/emailTemplates/file-1659100896378-328095968.jpeg"
                            className="dummy-profile-pic"
                            alt=""
                          />
                        )}
                        <div className="d-flex align-items-end justify-content-center">
                          {type === "incserve" ? (
                            <>
                              <Rating
                                size={18}
                                readonly={true}
                                initialValue={
                                  interviewerDetails &&
                                  interviewerDetails?.averageInCServeRatings &&
                                  interviewerDetails?.averageInCServeRatings
                                    .length > 0 &&
                                  interviewerDetails?.averageInCServeRatings[0]
                                    ?.overall?.avgInterviewerRatings
                                    ? interviewerDetails?.averageInCServeRatings[0]?.overall?.avgInterviewerRatings.toFixed(
                                        2
                                      )
                                    : 0
                                }
                              />
                              <div className="f-12 f-sem-bol">
                                (
                                {interviewerDetails &&
                                interviewerDetails?.averageInCServeRatings &&
                                interviewerDetails?.averageInCServeRatings
                                  .length > 0 &&
                                interviewerDetails?.averageInCServeRatings[0]
                                  ?.overall?.avgInterviewerRatings
                                  ? interviewerDetails?.averageInCServeRatings[0]?.overall?.avgInterviewerRatings.toFixed(
                                      2
                                    )
                                  : 0}
                                )
                              </div>
                            </>
                          ) : type === "incexit" ? (
                            <>
                              <Rating
                                size={18}
                                readonly={true}
                                initialValue={
                                  interviewerDetails &&
                                  interviewerDetails?.averageInCExitRatings &&
                                  interviewerDetails?.averageInCExitRatings
                                    .length > 0 &&
                                  interviewerDetails?.averageInCExitRatings[0]
                                    ?.overall?.avgInterviewerRatings
                                    ? interviewerDetails?.averageInCExitRatings[0]?.overall?.avgInterviewerRatings.toFixed(
                                        2
                                      )
                                    : 0
                                }
                              />
                              <div className="f-12 f-sem-bol">
                                (
                                {interviewerDetails &&
                                interviewerDetails?.averageInCServeRatings &&
                                interviewerDetails?.averageInCServeRatings
                                  .length > 0 &&
                                interviewerDetails?.averageInCServeRatings[0]
                                  ?.overall?.avgInterviewerRatings
                                  ? interviewerDetails?.averageInCServeRatings[0]?.overall?.avgInterviewerRatings.toFixed(
                                      2
                                    )
                                  : 0}
                                )
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
                {interviewerDetails?.companyName && (
                  <tr className="info-tabel">
                    <td>Company Name</td>
                    <td>
                      {interviewerDetails?.companyName
                        ? interviewerDetails?.companyName
                        : "Not Available"}{" "}
                    </td>
                  </tr>
                )}
                {interviewerDetails?.companyName && (
                  <tr className="info-tabel">
                    <td>Client Name</td>
                    <td>
                      {interviewerDetails?.clientName
                        ? interviewerDetails?.clientName
                        : "Not Available"}{" "}
                    </td>
                  </tr>
                )}
                {interviewerDetails?.email && (
                  <tr className="info-tabel">
                    <td>Email</td>
                    <td>
                      {interviewerDetails?.email
                        ? interviewerDetails?.email
                        : "Not Available"}{" "}
                    </td>
                  </tr>
                )}
                {interviewerDetails?.phone && (
                  <tr className="info-tabel">
                    <td>Mobile No.</td>
                    <td>
                      {interviewerDetails?.phone
                        ? interviewerDetails?.phone
                        : "Not Available"}{" "}
                    </td>
                  </tr>
                )}
                {interviewerDetails?.whatsapp && (
                  <tr className="info-tabel">
                    <td>Whatsapp No.</td>
                    <td>
                      {interviewerDetails?.whatsapp
                        ? interviewerDetails?.whatsapp
                        : "Not Available"}{" "}
                    </td>
                  </tr>
                )}
                {interviewerDetails?.address && (
                  <tr className="info-tabel">
                    <td>State</td>
                    <td>
                      {interviewerDetails?.address?.state
                        ? interviewerDetails?.address?.state
                        : "Not Available"}{" "}
                    </td>
                  </tr>
                )}
                {interviewerDetails?.address && (
                  <tr className="info-tabel">
                    <td>City</td>
                    <td>
                      {interviewerDetails?.address?.city
                        ? interviewerDetails?.address?.city
                        : "Not Available"}{" "}
                    </td>
                  </tr>
                )}
                {interviewerDetails?.address && (
                  <tr className="info-tabel">
                    <td>Postal Code</td>
                    <td>
                      {interviewerDetails?.address?.pincode
                        ? interviewerDetails?.address?.pincode
                        : "Not Available"}{" "}
                    </td>
                  </tr>
                )}
                {interviewerDetails?.domain && (
                  <tr className="info-tabel">
                    <td>Domain</td>
                    <td>
                      {interviewerDetails?.domain?.name
                        ? interviewerDetails?.domain?.name
                        : "Not Available"}{" "}
                    </td>
                  </tr>
                )}
                {interviewerDetails?.qualification && (
                  <tr className="info-tabel">
                    <td>Qualification</td>
                    <td>
                      {interviewerDetails?.qualification
                        ? interviewerDetails?.qualification
                        : "Not Available"}{" "}
                    </td>
                  </tr>
                )}
                {interviewerDetails?.experience && (
                  <tr className="info-tabel">
                    <td>Experience</td>
                    <td>
                      {interviewerDetails?.experience
                        ? interviewerDetails?.experience
                        : "Not Available"}{" "}
                    </td>
                  </tr>
                )}
                {interviewerDetails?.designation && (
                  <tr className="info-tabel">
                    <td>Designation</td>
                    <td>
                      {interviewerDetails?.designation
                        ? interviewerDetails?.designation
                        : "Not Available"}{" "}
                    </td>
                  </tr>
                )}
                {interviewerDetails?.areaOfExpertise && (
                  <tr className="info-tabel">
                    <td>Area of Experties</td>
                    <td>
                      {interviewerDetails?.areaOfExpertise
                        ? interviewerDetails?.areaOfExpertise
                        : "Not Available"}
                    </td>
                  </tr>
                )}
                {interviewerDetails?.photoURL && (
                  <tr className="info-tabel">
                    <td>Photo ID</td>
                    <td
                      onClick={() =>
                        downloadFile(
                          interviewerDetails?.photoURL,
                          `${interviewerDetails?.photo} - Photo`
                        )
                      }
                      className="text-primary f-sem-bol cursor-pointer"
                    >
                      Download Photo ID
                      <img
                        src={downloadImg}
                        style={{
                          height: "12px",
                          width: "auto",
                          marginLeft: "10px",
                        }}
                      />
                    </td>
                  </tr>
                )}
                {interviewerDetails?.resumeURL && (
                  <tr className="info-tabel">
                    <td>Resume</td>
                    <td
                      onClick={() =>
                        downloadFile(
                          interviewerDetails?.resumeURL,
                          `${interviewerDetails?.resume} - Resume`
                        )
                      }
                      className="text-primary f-sem-bol cursor-pointer"
                    >
                      Download Resume
                      <img
                        src={downloadImg}
                        style={{
                          height: "12px",
                          width: "auto",
                          marginLeft: "10px",
                        }}
                      />
                    </td>
                  </tr>
                )}
                {interviewerDetails?.skills && (
                  <tr className="info-tabel">
                    <td>Keywords</td>
                    <td>
                      {interviewerDetails?.skills &&
                      interviewerDetails?.skills?.length > 0
                        ? interviewerDetails?.skills.map((data, index) => {
                            return `${data?.name}${
                              index === interviewerDetails?.skills?.length - 1
                                ? "."
                                : ", "
                            }`;
                          })
                        : "Not Available"}{" "}
                    </td>
                  </tr>
                )}
                {interviewerDetails?.linkedInProfile && (
                  <tr className="info-tabel">
                    <td>LinkedIn Profile Link</td>
                    <td>
                      <a
                        href={
                          interviewerDetails?.linkedInProfile
                            ? interviewerDetails?.linkedInProfile
                            : ""
                        }
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        {interviewerDetails?.linkedInProfile
                          ? interviewerDetails?.linkedInProfile
                          : "Not Available"}
                      </a>
                      {status === "approval_pending" &&
                      !interviewerDetails?.isLinkedinVerified ? (
                        <span>
                          <img
                            src={redTick}
                            onClick={() => {
                              setVerifyLinkdin(true);
                            }}
                            alt="cancel reason"
                            className="cancel-reason-img ms-2 me-1"
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content={"Verify"}
                            data-tooltip-place="top"
                          />
                          <Tooltip id="my-tooltip" />
                        </span>
                      ) : (
                        <span>
                          <img
                            src={greenTick}
                            alt="cancel reason"
                            className="cancel-reason-img ms-2 me-1"
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content={"Verified"}
                            data-tooltip-place="top"
                          />
                          <Tooltip id="my-tooltip" />
                        </span>
                      )}
                    </td>
                  </tr>
                )}
                {status === "active" || status === "approved" ? (
                  <>
                    {checkPermissions(
                      "IncServe",
                      "Show Call Records",
                      "View"
                    ) ? (
                      <tr className="info-tabel">
                        <td>Audio Logs</td>
                        <td>
                          <Button
                            text="Logs"
                            className="call_btn logs_btn d-center"
                            onClick={() => {
                              setAudioLogs(true);
                              setInterviewerIDForLogs({
                                _id: interviewerDetails?._id,
                                code: interviewerDetails?.code,
                              });
                            }}
                          />
                        </td>
                      </tr>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <tr className="info-tabel">
                    <td>Audio Logs</td>
                    <td>
                      <Button
                        text="Logs"
                        className="call_btn logs_btn d-center"
                        onClick={() => {
                          setAudioLogs(true);
                          setIsEditable(false);
                          setInterviewerIDForLogs({
                            _id: interviewerDetails?._id,
                            code: interviewerDetails?.code,
                          });
                        }}
                      />
                    </td>
                  </tr>
                )}
                {interviewerDetails?.createdAt && (
                  <tr className="info-tabel">
                    <td>Registered on</td>
                    <td>
                      {interviewerDetails?.createdAt
                        ? moment(interviewerDetails?.createdAt).format(
                            "MMMM Do YYYY, h:mm a"
                          )
                        : "Not Available"}
                    </td>
                  </tr>
                )}
                {interviewerDetails?.onboardedBy && (
                  <tr className="info-tabel">
                    <td>onboarded By</td>
                    <td>
                      {interviewerDetails?.onboardedBy
                        ? interviewerDetails?.onboardedBy?.name
                        : "Not Available"}
                      <br />
                      {interviewerDetails?.onboardedAt
                        ? moment(interviewerDetails?.onboardedAt).format(
                            "MMMM Do YYYY, h:mm a"
                          )
                        : "Not Available"}
                    </td>
                  </tr>
                )}
                {interviewerDetails?.approvedBy && (
                  <tr className="info-tabel">
                    <td>Approved By</td>
                    <td>
                      {interviewerDetails?.approvedBy
                        ? interviewerDetails?.approvedBy?.name
                        : "Not Available"}
                      <br />
                      {interviewerDetails?.approvedAt
                        ? moment(interviewerDetails?.approvedAt).format(
                            "MMMM Do YYYY, h:mm a"
                          )
                        : "Not Available"}
                    </td>
                  </tr>
                )}
                {interviewerDetails?.bankAccount && (
                  <tr className="info-tabel-header">
                    <td colSpan={2} className="f-sem-bol">
                      Account Details
                    </td>
                  </tr>
                )}
                {interviewerDetails?.bankAccount && (
                  <tr className="info-tabel">
                    <td>Bank Name</td>
                    <td>
                      {interviewerDetails?.bankAccount?.bankName
                        ? interviewerDetails?.bankAccount?.bankName
                        : "Not Available"}{" "}
                    </td>
                  </tr>
                )}
                {interviewerDetails?.bankAccount && (
                  <tr className="info-tabel">
                    <td>Account Holder Name</td>
                    <td>
                      {interviewerDetails?.bankAccount?.accountHolderName
                        ? interviewerDetails?.bankAccount?.accountHolderName
                        : "Not Available"}{" "}
                    </td>
                  </tr>
                )}
                {interviewerDetails?.bankAccount && (
                  <tr className="info-tabel">
                    <td>Account Number</td>
                    <td>
                      {interviewerDetails?.bankAccount?.accountNumber
                        ? interviewerDetails?.bankAccount?.accountNumber
                        : "Not Available"}{" "}
                    </td>
                  </tr>
                )}
                {interviewerDetails?.bankAccount && (
                  <tr className="info-tabel">
                    <td>IFSC Code</td>
                    <td>
                      {interviewerDetails?.bankAccount?.IFSC
                        ? interviewerDetails?.bankAccount?.IFSC
                        : "Not Available"}{" "}
                    </td>
                  </tr>
                )}
                {interviewerDetails?.bankAccount && (
                  <tr className="info-tabel">
                    <td>PAN Number</td>
                    <td>
                      {interviewerDetails?.bankAccount?.PAN
                        ? interviewerDetails?.bankAccount?.PAN
                        : "Not Available"}{" "}
                    </td>
                  </tr>
                )}
                {interviewerDetails?.bankAccount && (
                  <tr className="info-tabel">
                    <td>PAN Card Image</td>
                    <td className="text-primary f-sem-bol cursor-pointer">
                      <span
                        panCard
                        onClick={() =>
                          downloadFile(
                            interviewerDetails?.bankAccount?.panURL,
                            `${interviewerDetails?.bankAccount?.panCard} - PAN`
                          )
                        }
                      >
                        Download PAN
                        <img
                          src={downloadImg}
                          alt=""
                          style={{
                            height: "12px",
                            width: "auto",
                            marginLeft: "10px",
                          }}
                        />
                      </span>
                      <span>
                        <a
                          href={interviewerDetails?.bankAccount?.panURL}
                          target="_blank"
                        >
                          <Button
                            isImage={true}
                            image={eye}
                            className={"preview-btn"}
                          />
                        </a>
                      </span>
                    </td>
                  </tr>
                )}
                {interviewerDetails?.bankAccount && (
                  <tr className="info-tabel">
                    <td>Cancelled Cheque</td>
                    <td className="text-primary f-sem-bol cursor-pointer">
                      <span
                        onClick={() =>
                          downloadFile(
                            interviewerDetails?.bankAccount?.cancelledChequeURL,
                            `${interviewerDetails?.bankAccount?.cancelledCheque} - Cancelled Cheque`
                          )
                        }
                      >
                        Download Cancelled Cheque
                        <img
                          src={downloadImg}
                          style={{
                            height: "12px",
                            width: "auto",
                            marginLeft: "10px",
                          }}
                        />
                      </span>
                      <span>
                        <a
                          href={
                            interviewerDetails?.bankAccount?.cancelledChequeURL
                          }
                          target="_blank"
                        >
                          <Button
                            isImage={true}
                            image={eye}
                            className={"preview-btn"}
                          />
                        </a>
                      </span>
                    </td>
                  </tr>
                )}
                <tr className="info-tabel-header">
                  <td colSpan={2} className="f-sem-bol">
                    Admin Remarks
                  </td>
                </tr>
                {interviewerDetails?.companyType && (
                  <tr className="info-tabel">
                    <td>Company Name</td>
                    <td>
                      {interviewerDetails?.companyType
                        ? interviewerDetails?.companyType
                        : "Not Available"}{" "}
                    </td>
                  </tr>
                )}
                {interviewerDetails?.shiftTiming && (
                  <tr className="info-tabel">
                    <td>Shift Timing</td>
                    <td>
                      {interviewerDetails?.shiftTiming
                        ? interviewerDetails?.shiftTiming
                        : "Not Available"}{" "}
                    </td>
                  </tr>
                )}
                <tr className="info-tabel">
                  <td>Alternate Contact No.</td>
                  <td>
                    <Input
                      inputClass="input-shadow rounded"
                      className={"common-input"}
                      value={adminRemarksForm?.alternatePhone}
                      onChange={(e) =>
                        setAdminRemarksForm((prev) => ({
                          ...prev,
                          alternatePhone: e.target.value,
                        }))
                      }
                      placeholder={"Select enter alternate phone number."}
                      type="number"
                      borderNone
                    />
                  </td>
                </tr>
                <tr className="info-tabel">
                  <td>Total Experience</td>
                  <td>
                    <Input
                      inputClass="input-shadow rounded"
                      className={"common-input"}
                      value={adminRemarksForm?.totalExperience}
                      onChange={(e) =>
                        setAdminRemarksForm((prev) => ({
                          ...prev,
                          totalExperience: e.target.value,
                        }))
                      }
                      type="number"
                      placeholder={"Enter total exp."}
                      borderNone
                    />
                  </td>
                </tr>
                <tr className="info-tabel">
                  <td>Relevant Experience</td>
                  <td>
                    <Input
                      inputClass="input-shadow rounded"
                      className={"common-input"}
                      value={adminRemarksForm?.relevantExperience}
                      onChange={(e) =>
                        setAdminRemarksForm((prev) => ({
                          ...prev,
                          relevantExperience: e.target.value,
                        }))
                      }
                      type="number"
                      placeholder={"Enter relevant exp."}
                      borderNone
                    />
                  </td>
                </tr>
                <tr className="info-tabel">
                  <td>Expertise</td>
                  <td>
                    <SearchSelect
                      inputClass="input-shadow rounded"
                      options={DomainList}
                      value={adminRemarksForm?.domain}
                      onChange={(x, y) =>
                        setAdminRemarksForm((prev) => ({
                          ...prev,
                          domain: x.value,
                        }))
                      }
                      borderNone
                      placeholder={"Select expertise."}
                    />
                  </td>
                </tr>
                <tr className="info-tabel">
                  <td>Skillset Of Expertise</td>
                  <td>
                    <CreatableSelect
                      inputClass="input-shadow rounded"
                      isMulti={true}
                      options={SkillsList}
                      value={adminRemarksForm?.skills.concat(
                        adminRemarksForm?.customSkills
                      )}
                      onChange={(x, y) =>
                        handleCreatableSelectChange(x, y, "skills")
                      }
                      borderNone
                      placeholder={"Select expertise."}
                    />
                  </td>
                </tr>
                <tr className="info-tabel">
                  <td>Years of Exp. In Core skill </td>
                  <td>
                    <Input
                      inputClass="input-shadow rounded"
                      className={"common-input"}
                      value={adminRemarksForm?.coreExperience}
                      onChange={(e) =>
                        setAdminRemarksForm((prev) => ({
                          ...prev,
                          coreExperience: e.target.value,
                        }))
                      }
                      type="number"
                      placeholder={"Enter years of exp."}
                      borderNone
                    />
                  </td>
                </tr>
                <tr className="info-tabel">
                  <td>Comfortable for Tele/VC Round </td>
                  <td>
                    <SearchSelect
                      inputClass="input-shadow rounded"
                      options={[
                        { label: "Yes", value: true },
                        { label: "No", value: false },
                      ]}
                      value={adminRemarksForm?.telephonic_or_videoConf}
                      onChange={(x, y) =>
                        setAdminRemarksForm((prev) => ({
                          ...prev,
                          telephonic_or_videoConf: x.value,
                        }))
                      }
                      placeholder={"Select comfortable for."}
                      borderNone
                    />
                  </td>
                </tr>
                <tr className="info-tabel">
                  <td>No. of Interview can be conducted</td>
                  <td>
                    <div className="d-flex justify-content-between">
                      <div style={{ width: "180px" }}>
                        <Input
                          inputClass="input-shadow rounded"
                          className={"common-input"}
                          value={adminRemarksForm?.actualInterviews?.number}
                          onChange={(e) =>
                            setAdminRemarksForm((prev) => ({
                              ...prev,
                              actualInterviews: {
                                ...prev?.actualInterviews,
                                number: e.target.value,
                              },
                            }))
                          }
                          placeholder={
                            "Enter No. of interview can be conducted"
                          }
                          type="number"
                          borderNone
                        />
                      </div>
                      <div style={{ width: "180px" }}>
                        <SearchSelect
                          inputClass="input-shadow rounded"
                          options={[
                            { label: "Per Week", value: "weekly" },
                            { label: "Per Month", value: "monthly" },
                          ]}
                          onChange={(x, y) =>
                            setAdminRemarksForm((prev) => ({
                              ...prev,
                              actualInterviews: {
                                ...prev?.actualInterviews,
                                type: x.value,
                              },
                            }))
                          }
                          placeholder={"Select actual interviews."}
                          value={adminRemarksForm?.actualInterviews?.type}
                          borderNone
                        />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className="info-tabel">
                  <td>Preferred Timings in Weekday</td>
                  <td>
                    <div className="d-flex justify-content-between">
                      <div style={{ width: "180px" }}>
                        <Input
                          inputClass="input-shadow rounded"
                          className={"common-input"}
                          type="time"
                          value={
                            adminRemarksForm?.preferredWeekdayTimings?.start
                          }
                          onChange={(e) =>
                            setAdminRemarksForm((prev) => ({
                              ...prev,
                              preferredWeekdayTimings: {
                                ...prev?.preferredWeekdayTimings,
                                start: e.target.value,
                              },
                            }))
                          }
                          placeholder={"Enter preferred timings in weekday."}
                          borderNone
                        />
                      </div>
                      <div style={{ width: "180px" }}>
                        <Input
                          inputClass="input-shadow rounded"
                          className={"common-input"}
                          type="time"
                          value={adminRemarksForm?.preferredWeekdayTimings?.end}
                          onChange={(e) =>
                            setAdminRemarksForm((prev) => ({
                              ...prev,
                              preferredWeekdayTimings: {
                                ...prev?.preferredWeekdayTimings,
                                end: e.target.value,
                              },
                            }))
                          }
                          placeholder={"Select timings in weekday."}
                          borderNone
                        />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className="info-tabel">
                  <td>Preferred Timings in Weekend</td>
                  <td>
                    <div className="d-flex justify-content-between">
                      <div style={{ width: "180px" }}>
                        <Input
                          inputClass="input-shadow rounded"
                          className={"common-input"}
                          type="time"
                          value={
                            adminRemarksForm?.preferredWeekendTimings?.start
                          }
                          onChange={(e) =>
                            setAdminRemarksForm((prev) => ({
                              ...prev,
                              preferredWeekendTimings: {
                                ...prev?.preferredWeekendTimings,
                                start: e.target.value,
                              },
                            }))
                          }
                          placeholder={"Enter preferred timings in weekend."}
                          borderNone
                        />
                      </div>
                      <div style={{ width: "180px" }}>
                        <Input
                          inputClass="input-shadow rounded"
                          className={"common-input"}
                          type="time"
                          value={adminRemarksForm?.preferredWeekendTimings?.end}
                          onChange={(e) =>
                            setAdminRemarksForm((prev) => ({
                              ...prev,
                              preferredWeekendTimings: {
                                ...prev?.preferredWeekendTimings,
                                end: e.target.value,
                              },
                            }))
                          }
                          placeholder={"Enter preferred timings in weekend."}
                          borderNone
                        />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className="info-tabel">
                  <td>Current CTC</td>
                  <td>
                    <Input
                      inputClass="input-shadow rounded"
                      className={"common-input"}
                      type="number"
                      borderNone
                      value={adminRemarksForm?.currentCTC?.value}
                      onChange={(e) =>
                        setAdminRemarksForm((prev) => ({
                          ...prev,
                          currentCTC: {
                            ...prev?.currentCTC,
                            value: e.target.value,
                          },
                        }))
                      }
                      placeholder={"Enter current CTC."}
                    />
                  </td>
                </tr>
                <tr className="info-tabel">
                  <td>Pay per Interview</td>
                  <td>
                    <Input
                      inputClass="input-shadow rounded"
                      className={"common-input"}
                      type="number"
                      borderNone
                      value={adminRemarksForm?.payPerInterview?.value}
                      onChange={(e) =>
                        setAdminRemarksForm((prev) => ({
                          ...prev,
                          payPerInterview: {
                            ...prev?.payPerInterview,
                            value: e.target.value,
                          },
                        }))
                      }
                      placeholder={"Enter pay per interview."}
                    />
                  </td>
                </tr>
                <tr className="info-tabel">
                  <td>Enter similar registered websites</td>
                  <td>
                    <CreatableSelect
                      inputClass="input-shadow rounded"
                      isMulti={true}
                      options={WebsiteList}
                      value={adminRemarksForm?.similarRegisteredWebsites.concat(
                        adminRemarksForm?.customSimilarRegisteredWebsites
                      )}
                      onChange={(x, y) =>
                        handleCreatableSelectChange(x, y, "websites")
                      }
                      borderNone
                      placeholder={"Enter similar registered websites."}
                    />
                  </td>
                </tr>
                <tr className="info-tabel">
                  <td>Remarks</td>
                  <td>
                    <Textarea
                      textClass={"input-shadow rounded"}
                      value={adminRemarksForm?.remarks}
                      onChange={(e) =>
                        setAdminRemarksForm((prev) => ({
                          ...prev,
                          remarks: e.target.value,
                        }))
                      }
                      placeholder={"Enter remarks."}
                    />
                  </td>
                </tr>
                <tr className="info-tabel">
                  <td>Communication Skills</td>
                  <td>
                    <ExperienceSearchSelect
                      inputClass="input-shadow rounded"
                      options={ratingOptions}
                      value={adminRemarksForm?.communicationSkills?.score}
                      onChange={(x, y) => {
                        setAdminRemarksForm((prev) => ({
                          ...prev,
                          communicationSkills: {
                            ...prev?.communicationSkills,
                            score: x?.value,
                          },
                        }));
                      }}
                      placeholder={"Select communication skills."}
                      borderNone
                    />
                  </td>
                </tr>
                <tr className="info-tabel">
                  <td>Job knowledge</td>
                  <td>
                    <ExperienceSearchSelect
                      inputClass="input-shadow rounded"
                      options={ratingOptions}
                      value={adminRemarksForm?.jobKnowledge?.score}
                      onChange={(x, y) => {
                        setAdminRemarksForm((prev) => ({
                          ...prev,
                          jobKnowledge: {
                            ...prev?.jobKnowledge,
                            score: x?.value,
                          },
                        }));
                      }}
                      placeholder={"Select job knowledge."}
                      borderNone
                    />
                  </td>
                </tr>
                <tr className="info-tabel">
                  <td>Confidence</td>
                  <td>
                    <ExperienceSearchSelect
                      inputClass="input-shadow rounded"
                      options={ratingOptions}
                      value={adminRemarksForm?.confidence?.score}
                      onChange={(x, y) => {
                        setAdminRemarksForm((prev) => ({
                          ...prev,
                          confidence: {
                            ...prev?.confidence,
                            score: x?.value,
                          },
                        }));
                      }}
                      placeholder={"Select confidence."}
                      borderNone
                    />
                  </td>
                </tr>
                <tr className="info-tabel">
                  <td>Interview Experience</td>
                  <td>
                    <ExperienceSearchSelect
                      inputClass="input-shadow rounded"
                      options={ratingOptions}
                      value={adminRemarksForm?.interviewExperience?.score}
                      onChange={(x, y) => {
                        setAdminRemarksForm((prev) => ({
                          ...prev,
                          interviewExperience: {
                            ...prev?.interviewExperience,
                            score: x?.value,
                          },
                        }));
                      }}
                      placeholder={"Select interview experience."}
                      borderNone
                    />
                  </td>
                </tr>
                <tr className="info-tabel">
                  <td>Profile Rating</td>
                  <td>
                    <div className="d-flex align-items-end">
                      {type === "incserve" ? (
                        <Rating
                          size={18}
                          readonly={true}
                          initialValue={
                            interviewerDetails &&
                            interviewerDetails?.averageInCServeRatings &&
                            interviewerDetails?.averageInCServeRatings.length >
                              0 &&
                            interviewerDetails?.averageInCServeRatings[0]
                              ?.overall?.avgInterviewerRatings
                              ? interviewerDetails?.averageInCServeRatings[0]?.overall?.avgInterviewerRatings.toFixed(
                                  2
                                )
                              : 0
                          }
                        />
                      ) : type === "incexit" ? (
                        <Rating
                          size={18}
                          readonly={true}
                          initialValue={
                            interviewerDetails &&
                            interviewerDetails?.averageInCExitRatings &&
                            interviewerDetails?.averageInCExitRatings.length >
                              0 &&
                            interviewerDetails?.averageInCExitRatings[0]
                              ?.overall?.avgInterviewerRatings
                              ? interviewerDetails?.averageInCExitRatings[0]?.overall?.avgInterviewerRatings.toFixed(
                                  2
                                )
                              : 0
                          }
                        />
                      ) : (
                        ""
                      )}

                      <div className="f-12 f-sem-bol">
                        {type === "incserve"
                          ? interviewerDetails &&
                            interviewerDetails?.averageInCServeRatings &&
                            interviewerDetails?.averageInCServeRatings.length >
                              0 &&
                            interviewerDetails?.averageInCServeRatings[0]
                              ?.overall?.avgInterviewerRatings
                            ? interviewerDetails?.averageInCServeRatings[0]?.overall?.avgInterviewerRatings.toFixed(
                                2
                              )
                            : 0
                          : type === "incexit"
                          ? interviewerDetails &&
                            interviewerDetails?.averageInCExitRatings &&
                            interviewerDetails?.averageInCExitRatings.length >
                              0 &&
                            interviewerDetails?.averageInCExitRatings[0]
                              ?.overall?.avgInterviewerRatings
                            ? interviewerDetails?.averageInCExitRatings[0]?.overall?.avgInterviewerRatings.toFixed(
                                2
                              )
                            : 0
                          : ""}
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className="info-tabel">
                  <td>Interview Rating</td>
                  <td>
                    <div className="d-flex align-items-end">
                      {type === "incserve" ? (
                        <>
                          {" "}
                          <Rating
                            size={18}
                            readonly={true}
                            initialValue={
                              interviewerDetails &&
                              interviewerDetails?.averageInCServeRatings &&
                              interviewerDetails?.averageInCServeRatings
                                .length > 0 &&
                              interviewerDetails?.averageInCServeRatings[0]
                                ?.overall?.avgOverallRatings
                                ? interviewerDetails?.averageInCServeRatings[0]?.overall?.avgOverallRatings.toFixed(
                                    2
                                  )
                                : 0
                            }
                          />
                          <div className="f-12 f-sem-bol">
                            (
                            {interviewerDetails &&
                            interviewerDetails?.averageInCServeRatings &&
                            interviewerDetails?.averageInCServeRatings.length >
                              0 &&
                            interviewerDetails?.averageInCServeRatings[0]
                              ?.overall?.avgOverallRatings
                              ? interviewerDetails?.averageInCServeRatings[0]?.overall?.avgOverallRatings.toFixed(
                                  2
                                )
                              : 0}
                            )
                          </div>
                        </>
                      ) : type === "incexit" ? (
                        <>
                          {" "}
                          <Rating
                            size={18}
                            readonly={true}
                            initialValue={
                              interviewerDetails &&
                              interviewerDetails?.averageInCExitRatings &&
                              interviewerDetails?.averageInCExitRatings.length >
                                0 &&
                              interviewerDetails?.averageInCExitRatings[0]
                                ?.overall?.avgOverallRatings
                                ? interviewerDetails?.averageInCExitRatings[0]?.overall?.avgOverallRatings.toFixed(
                                    2
                                  )
                                : 0
                            }
                          />
                          <div className="f-12 f-sem-bol">
                            (
                            {interviewerDetails &&
                            interviewerDetails?.averageInCExitRatings &&
                            interviewerDetails?.averageInCExitRatings.length >
                              0 &&
                            interviewerDetails?.averageInCExitRatings[0]
                              ?.overall?.avgOverallRatings
                              ? interviewerDetails?.averageInCExitRatings[0]?.overall?.avgOverallRatings.toFixed(
                                  2
                                )
                              : 0}
                            )
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </td>
                </tr>
                <tr className="info-tabel">
                  <td>Feedback Report Rating</td>
                  <td>
                    <div className="d-flex align-items-end">
                      {type === "incserve" ? (
                        <>
                          <Rating
                            size={18}
                            readonly={true}
                            initialValue={
                              interviewerDetails &&
                              interviewerDetails?.averageInCServeRatings &&
                              interviewerDetails?.averageInCServeRatings
                                .length > 0 &&
                              interviewerDetails?.averageInCServeRatings[0]
                                ?.overall?.avgFeedbackReportRatings
                                ? interviewerDetails?.averageInCServeRatings[0]?.overall?.avgFeedbackReportRatings.toFixed(
                                    2
                                  )
                                : 0
                            }
                          />
                          <div className="f-12 f-sem-bol">
                            (
                            {interviewerDetails &&
                            interviewerDetails?.averageInCServeRatings &&
                            interviewerDetails?.averageInCServeRatings.length >
                              0 &&
                            interviewerDetails?.averageInCServeRatings[0]
                              ?.overall?.avgOverallRatings
                              ? interviewerDetails?.averageInCServeRatings[0]?.overall?.avgOverallRatings.toFixed(
                                  2
                                )
                              : 0}
                            )
                          </div>
                        </>
                      ) : type === "incexit" ? (
                        <>
                          <Rating
                            size={18}
                            readonly={true}
                            initialValue={
                              interviewerDetails &&
                              interviewerDetails?.averageInCExitRatings &&
                              interviewerDetails?.averageInCExitRatings.length >
                                0 &&
                              interviewerDetails?.averageInCExitRatings[0]
                                ?.overall?.avgFeedbackReportRatings
                                ? interviewerDetails?.averageInCExitRatings[0]?.overall?.avgFeedbackReportRatings.toFixed(
                                    2
                                  )
                                : 0
                            }
                          />
                          <div className="f-12 f-sem-bol">
                            (
                            {interviewerDetails &&
                            interviewerDetails?.averageInCExitRatings &&
                            interviewerDetails?.averageInCExitRatings.length >
                              0 &&
                            interviewerDetails?.averageInCExitRatings[0]
                              ?.overall?.avgOverallRatings
                              ? interviewerDetails?.averageInCExitRatings[0]?.overall?.avgOverallRatings.toFixed(
                                  2
                                )
                              : 0}
                            )
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </td>
                </tr>
                <tr className="info-tabel">
                  <td>Average of Last 3 Interview Rating</td>
                  <td>
                    <tr>
                      <td>Overall Interview</td>
                      <td>
                        <div className="d-flex align-items-end">
                          {type === "incserve" ? (
                            <>
                              <Rating
                                size={18}
                                readonly={true}
                                initialValue={
                                  interviewerDetails &&
                                  interviewerDetails?.averageInCServeRatings &&
                                  interviewerDetails?.averageInCServeRatings
                                    .length > 0 &&
                                  interviewerDetails?.averageInCServeRatings[0]
                                    ?.lastThree?.avgOverallRatings
                                    ? interviewerDetails?.averageInCServeRatings[0]?.lastThree?.avgOverallRatings.toFixed(
                                        2
                                      )
                                    : 0
                                }
                              />
                              <div className="f-12 f-sem-bol">
                                (
                                {interviewerDetails &&
                                interviewerDetails?.averageInCServeRatings &&
                                interviewerDetails?.averageInCServeRatings
                                  .length > 0 &&
                                interviewerDetails?.averageInCServeRatings[0]
                                  ?.lastThree?.avgOverallRatings
                                  ? interviewerDetails?.averageInCServeRatings[0]?.lastThree?.avgOverallRatings.toFixed(
                                      2
                                    )
                                  : 0}
                                )
                              </div>
                            </>
                          ) : type === "incexit" ? (
                            <>
                              <Rating
                                size={18}
                                readonly={true}
                                initialValue={
                                  interviewerDetails &&
                                  interviewerDetails?.averageInCExitRatings &&
                                  interviewerDetails?.averageInCExitRatings
                                    .length > 0 &&
                                  interviewerDetails?.averageInCExitRatings[0]
                                    ?.lastThree?.avgOverallRatings
                                    ? interviewerDetails?.averageInCExitRatings[0]?.lastThree?.avgOverallRatings.toFixed(
                                        2
                                      )
                                    : 0
                                }
                              />
                              <div className="f-12 f-sem-bol">
                                (
                                {interviewerDetails &&
                                interviewerDetails?.averageInCExitRatings &&
                                interviewerDetails?.averageInCExitRatings
                                  .length > 0 &&
                                interviewerDetails?.averageInCExitRatings[0]
                                  ?.lastThree?.avgOverallRatings
                                  ? interviewerDetails?.averageInCExitRatings[0]?.lastThree?.avgOverallRatings.toFixed(
                                      2
                                    )
                                  : 0}
                                )
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Interviewer</td>
                      <td>
                        <div className="d-flex align-items-end">
                          {type === "incserve" ? (
                            <>
                              {" "}
                              <Rating
                                size={18}
                                readonly={true}
                                initialValue={
                                  interviewerDetails &&
                                  interviewerDetails?.averageInCServeRatings &&
                                  interviewerDetails?.averageInCServeRatings
                                    .length > 0 &&
                                  interviewerDetails?.averageInCServeRatings[0]
                                    ?.lastThree?.avgInterviewerRatings
                                    ? interviewerDetails?.averageInCServeRatings[0]?.lastThree?.avgInterviewerRatings.toFixed(
                                        2
                                      )
                                    : 0
                                }
                              />
                              <div className="f-12 f-sem-bol">
                                (
                                {interviewerDetails &&
                                interviewerDetails?.averageInCServeRatings &&
                                interviewerDetails?.averageInCServeRatings
                                  .length > 0 &&
                                interviewerDetails?.averageInCServeRatings[0]
                                  ?.lastThree?.avgInterviewerRatings
                                  ? interviewerDetails?.averageInCServeRatings[0]?.lastThree?.avgInterviewerRatings.toFixed(
                                      2
                                    )
                                  : 0}
                                )
                              </div>
                            </>
                          ) : type === "incexit" ? (
                            <>
                              {" "}
                              <Rating
                                size={18}
                                readonly={true}
                                initialValue={
                                  interviewerDetails &&
                                  interviewerDetails?.averageInCExitRatings &&
                                  interviewerDetails?.averageInCExitRatings
                                    .length > 0 &&
                                  interviewerDetails?.averageInCExitRatings[0]
                                    ?.lastThree?.avgInterviewerRatings
                                    ? interviewerDetails?.averageInCExitRatings[0]?.lastThree?.avgInterviewerRatings.toFixed(
                                        2
                                      )
                                    : 0
                                }
                              />
                              <div className="f-12 f-sem-bol">
                                (
                                {interviewerDetails &&
                                interviewerDetails?.averageInCExitRatings &&
                                interviewerDetails?.averageInCExitRatings
                                  .length > 0 &&
                                interviewerDetails?.averageInCExitRatings[0]
                                  ?.lastThree?.avgInterviewerRatings
                                  ? interviewerDetails?.averageInCExitRatings[0]?.lastThree?.avgInterviewerRatings.toFixed(
                                      2
                                    )
                                  : 0}
                                )
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Feedback Report Rating</td>
                      <td>
                        <div className="d-flex align-items-end">
                          {type === "incserve" ? (
                            <>
                              <Rating
                                size={18}
                                readonly={true}
                                initialValue={
                                  interviewerDetails &&
                                  interviewerDetails?.averageInCServeRatings &&
                                  interviewerDetails?.averageInCServeRatings
                                    .length > 0 &&
                                  interviewerDetails?.averageInCServeRatings[0]
                                    ?.lastThree?.avgFeedbackReportRatings
                                    ? interviewerDetails?.averageInCServeRatings[0]?.lastThree?.avgFeedbackReportRatings.toFixed(
                                        2
                                      )
                                    : 0
                                }
                              />
                              <div className="f-12 f-sem-bol">
                                (
                                {interviewerDetails &&
                                interviewerDetails?.averageInCServeRatings &&
                                interviewerDetails?.averageInCServeRatings
                                  .length > 0 &&
                                interviewerDetails?.averageInCServeRatings[0]
                                  ?.lastThree?.avgFeedbackReportRatings
                                  ? interviewerDetails?.averageInCServeRatings[0]?.lastThree?.avgFeedbackReportRatings.toFixed(
                                      2
                                    )
                                  : 0}
                                )
                              </div>
                            </>
                          ) : type === "incexit" ? (
                            <>
                              <Rating
                                size={18}
                                readonly={true}
                                initialValue={
                                  interviewerDetails &&
                                  interviewerDetails?.averageInCExitRatings &&
                                  interviewerDetails?.averageInCExitRatings
                                    .length > 0 &&
                                  interviewerDetails?.averageInCExitRatings[0]
                                    ?.lastThree?.avgFeedbackReportRatings
                                    ? interviewerDetails?.averageInCExitRatings[0]?.lastThree?.avgFeedbackReportRatings.toFixed(
                                        2
                                      )
                                    : 0
                                }
                              />
                              <div className="f-12 f-sem-bol">
                                (
                                {interviewerDetails &&
                                interviewerDetails?.averageInCExitRatings &&
                                interviewerDetails?.averageInCExitRatings
                                  .length > 0 &&
                                interviewerDetails?.averageInCExitRatings[0]
                                  ?.lastThree?.avgFeedbackReportRatings
                                  ? interviewerDetails?.averageInCExitRatings[0]?.lastThree?.avgFeedbackReportRatings.toFixed(
                                      2
                                    )
                                  : 0}
                                )
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Remarks</td>
                      <td>{interviewerDetails?.adminRemarks?.remarks}</td>
                    </tr>
                  </td>
                </tr>
                {interviewerDetails?.status === "approved" &&
                interviewerDetails?.mockInterview?.meetInviteDetails ? (
                  <>
                    <tr className="info-tabel-header">
                      <td colSpan={2} className="f-sem-bol">
                        Mock Interview Details
                      </td>
                    </tr>

                    <tr className="info-tabel">
                      <td>Interview Link</td>
                      <td>
                        <div className="d-flex">
                          <a
                            href={`${interviewerDetails?.mockInterview?.meetInviteDetails?.adminLink}?key=${interviewerDetails?.mockInterview?.meetInviteDetails?.adminCode}&name=${interviewerDetails?.mockInterview.scheduledBy?.user?.name}`}
                            title="Join Meeting"
                            target="_blank"
                            rel="noreferrer"
                            className="invite-div-join-meet d-center f-10 f-sem-bol rounded"
                          >
                            <img
                              src={video}
                              alt="Join Meeting"
                              className="img-fluid join-meet-img"
                            />
                          </a>
                          <MeetingLinkBtn
                            className={"me-0"}
                            link={`${interviewerDetails?.mockInterview?.meetInviteDetails?.candidateLink}?key=${interviewerDetails?.mockInterview?.meetInviteDetails?.candidateCode}`}
                            // mode={data?.meetInviteDetails?.mode}
                            // type={data?.meetInviteDetails?.type}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr className="info-tabel">
                      <td>Video</td>
                      <td>
                        <div className="d-flex align-items-center ">
                          <a
                            href={`${interviewerDetails?.mockInterview?.meetInviteDetails?.adminLink}?key=${interviewerDetails?.mockInterview?.meetInviteDetails?.adminCode}&name=${interviewerDetails?.mockInterview?.scheduledBy?.user?.name}`}
                            title="Join Meeting"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <button
                              className={`view-recording-btn d-center rounded`}
                            >
                              <img
                                src={video}
                                alt="excel"
                                className="img-fluid join-meet-img"
                              />
                              <span className="f-l0 f-med no-wrap ms-2">
                                View Interview Video
                              </span>
                            </button>
                          </a>
                          <span className="copy-btn ms-2">
                            <MeetingLinkBtn
                              className={"me-0"}
                              link={`${interviewerDetails?.mockInterview?.meetInviteDetails?.candidateLink}?key=${interviewerDetails?.mockInterview?.meetInviteDetails?.candidateCode}`}
                              // mode={data?.meetInviteDetails?.mode}
                              // type={data?.meetInviteDetails?.type}
                            />
                          </span>
                        </div>
                      </td>
                    </tr>

                    {!interviewerDetails?.mockRemark &&
                    interviewerDetails?.mockInterview?.interviewStatus &&
                    [
                      "shortlisted",
                      "hold",
                      "rejected",
                      "noshow",
                      "very_poor",
                      "poor",
                      "average",
                      "above_average",
                      "good",
                      "very_good",
                      "excellent",
                    ].includes(
                      interviewerDetails?.mockInterview?.interviewStatus
                    ) ? (
                      <tr className="info-tabel">
                        <td>Mock Remarks</td>
                        <td>
                          <Input
                            inputClass="input-shadow rounded"
                            className={"common-input"}
                            value={mockForm?.remarks}
                            onChange={(e) =>
                              setMockForm(() => ({
                                remarks: e.target.value,
                              }))
                            }
                            placeholder={"Enter Remarks."}
                            borderNone
                          />
                        </td>
                      </tr>
                    ) : interviewerDetails?.mockRemark ? (
                      <tr className="info-tabel">
                        <td>Mock Remarks</td>
                        <td>{interviewerDetails?.mockRemark}</td>
                      </tr>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  ""
                )}
                {interviewerDetails &&
                  interviewerDetails?.actions &&
                  interviewerDetails?.actions?.length > 0 && (
                    <tr className="info-tabel">
                      <td>Interviewer Logs</td>
                      <td>
                        <div className="d-flex text-align-center">
                          <div className="w-33 logs-header">Action</div>
                          <div className="w-33 logs-header">Action By</div>
                          <div className="w-33 logs-header">Reason</div>
                          <div className="w-33 logs-header">Created On</div>
                        </div>
                        {interviewerDetails?.actions?.map((logs, i) => (
                          <div
                            key={i}
                            className="d-flex p-2 logs-tabel text-align-center"
                          >
                            <div className="w-33">
                              {logs?.type ? logs.type : "Not Available"}
                            </div>
                            <div className="w-33">
                              {logs?.user?.name
                                ? logs?.user?.name
                                : "Not Available"}
                            </div>
                            <div className="w-33">
                              {logs?.reason
                                ? capitalizeAll(logs?.reason)
                                : "Not Available"}
                              <br />
                            </div>
                            <div className="w-33">
                              {moment(logs?.createdAt).format("Do MMM YYYY")}
                              <br />
                              {moment(logs?.createdAt).format("LT")}
                            </div>
                          </div>
                        ))}
                      </td>
                    </tr>
                  )}

                <tr className="info-tabel">
                  <td colSpan={2} className="f-sem-bol">
                    <div className="d-flex action-btn-container">
                      {!interviewerDetails?.mockRemark &&
                      interviewerDetails?.status === "approved" &&
                      role === "Quality Team" ? (
                        <button
                          className="abc-type-1"
                          onClick={handleApproveInterviewer}
                        >
                          Approve Profile
                        </button>
                      ) : (
                        ""
                      )}
                      <button
                        className="abc-type-1"
                        onClick={handleSaveChanges}
                      >
                        Save Changes
                      </button>
                      <button
                        className="abc-type-2"
                        onClick={() => {
                          navigate("/interviewer-company-profile", {
                            state: interviewerDetails,
                          });
                        }}
                      >
                        Edit Profile
                      </button>
                      {status !== "deleted" && (
                        <button
                          className="abc-type-3"
                          onClick={() => {
                            handleChangeStatus({
                              id: interviewerDetails._id,
                              status: "deleted",
                            });
                          }}
                        >
                          Delete Profile
                        </button>
                      )}
                      {status !== "blacklisted" && (
                        <button
                          className="abc-type-4"
                          onClick={() => {
                            handleChangeStatus({
                              id: interviewerDetails._id,
                              status: "blacklisted",
                            });
                          }}
                        >
                          Blacklist Interviewer
                        </button>
                      )}

                      {status !== "deactivated" && (
                        <button
                          className="abc-type-3"
                          onClick={() => {
                            handleChangeStatus({
                              id: interviewerDetails._id,
                              status: "inactive",
                            });
                          }}
                        >
                          Deactivate
                        </button>
                      )}
                      {status === "hold" ? (
                        <button
                          className="abc-type-5"
                          onClick={() => {
                            handleChangeStatus({
                              id: interviewerDetails._id,
                              status: "approved",
                            });
                          }}
                        >
                          Unhold Account
                        </button>
                      ) : status === "approved" ? (
                        <button
                          className="abc-type-5"
                          onClick={() => {
                            handleChangeStatus({
                              id: interviewerDetails._id,
                              status: "hold",
                            });
                          }}
                        >
                          Hold Account
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </td>
                </tr>
              </table>
            </>
          ) : (
            <>
              <NoRecordFound />
            </>
          )}
        </div>
      </div>{" "}
    </div>
  );
};

export default InterviewerInfoTable;
