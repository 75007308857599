import React, { memo } from "react";

const Loader = memo(() => {
  return (
    <div className="wrapper">
      <div className="loader"></div>
    </div>
  );
});

export default Loader;
