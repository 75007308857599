import { useState } from "react";
import Button from "./Button";
import { useDispatch } from "react-redux";
import BIN from "../../assets/images/bin_icon.svg";

import {
  getIncserveRequirements,
  updateIncserveAddQuestion,
} from "../../redux/action";
import Input from "./Input";
import { toast } from "react-toastify";
import { Toast } from "../../utils/helper";

const IncServeAddQuestionPopUp = ({
  setAddQuestionPopUp,
  sideSectionData,
  status,
  currentPage,
  entriesLimit,
  search,
  sortBy,
  isFilterBtnData,
  isReportApproved,
  setIsSideSectionOpen,
}) => {
  const dispatch = useDispatch();
  const [screeningQuestionInput, setScreeningQuestionInput] = useState("");
  const [form, setForm] = useState({
    screeningQuestions: sideSectionData?.screeningQuestions
      ? sideSectionData?.screeningQuestions
      : [],
    customScreeningQuestions: [],
    user: sideSectionData?.user?._id,
    interviewId: sideSectionData?._id,
  });
  const [error, setError] = useState("");

  const handleAddClick = () => {
    const data = form;
    const screeningQuestionIds = form?.screeningQuestions.map(
      (item) => item?._id
    );

    setError("");
    dispatch(
      updateIncserveAddQuestion({
        data,
        screeningQuestionIds,
        callback: () => {
          setIsSideSectionOpen(false);
          setAddQuestionPopUp(false);
          dispatch(
            getIncserveRequirements({
              status: status,
              search: search,
              page: currentPage,
              pageSize: entriesLimit,
              filters: isFilterBtnData,
            })
          );
        },
      })
    );
  };
  const handleOnChangeInput = (e) => {
    setScreeningQuestionInput(e.target.value);
    setError("");
  };
  const handleAddScreeningQuestion = () => {
    if (screeningQuestionInput !== "") {
      setForm((prev) => ({
        ...prev,
        customScreeningQuestions: [
          ...prev.customScreeningQuestions,
          screeningQuestionInput,
        ],
      }));
    } else {
      setError("Please add question.");
    }
    setScreeningQuestionInput("");
  };

  const handleRemoveScreeningQuestion = (index) => {
    setForm((prev) => {
      const updatedQuestions = [...prev.customScreeningQuestions];
      updatedQuestions.splice(index, 1);
      return {
        ...prev,
        customScreeningQuestions: updatedQuestions,
      };
    });
  };

  const handleToggleScreeningQuestion = (question) => {
    setForm((prev) => {
      const updatedQuestions = [...prev.screeningQuestions];
      const questionIndex = updatedQuestions.filter(
        (item) => item._id !== question._id
      );
      return {
        ...prev,
        screeningQuestions: questionIndex,
      };
    });
  };

  return (
    <>
      <div className="sp-popup">
        <div className="popup-box allot_interview_popUp_box position-relative p-0">
          <div className="titile d-flex justify-content-between align-items-center p-4 pb-0">
            <h6 className="schedule_popUp_title f-20">
              Add Interview Questions
            </h6>
            <Button
              type="button"
              className={"btn-close p-2 m-2"}
              label={"Close"}
              onClick={() => setAddQuestionPopUp(false)}
            />
          </div>
          <table className="table-list f-l f-reg mt-1 mx-4 w-91">
            <tr className="info-tabel">
              <td className="w-25">Interview ID</td>
              <td>
                {sideSectionData?.code
                  ? sideSectionData?.code
                  : sideSectionData?.bulkCode
                  ? sideSectionData?.bulkCode
                  : "Not Available"}
              </td>
            </tr>
          </table>
          <div className="question_content_container">
            <table className="table-list f-l f-reg mt-0 mx-4 w-91">
              {/* screening questions */}
              <tr className="info-tabel bg-transparent">
                <div colspan="2" className="font-bold f-14 py-3">
                  Questions :
                </div>
              </tr>
              <tr className="info-tabel bg-transparent"></tr>
              {form?.screeningQuestions && form?.screeningQuestions?.length > 0
                ? form?.screeningQuestions.map((item, index) => (
                    <tr className="position-relative" colspan="2">
                      <div className="w-91">
                        <td
                          style={{
                            width: "auto",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <span className="me-3">{`Q${index + 1}.`}</span>
                        </td>

                        <td className="w-91">{item?.question}</td>
                      </div>
                      <div className="d-center bin_icon">
                        <img
                          src={BIN}
                          className="cursor-pointer"
                          alt="bin"
                          onClick={() => handleToggleScreeningQuestion(item)}
                        />
                      </div>
                    </tr>
                  ))
                : " "}
              {form?.customScreeningQuestions
                ? form?.customScreeningQuestions.map((item, index) => (
                    <tr className="position-relative" colSpan="2">
                      <div className="w-91">
                        <td
                          style={{
                            width: "auto",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <span className="me-3">{`Q${
                            form?.screeningQuestions?.length + index + 1
                          }.`}</span>
                        </td>
                        <td className="w-91">{item}</td>
                        <img
                          src={BIN}
                          className="cursor-pointer bin_icon"
                          alt="bin"
                          onClick={() => handleRemoveScreeningQuestion(index)}
                        />
                      </div>
                    </tr>
                  ))
                : " "}
              <tr>
                <td className="mt-3 position-relative" colSpan="2">
                  <Input
                    type="text"
                    name="screeningQuestion"
                    error={error}
                    labelClass="my-2 f-l f-sem-bol"
                    inputClass="f-l f-reg rounded input-shadow w-100"
                    placeholder="Enter Question"
                    className={"common-input w-100"}
                    onChange={handleOnChangeInput}
                    value={screeningQuestionInput}
                    borderNone
                  />
                  <button
                    style={{ top: "9px" }}
                    className="plus_btn"
                    onClick={() => handleAddScreeningQuestion()}
                  >
                    +
                  </button>
                </td>
              </tr>
              <tr className="py-3">
                <td colspan="2">
                  <div className="d-center gap-20">
                    <Button
                      text="Save"
                      className="save-btn rounded allot_btn"
                      onClick={() => handleAddClick()}
                      style={{ height: "35px" }}
                    />
                    <Button
                      text="Cancel"
                      className="cancel-btn rounded allot_btn"
                      onClick={() => setAddQuestionPopUp(false)}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td></td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
export default IncServeAddQuestionPopUp;
