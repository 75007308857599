import React from "react";

const SelectDropdown = ({ selected, options, handleSubmit }) => {
  return (
    <select
      className="select-dropdown f-sem-bol"
      value={selected}
      onChange={handleSubmit}
    >
      {options.map((data, index) => (
        <option value={data.value} key={index} className="option">
          {data.name}
        </option>
      ))}
    </select>
  );
};

export default SelectDropdown;
