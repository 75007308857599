import React, { lazy, memo } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import RoutesFile from "./RoutesFile";
import { useEffect } from "react";
const NotFound = lazy(() => import("../container/404NotFound"));
const MainPage = lazy(() => import("../container/MainPage"));

const RouteList = memo(() => {
  return (
    <Routes>
      {RoutesFile.map((itm, key) => (
        <Route
          key={key}
          path={itm.path}
          exact={itm.exact}
          element={
            <MainPage
              name={itm.name}
              Component={itm.component}
              mainResource={itm}
              resource={itm?.resource}
              action={itm?.action}
              permission={itm?.permissions}
              pvt={itm.private}
            />
          }
        />
      ))}
      <Route path="/404" element={<NotFound />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
});

export default RouteList;
