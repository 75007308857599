import React from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  getInvoiceDetailsByMonth,
  interviewAccountDetailsList,
} from "../../../redux/action";

import { Toast, numberToWords } from "../../../utils/helper";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { savePDF } from "@progress/kendo-react-pdf";
import { useRef } from "react";
import Invoice from "../../EmployerList/EmployerProfile/Invoice";
import moment from "moment";
const InterviewerInvoices = () => {
  const [invoiceData, setInvoiceAccountList] = useState([]);
  const [isDownload, setIsDownload] = useState(false);
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const data = useParams();

  useEffect(() => {
    if (data) {
      dispatch(
        getInvoiceDetailsByMonth({
          data,
          type: "incserve",
          callback: (res) => {
            setInvoiceAccountList(res?.data?.result);
            if (res?.data?.result?.data?.length > 0) {
              setIsDownload(true);
              // toast.success(<Toast msg={"Invoice successfully downloaded."} />);
            } else {
              setIsDownload(false);
              toast.error(<Toast msg={"Invoice not found."} />);
            }
          },
        })
      );
    }
  }, [data, dispatch]);

  const downloadInvoice = () => {
    if (isDownload) {
      let element = inputRef.current;
      savePDF(element, {
        paperSize: "auto",
      });
      setIsDownload(false);
    }
  };

  return (
    <div className="w-100 d-flex justify-content-center align-items-center">
      <div
        style={{ width: "600px", height: "100vh", backgroundColor: "#EFEFEF" }}
      >
        <div className="d-flex justify-content-end">
          <button
            className="excel-btn d-center m-2 rounded w-auto no-wrap justify-content-end"
            onClick={downloadInvoice}
          >
            <span className="f-l f-med">Download Invoice</span>
          </button>
        </div>
        <div className="interviewer-invoice-container" ref={inputRef}>
          <div
            className="invoice p-2"
            style={{ top: "50px", backgroundColor: "#EFEFEF" }}
          >
            <div className="invoice-main-content">
              <div className="d-flex justify-content-center f-30 my-3 f-bol invoice-title">
                INVOICE
              </div>
              <div className="company-details d-flex justify-content-between">
                <div className="p-1 part-1 f-14">
                  <p className="company-name f-16 f-bol">{invoiceData?.name}</p>
                  <p className="company-address f-12 f-reg">
                    {invoiceData?.address?.address}
                    <br />
                    {invoiceData?.address?.city}
                    <br />
                    {invoiceData?.address?.state}
                    <br />
                    {invoiceData?.address?.pincode}
                  </p>
                </div>{" "}
                <div className="part-1 f-14 d-flex justify-content-between">
                  <div className="part-title">
                    <div className="d-flex f-12">Interviewer ID</div>
                    <div className="d-flex f-12">Interviewer Name</div>
                    <div className="d-flex f-12">Invoice Month</div>
                    <div className="d-flex f-12">Total Interviews</div>
                    <div className="d-flex f-12">Conducted Interviews</div>
                    <div className="d-flex f-12">No Shows</div>
                  </div>
                  <div className="part-title">
                    <div className="d-flex f-12">
                      :{" "}
                      {invoiceData?.code ? invoiceData?.code : "Not Available"}
                    </div>
                    <div className="d-flex f-12">
                      :{" "}
                      {invoiceData?.name ? invoiceData?.name : "Not Available"}
                    </div>
                    <div className="d-flex f-12">
                      :{" "}
                      {invoiceData &&
                      invoiceData?.data &&
                      invoiceData?.data[0]?.month
                        ? invoiceData?.data[0]?.month
                        : "Not Available"}
                    </div>
                    <div className="d-flex f-12">
                      :{" "}
                      {invoiceData &&
                      invoiceData?.data &&
                      invoiceData?.data[0]?.totalInterviews
                        ? invoiceData?.data[0]?.totalInterviews
                        : "Not Available"}
                    </div>
                    <div className="d-flex f-12">
                      :
                      {invoiceData &&
                      invoiceData?.data &&
                      invoiceData?.data[0]?.conductedInterviews
                        ? invoiceData?.data[0]?.conductedInterviews
                        : "Not Available"}{" "}
                    </div>
                    <div className="d-flex f-12">
                      :{" "}
                      {invoiceData &&
                      invoiceData?.data &&
                      invoiceData?.data[0]?.noshowInterviews
                        ? invoiceData?.data[0]?.noshowInterviews
                        : "Not Available"}{" "}
                    </div>
                  </div>
                </div>
                <div className="part-2 f-14 d-flex flex-column justify-content-between">
                  <div>
                    <div className="divart-title f-med f-12">
                      Invoice number
                    </div>
                    <div className="f-sem-bol text-dark">
                      #{invoiceData?.invoice}
                    </div>
                  </div>
                  <div>
                    <div className="divart-title f-med f-12">Invoice Date</div>
                    <div className="f-sem-bol text-dark">
                      {moment(invoiceData?.createdAt).format("ll")}
                    </div>
                  </div>
                </div>
              </div>
              <table className="product-details w-100 f-12">
                <thead className="p-3">
                  <tr className="invoice-table-header tr-border">
                    <th className="f-10 p-2">NO.</th>
                    <th className="f-10 text-center">
                      INTERVIEW <br /> ID
                    </th>
                    <th className="text-center f-10">
                      CANDIDATE <br /> NAME
                    </th>
                    <th className="text-center f-10">
                      DATE OF <br /> INTERVIEW
                    </th>
                    <th className="text-center f-10">FEEDBACK</th>
                    <th className="text-center f-10">
                      PAY PER <br /> INTERVIEW
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {invoiceData &&
                    invoiceData?.data &&
                    invoiceData?.data[0]?.interviews?.length > 0 &&
                    invoiceData?.data[0]?.interviews.map((itm, index) => (
                      <tr className="f-sem-bol f-14  tr-border" key={index}>
                        <td className="f-med f-12 text-center p-2">
                          {index + 1}
                        </td>
                        <td className="f-med f-12 text-center">{itm?.code}</td>
                        <td className="f-med f-12 text-center">
                          {itm?.candidateName}
                        </td>
                        <td className="f-med f-12 text-center">
                          {moment(itm?.interviewDate).format("Do MMM YYYY")}
                          <br />
                          {
                            new Date(itm?.interviewTime)
                              .toLocaleString()
                              .split(", ")[1]
                          }
                        </td>
                        <td className="f-med f-12 text-center">
                          {itm?.status === "very_poor"
                            ? "Very poor"
                            : itm?.status === "good"
                            ? "Good"
                            : itm?.status === "poor"
                            ? "Poor"
                            : itm?.status === "average"
                            ? "Average"
                            : itm?.status === "above_average"
                            ? "Above Average"
                            : itm?.status === "very_good"
                            ? "Very Good"
                            : itm?.status === "excellent"
                            ? "Excellent"
                            : ""}
                        </td>
                        <td className="f-med f-12 text-center">
                          {itm?.payPerInterview}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <div className="total-container d-flex justify-content-between align-items-end tr-border">
                <span className="p-1 f-12 f-sem-bol">
                  Total Amount (In words)
                </span>
                <table className="f-14 f-sem-bol">
                  <tbody>
                    <tr>
                      <td className="f-12 f-med">Total</td>
                      <td className="text-end f-16 f-sem-bol">
                        INR
                        {invoiceData?.data
                          ? invoiceData?.data.length > 0
                            ? invoiceData?.data[0]?.totalPayPerInterview ===
                                0 ||
                              invoiceData?.data[0]?.totalPayPerInterview ===
                                null
                              ? 0.0
                              : invoiceData?.data[0]?.totalPayPerInterview?.toFixed(
                                  2
                                )
                            : 0.0
                          : 0.0}
                      </td>{" "}
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="f-14 f-sem-bol">
                {invoiceData?.data && invoiceData?.data.length > 0
                  ? numberToWords(invoiceData?.data[0]?.totalPayPerInterview)
                  : "Zero Only"}
              </div>
              <div className="d-flex justify-content-center f-30 my-3 f-bol invoice-title">
                THANK YOU
              </div>
            </div>
            <div className="d-flex align-items-end flex-column">
              {/* <p className="title f-10 f-bol">
            For Incruiter India Private Limited
          </p> */}
              <p className="auth f-10 f-med">Authorized Signatory</p>
            </div>
            <div className="invoice-footer">
              <div className="f-10 f-med text-center">
                This is a Computer Generated Invoice
              </div>
              <div className="tr-border"></div>
              <div className="additional-info f-12 f-med text-center my-1">
                080-471-87100 | www.incruiter.com | contact@incruiter.com
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InterviewerInvoices;
