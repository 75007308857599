import React, { memo, useState, useEffect, useRef } from "react";
import {
  capitalize,
  capitalizeAll,
  filterFromdateName,
  filterTodateName,
  useOutsideAlerter,
} from "../../utils/helper";
import filter from "../../assets/images/filter.svg";
import SearchSelect from "./SearchSelect";

import { useDispatch, useSelector } from "react-redux";
import { getAllEmployers } from "../../redux/action";
import { useMemo } from "react";

const EventTrackingFilter = memo(
  ({ setFilterStates, filterStates, setShowFilter, showFilter }) => {
    // const [employerList, setEmployerList] = useState([]);
    const ref = useRef();
    const dispatch = useDispatch();
    useOutsideAlerter(ref, () => setShowFilter(false));

    const allEmployerList = useSelector(
      (state) => state.UserAuth.allEmployerList
    );

    useEffect(() => {
      dispatch(getAllEmployers());
    }, [dispatch]);

    const employerList = useMemo(() => {
      const tmpArray = [
        {
          value: "",
          label: "Select Employer",
        },
      ];
      if (allEmployerList && allEmployerList.length > 0) {
        allEmployerList.forEach((itm) => {
          if (itm?.employer?.company?.name) {
            tmpArray.push({
              value: itm?.employer?.user,
              label: itm?.employer?.company?.name,
            });
          }
        });
      }
      return tmpArray;
    }, [allEmployerList]);

    const handleShowFilter = () => {
      setShowFilter(!showFilter);
    };

    const handleSelectChange = (e, name) => {
      setFilterStates({ ...filterStates, [name]: e.value });
    };

    const handleFilterChange = (e, inputType) => {
      setFilterStates({ ...filterStates, [inputType]: e.target.value });
    };

    const handleClearFilter = () => {
      setFilterStates("");
      setShowFilter(false);
    };

    return (
      <div className={`filter-btn-container position-relative ms-2`} ref={ref}>
        <button className="filter-btn d-center" onClick={handleShowFilter}>
          <img src={filter} alt="filter-btn" className="filter-img img-fluid" />
        </button>
        {showFilter && (
          <div
            className={`position-absolute event-track-filter-popup input-shadow rounded f-reg `}
          >
            <div className="date-container d-flex justify-content-between f-reg">
              <label htmlFor="" className={"f-l"}>
                <span className="f-sem-bol no-wrap">From Date</span>
                <input
                  type="date"
                  max={filterStates?.to || ""}
                  value={filterStates?.from || ""}
                  onChange={(e) => handleFilterChange(e, "from")}
                  // max={maxDate}
                />
              </label>
              <label htmlFor="" className={"f-l"}>
                <span className="f-sem-bol no-wrap">To Date</span>
                <input
                  type="date"
                  value={filterStates?.to || ""}
                  onChange={(e) => handleFilterChange(e, "to")}
                  min={filterStates?.from || ""}
                  // max={maxDate}
                />
              </label>
            </div>

            <>
              <SearchSelect
                label="Posted By"
                name="user"
                labelClass="my-1 f-12 f-bol"
                inputClass="f-12 rounded input-shadow"
                borderNone
                placeholder={"Select a Employer"}
                options={employerList}
                // error={error.interviewerType}
                value={filterStates.user}
                onChange={(e) => handleSelectChange(e, "user")}
              />
            </>
            <button
              className={`filter-clear-btn d-flex align-items-center justify-content-center `}
              onClick={handleClearFilter}
            >
              Clear Filter
            </button>
          </div>
        )}
      </div>
    );
  }
);

export default EventTrackingFilter;
