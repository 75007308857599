import { combineReducers } from "redux";
import Candidate from "./candidateReducer";
import Interview from "./interviewReducer";
import Skills from "./skillsReducer";
import Subscription from "./subscriptionReducer";
import UserAuth from "./userAuthReducer";
import ProductPermission from "./productPermissionReducer";

const appReducer = combineReducers({
  Candidate,
  Interview,
  Skills,
  Subscription,
  UserAuth,
  ProductPermission
});

const reducers = (state, action) => {
  return appReducer(state, action);
};

export default reducers;
