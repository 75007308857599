import React, { memo, useEffect, useState, useRef } from "react";
import { Bars } from "react-loader-spinner";
import { savePDF } from "@progress/kendo-react-pdf";
import TextareaAutosize from "react-textarea-autosize";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useDispatch, useSelector } from "react-redux";
import PrintContent from "../../../component/commonComponent/PrintContent";
const CreateJD = memo(
  ({
    jDData,
    isCreatedJD,
    setIsCreatedJD,
    JDTitle,
    companyData,
    whiteLabel,
    isDownloadJdButtonVisible,
  }) => {
    const inputRef = useRef(null);
    const [isDownload, setIsDownload] = useState(false);
    const [jDDataValue, setJDDataValue] = useState(jDData ? jDData : "");
    const textareaRef = useRef(null);

    const removeFirstOccurrence = (string, text) => {
      const afterText = string.slice(text.length);
      return afterText;
    };

    useEffect(() => {
      let tmpDataSplit = jDData.split("\\n\\n", 1);
      let newFinalValue = jDData;
      let newText = tmpDataSplit[0] + "\\n\\n";
      if (tmpDataSplit[0].length < 3) {
        newFinalValue = removeFirstOccurrence(newFinalValue, newText);
      }
      if (inputRef?.current) {
        inputRef?.current.focus();
        inputRef?.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      }
      newFinalValue = newFinalValue.replace("\t", "");
      setJDDataValue(newFinalValue ? newFinalValue : "");
    }, [jDData]);

    useEffect(() => {
      if (isDownload) {
        let element = inputRef.current;
        savePDF(element, {
          width: "100%",
          paperSize: "A4",
          title: JDTitle,
          fileName: JDTitle,
        });
        setIsDownload(false);
      }
    }, []);

    const handleCreatedJD = () => {
      setIsCreatedJD(false);
    };
    const handleProcedureContentChange = (e) => {
      setJDDataValue(e?.target?.value);
    };
    const encodedFileName = encodeURIComponent(JDTitle);
    const sanitizedFileName = JDTitle?.replace(/\./g, "_");

    const handleDownloadClick = () => {
      setIsDownload(true);
    };

    return (
      isCreatedJD && (
        <div
          className={`questions-wrapper p-3 flex-column input-shadow ${
            isCreatedJD ? "d-flex" : "d-none"
          }`}
        >
          <div className="d-flex justify-content-between f-14 f-sem-bol mb-2">
            <span className="text-primary">JOB DESCRIPTION</span>
          </div>
          <div className="screen-qs-container2 pe-1">
            {!jDDataValue ? (
              <div className="w-100 d-center flex-column">
                <Bars
                  height="40"
                  width="40"
                  color="#133f70"
                  ariaLabel="bars-loading"
                  visible={true}
                />
                <div className="f-12 f-sem-bol text-theme-gray mt-2">
                  Creating job description...
                </div>
              </div>
            ) : (
              <>
                <div
                  className="jDDataValue-container position-relative"
                  id={"report"}
                  ref={inputRef}
                >
                  <div className="jd-title-container">
                    <div className="jd-title f-18 f-sem-bol">{JDTitle}</div>
                    {companyData?.company?.name ? (
                      <>
                        <div className="jd-company-name f-12 f-sem-bol">
                          {companyData?.company?.name}
                        </div>
                      </>
                    ) : (
                      companyData?.name && (
                        <>
                          <div className="jd-company-name f-12 f-sem-bol">
                            {companyData?.name}
                          </div>
                        </>
                      )
                    )}
                  </div>
                  {companyData?.company?.logo ? (
                    <div className="jd-logo-container">
                      <img
                        className="jd-logo"
                        src={companyData?.company?.logo}
                      />
                    </div>
                  ) : (
                    companyData?.logo && (
                      <div className="jd-logo-container">
                        <img className="jd-logo" src={companyData?.logo} />
                      </div>
                    )
                  )}
                  <TextareaAutosize
                    className={`jDDataValue-div f-12 f-med`}
                    name={"create-jd"}
                    ref={textareaRef}
                    placeholder={"Create Job Description"}
                    onChange={handleProcedureContentChange}
                    value={jDDataValue.replace(/\\n/g, "\n")}
                  />
                </div>
              </>
            )}
          </div>
          <button
            type="button"
            className="btn-close position-absolute"
            aria-label="Close"
            onClick={handleCreatedJD}
          ></button>
          <div className="print-main-co">
            <PDFDownloadLink
              document={
                <PrintContent
                  value={jDDataValue}
                  JDTitle={JDTitle}
                  company={
                    companyData?.company?.name
                      ? companyData?.company?.name
                      : companyData?.name
                      ? companyData?.name
                      : ""
                  }
                  logo={whiteLabel?.url ? whiteLabel?.url : ""}
                />
              }
              fileName={sanitizedFileName}
            >
              {isDownloadJdButtonVisible && (
                <>
                  <button
                    type="button"
                    className="f-l cpp-btn f-sem-bol common-btn mt-2 w-100"
                    aria-label="Download JD"
                    onClick={handleDownloadClick}
                  >
                    Download JD
                  </button>
                </>
              )}{" "}
            </PDFDownloadLink>
          </div>
        </div>
      )
    );
  }
);

export default CreateJD;
