import React, { memo, useState, useRef } from "react";
import { useOutsideAlerter, accessRoutes } from "../../utils/helper";

const MenuButton = memo(
  ({
    wrapperClass,
    img,
    handleDelete,
    handleEdit,
    isThirdOptionVisible,
    thirdOptionText,
    handleThirdOption,
    isDeleteVisible,
    isEditVisible,
    isFourthOptionVisible,
    fourthOptionText,
    handleFourthOption,
    isFifthOptionVisible,
    fifthOptionText,
    handleFifthOption,
    disabled,
  }) => {
    const [openMenu, setOpenMenu] = useState(false);
    const ref = useRef();
    useOutsideAlerter(ref, () => setOpenMenu(false));
    return (
      <div className="dropdown">
        <button
          className="threedot-btn d-flex align-items-center justify-content-center rounded f-ph"
          type="button"
          onClick={() => setOpenMenu(!openMenu)}
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img src={img} alt="threeDot" className="threedot-img" />
        </button>
        <ul
          className="dropdown-menu shadow-6 threedot-menu rounded"
          aria-labelledby="dropdownMenuButton1"
        >
          {isEditVisible && (
            <li
              className="threedot-menu-content d-flex align-items-center f-l f-bol m-2 rounded ps-2"
              onClick={() => {
                handleEdit();
                setOpenMenu(false);
              }}
            >
              Edit
            </li>
          )}
          {isThirdOptionVisible && (
            <li
              className="threedot-menu-content d-flex align-items-center f-l f-bol m-2 rounded ps-2 content-edit"
              onClick={() => {
                handleThirdOption();
                setOpenMenu(false);
              }}
            >
              {thirdOptionText}
            </li>
          )}
          {isDeleteVisible && (
            <li
              className="threedot-menu-content text-theme-red d-flex align-items-center f-l f-bol m-2 rounded ps-2"
              onClick={() => {
                handleDelete();
                setOpenMenu(false);
              }}
            >
              Delete
            </li>
          )}

          {isFourthOptionVisible && (
            <li
              className="threedot-menu-content d-flex align-items-center f-l f-bol m-2 rounded ps-2 content-edit"
              onClick={() => {
                // handleEdit();
                handleFourthOption();
                setOpenMenu(false);
              }}
            >
              {fourthOptionText}
            </li>
          )}
          {isFifthOptionVisible && (
            <li
              className="threedot-menu-content d-flex align-items-center f-l f-bol m-2 rounded ps-2 content-edit"
              onClick={() => {
                handleFifthOption();
                setOpenMenu(false);
              }}
            >
              {fifthOptionText}
            </li>
          )}
        </ul>
      </div>
    );
  }
);

export default MenuButton;
