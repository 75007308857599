import React, { memo, useEffect, useState } from "react";
import { Button, Input } from "../../component/commonComponent";
import { Bars } from "react-loader-spinner";
import { remarksChecker } from "../../utils/helper";
import removeField from "../../assets/images/remove-field.svg";

import { useRef } from "react";
const AddQuestions = memo(
  ({
    setIsScreeningQuestion,
    inputQuestion,
    setInputQuestion,
    screeningQuestionsList,
    setScreeningQuestionsList,
    form,
    setForm,
    screeningQsLoader,
    handleGenrateAiQs,
    aiScreeningQuestions,
    customScreeningQuestionLists,
    setCustomScreeningQuestionLists,
    allAIQuestions,
    setAllAIQuestions,
    directUploadQuestion,
    setDirectUploadQuestion,
    newQuestions,
    setNewQuestions,
    screeningQuestion,
    isScreeningQuestion,
  }) => {
    const inputRef = useRef(null);

    useEffect(() => {
      const isDuplicate =
        screeningQuestionsList &&
        screeningQuestionsList.length > 0 &&
        screeningQuestionsList.some((question) =>
          screeningQuestionsList.some(
            (item) => item.question === question.question
          )
        );
      if (isDuplicate) {
        const uniqueQuestions =
          screeningQuestion &&
          screeningQuestion.length > 0 &&
          screeningQuestion.filter(
            (question) =>
              !screeningQuestionsList.some(
                (item) => item.question === question.question
              )
          );
      }
    }, [screeningQuestion, screeningQuestionsList]);

    useEffect(() => {
      if (aiScreeningQuestions && aiScreeningQuestions.length > 0) {
        let startIndex = aiScreeningQuestions.indexOf("1.");
        let trimmedQuestions = aiScreeningQuestions.substring(startIndex);
        let tmpDataArray = trimmedQuestions.split(/\d+\.\s/).slice(1);
        tmpDataArray = tmpDataArray
          .filter((que) => que !== "")
          .map((question) => {
            return question;
          });
        if (inputRef.current) {
          inputRef.current.focus();
          inputRef.current.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
        }
        setNewQuestions(tmpDataArray);
      }
    }, [aiScreeningQuestions]);

    useEffect(() => {
      let tempAllAIQuestions =
        allAIQuestions && allAIQuestions.length > 0 ? allAIQuestions : [];

      let tempNewQuestions =
        newQuestions && newQuestions?.length > 0 ? newQuestions : [];

      let totalQuestion = [];

      if (tempAllAIQuestions.length > 0) {
        tempAllAIQuestions.map((data) => {
          totalQuestion.push(data);
        });
      }
      if (tempNewQuestions.length > 0) {
        tempNewQuestions.map((data) => {
          totalQuestion.push(data);
        });
      }
      setCustomScreeningQuestionLists((prev) => [...prev, ...totalQuestion]);
    }, [allAIQuestions, newQuestions]);

    useEffect(() => {
      if (directUploadQuestion) {
        setAllAIQuestions([...allAIQuestions, ...newQuestions]);
        setNewQuestions([]);
      }
    }, [directUploadQuestion]);

    useEffect(() => {
      setCustomScreeningQuestionLists([...allAIQuestions, ...newQuestions]);
    }, [allAIQuestions, newQuestions]);

    const handleInputQuestionChange = (e) => {
      setInputQuestion((prev) => ({ ...prev, question: e.target.value }));
    };

    const handleAddCustomScreeningQuestion = () => {
      if (inputQuestion?.question.length === 0) {
        setInputQuestion((prev) => ({
          ...prev,
          error: "Please select or enter the screening questions.",
        }));
      } else if (inputQuestion?.question.length.length < 10) {
        setInputQuestion((prev) => ({
          ...prev,
          error: "The minimum length required is 10 characters.",
        }));
      } else if (inputQuestion?.question?.includes("  ")) {
        setInputQuestion((prev) => ({
          ...prev,
          error: "The question should not contain any double spaces",
        }));
      } else {
        setCustomScreeningQuestionLists((prev) => [
          ...prev,
          inputQuestion?.question,
        ]);
        setInputQuestion({
          question: "",
          error: "",
        });
      }
    };

    const handleCheckUncheckCustomQuestion = (customQuestion, checked) => {
      if (checked) {
        let questions = form?.customScreeningQuestions;
        questions.push(customQuestion);
        setForm((prev) => ({
          ...prev,
          customScreeningQuestions: questions,
        }));
      } else {
        let questions =
          form?.customScreeningQuestions &&
          form?.customScreeningQuestions.length > 0 &&
          form?.customScreeningQuestions.filter(
            (data) => data !== customQuestion
          );
        setForm((prev) => ({ ...prev, customScreeningQuestions: questions }));
      }
    };

    const handleRemoveCustomQuestion = (value) => {
      const tempCustomScreeningQuestionLists =
        customScreeningQuestionLists && customScreeningQuestionLists.length > 0
          ? customScreeningQuestionLists.filter((itm) => itm !== value)
          : [];

      const tempAllAIQuestions =
        allAIQuestions && allAIQuestions.length > 0
          ? allAIQuestions.filter((itm) => itm !== value)
          : [];
      setCustomScreeningQuestionLists(tempCustomScreeningQuestionLists);
      setAllAIQuestions(tempAllAIQuestions);

      setForm({
        ...form,
        customScreeningQuestions: tempAllAIQuestions,
      });
    };

    const handleRemoveScreeningQuestion = (questionId) => {
      const updatedQuestions = form.screeningQuestions.filter(
        (itm) => itm !== questionId
      );

      const updatedScreeningQuestionList = screeningQuestionsList.filter(
        (itm) => itm._id !== questionId
      );
      setScreeningQuestionsList(updatedScreeningQuestionList);
      setForm((prev) => ({
        ...prev,
        screeningQuestions: updatedQuestions,
      }));
    };

    const handleOnChange = (e) => {
      setInputQuestion((prev) => ({
        ...prev,
        question: remarksChecker(e.target.value).replace(/  /g, " "),
      }));
    };

    const handleCheck = (itm, checked) => {
      if (checked) {
        setForm((prev) => ({
          ...prev,
          screeningQuestions: [...prev?.screeningQuestions, itm?._id],
        }));
      } else {
        const filteredScreeningQuestions = form?.screeningQuestions.filter(
          (data) => data !== itm?._id
        );
        setForm((prev) => ({
          ...prev,
          screeningQuestions: filteredScreeningQuestions,
        }));
      }
    };

    return (
      <>
        {isScreeningQuestion && (
          <div
            className={`screening-questions-wrapper flex-column justify-content-between input-shadow`}
          >
            <>
              <div className="screen-qs-wrapper">
                <div className="d-flex justify-content-between f-14 f-sem-bol">
                  <span className="sq-heading">Screening Questions</span>
                  {/* <span className="me-3 sq-add">Add</span> */}
                </div>
                <div className="screen-qs-container pe-2">
                  {screeningQuestion &&
                    screeningQuestion.map((itm, index) => (
                      <div
                        key={index}
                        className="question d-flex justify-content-between align-items-center my-2"
                      >
                        <label className="f-12 f-med w-90" htmlFor="">
                          {index + 1}. {itm?.question}
                        </label>
                        <div className="d-center">
                          <input
                            type="checkbox"
                            name={itm?._id}
                            onChange={(e) => handleCheck(itm, e.target.checked)}
                            checked={form?.screeningQuestions.includes(
                              itm?._id
                            )}
                          />
                          <div className="spacer-div ms-1"></div>
                        </div>
                      </div>
                    ))}
                  <div ref={inputRef}>
                    {screeningQuestionsList &&
                      screeningQuestionsList.length > 0 &&
                      screeningQuestionsList.map((itm, index) => (
                        <div
                          key={index}
                          className="question d-flex justify-content-between align-items-center my-2 screening"
                        >
                          {/* <label className="f-12 f-med w-90" htmlFor="">
                            {index + 1}. {itm?.question}
                          </label> */}
                          <label className="f-12 f-med w-90" htmlFor="">
                            {screeningQuestion && screeningQuestion.length > 0
                              ? screeningQuestion.length + index + 1
                              : index + 1}
                            . {itm?.question}
                          </label>
                          <div className="d-center">
                            <input
                              type="checkbox"
                              onChange={(e) =>
                                handleCheck(itm, e.target.checked)
                              }
                              checked={
                                form?.screeningQuestions &&
                                form?.screeningQuestions.length > 0
                                  ? form?.screeningQuestions.includes(itm._id)
                                  : false
                              }
                            />
                            <img
                              src={removeField}
                              className="qs-remove-icon ms-1"
                              onClick={() =>
                                handleRemoveScreeningQuestion(itm._id)
                              }
                              alt="remove"
                            />
                          </div>
                        </div>
                      ))}
                    {customScreeningQuestionLists &&
                      customScreeningQuestionLists.length > 0 &&
                      customScreeningQuestionLists?.map((itm, key) => (
                        <div
                          key={key}
                          className="question d-flex justify-content-between align-items-center my-2 custom"
                        >
                          {/* <label className="f-12 f-med w-90" htmlFor="">
                            {screeningQuestionsList &&
                            screeningQuestion &&
                            screeningQuestion.length > 0
                              ? screeningQuestionsList.length + key + 1
                              : key + 1}
                            . {itm.replaceAll("\\r", "")}
                          </label> */}
                          <label className="f-12 f-med w-90" htmlFor="">
                            {screeningQuestionsList &&
                              screeningQuestion &&
                              screeningQuestionsList.length +
                                screeningQuestion.length +
                                key +
                                1}
                            . {itm.replaceAll("\\r", "")}
                          </label>
                          <div className="d-center">
                            <input
                              type="checkbox"
                              name={itm}
                              onChange={(e) =>
                                handleCheckUncheckCustomQuestion(
                                  itm.replaceAll("\\r", ""),
                                  e.target.checked
                                )
                              }
                              checked={
                                form?.customScreeningQuestions &&
                                form?.customScreeningQuestions.length > 0
                                  ? form?.customScreeningQuestions.includes(
                                      itm.replaceAll("\\r", "")
                                    )
                                  : false
                              }
                            />
                            <img
                              src={removeField}
                              className="qs-remove-icon ms-1"
                              onClick={() => handleRemoveCustomQuestion(itm)}
                              alt="remove"
                            />
                          </div>
                        </div>
                      ))}
                    {screeningQsLoader && (
                      <div className="h-100 w-100 d-center flex-column">
                        <Bars
                          height="40"
                          width="40"
                          color="#133f70"
                          ariaLabel="bars-loading"
                          visible={true}
                        />
                        <div className="f-12 f-sem-bol text-theme-gray mt-2">
                          Waiting for questions...
                        </div>
                      </div>
                    )}
                    <Button
                      className="sq-generate-btn f-12 f-sem-bol cursor-pointer my-2"
                      text={"+ Generate Questions"}
                      onClick={() => {
                        setDirectUploadQuestion(false);
                        handleGenrateAiQs();
                      }}
                      border
                    />
                  </div>
                </div>
              </div>
              <div className="custom-qs-container">
                <div className="align-item-center d-flex justify-content-between">
                  <div className="w-90">
                    <Input
                      type="text"
                      labelClass="my-1 f-l f-sem-bol"
                      inputClass="f-l f-reg rounded input-shadow"
                      className={"qs-question-input"}
                      value={inputQuestion?.question}
                      onChange={handleOnChange}
                      borderNone
                      placeholder={"Please Enter Question"}
                    />
                  </div>
                  <div
                    className="f-20 f-sem-bol qs-add-div cursor-pointer"
                    onClick={handleAddCustomScreeningQuestion}
                  >
                    +
                  </div>
                </div>
                <div className="text-center my-2">
                  <span className="invalid undefined">
                    {inputQuestion?.error ? inputQuestion?.error : ""}
                  </span>
                </div>
              </div>
              <button
                type="button"
                className="btn-close position-absolute"
                aria-label="Close"
                onClick={() => setIsScreeningQuestion(false)}
              ></button>
            </>
          </div>
        )}
      </>
    );
  }
);

export default AddQuestions;
