import React from "react";
import leftArrow from "../../assets/images/arrow_left.svg";
import rightArrow from "../../assets/images/arrow_right.svg";
import { usePagination, DOTS } from "../../utils/usePagination";

const Pagination = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  // if (currentPage === 0 || paginationRange?.length < 2) {
  //   return null;
  // }

  const onNext = (e) => {
    onPageChange(currentPage + 1, e);
  };

  const onPrevious = (e) => {
    onPageChange(currentPage - 1, e);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  let lastCount = currentPage * pageSize;

  return (
    <>
      <div className="row mt-2 mx-0 w-100">
        <div className="col-12 col-md-4 d-flex align-items-center pagination-text">
          Showing {currentPage * pageSize - pageSize + 1} to{" "}
          {lastCount > totalCount ? totalCount : lastCount} of {totalCount}{" "}
          entries
        </div>
        <div className="col-12 col-md-8 mt-2 mt-md-0">
          <ul className={"pagination-container float-md-end"}>
            <li
              className={`pagination-item ${
                currentPage === 1 ? "disabled" : ""
              }`}
              onClick={(e) => onPrevious(e)}
            >
              <img
                src={leftArrow}
                alt="left-arrow"
                className="pagination-arrow"
              />
            </li>
            {paginationRange.map((pageNumber, index) => {
              if (pageNumber === DOTS) {
                return (
                  <li className="pagination-item dots" key={index}>
                    &#8230;
                  </li>
                );
              } else {
                return (
                  <li
                    className={`pagination-item ${
                      currentPage === pageNumber ? "selected" : ""
                    }`}
                    onClick={(e) => {
                      onPageChange(pageNumber, e);
                    }}
                    key={index}
                  >
                    {pageNumber}
                  </li>
                );
              }
            })}
            <li
              className={`pagination-item ${
                currentPage === lastPage ? "disabled" : ""
              }`}
              onClick={(e) => onNext(e)}
            >
              <img
                src={rightArrow}
                alt="right-arrow"
                className="pagination-arrow"
              />
            </li>
          </ul>
        </div>
      </div>
      <div className="pagination-divider mt-2"></div>
    </>
  );
};

export default Pagination;
