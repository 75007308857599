import React from "react";
import Select from "react-select";

const ExperienceSearchSelect = ({
  name,
  value,
  onChange,
  labelClass,
  inputClass,
  errorClass,
  error,
  label,
  isRequired,
  options,
  isMulti,
  defaultValue,
  isSearchable,
  provideNoOptionsPlaceholder,
  noOptionsPlaceholder,
  placeholder,
}) => {
  return (
    <>
      {label && (
        <label htmlFor={name} className={`c-label ${labelClass}`}>
          {label} {isRequired && <span className="asterisk f-12">&#42;</span>}
        </label>
      )}
      <div className={`position-relative`}>
        <Select
          className={`${inputClass} ${error ? "error" : ""}`}
          isMulti={isMulti}
          classNamePrefix="select"
          isSearchable={isSearchable}
          options={options}
          name={name}
          defaultValue={defaultValue}
          value={options && options?.find((itm) => itm?.value === value)}
          onChange={onChange}
          noOptionsMessage={() => noOptionsPlaceholder}
          placeholder={placeholder}
          maxMenuHeight={200}
        />
      </div>
      {error && <div className={`invalid ${errorClass}`}>{error}</div>}
    </>
  );
};

export default ExperienceSearchSelect;
