import React, { memo, useRef } from "react";
import file from "../../assets/images/file.svg";
import { capitalizeAll } from "../../utils/helper";

const FileInputComponent = memo(
  ({
    value,
    name,
    onChange,
    placeholder,
    labelClass,
    inputClass,
    errorClass,
    error,
    label,
    isRequired,
    disabled,
    fileType,
    validation,
  }) => {
    const inputRef = useRef(null);
    const handleChange = (e) => {
      const isValid = validation(e);
      if (!isValid) {
        if (inputRef.current) {
          inputRef.current.value = null;
        }
      } else onChange(e);
    };
    return (
      <>
        {label && (
          <label htmlFor={name} className={`c-label ${labelClass}`}>
            {label} {isRequired && <span className="asterisk f-12">&#42;</span>}
          </label>
        )}
        <div
          onClick={() => {
            if (inputRef.current) {
              inputRef.current.click();
            }
          }}
          className={`position-relative file-input bg-white d-flex align-items-center justify-content-center rounded`}
        >
          <input
            ref={inputRef}
            type="file"
            className={`real-file-input ${
              error ? "error" : ""
            } ${inputClass} position-relative`}
            name={name}
            placeholder={capitalizeAll(placeholder)}
            onChange={handleChange}
            disabled={disabled}
          />
          <div
            className={`real-file-input2 f-12 f-reg rounded w1${
              !value ? " real-file-text-gray" : ""
            }`}
          >
            {value?.name
              ? value?.name
              : value
              ? value
              : capitalizeAll(placeholder)}
          </div>
          <img src={file} alt="file" className="file" />
        </div>
        <span className="upload-type f-10 f-reg">{fileType}</span>
        {error && <div className={`invalid ${errorClass}`}>{error}</div>}
      </>
    );
  }
);

export default FileInputComponent;
