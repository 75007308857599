// import React from "react";
// import { useNavigate } from "react-router-dom";

// const Footer = () => {
//   const navigate = useNavigate();
//   return (
//     <div className="footer f-14">
//       <div className="f-sem-bol">
//         &#169; 2019 - 2023
//         <span className="footer-text m-2 f-bol" onClick={() => navigate("/")}>
//           Incruiter
//         </span>
//       </div>
//     </div>
//   );
// };

// export default Footer;

import React from "react";
const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="footer f-14">
      <div className="cpp-divider mb-2"></div>
      <div className="f-sem-bol">
        &#169; 2019 - {currentYear}
        <a
          href="https://incruiter.com"
          target="_blank"
          rel="noreferrer"
          className="footer-text m-2 f-bol footer-link"
        >
          InCruiter
        </a>
      </div>
    </div>
  );
};
export default Footer;
