import React from "react";
import { all, takeEvery, put, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import {
  Toast,
  localStorageEncrypt,
  localStorageRemove,
} from "../../utils/helper";
import {
  GET_PANELIST,
  NOTIFY_PANELIST,
  UPDATE_PANELIST,
  GET_INTERVIEW,
  GET_FEEDBACK_REPORT,
  CREATE_OR_UPDATE_POSITION,
  GET_POSITION,
  DELETE_POSITION,
  GET_POSITION_LIST,
  GET_PREDEFINED_POSITION_LIST,
  UPDATE_POSITION_LIST,
  DASHBOARD_STATS,
  GET_INTERVIEWER_LIST,
  GET_INTERVIEWER_ACCOUNT_DETAILS,
  ADD_OR_EDIT_INTERVIEWER_ACCOUNT_DETAILS,
  GET_INTERVIEWER_BANK_NAME_LIST,
  UPDATE_INTERVIEWER_USER_INFORMATION,
  GET_INTERVIEWER_USER_INFORMATION,
  UPDATE_INTERVIEWER_EDU_AND_SKILL_SET,
  GET_INTERVIEWER_ADMIN_REMARKS_INFO,
  GET_INCSERVE_POSITIONS,
  GET_INCSERVE_REQUIREMENTS,
  RESEND_EMAIL_VERIFICATION,
  CHANGE_INTERVIEWER_STATUS,
  ALLOW_INTERVIEWER,
  VERIFY_INTERVIEWER,
  CREATE_OR_UPDATE_INCSERVE_POSITIONS,
  DELETE_INCSERVE_POSITIONS,
  GET_POSITIONS_DROPDOWN,
  GET_INCSERVE_INTERVIEWS,
  GET_INCSERVE_INTERVIEWS_BY_ID,
  CREATE_OR_UPDATE_INCSERVE_INTERVIEWS,
  GET_INCSERVE_POSITIONS_BY_ID,
  GET_EMPLOYER_INTERVIEW_DETAILS,
  GET_EMPLOYER_POSITION_DETAILS,
  GET_INCEXIT_INTERVIEW,
  GET_ALL_INTERVIEWERS,
  ACCEPT_INCEXIT_INTERVIEW,
  PREVIOUSLY_ALLOTED_INTERVIEWER_LIST,
  DESIGNATION_LIST,
  UPDATE_INCEXIT_INTERVIEW,
  CREATE_INCEXIT_INTERVIEW,
  INCSERVE_APPROVE_FEEDBACK,
  INCSERVE_CANCEL_INTERVIEW,
  INCSERVE_UPDATE_PAY_PER_INTERVIEW,
  INCSERVE_ACCEPT_INTERVIEW,
  INCSERVE_RESCHEDULE_INTERVIEW,
  GET_INCSERVE_INTERVIEWS_SEARCH_LIST,
  CANCEL_INCEXIT_INTERVIEW,
  UPDATE_INTERVIEWER_ADMIN_REMARKS_INFO,
  GET_INTERVIEWER_RATINGS,
  UPDATE_INTERVIEWER_RATINGS,
  CALL_INTERVIEWER_AND_CANDIDATE,
  GET_SIMILAR_WEBSITE_LIST,
  CHANGE_PLATFORM,
  ALLOT_AND_REALLOT_INTERVIEWER,
  GET_INVOICE_DETAILS,
  ALLOT_INCEXIT_INTERVIEWER,
  SCHEDULE_INCEXIT_INTERVIEW,
  GET_INCEXIT_INTERVIEWS_SEARCH_LIST,
  BULK_INCEXIT_INTERVIEW,
  ACCEPT_BULK_INCEXIT_INTERVIEW,
  INCSERVE_RESCHEDULED_ACCEPT_REQUEST,
  INCSERVE_POST_REVIEW_REQUEST,
  INCSERVE_GIVE_FEEDBACK,
  GET_INCSERVE_SINGLE_DELETE_LIST,
  GET_INCSERVE_BULK_DELETE_LIST,
  BULK_INCSERVE_REQUIREMENT,
  ACCEPT_BULK_INCSERVE_REQUIREMENT,
  ADD_INCEXIT_FEEDBACK,
  CLOSE_BULK_INCEXIT_INTERVIEW,
  RESCHEDULE_INCEXIT_INTERVIEW,
  CLOSE_BULK_INCSERVE_REQUIREMENT,
  ADD_UPDATE_BULK_INCSERVE_REQUIREMENT,
  UPDATE_BULK_INCEXIT_INTERVIEW,
  MAPPED_INTERVIEWERS,
  UPDATE_INTERVIEW_FEEDBACK_RATING,
  RESPOND_RESCHEDULE_INCEXIT_INTERVIEW,
  INCSERVE_INTERVIEWER_DETAILS,
  INCSERVE_CALENDAR_INVITE,
  INCEXIT_CALENDAR_INVITE,
  INCSERVE_REQUEST_FOR_RESCHEDULED,
  ADD_CUSTOM_FILE,
  GET_INTERVIEWS_COUNT,
  GET_INVOICE_DETAILS_BY_MONTH,
  APPROVE_INCEXIT_FEEDBACK,
  GET_INTERVIEWER_AUDIO_LOGS,
  CREATE_EMPLOYER_POSITION,
  GET_CALENDAR_EVENT,
  INCSERVE_INCEXIT_PREVIOUSLY_ALLOTED_INTERVIEWER_LIST,
  GET_CALL_LOGS,
  UPDATE_ADD_QUESTION,
  UPDATE_INCSERVE_ADD_QUESTION,
  GET_INTERVIEWER_CALL_LOGS,
  VERIFY_LINKEDIN_URL,
  SEND_CONVERSION_REPORT,
  GET_INCBOT_INTERVIEWS_SEARCH_LIST,
  GET_INCBOT_POSITIONS,
  GET_INCBOT_INTERVIEWS,
  INTERVIEWER_ACCOUNT_DETAILS_LIST,
  GET_ACTIVE_INCBOT_EMPLOYER,
  INCSERVE_HOLD_INTERVIEW,
  UPDATE_CANDIDATE_RATINGS,
  PUT_INTERVIEWER_MOCK_REMARKS_INFO,
  GET_API_CALL_LOGS,
  UPDATE_API_CALL_LOGS,
  GET_MEETING_DETAILS,
  POST_MEETING_DETAILS,
  GET_EVENTS_LIST,
  FEEDBACK_APPROVAL,
  UPDATE_FEEDBACK,
  FETCH_RESUME_SUMMARY,
  FETCH_TRANSCRIPT,
  FETCH_AI_COMMENT,
  FETCH_FINAL_REMARKS,
  FETCH_AI_COMMENT_FOR_CODE,
  SET_NETWORK_DISCONNECTED,
} from "../action/types";
import {
  getPanelistFailure,
  getPanelistSuccess,
  notifyPanelistSuccess,
  notifyPanelistFailure,
  updatePanelistSuccess,
  updatePanelistFailure,
  getInterviewSuccess,
  getInterviewFailure,
  getFeedbackReportSuccess,
  getFeedbackReportFailure,
  createOrUpdatePositionSuccess,
  createOrUpdatePositionFailure,
  getPositionSuccess,
  getPositionFailure,
  deletePositionSuccess,
  deletePositionFailure,
  getPositionListSuccess,
  getPositionListFailure,
  getPredefinedPositionListSuccess,
  getPredefinedPositionListFailure,
  updatePositionListSuccess,
  updatePositionListFailure,
  getDashboardStatsFailure,
  getDashboardStatsSuccess,
  getInterviewerListSuccess,
  getInterviewerListFailure,
  addOrEditInterviewerAccountDetailSuccess,
  addOrEditInterviewerAccountDetailFailure,
  getInterviewerAccountDetailSuccess,
  getInterviewerAccountDetailFailure,
  getInterviewerBankNameListSuccess,
  getInterviewerBankNameListFailure,
  getInterviewerUserInformationSuccess,
  getInterviewerUserInformationFailure,
  updateInterviewerUserInformationSuccess,
  updateInterviewerUserInformationFailure,
  updateInterviewerEduAndSkillSetSuccess,
  updateInterviewerEduAndSkillSetFailure,
  getInterviewerAdminRemarksInfoSuccess,
  getInterviewerAdminRemarksInfoFailure,
  getIncservePositionsSuccess,
  getIncservePositionsFailure,
  getIncserveRequirementsSuccess,
  getIncserveRequirementsFailure,
  resendEmailVerificationSuccess,
  resendEmailVerificationFailure,
  changeInterviewerStatusSuccess,
  changeInterviewerStatusFailure,
  allowInterviewerSuccess,
  allowInterviewerFailure,
  verifyInterviewerSuccess,
  verifyInterviewerFailure,
  deleteIncservePositionSuccess,
  deleteIncservePositionFailure,
  getDropdownPositionListSuccess,
  getDropdownPositionListFailure,
  getIncserveInterviewsSuccess,
  getIncserveInterviewsFailure,
  getIncserveInterviewByIdSuccess,
  getIncserveInterviewByIdFailure,
  createOrUpdateIncserveInterviewSuccess,
  createOrUpdateIncserveInterviewFailure,
  getIncservePositionByIdSuccess,
  getEmployerInterviewDetailsSuccess,
  getEmployerInterviewDetailsFailure,
  getEmployerPositionDetailsSuccess,
  getEmployerPositionDetailsFailure,
  getIncExitInterviewsSuccess,
  getIncExitInterviewsFailure,
  getIncserveInterviewersDropdownSuccess,
  getIncserveInterviewersDropdownFailure,
  acceptIncExitInterviewsSuccess,
  acceptIncExitInterviewsFailure,
  previouslyAllotedInterviewerListSuccess,
  previouslyAllotedInterviewerListFailure,
  getDesignationListSuccess,
  getDesignationListFailure,
  updateIncexitInterviewSucees,
  updateIncexitInterviewFailure,
  createIncexitInterviewSucees,
  createIncexitInterviewFailure,
  incserveApproveFeedbackSuccess,
  incserveApproveFeedbackFailure,
  incserveCancelInterviewSuccess,
  incserveCancelInterviewFailure,
  incserveUpdatePayPerInterviewSuccess,
  incserveUpdatePayPerInterviewFailure,
  incserveAcceptInterviewSuccess,
  incserveAcceptInterviewFailure,
  incserveRescheduleInterviewSuccess,
  incserveRescheduleInterviewFailure,
  getIncserveInterviewsSearchListSuccess,
  getIncserveInterviewsSearchListFailure,
  cancelIncexitInterviewSuccess,
  cancelIncexitInterviewFailure,
  updateInterviewerAdminRemarksInfoSuccess,
  updateInterviewerAdminRemarksInfoFailure,
  getInterviewerRatingsSuccess,
  getInterviewerRatingsFailure,
  updateInterviewerRatingsSuccess,
  updateInterviewerRatingsFailure,
  callInterviewerAndCandidateSuccess,
  callInterviewerAndCandidateFailure,
  getSimilarWebsiteListSuccess,
  getSimilarWebsiteListFailure,
  changePlatformFailure,
  changePlatformSuccess,
  incserveAllotAndReallotInterviewerSuccess,
  incserveAllotAndReallotInterviewerFailure,
  getInvoiceDetailsSuccess,
  getInvoiceDetailsFailure,
  allotIncexitInterviewerSuccess,
  allotIncexitInterviewerFailure,
  scheduleInterviewSuccess,
  scheduleInterviewFailure,
  getIncexitInterviewsSearchListSuccess,
  getIncexitInterviewsSearchListFailure,
  bulkIncExitInterviewSuccess,
  bulkIncExitInterviewFailure,
  incserveRescheduleRequestSuccess,
  incserveRescheduleRequestFailure,
  incservePostReviewRequestSuccess,
  incservePostReviewRequestFailure,
  incserveGiveFeedbackSuccess,
  incserveGiveFeedbackFailure,
  getIncserveSingleDeleteRequirementSuccess,
  getIncserveSingleDeleteRequirementFailure,
  getIncserveBulkDeleteRequirementSuccess,
  getIncserveBulkDeleteRequirementFailure,
  acceptBulkIncExitInterviewSuccess,
  acceptBulkIncExitInterviewFailure,
  bulkIncserveRequirementSuccess,
  bulkIncserveRequirementFailure,
  acceptBulkIncserveRequirementsSuccess,
  acceptBulkIncserveRequirementsFailure,
  addIncexitFeddbackSuccess,
  addIncexitFeddbackFailure,
  closeBulkIncExitInterviewSuccess,
  closeBulkIncExitInterviewFailure,
  reScheduleInterviewSuccess,
  reScheduleInterviewFailure,
  closeBulkIncserveRequirementsSuccess,
  closeBulkIncserveRequirementsFailure,
  addUpdateBulkIncserveRequirementsSuccess,
  addUpdateBulkIncserveRequirementsFailure,
  mappedInterviewersSuccess,
  mappedInterviewersFailure,
  incserveInterviewFeedbackRatingRequestSuccess,
  incserveInterviewFeedbackRatingRequestFailure,
  respondReScheduleInterviewSuccess,
  respondReScheduleInterviewFailure,
  getIncserveInterviewersDetailsSuccess,
  getIncserveInterviewersDetailsFailure,
  incserveCalendarInviteSuccess,
  incserveCalendarInviteFailure,
  incexitCalendarInviteSuccess,
  incexitCalendarInviteFailure,
  incserveRequestForRescheduledSuccess,
  incserveRequestForRescheduledFailure,
  addCustomFileSuccess,
  addCustomFileFailure,
  getInterviewsCountSuccess,
  getInterviewsCountFailure,
  getInvoiceDetailsByMonthSuccess,
  getInvoiceDetailsByMonthFailure,
  approveIncExitFeedbackFailure,
  getInterviewerAudioLogsSuccess,
  getInterviewerAudioLogsFailure,
  updateBulkIncExitInterviewSuccess,
  updateBulkIncExitInterviewFailure,
  createEmployerPositionSuccess,
  createEmployerPositionFailure,
  getCalendarEventSuccess,
  getCalendarEventFailure,
  incserveIncexitPreviouslyAllotedInterviewerListSuccess,
  incserveIncexitPreviouslyAllotedInterviewerListFailure,
  getCallLogsSuccess,
  getCallLogsFailure,
  updateAddQuestionSuccess,
  updateAddQuestionFailure,
  updateIncserveAddQuestionSuccess,
  updateIncserveAddQuestionFailure,
  getInterviewerCallLogsSuccess,
  getInterviewerCallLogsFailure,
  verifyLinkedinUrlSuccess,
  verifyLinkedinUrlFailure,
  sendConversionReportSuccess,
  sendConversionReportFailure,
  getIncbotInterviewsSearchListSuccess,
  getIncbotInterviewsSearchListFailure,
  getIncbotPositionsFailure,
  getIncbotPositionsSuccess,
  getIncbotInterviewsFailure,
  getIncbotInterviewsSuccess,
  interviewAccountDetailsListSuccess,
  interviewAccountDetailsListFailure,
  getActiveIncbotEmployersSuccess,
  getActiveIncbotEmployersFailure,
  incserveHoldInterviewSuccess,
  incserveHoldInterviewFailure,
  updateCandidateRatingsSuccess,
  updateCandidateRatingsFailure,
  putInterviewerMockRemarksInfoFailure,
  putInterviewerMockRemarksInfoSuccess,
  getApiCallLogsSuccess,
  getApiCallLogsFailure,
  updateApiCallLogsSuccess,
  updateApiCallLogsFailure,
  getMeetingDetailsSuccess,
  getMeetingDetailsFailure,
  postMeetingDetailsSuccess,
  postMeetingDetailsFailure,
  getEventsListSuccess,
  getEventsListFailure,
  feedbackApprovalSuccess,
  feedbackApprovalFailure,
  updateFeedbackSuccess,
  updateFeedbackFailure,
  fetchResumeSummarySuccess,
  fetchResumeSummaryFailure,
  fetchTranscript,
  fetchAiCommentSuccess,
  fetchAiCommentFailure,
  fetchAiCommentForCodeSuccess,
  fetchAiCommentForCodeFailure,
  setNetworkDisconnectedSuccess,
  setNetworkDisconnectedFailure,
} from "../action";
import INTERVIEW from "../../utils/api/interview";
import CALENDAR from "../../utils/api/calendar";
import AI from "../../utils/api/ai";
function* feedbackApprovalRequest(action) {
  try {
    const res = yield INTERVIEW.patch(
      `admin/incbot/approveFeedback/${action?.payload?.interviewId}`
    );
    if (res.status === 201) {
      yield put(feedbackApprovalSuccess());
      // yield toast.success(<Toast msg={"Feedback approved successfully"} />);
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(feedbackApprovalFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* createEmployerPositionRequest(action) {
  try {
    let res;
    const formData = new FormData();
    formData.append("title", action.payload.data.title);
    formData.append("domain", action.payload.data.domain);
    formData.append("expRange", JSON.stringify(action.payload.data.expRange));
    formData.append("jobDescription", action.payload.data.jobDescription);
    formData.append("remarks", action.payload.data.remarks);
    formData.append("user", action.payload.data.user);
    formData.append("noOfPositions", action.payload.data.noOfPositions);
    formData.append("product", action.payload.data.product);
    formData.append("skills", JSON.stringify(action.payload.data.skills));
    formData.append(
      "customSkills",
      JSON.stringify(action.payload.data.customSkills)
    );
    formData.append(
      "screeningQuestions",
      JSON.stringify(action.payload.data.screeningQuestions)
    );
    formData.append(
      "customScreeningQuestions",
      JSON.stringify(action.payload.data.customScreeningQuestions)
    );
    formData.append(
      "customField",
      JSON.stringify(
        action.payload.data.customField ? action.payload.data.customField : []
      )
    );
    res = yield INTERVIEW.post("admin/positions", formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    if (res.status === 201 || res.status === 200) {
      yield put(createEmployerPositionSuccess());
      yield call(action.payload.callback);
      yield toast.success(<Toast msg="Position created successfully." />);
    }
  } catch (e) {
    yield put(createEmployerPositionFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

//INCSERVE INTERVIEWER LIST

function* verifyInterviewerRequest(action) {
  try {
    const res = yield INTERVIEW.patch(
      `admin/interviewers/verify/email/${action?.payload?.interviewerId}`
    );
    if (res.status === 201) {
      yield put(verifyInterviewerSuccess());
      yield toast.success(<Toast msg={"Interviewer verified successfully"} />);
      action.payload.navigate(-1);
    }
  } catch (e) {
    yield put(verifyInterviewerFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* interviewerAccountDetailsListRequest(action) {
  const month = action?.payload?.form?.month;
  const year = action?.payload?.form?.year;
  try {
    const res = yield INTERVIEW.get(
      `admin/interviewers/invoice/incserve?month=${month}&year=${year}`
    );

    if (res.status === 200 || res.status === 201) {
      yield put(interviewAccountDetailsListSuccess(res.data.result));
      yield call(action?.payload?.callback(res));
      //  yield toast.success(<Toast msg={"Interviewer verified successfully"} />);
    }
  } catch (e) {
    yield put(interviewAccountDetailsListFailure());
  }
}
function* allowInterviewerRequest(action) {
  try {
    const res = yield INTERVIEW.patch(
      `admin/interviewers/status/allow/${action?.payload?.interviewerId}`
    );
    if (res.status === 201) {
      yield put(allowInterviewerSuccess());
      yield toast.success(<Toast msg={"Interviewer allowed successfully"} />);
      action.payload.navigate(-1);
    }
  } catch (e) {
    yield put(allowInterviewerFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* changePlatformRequest(action) {
  try {
    const res = yield INTERVIEW.put(
      `/admin/incserve/interviews/changePlatform`,
      action?.payload
    );
    if (res.status === 200) {
      yield put(changePlatformSuccess());
      yield call(action.payload.callback);
      yield toast.success(<Toast msg={"Platform Changed successfully"} />);
    }
  } catch (e) {
    yield put(changePlatformFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* callInterviewerAndCandidateRequest(action) {
  try {
    const res = yield INTERVIEW.post(
      `/admin/incserve/interviews/call`,
      action?.payload?.data
    );
    if (res.status === 201) {
      yield put(callInterviewerAndCandidateSuccess());
      yield toast.success(<Toast msg={"Call initiated successfully"} />);
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(callInterviewerAndCandidateFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* changeInterviewerStatusRequest(action) {
  try {
    const res = yield INTERVIEW.patch(
      `admin/interviewers/status/${action?.payload?.data?.id}/${action?.payload?.data?.status}`,
      { reason: action?.payload?.data?.reason }
    );
    if (res.status === 201) {
      yield put(changeInterviewerStatusSuccess(res.result));
      yield toast.success(
        <Toast msg={`Interviewer ${res?.data?.result?.status} successfully`} />
      );
      yield call(action?.payload?.callback);
      // action?.payload?.navigate(-1);
    }
  } catch (e) {
    yield put(changeInterviewerStatusFailure());
    // yield toast.error(
    //   <Toast msg={"Oops. Some error occurred while sending email"} />
    // );
  }
}

function* updateCandidateRatingsRequest(action) {
  try {
    const res = yield INTERVIEW.put(
      `admin/incserve/interviews/candidateRating/${action?.payload?.data?.interviewId}`,
      action?.payload?.data
    );
    if (res.status === 200) {
      yield put(updateCandidateRatingsSuccess());
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(updateCandidateRatingsFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* resendEmailVerificationRequest(action) {
  try {
    const res = yield INTERVIEW.patch(
      `admin/interviewers/resend/email/${action?.payload?.id}`
    );
    if (res.status === 201) {
      yield put(resendEmailVerificationSuccess());
      yield toast.success(<Toast msg={"Email sent successfully"} />);
    }
  } catch (e) {
    yield put(resendEmailVerificationFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
    // yield toast.error(
    //   <Toast msg={"Oops. Some error occurred while sending email"} />
    // );
  }
}

// INCSERVE API CALLS

// INCSERVE REQUIREMENTS

function* getIncserveRequirementsRequest(action) {
  // let isFilter;
  const isSearch = action?.payload?.search
    ? `&search=${encodeURIComponent(action?.payload?.search)}`
    : "";
  const isSort = action?.payload?.filters
    ? action?.payload?.filters?.sort
      ? `&sort=${action?.payload?.filters?.sort}`
      : action?.payload?.sort
      ? `&sort=${action?.payload?.sort}`
      : ""
    : action?.payload?.sort
    ? `&sort=${action?.payload?.sort}`
    : "";
  const isPageAndPageSize =
    action?.payload?.page && action?.payload?.pageSize
      ? `?page=${action?.payload?.page}&pageSize=${action?.payload?.pageSize}`
      : "";
  const status =
    action?.payload?.status && action?.payload?.status
      ? `&status=${action?.payload?.status}`
      : "";
  const searchKeyword = action?.payload?.searchKeyword
    ? `&searchKeyword=${action?.payload?.searchKeyword}`
    : "";

  // if (action?.payload?.filters) {
  const isFilter = action?.payload?.filters
    ? action?.payload?.filters && action?.payload?.filters !== ""
      ? (action?.payload?.filters?.from
          ? `&from=${action?.payload?.filters?.from}T00:00:00`
          : "") +
        (action?.payload?.filters?.to
          ? `&to=${action?.payload?.filters?.to}T23:59:59`
          : "") +
        (action?.payload?.filters?.acceptedFrom
          ? `&acceptedFrom=${action?.payload?.filters?.acceptedFrom}T23:59:59`
          : "") +
        (action?.payload?.filters?.acceptedTo
          ? `&acceptedTo=${action?.payload?.filters?.acceptedTo}T23:59:59`
          : "") +
        (action?.payload?.filters?.allotedFrom
          ? `&allotedFrom=${action?.payload?.filters?.allotedFrom}T23:59:59`
          : "") +
        (action?.payload?.filters?.allotedTo
          ? `&allotedTo=${action?.payload?.filters?.allotedTo}T23:59:59`
          : "") +
        (action?.payload?.filters?.canceledFrom
          ? `&canceledFrom=${action?.payload?.filters?.canceledFrom}T23:59:59`
          : "") +
        (action?.payload?.filters?.canceledTo
          ? `&canceledTo=${action?.payload?.filters?.canceledTo}T23:59:59`
          : "") +
        (action?.payload?.filters?.rescheduledFrom
          ? `&rescheduledFrom=${action?.payload?.filters?.rescheduledFrom}T23:59:59`
          : "") +
        (action?.payload?.filters?.rescheduledTo
          ? `&rescheduledTo=${action?.payload?.filters?.rescheduledTo}T23:59:59`
          : "") +
        (action?.payload?.filters?.scheduledFrom
          ? `&scheduledFrom=${action?.payload?.filters?.scheduledFrom}T23:59:59`
          : "") +
        (action?.payload?.filters?.scheduledTo
          ? `&scheduledTo=${action?.payload?.filters?.scheduledTo}T23:59:59`
          : "") +
        (action?.payload?.filters?.submittedFrom
          ? `&submittedFrom=${action?.payload?.filters?.submittedFrom}T23:59:59`
          : "") +
        (action?.payload?.filters?.submittedTo
          ? `&submittedTo=${action?.payload?.filters?.submittedTo}T23:59:59`
          : "") +
        (action?.payload?.filters?.updatedFrom
          ? `&updatedFrom=${action?.payload?.filters?.updatedFrom}T23:59:59`
          : "") +
        (action?.payload?.filters?.updatedTo
          ? `&updatedTo=${action?.payload?.filters?.updatedTo}T23:59:59`
          : "") +
        (action?.payload?.filters?.createdBy
          ? `&createdBy=${action?.payload?.filters?.createdBy}`
          : "") +
        (action?.payload?.filters?.experience
          ? `&experience=${action?.payload?.filters?.experience}`
          : "") +
        (action?.payload?.filters?.approvedBy
          ? `&approvedBy=${action?.payload?.filters?.approvedBy}`
          : "") +
        (action?.payload?.filters?.user
          ? `&user=${action?.payload?.filters?.user}`
          : "") +
        (action?.payload?.filters?.creditType
          ? `&creditType=${action?.payload?.filters?.creditType}`
          : "") +
        (action?.payload?.filters?.mode
          ? `&mode=${action?.payload?.filters?.mode}`
          : "")
      : ""
    : "";

  try {
    const res = yield INTERVIEW.get(
      `admin/incserve/interviews/list${isPageAndPageSize}${status}${isSort}${isSearch}${isFilter}${searchKeyword}`
    );
    if (res.status === 200) {
      if (action?.payload?.page === "all") {
        yield put(getIncserveRequirementsFailure());
        yield call(action.payload.callback, res.data.result);
      } else {
        yield put(getIncserveRequirementsSuccess(res.data.result));
      }
    }
  } catch (e) {
    yield put(getIncserveRequirementsFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* getIncserveSingleDeleteRequirementsRequest(action) {
  let isFilter;
  const isSearch = action.payload.search
    ? `&search=${encodeURIComponent(action.payload.search)}`
    : "";
  const isStatus = action.payload.status
    ? `&status=${encodeURIComponent(action.payload.status)}`
    : "";
  const isSort = action.payload.sort ? `&sort=${action.payload.sort}` : "";
  const isPageAndPageSize =
    action.payload.page && action.payload.pageSize
      ? `?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
      : "";
  if (action.payload.filters) {
    isFilter = action.payload.filters
      ? (action.payload.filters.from
          ? `&from=${action.payload.filters.from}T00:00:00`
          : "") +
        (action.payload.filters.to
          ? `&to=${action.payload.filters.to}T23:59:59`
          : "") +
        (action.payload.filters.createdBy
          ? `&createdBy=${action.payload.filters.createdBy}`
          : "") +
        (action.payload.filters.experience
          ? `&experience=${action.payload.filters.experience}`
          : "")
      : "";
  }
  try {
    const res = yield INTERVIEW.get(
      `admin/incserve/interviews/list${isPageAndPageSize}${isSort}${isSearch}${isFilter}${isStatus}`
    );
    if (res.status === 200) {
      if (action?.payload?.page === "all") {
        yield put(getIncserveSingleDeleteRequirementFailure());
        yield call(action.payload.callback, res.data.result);
      } else {
        yield put(getIncserveSingleDeleteRequirementSuccess(res.data.result));
      }
    }
  } catch (e) {
    yield put(getIncserveSingleDeleteRequirementFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* getIncserveBulkDeleteRequirementsRequest(action) {
  let isFilter;
  const isSearch = action.payload.search
    ? `&search=${encodeURIComponent(action.payload.search)}`
    : "";
  const isSort = action.payload.sort ? `&sort=${action.payload.sort}` : "";
  const isPageAndPageSize =
    action.payload.page && action.payload.pageSize
      ? `?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
      : "";
  const isStatus = action.payload.status
    ? `&status=${encodeURIComponent(action.payload.status)}`
    : "";
  if (action.payload.filters) {
    isFilter = action.payload.filters
      ? (action.payload.filters.from
          ? `&from=${action.payload.filters.from}T00:00:00`
          : "") +
        (action.payload.filters.to
          ? `&to=${action.payload.filters.to}T23:59:59`
          : "") +
        (action.payload.filters.createdBy
          ? `&createdBy=${action.payload.filters.createdBy}`
          : "") +
        (action.payload.filters.experience
          ? `&experience=${action.payload.filters.experience}`
          : "")
      : "";
  }
  try {
    const res = yield INTERVIEW.get(
      `admin/incserve/interviews/Bulklist${isPageAndPageSize}${isSort}${isFilter}${isSearch}${isStatus}`
    );
    if (res.status === 200) {
      if (action?.payload?.page === "all") {
        yield put(getIncserveBulkDeleteRequirementFailure());
        yield call(action.payload.callback, res.data.result);
      } else {
        yield put(getIncserveBulkDeleteRequirementSuccess(res.data.result));
      }
    }
  } catch (e) {
    yield put(getIncserveBulkDeleteRequirementFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

//INCSERVE INTERVIEWS

function* getAllInterviewersRequest(action) {
  try {
    const res = yield INTERVIEW.get(`admin/interviewers/dropdown`);
    if (res.status === 200) {
      yield put(getIncserveInterviewersDropdownSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getIncserveInterviewersDropdownFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* getApiCallLogsRequest(action) {
  try {
    const res = yield INTERVIEW.get(`admin/interviews/api-trigger`);
    if (res.status === 200) {
      yield put(getApiCallLogsSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getApiCallLogsFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* updateApiCallLimitRequest(action) {
  try {
    const res = yield INTERVIEW.patch(
      `/admin/interviews/api-trigger/${action.payload.id}`,
      action.payload.data
    );
    if (res.status === 200 || res.status === 201) {
      yield put(updateApiCallLogsSuccess(res.data.result));
      yield toast.success(<Toast msg={"Limit updated successfully"} />);
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(updateApiCallLogsFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* updateIncserveInterviewFeedbackRatingRequest(action) {
  try {
    const res = yield INTERVIEW.put(
      `/admin/incserve/interviews/ratings/admin/${action.payload.interviewId}`,
      action.payload.data
    );
    if (res.status === 200) {
      yield put(incserveInterviewFeedbackRatingRequestSuccess(res.data.result));
      yield toast.success(
        <Toast msg={"Interview feedback added successfully"} />
      );
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(incserveInterviewFeedbackRatingRequestFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* approveIncserveFeedbackRequest(action) {
  try {
    const res = yield INTERVIEW.patch(
      `/admin/incserve/interviews/approveFeedback`,
      action.payload.data
    );
    if (res.status === 201 || res.status === 200) {
      yield put(incserveApproveFeedbackSuccess());
      yield toast.success(<Toast msg={"Feedback approved successfully"} />);
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(incserveApproveFeedbackFailure);
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* postIncserveReviewRequestRequest(action) {
  try {
    const res = yield INTERVIEW.put(
      `/admin/incserve/interviews/reviewFeedback`,
      action.payload.data
    );
    if (res.status === 201 || res.status === 200) {
      yield put(incservePostReviewRequestSuccess());
      yield toast.success(<Toast msg={"Review request sent successfully"} />);
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(incservePostReviewRequestFailure);
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* giveIncserveFeedbackRequest(action) {
  try {
    const formData = new FormData();
    formData.append("interviewId", action.payload.data.interviewId);
    formData.append("status", action.payload.data.status);
    formData.append("CARFile", action.payload.data.CARFile);
    const res = yield INTERVIEW.put(
      "/admin/incserve/interviews/provideFeedback",
      formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
    if (res.status === 201 || res.status === 200) {
      yield put(incserveGiveFeedbackSuccess());
      yield toast.success(<Toast msg={"Feedback sent successfully"} />);
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(incserveGiveFeedbackFailure);
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* cancelIncserveInterviewRequest(action) {
  try {
    const res = yield INTERVIEW.patch(
      `admin/incserve/interviews/cancel`,
      action.payload.data
    );
    if (res.status === 201) {
      yield put(incserveCancelInterviewSuccess());
      yield toast.success(<Toast msg={"Interview cancelled successfully"} />);
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(incserveCancelInterviewFailure);
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* holdIncserveInterviewRequest(action) {
  try {
    const res = yield INTERVIEW.patch(
      `/admin/incserve/interviews/onHoldSwitch`,
      action.payload.data
    );
    if (res.status === 201) {
      yield put(incserveHoldInterviewSuccess());
      // yield toast.success(<Toast msg={"Interview hold successfully"} />);
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(incserveHoldInterviewFailure);
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* approveIncExitFeedbackRequest(action) {
  try {
    const res = yield INTERVIEW.patch(
      `/admin/incExit/interviews/approveFeedback`,
      action.payload.data
    );
    if (res.status === 201) {
      yield put(approveIncExitFeedbackRequest());
      yield toast.success(<Toast msg={"Feedback Approved successfully"} />);
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(approveIncExitFeedbackFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* acceptIncserveInterviewRequest(action) {
  try {
    const res = yield INTERVIEW.patch(
      `/admin/incserve/interviews/changeStatus`,
      action.payload
    );
    if (res.status === 201) {
      yield put(incserveAcceptInterviewSuccess());
      yield toast.success(<Toast msg={"Interview accepted successfully"} />);
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(incserveAcceptInterviewFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* updateAddQuestionRequest(action) {
  const updatedPayload = {
    ...action.payload.data,
    screeningQuestions: action.payload.screeningQuestionIds,
    positionEvaQues: action.payload.positionEvaQuesIds,
    departmentalEvaQues: action.payload.departmentalEvaQuesIds,
    organizationEvaQues: action.payload.organizationEvaQuesIds,
  };
  try {
    const res = yield INTERVIEW.put(
      `/admin/incExit/interviews/addQuestions`,
      // action.payload.data
      updatedPayload
    );
    if (res.status === 201 || res.status === 200) {
      yield put(updateAddQuestionSuccess());
      yield toast.success(<Toast msg={"Question Updated Successfully"} />);
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(updateAddQuestionFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* verifyLinkedinUrl(action) {
  try {
    const res = yield INTERVIEW.patch(
      `/admin/interviewers/approve/linkedin/${action?.payload?.interviewerID}`
    );
    if (res.status === 201 || res.status === 200) {
      yield put(verifyLinkedinUrlSuccess());
      yield toast.success(<Toast msg={"URL verified Successfully"} />);
    }
  } catch (e) {
    yield put(verifyLinkedinUrlFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* sendConversionReport(action) {
  try {
    const res = yield INTERVIEW.post(
      `/incserve/interviews/conversionReport`,
      action?.payload?.data,
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );
    if (res.status === 201 || res.status === 200) {
      yield put(sendConversionReportSuccess());
      yield toast.success(
        <Toast msg={"Conversion report sent Successfully"} />
      );
    }
  } catch (e) {
    yield put(sendConversionReportFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* updateIncserveAddQuestionRequest(action) {
  const updatedPayload = {
    ...action.payload.data,
    screeningQuestions: action.payload.screeningQuestionIds,
  };
  try {
    const res = yield INTERVIEW.put(
      `admin/incserve/interviews/addQuestions`,
      // action.payload.data
      updatedPayload
    );
    if (res.status === 201 || res.status === 200) {
      yield put(updateIncserveAddQuestionSuccess());
      yield toast.success(<Toast msg={"Question Updated Successfully"} />);
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(updateIncserveAddQuestionFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* acceptRescheduleRequestRequest(action) {
  try {
    const res = yield INTERVIEW.patch(
      `/admin/incserve/interviews/rescheduleRequest/${action.payload.interviewId}/${action.payload.status}`,
      action.payload
    );
    if (res.status === 201) {
      yield put(incserveRescheduleRequestSuccess());
      yield toast.success(<Toast msg={"Interview Accepted successfully"} />);
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(incserveRescheduleRequestFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* requestForRescheduledRequest(action) {
  try {
    const res = yield INTERVIEW.put(
      `/admin/incserve/interviews/rescheduleInterview`,
      action.payload.data
    );
    if (res.status === 200 || res.status === 201) {
      yield put(incserveRequestForRescheduledSuccess());
      yield toast.success(<Toast msg={"Interview rescheduled successfully"} />);
      yield call(action?.payload?.callback, res);
    }
  } catch (e) {
    yield put(incserveRequestForRescheduledFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* rescheduleIncserveInterviewRequest(action) {
  const formData = new FormData();

  if (action?.payload?.data?.CARFile !== "") {
    formData.append("CARFile", action?.payload?.data?.CARFile);
  }
  formData.append("interviewDate", action?.payload?.data?.interviewDate);
  formData.append("interviewId", action?.payload?.data?.interviewId);
  formData.append("interviewTime", action?.payload?.data?.interviewTime);
  formData.append("mode", action?.payload?.data?.mode);
  // formData.append("platform", action?.payload?.data?.platform);
  formData.append("remarks", action?.payload?.data?.remarks);
  if (action?.payload?.data?.platform === "") {
    delete action?.payload?.data?.platform;
  } else {
    formData.append(
      "platform",
      action?.payload?.data?.platform === "auto"
        ? "100ms"
        : action?.payload?.data?.platform
    );
  }
  try {
    const res = yield INTERVIEW.post(
      `/admin/incserve/interviews/scheduleInterview`,
      formData
    );
    if (res.status === 201) {
      yield toast.success(<Toast msg={"Interview scheduled successfully"} />);
      yield put(incserveRescheduleInterviewSuccess());
      yield call(action.payload.callback, res);
    }
  } catch (e) {
    yield put(incserveRescheduleInterviewFailure());
    if (e.response.status === 500) {
      yield toast.error(<Toast msg={e?.response?.data?.message} />);
    }
    // yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* updatePayPerIncserveInterviewRequest(action) {
  delete action?.payload?.data?.maxPayPerHire;
  try {
    const res = yield INTERVIEW.patch(
      `/admin/incserve/interviews/updatePayPerInterview`,
      action.payload.data
    );
    if (res.status === 201) {
      yield put(incserveUpdatePayPerInterviewSuccess());
      yield toast.success(
        <Toast msg={"Pay per interview edited successfully"} />
      );
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(incserveUpdatePayPerInterviewFailure());
    // yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* getIncserveInterviewsRequest(action) {
  const isPageAndPageSize =
    action.payload.page && action.payload.pageSize
      ? `?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
      : "";

  const isSort = action.payload.filters
    ? action.payload.filters.sort
      ? `&sort=${action.payload.filters.sort}`
      : action.payload.sort
      ? `&sort=${action.payload.sort}`
      : ""
    : action.payload.sort
    ? `&sort=${action.payload.sort}`
    : "";
  const status = action.payload.status
    ? `&status=${encodeURIComponent(action.payload.status)}`
    : "";
  try {
    const res = yield INTERVIEW.get(
      `admin/incServe/interviews/list${isPageAndPageSize}${status}${isSort}`
    );
    if (res.status === 200) {
      yield put(getIncserveInterviewsSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getIncserveInterviewsFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

// ACCEPT BULK REQUIREMENTS

function* acceptBulkIncserveRequirementsRequest(action) {
  const bulkId = encodeURIComponent(action.payload.bulkId);
  const userId = action.payload.userId;
  try {
    const res = yield INTERVIEW.post(
      `/admin/incserve/interviews/accept/BulkInterviews?bulkId=${bulkId}&user=${userId}`,
      action.payload.data
    );
    if (res.status === 201) {
      yield put(acceptBulkIncserveRequirementsSuccess(res.data?.result));
      yield toast.success(<Toast msg="Interview Accepted Successfully" />);
    }
  } catch (e) {
    yield put(acceptBulkIncserveRequirementsFailure());
  }
}

// ADD AND UPDATE BULK REQUIREMENTS

function* createOrUpdateIncserveBulkRequirementsRequest(action) {
  try {
    let res;
    const formData = new FormData();
    formData.append("position", action?.payload?.data?.title);
    formData.append("domain", action?.payload?.data?.domain);
    formData.append("user", action?.payload?.data?.user);
    formData.append("timeSlots", action?.payload?.data?.timeSlots);
    formData.append(
      "customSkills",
      JSON.stringify(action?.payload?.data?.customSkills)
    );
    formData.append("skills", JSON.stringify(action?.payload?.data?.skills));
    formData.append("confirm", JSON.stringify(action?.payload?.data?.confirm));
    formData.append(
      "screeningQuestions",
      JSON.stringify(action?.payload?.data?.screeningQuestions)
    );
    let resType = typeof action?.payload?.data?.resume;
    let descriptionType = typeof action?.payload?.data?.jobDescription;
    let candidateTracker = typeof action?.payload?.data?.candidateTracker;
    if (resType !== "string") {
      formData.append("resumeZip ", action.payload.data.resume);
    }
    if (descriptionType !== "string") {
      formData.append("jobDescription", action.payload.data.jobDescription);
    }
    if (candidateTracker !== "string") {
      formData.append("candidateTracker", action.payload.data.candidateTracker);
    }
    formData.append(
      "customScreeningQuestions",
      JSON.stringify(action?.payload?.data?.customScreeningQuestions)
    );
    formData.append(
      "customField",
      JSON.stringify(
        action.payload.data.customField ? action.payload.data.customField : []
      )
    );
    let message;
    if (action?.payload?.id) {
      res = yield INTERVIEW.put(
        `admin/incserve/interviews/bulkUpload/${action?.payload?.id}`,
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      );
      message = "Bulk requirement updated successfully.";
    } else {
      res = yield INTERVIEW.post(
        `/incserve/interviews/bulkRequirements`,
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      );
      message = "Bulk requirement added successfully.";
    }
    if (res.status === 201 || res.status === 200) {
      yield put(addUpdateBulkIncserveRequirementsSuccess());
      yield toast.success(<Toast msg={`${message}`} />);
      yield call(action.payload.callback);
    }
  } catch (e) {
    if (e?.response?.status === 400) {
      yield put(
        addUpdateBulkIncserveRequirementsFailure(e?.response?.data?.message)
      );
    } else {
      yield put(addUpdateBulkIncserveRequirementsFailure());
      yield e?.response?.data?.message &&
        toast.error(<Toast msg={e?.response?.data?.message} />);
    }
  }
}

// CLOSE BULK REQUIREMENTS

function* closeBulkIncserveRequirementsRequest(action) {
  const bulkId = encodeURIComponent(action.payload.bulkId);
  const userId = action.payload.userId;
  try {
    const res = yield INTERVIEW.post(
      `/admin/incserve/interviews/close/BulkInterviews?bulkId=${bulkId}&user=${userId}`,
      action.payload.data
    );
    if (res.status === 201) {
      yield put(closeBulkIncserveRequirementsSuccess(res.data?.result));
      yield toast.success(<Toast msg="Interview Closed Successfully" />);
    }
  } catch (e) {
    yield put(closeBulkIncserveRequirementsFailure());
  }
}

function* getIncserveInterviewsSearchListRequest(action) {
  const isPageAndPageSize =
    action.payload.page && action.payload.pageSize
      ? `?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
      : "";
  const isFilter = action?.payload?.form
    ? (action?.payload?.form?.to ? `&to=${action.payload.form.to}` : "") +
      (action?.payload?.form?.from
        ? `&from=${action?.payload?.form?.from}`
        : "") +
      (action?.payload?.form?.mode ? `&mode=${action.payload.form.mode}` : "") +
      (action?.payload?.form?.feedback
        ? `&status=${action.payload.form.feedback}`
        : "") +
      (action.payload.form.interviewer
        ? `&interviewer=${action.payload.form.interviewer}`
        : "") +
      (action.payload.form.employer
        ? `&user=${action.payload.form.employer}`
        : "") +
      (action.payload.form.code
        ? `&code=${encodeURIComponent(action.payload.form.code)}`
        : "") +
      (action.payload.form.candidateNumber
        ? `&candidateNumber=${action.payload.form.candidateNumber}`
        : "") +
      (action.payload.form.candidateName
        ? `&candidateName=${action.payload.form.candidateName}`
        : "")
    : "";
  const isSort = action.payload.filters
    ? action.payload.filters.sort
      ? `&sort=${action.payload.filters.sort}`
      : action.payload.sort
      ? `&sort=${action.payload.sort}`
      : ""
    : action.payload.sort
    ? `&sort=${action.payload.sort}`
    : "";
  try {
    const res = yield INTERVIEW.get(
      `admin/incServe/interviews/list/search${isPageAndPageSize}${isFilter}${isSort}`
    );
    if (res.status === 200) {
      yield put(getIncserveInterviewsSearchListSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getIncserveInterviewsSearchListFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* getIncbotInterviewsSearchListRequest(action) {
  const isPageAndPageSize =
    action.payload.page && action.payload.pageSize
      ? `?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
      : "";
  const isFilter = action?.payload?.form
    ? (action?.payload?.form?.to ? `&to=${action.payload.form.to}` : "") +
      (action?.payload?.form?.from
        ? `&from=${action?.payload?.form?.from}`
        : "") +
      (action?.payload?.form?.feedback
        ? `&status=${action.payload.form.feedback}`
        : "") +
      (action.payload.form.employer
        ? `&user=${action.payload.form.employer}`
        : "") +
      (action.payload.form.code
        ? `&code=${encodeURIComponent(action.payload.form.code)}`
        : "") +
      (action.payload.form.candidateNumber
        ? `&candidateNumber=${action.payload.form.candidateNumber}`
        : "") +
      (action.payload.form.candidateName
        ? `&candidateName=${action.payload.form.candidateName}`
        : "")
    : "";
  const isSort = action.payload.filters
    ? action.payload.filters.sort
      ? `&sort=${action.payload.filters.sort}`
      : action.payload.sort
      ? `&sort=${action.payload.sort}`
      : ""
    : action.payload.sort
    ? `&sort=${action.payload.sort}`
    : "";
  try {
    const res = yield INTERVIEW.get(
      `admin/incbot/list/search${isPageAndPageSize}${isFilter}${isSort}`
    );
    if (res.status === 200) {
      yield put(getIncbotInterviewsSearchListSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getIncbotInterviewsSearchListFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* getIncserveBulkInterviewListRequest(action) {
  const isSearch = action.payload.search
    ? `&search=${encodeURIComponent(action.payload.search)}`
    : "";
  const isSort = action.payload.sort ? `&sort=${action.payload.sort}` : "";
  const isPageAndPageSize =
    action.payload.page && action.payload.pageSize
      ? `?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
      : "";
  const isFilter = action?.payload?.filters
    ? action?.payload?.filters && action?.payload?.filters !== ""
      ? (action?.payload?.filters?.from
          ? `&from=${action?.payload?.filters?.from}T00:00:00`
          : "") +
        (action?.payload?.filters?.to
          ? `&to=${action?.payload?.filters?.to}T23:59:59`
          : "") +
        (action?.payload?.filters?.acceptedFrom
          ? `&acceptedFrom=${action?.payload?.filters?.acceptedFrom}T23:59:59`
          : "") +
        (action?.payload?.filters?.acceptedTo
          ? `&acceptedTo=${action?.payload?.filters?.acceptedTo}T23:59:59`
          : "") +
        (action?.payload?.filters?.allotedFrom
          ? `&allotedFrom=${action?.payload?.filters?.allotedFrom}T23:59:59`
          : "") +
        (action?.payload?.filters?.allotedTo
          ? `&allotedTo=${action?.payload?.filters?.allotedTo}T23:59:59`
          : "") +
        (action?.payload?.filters?.canceledFrom
          ? `&canceledFrom=${action?.payload?.filters?.canceledFrom}T23:59:59`
          : "") +
        (action?.payload?.filters?.canceledTo
          ? `&canceledTo=${action?.payload?.filters?.canceledTo}T23:59:59`
          : "") +
        (action?.payload?.filters?.rescheduledFrom
          ? `&rescheduledFrom=${action?.payload?.filters?.rescheduledFrom}T23:59:59`
          : "") +
        (action?.payload?.filters?.rescheduledTo
          ? `&rescheduledTo=${action?.payload?.filters?.rescheduledTo}T23:59:59`
          : "") +
        (action?.payload?.filters?.scheduledFrom
          ? `&scheduledFrom=${action?.payload?.filters?.scheduledFrom}T23:59:59`
          : "") +
        (action?.payload?.filters?.scheduledTo
          ? `&scheduledTo=${action?.payload?.filters?.scheduledTo}T23:59:59`
          : "") +
        (action?.payload?.filters?.submittedFrom
          ? `&submittedFrom=${action?.payload?.filters?.submittedFrom}T23:59:59`
          : "") +
        (action?.payload?.filters?.submittedTo
          ? `&submittedTo=${action?.payload?.filters?.submittedTo}T23:59:59`
          : "") +
        (action?.payload?.filters?.updatedFrom
          ? `&updatedFrom=${action?.payload?.filters?.updatedFrom}T23:59:59`
          : "") +
        (action?.payload?.filters?.updatedTo
          ? `&updatedTo=${action?.payload?.filters?.updatedTo}T23:59:59`
          : "") +
        (action?.payload?.filters?.createdBy
          ? `&createdBy=${action?.payload?.filters?.createdBy}`
          : "") +
        (action?.payload?.filters?.experience
          ? `&experience=${action?.payload?.filters?.experience}`
          : "") +
        (action?.payload?.filters?.approvedBy
          ? `&approvedBy=${action?.payload?.filters?.approvedBy}`
          : "") +
        (action?.payload?.filters?.user
          ? `&user=${action?.payload?.filters?.user}`
          : "") +
        (action?.payload?.filters?.creditType
          ? `&creditType=${action?.payload?.filters?.creditType}`
          : "") +
        (action?.payload?.filters?.mode
          ? `&mode=${action?.payload?.filters?.mode}`
          : "")
      : ""
    : "";
  try {
    const res = yield INTERVIEW.get(
      `/admin/incserve/interviews/Bulklist${isPageAndPageSize}${isFilter}${isSort}${isSearch}`
    );
    if (res.status === 200 || res.status === 201) {
      if (action?.payload?.page === "all") {
        yield call(action.payload.callback, res.data.result);
        yield put(bulkIncserveRequirementFailure());
      } else {
        yield put(bulkIncserveRequirementSuccess(res.data.result));
      }
    }
  } catch (e) {
    yield put(bulkIncserveRequirementFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* getIncexitInterviewsSearchListRequest(action) {
  const isPageAndPageSize =
    action.payload.page && action.payload.pageSize
      ? `?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
      : "";
  const isFilter = action.payload.form
    ? action.payload.form.from
      ? action.payload.form.to
        ? `&from=${action.payload.form.from}&to=${action.payload.form.to}`
        : `&from=${action.payload.form.from}&to=3000-12-31T23:59:59`
      : action.payload.form.to
      ? `&from=2000-01-01T00:00:00&to=${action.payload.form.to}`
      : "" +
        (action.payload.form.mode ? `&mode=${action.payload.form.mode}` : "") +
        (action.payload.form.status
          ? `&status=${action.payload.form.status}`
          : "") +
        (action.payload.form.interviewer
          ? `&interviewer=${action.payload.form.interviewer}`
          : "") +
        (action.payload.form.employer
          ? `&user=${action.payload.form.employer}`
          : "") +
        (action.payload.form.code
          ? `&code=${encodeURIComponent(action.payload.form.code)}`
          : "") +
        (action.payload.form.candidateNumber
          ? `&candidateNumber=${action.payload.form.candidateNumber}`
          : "") +
        (action.payload.form.candidateName
          ? `&candidateName=${action.payload.form.candidateName}`
          : "")
    : "";
  const isSort = action.payload.filters
    ? action.payload.filters.sort
      ? `&sort=${action.payload.filters.sort}`
      : action.payload.sort
      ? `&sort=${action.payload.sort}`
      : ""
    : action.payload.sort
    ? `&sort=${action.payload.sort}`
    : "";
  try {
    const res = yield INTERVIEW.get(
      `/admin/incExit/interviews/list/search${isPageAndPageSize}${isFilter}${isSort}`
    );
    if (res.status === 200) {
      yield put(getIncexitInterviewsSearchListSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getIncexitInterviewsSearchListFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
// IncExit interview
function* getIncExitInterviewsRequest(action) {
  let isFilter;
  const status =
    action.payload.status && action.payload.status
      ? `&status=${action.payload.status}`
      : "";
  const isPageAndPageSize =
    action.payload.page && action.payload.pageSize
      ? `?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
      : "";
  const isSearch = action.payload.search
    ? `&search=${encodeURIComponent(action.payload.search)}`
    : "";
  const isSort = action.payload.filters
    ? action.payload.filters.sort
      ? `&sort=${action.payload.filters.sort}`
      : action.payload.sort
      ? `&sort=${action.payload.sort}`
      : ""
    : action.payload.sort
    ? `&sort=${action.payload.sort}`
    : "";
  if (action.payload.filters) {
    isFilter = action.payload.filters
      ? (action.payload.filters.from
          ? `&from=${action.payload.filters.from}T00:00:00`
          : "") +
        (action.payload.filters.to
          ? `&to=${action.payload.filters.to}T23:59:59`
          : "") +
        (action.payload.filters.createdBy
          ? `&createdBy=${action.payload.filters.createdBy}`
          : "") +
        (action.payload.filters.experience
          ? `&experience=${action.payload.filters.experience}`
          : "")
      : "";
  } else {
    isFilter = action.payload.form
      ? (action.payload.form.from
          ? `&from=${action.payload.form.from}T00:00:00`
          : "") +
        (action.payload.form.to
          ? `&to=${action.payload.form.to}T23:59:59`
          : "") +
        (action.payload.form.createdBy
          ? `&createdBy=${action.payload.form.createdBy}`
          : "") +
        (action.payload.form.experience
          ? `&experience=${action.payload.form.experience}`
          : "") +
        (action.payload.form.interviewId
          ? `&interviewId=${action.payload.form.interviewId}`
          : "") +
        (action.payload.form.employer
          ? `&employer=${action.payload.form.employer}`
          : "") +
        (action.payload.form.interviewer
          ? `&interviewer=${action.payload.form.interviewer}`
          : "") +
        (action.payload.form.mode ? `&mode=${action.payload.form.mode}` : "") +
        (action.payload.form.candidateName
          ? `&candidateName=${action.payload.form.candidateName}`
          : "") +
        (action.payload.form.feedback
          ? `&feedback=${action.payload.form.feedback}`
          : "")
      : "";
  }
  try {
    const res = yield INTERVIEW.get(
      `/admin/incExit/interviews/list${isPageAndPageSize}${isSearch}${isFilter}${status}${isSort}`
    );
    if (res.status === 200) {
      if (action?.payload?.page === "all") {
        yield call(action.payload.callback, res.data.result);
        yield put(getIncExitInterviewsFailure());
      } else {
        yield put(getIncExitInterviewsSuccess(res.data.result));
      }
    }
  } catch (e) {
    yield put(getIncExitInterviewsFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

// IncExit accept interview
function* acceptIncExitInterviewRequest(action) {
  try {
    const res = yield INTERVIEW.patch(
      `/admin/incExit/interviews/Changestatus`,
      action.payload
    );
    if (res.status === 201) {
      yield put(acceptIncExitInterviewsSuccess());
      yield toast.success(<Toast msg={"Interview Accept successfully"} />);
      // action.payload.navigate(-1);
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(acceptIncExitInterviewsFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

// Incexit cancel interview
function* cancelIncexitInterviewRequest(action) {
  try {
    const res = yield INTERVIEW.patch(
      `admin/incExit/interviews/cancel`,
      action.payload.data
    );
    if (res.status === 201) {
      yield put(cancelIncexitInterviewSuccess());
      yield toast.success(<Toast msg={"Interview cancelled successfully"} />);
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(cancelIncexitInterviewFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

// INCEXIT UPDATED INTERVIEW
function* updateIncExitInterviewsRequest(action) {
  try {
    let res;
    const formData = new FormData();
    formData.append("name", action.payload.data.name);
    formData.append("designation", action.payload.data.designation);
    formData.append("email", action.payload.data.email);
    formData.append("phone", action.payload.data.phone);
    formData.append("exitForm", action.payload.data.exitForm);
    formData.append("joiningDate", action.payload.data.joiningDate);
    formData.append("relievingDate", action.payload.data.relievingDate);
    formData.append("dateOfBirth", action.payload.data.dateOfBirth);
    formData.append("age", action.payload.data.age);
    formData.append("gender", action.payload.data.gender);
    formData.append("timeSlots", action.payload.data.timeSlots);
    formData.append(
      "address",
      JSON.stringify({
        location: action.payload.data.state,
        subLocation: action.payload.data.city,
      })
    );
    formData.append("positionLevel", action.payload.data.positionLevel);
    formData.append("zone", action.payload.data.zone);
    action.payload.data.currentProject &&
      formData.append("currentProject", action.payload.data.currentProject);

    formData.append("countryCode", +91);
    formData.append("department", action.payload.data.department);
    formData.append("user", action.payload.userId);
    formData.append(
      "screeningQuestions",
      JSON.stringify(action.payload.screeningQuestionIds)
    );
    formData.append(
      "positionEvaQues",
      JSON.stringify(action.payload.positionEvaQuesIds)
    );
    formData.append(
      "departmentalEvaQues",
      JSON.stringify(action.payload.departmentalEvaQuesIds)
    );
    formData.append(
      "organizationEvaQues",
      JSON.stringify(action.payload.organizationEvaQuesIds)
    );
    if (
      action.payload.data.customScreeningQuestions &&
      action.payload.data.customScreeningQuestions.length > 0
    ) {
      formData.append(
        "customScreeningQuestions",
        JSON.stringify(action.payload.data.customScreeningQuestions)
      );
    }
    if (
      action.payload.data.customPositionEvaQues &&
      action.payload.data.customPositionEvaQues.length > 0
    ) {
      formData.append(
        "customPositionEvaQues",
        JSON.stringify(action.payload.data.customPositionEvaQues)
      );
    }
    if (
      action.payload.data.customDepartmentalEvaQues &&
      action.payload.data.customDepartmentalEvaQues.length > 0
    ) {
      formData.append(
        "customDepartmentalEvaQues",
        JSON.stringify(action.payload.data.customDepartmentalEvaQues)
      );
    }
    if (
      action.payload.data.customOrganizationEvaQues &&
      action.payload.data.customOrganizationEvaQues.length > 0
    ) {
      formData.append(
        "customOrganizationEvaQues",
        JSON.stringify(action.payload.data.customOrganizationEvaQues)
      );
    }
    let message;
    res = yield INTERVIEW.put(
      `/admin/incExit/interviews/${action?.payload?.interviewId}`,
      formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
    message = "Interview updated successfully.";

    if (res.status === 201 || res.status === 200) {
      yield put(updateIncexitInterviewSucees());
      yield call(action.payload.callback);
      yield toast.success(<Toast msg={`${message}`} />);
    }
  } catch (e) {
    if (e?.response?.status === 400) {
      yield put(updateIncexitInterviewFailure(e?.response?.data?.message));
      toast.error(<Toast msg={e?.response?.data?.message} />);
    } else {
      yield put(updateIncexitInterviewFailure());
      yield e?.response?.data?.message &&
        toast.error(<Toast msg={e?.response?.data?.message} />);
    }
  }
}

// INCEXIT CREATE INTERVIEW
function* createIncExitInterviewsRequest(action) {
  try {
    let res;
    const formData = new FormData();
    formData.append("name", action.payload.data.name);
    formData.append("designation", action.payload.data.designation);
    formData.append("email", action.payload.data.email);
    formData.append("phone", action.payload.data.phone);
    // formData.append("exitForm", action.payload.data.exitForm);
    formData.append("joiningDate", action.payload.data.joiningDate);
    formData.append("relievingDate", action.payload.data.relievingDate);
    formData.append("dateOfBirth", action.payload.data.dateOfBirth);
    formData.append("age", action.payload.data.age);
    formData.append("gender", action.payload.data.gender);
    formData.append("timeSlots", action.payload.data.timeSlots);
    formData.append("address", JSON.stringify(action.payload.address));
    formData.append("positionLevel", action.payload.data.positionLevel);
    formData.append("zone", action.payload.data.zone);
    formData.append("currentProject", action.payload.data.currentProject);
    formData.append(
      "screeningQuestions",
      JSON.stringify(action.payload.screeningQuestionIds)
    );
    formData.append(
      "positionEvaQues",
      JSON.stringify(action.payload.positionEvaQuesIds)
    );
    formData.append(
      "departmentalEvaQues",
      JSON.stringify(action.payload.departmentalEvaQuesIds)
    );
    formData.append(
      "organizationEvaQues",
      JSON.stringify(action.payload.organizationEvaQuesIds)
    );
    formData.append("countryCode", +91);
    formData.append("department", action.payload.data.department);
    formData.append("user", action.payload.userId);
    if (
      action.payload.data.customScreeningQuestions &&
      action.payload.data.customScreeningQuestions.length > 0
    ) {
      formData.append(
        "customScreeningQuestions",
        JSON.stringify(action.payload.data.customScreeningQuestions)
      );
    }
    if (
      action.payload.data.customPositionEvaQues &&
      action.payload.data.customPositionEvaQues.length > 0
    ) {
      formData.append(
        "customPositionEvaQues",
        JSON.stringify(action.payload.data.customPositionEvaQues)
      );
    }
    if (
      action.payload.data.customDepartmentalEvaQues &&
      action.payload.data.customDepartmentalEvaQues.length > 0
    ) {
      formData.append(
        "customDepartmentalEvaQues",
        JSON.stringify(action.payload.data.customDepartmentalEvaQues)
      );
    }
    if (
      action.payload.data.customOrganizationEvaQues &&
      action.payload.data.customOrganizationEvaQues.length > 0
    ) {
      formData.append(
        "customOrganizationEvaQues",
        JSON.stringify(action.payload.data.customOrganizationEvaQues)
      );
    }

    if (action.payload.fileChange === true) {
      formData.append("exitForm", action.payload.data.exitForm);
    }
    let message;
    res = yield INTERVIEW.post(`/admin/incExit/interviews/create`, formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    message = "Interview Added successfully.";

    if (res.status === 201 || res.status === 200) {
      yield put(createIncexitInterviewSucees());
      yield call(action.payload.callback);
      yield toast.success(<Toast msg={`${message}`} />);
    }
  } catch (e) {
    if (e?.response?.status === 400) {
      yield put(createIncexitInterviewFailure(e?.response?.data?.message));
    } else {
      yield put(createIncexitInterviewFailure());
      yield e?.response?.data?.message &&
        toast.error(<Toast msg={e?.response?.data?.message} />);
    }
  }
}

// INCEXIT GIVE FEEDBACK
function* addIncExitFeedbackRequest(action) {
  try {
    let res;
    const formData = new FormData();
    formData.append("user", action.payload.data.user);
    formData.append("interviewId", action.payload.data.interviewId);
    formData.append("candidateJoined", action.payload.data.candidateJoined);
    formData.append("reasonsForExit", action.payload.data.reasonsForExit);
    formData.append("rejoinCompany", action.payload.data.rejoinCompany);
    formData.append("CARFile", action.payload.data.CARFile);
    if (action.payload.data.candidateJoined === false) {
      formData.delete("reasonsForExit");
      formData.delete("rejoinCompany");
      formData.delete("CARFile");
    }
    let message;
    res = yield INTERVIEW.post(
      `/admin/incExit/interviews/addFeedback`,
      formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
    message = "Feedback Added successfully.";
    if (res.status === 201 || res.status === 200) {
      yield put(addIncexitFeddbackSuccess());
      yield call(action.payload.callback);
      yield toast.success(<Toast msg={`${message}`} />);
    }
  } catch (e) {
    if (e?.response?.status === 400) {
      yield put(addIncexitFeddbackFailure(e?.response?.data?.message));
      toast.error(<Toast msg={e?.response?.data?.message} />);
    } else {
      yield put(addIncexitFeddbackFailure());
      yield e?.response?.data?.message &&
        toast.error(<Toast msg={e?.response?.data?.message} />);
    }
  }
}

// INCEXIT ALLOT REALLOT INTERVIEWER
function* allotIncExitInterviewerRequest(action) {
  try {
    let res;
    let message;
    res = yield INTERVIEW.post(
      `/admin/incExit/interviews/allotReallotInterviewer`,
      action.payload.data
    );
    message = `Interviewer ${action.payload.type} successfully.`;

    if (res.status === 201 || res.status === 200) {
      yield put(allotIncexitInterviewerSuccess());
      yield call(action.payload.callback);
      yield toast.success(<Toast msg={`${message}`} />);
    }
  } catch (e) {
    if (e?.response?.status === 400) {
      yield put(allotIncexitInterviewerFailure(e?.response?.data?.message));
      toast.error(<Toast msg={e?.response?.data?.message} />);
    } else {
      yield put(allotIncexitInterviewerFailure());
      yield e?.response?.data?.message &&
        toast.error(<Toast msg={e?.response?.data?.message} />);
    }
  }
}

// INCEXIT Schedule
function* scheduleIncExitInterviewRequest(action) {
  if (action?.payload?.data?.platform === "") {
    delete action?.payload?.data?.platform;
  }
  try {
    let res;
    let message;
    res = yield INTERVIEW.post(
      `/admin/incExit/interviews/scheduleInterview`,
      action.payload.data
    );
    message = `Interview scheduled successfully.`;

    if (res.status === 201 || res.status === 200) {
      yield put(scheduleInterviewSuccess());
      yield call(action.payload.callback(res));
      yield toast.success(<Toast msg={`${message}`} />);
    }
  } catch (e) {
    if (e?.response?.status === 400) {
      yield put(scheduleInterviewFailure(e?.response?.data?.message));
      toast.error(<Toast msg={e?.response?.data?.message} />);
    } else {
      yield put(scheduleInterviewFailure());
      yield e?.response?.data?.message &&
        toast.error(<Toast msg={e?.response?.data?.message} />);
    }
  }
}

// INCEXIT ReSchedule
function* reScheduleIncExitInterviewRequest(action) {
  try {
    let res;
    let message;
    res = yield INTERVIEW.put(
      `/admin/incExit/interviews/rescheduleInterview

      `,
      action.payload.data
    );
    message = `Interview Rescheduled successfully.`;

    if (res.status === 201 || res.status === 200) {
      yield put(reScheduleInterviewSuccess());
      yield call(action.payload.callback(res));
      yield toast.success(<Toast msg={`${message}`} />);
    }
  } catch (e) {
    if (e?.response?.status === 400) {
      yield put(reScheduleInterviewFailure(e?.response?.data?.message));
      toast.error(<Toast msg={e?.response?.data?.message} />);
    } else {
      yield put(reScheduleInterviewFailure());
      yield e?.response?.data?.message &&
        toast.error(<Toast msg={e?.response?.data?.message} />);
    }
  }
}
// INCEXIT respond ReSchedule
function* respondReScheduleIncExitInterviewRequest(action) {
  try {
    let res;
    let message;
    const interviewId = action.payload.interviewId;
    const status = action.payload.status;
    res = yield INTERVIEW.patch(
      `/admin/incExit/interviews/rescheduleRequest/${interviewId}/${status}`
    );
    message = `Interview Rescheduled successfully.`;

    if (res.status === 201 || res.status === 200) {
      yield put(respondReScheduleInterviewSuccess());
      yield call(action.payload.callback);
      yield toast.success(<Toast msg={`${message}`} />);
    }
  } catch (e) {
    if (e?.response?.status === 400) {
      yield put(respondReScheduleInterviewFailure(e?.response?.data?.message));
      toast.error(<Toast msg={e?.response?.data?.message} />);
    } else {
      yield put(respondReScheduleInterviewFailure());
      yield e?.response?.data?.message &&
        toast.error(<Toast msg={e?.response?.data?.message} />);
    }
  }
}

// INCEXIT BULK REQUIREMENTS
function* getBulkInterviewListRequest(action) {
  const isSearch = action.payload.search
    ? `&search=${encodeURIComponent(action.payload.search)}`
    : "";
  const isSort = action.payload.sort ? `&sort=${action.payload.sort}` : "";
  const isPageAndPageSize =
    action.payload.page && action.payload.pageSize
      ? `?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
      : "";
  const status = action?.payload?.status
    ? `&status=${action.payload.status}`
    : "";
  try {
    const res = yield INTERVIEW.get(
      `admin/incExit/interviews/bulkUpload/list${isPageAndPageSize}${isSort}${isSearch}${status}`
    );
    if (res.status === 200 || res.status === 201) {
      yield put(bulkIncExitInterviewSuccess(res.data.result));
    }
  } catch (e) {
    yield put(bulkIncExitInterviewFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
// ACCEPT BULK REQUIREMENTS
function* acceptBulkRequirementsRequest(action) {
  const bulkId = encodeURIComponent(action.payload.bulkId);
  const userId = action.payload.userId;
  try {
    const res = yield INTERVIEW.post(
      `/admin/incExit/interviews/accept/BulkInterviews?bulkId=${bulkId}&user=${userId}`,
      action.payload.data
    );
    if (res.status === 201) {
      yield put(acceptBulkIncExitInterviewSuccess(res.data?.result));
      yield toast.success(<Toast msg="Interview Accepted Successfully" />);
    }
  } catch (e) {
    yield put(acceptBulkIncExitInterviewFailure());
  }
}
// UPDATE BULK REQUIREMENTS
function* updateBulkRequirementsRequest(action) {
  const userId = action.payload.userId;
  try {
    const formData = new FormData();
    formData.append("confirm", 2);
    if (action.payload.csvFileChange) {
      formData.append("candidateTracker", action.payload.data.candidateTracker);
    }
    if (!action.payload.csvFileChange) {
      formData.append(
        "candidateCSVUploadedName",
        action.payload.data.candidateCSVUploadedName
      );
    }
    if (!action.payload.exitFileChange) {
      formData.append(
        "exitFormzipUploadedName",
        action.payload.data.exitFormzipUploadedName
      );
    }

    if (action.payload.exitFileChange) {
      formData.append("exitFormZip", action.payload.data.exitFormZip);
    }
    formData.append(
      "screeningQuestions",
      JSON.stringify(action.payload.screeningQuestionIds)
    );
    formData.append("user", action?.payload?.data?.user);
    formData.append(
      "positionEvaQues",
      JSON.stringify(action.payload.positionEvaQuesIds)
    );
    formData.append(
      "departmentalEvaQues",
      JSON.stringify(action.payload.departmentalEvaQuesIds)
    );
    formData.append(
      "organizationEvaQues",
      JSON.stringify(action.payload.organizationEvaQuesIds)
    );
    if (
      action.payload.data.customScreeningQuestions &&
      action.payload.data.customScreeningQuestions.length > 0
    ) {
      formData.append(
        "customScreeningQuestions",
        JSON.stringify(action.payload.data.customScreeningQuestions)
      );
    }
    if (
      action.payload.data.customPositionEvaQues &&
      action.payload.data.customPositionEvaQues.length > 0
    ) {
      formData.append(
        "customPositionEvaQues",
        JSON.stringify(action.payload.data.customPositionEvaQues)
      );
    }
    if (
      action.payload.data.customDepartmentalEvaQues &&
      action.payload.data.customDepartmentalEvaQues.length > 0
    ) {
      formData.append(
        "customDepartmentalEvaQues",
        JSON.stringify(action.payload.data.customDepartmentalEvaQues)
      );
    }
    if (
      action.payload.data.customOrganizationEvaQues &&
      action.payload.data.customOrganizationEvaQues.length > 0
    ) {
      formData.append(
        "customOrganizationEvaQues",
        JSON.stringify(action.payload.data.customOrganizationEvaQues)
      );
    }
    const res = yield INTERVIEW.put(
      `/admin/incExit/interviews/bulkUpload/${userId}`,
      formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
    if (res.status === 200 || res.status === 201) {
      yield put(updateBulkIncExitInterviewSuccess(res.data?.result));
      yield call(action.payload.callback);
      yield toast.success(<Toast msg="Bulk Interview Updated Successfully" />);
    }
  } catch (e) {
    yield put(updateBulkIncExitInterviewFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

// close BULK REQUIREMENTS
function* closeBulkRequirementsRequest(action) {
  const bulkId = encodeURIComponent(action.payload.bulkId);
  const userId = action.payload.userId;
  try {
    const res = yield INTERVIEW.post(
      `/admin/incExit/interviews/close/BulkInterviews?bulkId=${bulkId}&user=${userId}`,
      action.payload.data
    );
    if (res.status === 201) {
      yield put(closeBulkIncExitInterviewSuccess(res.data?.result));
      yield toast.success(<Toast msg="Interview Closed Successfully" />);
    }
  } catch (e) {
    yield put(closeBulkIncExitInterviewFailure());
  }
}

// previously alloted Interviewer list
function* getPreviouslyAllotedInterviewerListRequest(action) {
  try {
    const res = yield INTERVIEW.get(`admin/interviewers/dropdown`);
    if (res.status === 200) {
      yield put(previouslyAllotedInterviewerListSuccess(res.data.result));
    }
  } catch (e) {
    yield put(previouslyAllotedInterviewerListFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

// get call logs
function* getCallLogsRequest(action) {
  const isPageAndPageSize =
    action.payload.page && action.payload.pageSize
      ? `?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
      : "";
  const isSort = action.payload.sort ? `&sort=${action.payload.sort}` : "";
  try {
    const res = yield INTERVIEW.get(
      `/admin/incserve/interviews/callLogs/${action?.payload?.interviewerId}${isPageAndPageSize}${isSort}`
    );
    if (res.status === 200 || res.status === 201) {
      yield put(getCallLogsSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getCallLogsFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* getInterviewerCallLogsRequest(action) {
  const isPageAndPageSize =
    action.payload.page && action.payload.pageSize
      ? `?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
      : "";
  const isSort = action.payload.sort ? `&sort=${action.payload.sort}` : "";
  try {
    const res = yield INTERVIEW.get(
      `/admin/interviewers/callLogs/${action?.payload?.interviewerId}${isPageAndPageSize}${isSort}`
    );
    if (res.status === 200 || res.status === 201) {
      yield put(getInterviewerCallLogsSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getInterviewerCallLogsFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

//incserve incexit previously alloted Interviewer list
function* getIncserveIncexitPreviouslyAllotedInterviewerListRequest(action) {
  try {
    let res;
    if (action.payload.type === "incExit") {
      res = yield INTERVIEW.get(
        `/admin/incexit/interviews/previouslyAllottedInterviewers?user=${action.payload?.data}`
      );
    } else if (action.payload.type === "incserve") {
      res = yield INTERVIEW.get(
        `/admin/incserve/interviews/previouslyAllottedInterviewers?user=${action.payload?.data}`
      );
    }

    if (res.status === 200) {
      yield put(
        incserveIncexitPreviouslyAllotedInterviewerListSuccess(res.data.result)
      );
    }
  } catch (e) {
    yield put(incserveIncexitPreviouslyAllotedInterviewerListFailure());
  }
}

// designations list
function* getDesignatiomListRequest(action) {
  const userId = action.payload.userId;
  try {
    const res = yield INTERVIEW.get(
      `admin/incExit/designations/dropdown?user=${userId}`
    );
    if (res.status === 200) {
      yield put(getDesignationListSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getDesignationListFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* getIncserveInterviewByIdRequest(action) {
  try {
    const res = yield INTERVIEW.get(
      `admin/incserve/interviews/requirements/${action.payload.data.id}`
    );
    if (res.status === 200) {
      yield put(getIncserveInterviewByIdSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getIncserveInterviewByIdFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* createOrUpdateIncserveInterviewsRequest(action) {
  try {
    let res;
    const formData = new FormData();
    if (action?.payload?.type === "bulk") {
      formData.append("bulkId", action?.payload?.bulkId);
      formData.append("user", action?.payload?.user);
    } else {
      formData.append("user", action?.payload?.data?.user);
    }
    formData.append("position", action?.payload?.data?.title);
    formData.append("domain", action?.payload?.data?.domain);
    formData.append("timeSlots", action?.payload?.data?.timeSlots);
    formData.append("skills", JSON.stringify(action?.payload?.data?.skills));
    formData.append(
      "candidate",
      JSON.stringify(action?.payload?.data?.candidate)
    );
    formData.append(
      "customSkills",
      JSON.stringify(action?.payload?.data?.customSkills)
    );
    formData.append(
      "customField",
      JSON.stringify(
        action.payload.data.customField &&
          action.payload.data.customField.length > 0
          ? action.payload.data.customField
          : []
      )
    );
    formData.append(
      "screeningQuestions",
      JSON.stringify(
        action?.payload?.data?.screeningQuestions &&
          action?.payload?.data?.screeningQuestions.length > 0
          ? action?.payload?.data?.screeningQuestions
          : []
      )
    );
    let resType = typeof action?.payload?.data?.resume;
    let descriptionType = typeof action?.payload?.data?.jobDescription;
    if (resType !== "string") {
      formData.append("resume", action.payload.data.resume);
    }
    if (descriptionType !== "string") {
      formData.append("jobDescription", action.payload.data.jobDescription);
    }
    formData.append(
      "customScreeningQuestions",
      JSON.stringify(
        action?.payload?.data?.customScreeningQuestions &&
          action?.payload?.data?.customScreeningQuestions.length > 0
          ? action?.payload?.data?.customScreeningQuestions
          : []
      )
    );
    let message;
    if (action?.payload?.id) {
      res = yield INTERVIEW.put(
        `admin/incserve/interviews/${action?.payload?.id}`,
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      );
      message = "Interview updated successfully.";
    } else if (action?.payload?.editableType === "replicate") {
      res = yield INTERVIEW.post(`admin/incserve/interviews/create`, formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      message = "Interview added successfully.";
    } else {
      res = yield INTERVIEW.post(`admin/incserve/interviews/create`, formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      message = "Interview added successfully.";
    }
    if (res.status === 201 || res.status === 200) {
      yield put(createOrUpdateIncserveInterviewSuccess());
      yield call(action.payload.callback);
      yield toast.success(<Toast msg={`${message}`} />);
    }
  } catch (e) {
    if (e?.response?.status === 400) {
      yield put(
        createOrUpdateIncserveInterviewFailure(e?.response?.data?.message)
      );
    } else {
      yield put(createOrUpdateIncserveInterviewFailure());
      yield e?.response?.data?.message &&
        toast.error(<Toast msg={e?.response?.data?.message} />);
    }
  }
}

// INCSERVE POSITIONS
function* getIncservePositionRequest(action) {
  const isSearch = action.payload.search
    ? `&search=${encodeURIComponent(action.payload.search)}`
    : "";
  const isSort = action.payload.filters
    ? action.payload.filters.sort
      ? `&sort=${action.payload.filters.sort}`
      : action.payload.sort
      ? `&sort=${action.payload.sort}`
      : ""
    : action.payload.sort
    ? `&sort=${action.payload.sort}`
    : "";
  const isPageAndPageSize =
    action.payload.page && action.payload.pageSize
      ? `?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
      : "";
  const productId = action.payload.productId
    ? `&product=${action.payload.productId}`
    : "";
  const isfilter = action.payload.filters
    ? (action.payload.filters.from
        ? action.payload.filters.to
          ? `&from=${action.payload.filters.from}T00:00:00&to=${action.payload.filters.to}T23:59:59`
          : `&from=${action.payload.filters.from}T00:00:00&to=3000-12-31T23:59:59`
        : action.payload.filters.to
        ? `&from=2000-01-01T00:00:00&to=${action.payload.filters.to}T23:59:59`
        : "") +
      (action.payload.filters.experience
        ? `&experience=${action.payload.filters.experience}`
        : `&experience=0`) +
      (action.payload.filters.createdBy
        ? `&createdBy=${action.payload.filters.createdBy}`
        : "")
    : "";

  try {
    const res = yield INTERVIEW.get(
      `admin/incserve/positions${isPageAndPageSize}${isSort}${isSearch}${productId}${isfilter}`
    );
    if (res.status === 200) {
      if (action?.payload?.page === "all") {
        yield put(getIncservePositionsFailure());
        yield call(action.payload.callback, res.data.result);
      } else {
        yield put(getIncservePositionsSuccess(res.data.result));
      }
    }
  } catch (e) {
    yield put(getIncservePositionsFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

// INCBOT POSITIONS
function* getIncbotPositionRequest(action) {
  const isSearch = action.payload.search
    ? `&search=${encodeURIComponent(action.payload.search)}`
    : "";
  const isSort = action.payload.filters
    ? action.payload.filters.sort
      ? `&sort=${action.payload.filters.sort}`
      : action.payload.sort
      ? `&sort=${action.payload.sort}`
      : ""
    : action.payload.sort
    ? `&sort=${action.payload.sort}`
    : "";
  const status = action.payload.filters
    ? action.payload.filters.status
      ? `&status=${action.payload.filters.status}`
      : action.payload.status
      ? `&status=${action.payload.status}`
      : ""
    : action.payload.status
    ? `&status=${action.payload.status}`
    : "";
  const isPageAndPageSize =
    action.payload.page && action.payload.pageSize
      ? `?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
      : "";
  const productId = action.payload.productId
    ? `&product=${action.payload.productId}`
    : "";
  const isfilter = action.payload.filters
    ? (action.payload.filters.from
        ? action.payload.filters.to
          ? `&from=${action.payload.filters.from}T00:00:00&to=${action.payload.filters.to}T23:59:59`
          : `&from=${action.payload.filters.from}T00:00:00&to=3000-12-31T23:59:59`
        : action.payload.filters.to
        ? `&from=2000-01-01T00:00:00&to=${action.payload.filters.to}T23:59:59`
        : "") +
      (action.payload.filters.user
        ? `&user=${action.payload.filters.user}`
        : "") +
      (action.payload.filters.createdBy
        ? `&createdBy=${action.payload.filters.createdBy}`
        : "")
    : "";

  try {
    const res = yield INTERVIEW.get(
      `admin/incbot/getPositions${isPageAndPageSize}${isSort}${isSearch}${productId}${isfilter}${status}`
    );
    if (res.status === 200) {
      if (action?.payload?.page === "all") {
        yield put(getIncbotPositionsFailure());
        yield call(action.payload.callback, res.data.result);
      } else {
        yield put(getIncbotPositionsSuccess(res.data.result));
      }
    }
  } catch (e) {
    yield put(getIncbotPositionsFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* getActiveIncbotEmployersRequest(action) {
  try {
    const res = yield INTERVIEW.get(`/admin/incbot/activeEmployers/list`);
    if (res.status === 200) {
      yield put(getActiveIncbotEmployersSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getActiveIncbotEmployersFailure());
  }
}

// INCBOT Interviews
function* getIncbotInterviewsRequest(action) {
  const isSearch = action.payload.search
    ? `&search=${encodeURIComponent(action.payload.search)}`
    : "";
  const isSort = action.payload.filters
    ? action.payload.filters.sort
      ? `&sort=${action.payload.filters.sort}`
      : action.payload.sort
      ? `&sort=${action.payload.sort}`
      : ""
    : action.payload.sort
    ? `&sort=${action.payload.sort}`
    : "";
  const status = action.payload.filters
    ? action.payload.filters.status
      ? `&status=${action.payload.filters.status}`
      : action.payload.status
      ? `&status=${action.payload.status}`
      : ""
    : action.payload.status
    ? `&status=${action.payload.status}`
    : "";
  const isPageAndPageSize =
    action.payload.page && action.payload.pageSize
      ? `?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
      : "";
  const productId = action.payload.productId
    ? `&product=${action.payload.productId}`
    : "";
  const isfilter = action.payload.filters
    ? (action.payload.filters.from
        ? action.payload.filters.to
          ? `&from=${action.payload.filters.from}T00:00:00&to=${action.payload.filters.to}T23:59:59`
          : `&from=${action.payload.filters.from}T00:00:00&to=3000-12-31T23:59:59`
        : action.payload.filters.to
        ? `&from=2000-01-01T00:00:00&to=${action.payload.filters.to}T23:59:59`
        : "") +
      (action.payload.filters.user
        ? `&user=${action.payload.filters.user}`
        : "") +
      (action.payload.filters.createdBy
        ? `&createdBy=${action.payload.filters.createdBy}`
        : "")
    : "";

  try {
    const res = yield INTERVIEW.get(
      `/admin/incbot/getCandidates${isPageAndPageSize}${isSort}${isSearch}${productId}${isfilter}${status}`
    );
    if (res.status === 200) {
      if (action?.payload?.page === "all") {
        yield put(getIncbotInterviewsFailure());
        yield call(action.payload.callback, res.data.result);
      } else {
        yield put(getIncbotInterviewsSuccess(res.data.result));
      }
    }
  } catch (e) {
    yield put(getIncbotInterviewsFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* getIncservePositionByIdRequest(action) {
  try {
    const res = yield INTERVIEW.get(
      `admin/incserve/positions/${action?.payload?.data?.id}`
    );
    if (res.status === 200) {
      yield put(getIncservePositionByIdSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getIncservePositionByIdSuccess());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* createOrUpdateIncservePositionRequest(action) {
  try {
    let res;
    const formData = new FormData();
    formData.append("title", action.payload.data.title);
    if (action?.payload?.data?.positionType === "Client Hiring") {
      formData.append("clientName", action.payload.data.clientName);
    }
    formData.append("domain", action.payload.data.domain);
    formData.append("expRange", JSON.stringify(action.payload.data.expRange));
    formData.append("jobDescription", action.payload.data.jobDescription);
    if (action.payload.data.feedbackFile) {
      formData.append(
        "feedbackFile",
        action.payload.data.feedbackFile ? action.payload.data.feedbackFile : ""
      );
    }
    formData.append("remarks", action.payload.data.remarks);
    formData.append("user", action.payload.data.user);
    formData.append("positionType", action.payload.data.positionType);
    formData.append("feedbackType", action.payload.data.feedbackType);
    formData.append("skills", JSON.stringify(action.payload.data.skills));
    formData.append(
      "customSkills",
      JSON.stringify(action.payload.data.customSkills)
    );
    formData.append(
      "screeningQuestions",
      JSON.stringify(action.payload.data.screeningQuestions)
    );
    formData.append(
      "customScreeningQuestions",
      JSON.stringify(action.payload.data.customScreeningQuestions)
    );
    formData.append(
      "customField",
      JSON.stringify(
        action.payload.data.customField ? action.payload.data.customField : []
      )
    );
    if (action?.payload?.id) {
      res = yield INTERVIEW.put(
        `admin/incserve/positions/${action?.payload?.id}`,
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      );
    } else {
      res = yield INTERVIEW.post("admin/incserve/positions", formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });
    }
    if (res.status === 201 || res.status === 200) {
      yield put(createOrUpdatePositionSuccess());
      yield call(action.payload.callback);
      yield toast.success(<Toast msg="Position edited successfully." />);
    }
  } catch (e) {
    yield put(createOrUpdatePositionFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* mappedIncserveInterviewersRequest(action) {
  try {
    const res = yield INTERVIEW.put(
      `/admin/incserve/positions/mapInterviewers`,
      action?.payload?.data
    );
    if (res.status === 200) {
      yield put(mappedInterviewersSuccess());
      yield toast.success("Interviewer Mapped Successfully");
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(mappedInterviewersFailure);
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* deleteIncservePositionRequest(action) {
  try {
    const res = yield INTERVIEW.delete(
      `admin/incserve/positions/${action?.payload?.deleteData?.id}?user=${action?.payload?.deleteData?.user}`
    );
    if (res.status === 204) {
      yield put(deleteIncservePositionSuccess());
      yield toast.success(<Toast msg="Position deleted successfully." />);
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(deleteIncservePositionFailure);
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* getInterviewerRatingsRequest(action) {
  try {
    const res = yield INTERVIEW.get(
      `/admin/interviewers/ratings/${action?.payload?.interviewerId}`
    );
    if (res.status === 200 || res.status === 201) {
      yield put(getInterviewerRatingsSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getInterviewerRatingsFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

//
function* updateInterviewerRatingsRequest(action) {
  try {
    const res = yield INTERVIEW.put(
      `/admin/interviewers/ratings/${action?.payload?.interviewerId}`,
      action?.payload?.data
    );
    if (res.status === 200 || res.status === 201) {
      yield put(updateInterviewerRatingsSuccess(res.data.result));
      yield call(action?.payload?.callback);
      yield toast.success(<Toast msg="Ratings updated Successfully" />);
    }
  } catch (e) {
    yield put(updateInterviewerRatingsFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* getInterviewerAdminRemarksInfoRequest(action) {
  try {
    const res = yield INTERVIEW.get(
      `/admin/interviewers/adminRemarks/${action?.payload?.interviewerId}`
    );
    if (res.status === 200 || res.status === 201) {
      yield put(getInterviewerAdminRemarksInfoSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getInterviewerAdminRemarksInfoFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* getMeetingDetailsInfoRequest(action) {
  const isSearch = action?.payload?.search
    ? `&search=${encodeURIComponent(action?.payload?.search)}`
    : "";
  const isPageAndPageSize =
    action?.payload?.page && action?.payload?.pageSize
      ? `?page=${action?.payload?.page}&pageSize=${action?.payload?.pageSize}`
      : "";
  try {
    const res = yield INTERVIEW.get(
      `/admin/meeting/list${isPageAndPageSize}${isSearch} `
    );
    if (res.status === 200 || res.status === 201) {
      yield put(getMeetingDetailsSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getMeetingDetailsFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* postMeetingDetailsInfoRequest(action) {
  try {
    const res = yield INTERVIEW.post(
      `/admin/meeting/create `,
      action?.payload?.data
    );
    if (res.status === 200 || res.status === 201) {
      yield put(postMeetingDetailsSuccess(res.data.result));
      yield call(action?.payload?.callback);
      yield toast.success(<Toast msg="Meeting Created Successfully" />);
    }
  } catch (e) {
    yield put(postMeetingDetailsFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* putInterviewerMockRemarksInfoRequest(action) {
  const obj = {
    interviewerId: action?.payload?.interviewerDetails?._id,
    status: action?.payload.interviewerDetails?.status,
    mockRemark: action?.payload?.mockForm?.remarks,
  };

  try {
    const res = yield INTERVIEW.put(
      `/admin/interviewers/updateMockRemarks/${action?.payload?.interviewerDetails?._id}`,
      obj
    );

    if (res.status === 200 || res.status === 201) {
      yield put(putInterviewerMockRemarksInfoSuccess(res.data.result));
    }
  } catch (e) {
    yield put(putInterviewerMockRemarksInfoFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* getInterviewerAudioLogsRequest(action) {
  const isPageAndPageSize =
    action.payload.page && action.payload.pageSize
      ? `?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
      : "";
  const isSort = action.payload.sort ? `&sort=${action.payload.sort}` : "";
  const type = action.payload.type ? `&type=${action.payload.type}` : "";
  try {
    const res = yield INTERVIEW.get(
      `admin/interviewers/callLogs/${action?.payload?.interviewerId}${isPageAndPageSize}${isSort}${type}`
    );
    if (res.status === 200 || res.status === 201) {
      yield put(getInterviewerAudioLogsSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getInterviewerAudioLogsFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* getSimilarWebsiteRequest(action) {
  try {
    const res = yield INTERVIEW.get(`/admin/interviewers/similarWebsite/list`);
    if (res.status === 200 || res.status === 201) {
      yield put(getSimilarWebsiteListSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getSimilarWebsiteListFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* updateInterviewerAdminRemarksInfoRequest(action) {
  if (action?.payload?.data?.domain === "") {
    delete action?.payload?.data?.domain;
  }
  try {
    const res = yield INTERVIEW.put(
      `/admin/interviewers/adminRemarks/${action?.payload?.interviewerId}`,
      action?.payload?.data
    );
    if (res.status === 200) {
      yield put(updateInterviewerAdminRemarksInfoSuccess(res.data.result));
      yield toast.success(<Toast msg="Admin remarks updated Successfully" />);
      yield call(action?.payload?.callback);
    }
  } catch (e) {
    yield put(updateInterviewerAdminRemarksInfoFailure());
    // yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* updateInterviewerEduAndSkillSetRequest(action) {
  const formData = new FormData();
  formData.append("domain", action?.payload?.data?.domain);
  formData.append("designation", action?.payload?.data?.designation);
  formData.append("qualification", action?.payload?.data?.qualification);
  formData.append("skills", JSON.stringify(action?.payload?.data?.skills));
  formData.append(
    "customSkills",
    JSON.stringify(action?.payload?.data?.customSkills)
  );
  formData.append("experience", parseInt(action?.payload?.data?.experience));
  formData.append("areaOfExpertise", action?.payload?.data?.areaOfExpertise);
  formData.append("resume", action?.payload?.data?.resume);
  formData.append("photo", action?.payload?.data?.photo);
  formData.append("shiftTiming", action?.payload?.data?.shiftTiming);
  formData.append("companyType", action?.payload?.data?.companyType);

  try {
    const res = yield INTERVIEW.put(
      `admin/interviewers/otherInfo/${action?.payload?.interviewerId}`,
      formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
    if (res.status === 201 || res.status === 200) {
      yield put(updateInterviewerEduAndSkillSetSuccess());
      yield toast.success(
        <Toast msg="Education and skills updated Successfully" />
      );
    }
  } catch (e) {
    yield put(updateInterviewerEduAndSkillSetFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* addOrEditInterviewerAccountDetailsRequest(action) {
  const formData = new FormData();

  formData.append("IFSC", action?.payload?.data?.IFSC);
  formData.append("PAN", action?.payload?.data?.PAN);
  formData.append(
    "accountHolderName",
    action?.payload?.data?.accountHolderName
  );
  formData.append("bankName", action?.payload?.data?.bankName);
  formData.append("panCard", action?.payload?.data?.panCard);
  formData.append("accountNumber", action?.payload?.data?.accountNumber);
  formData.append("accountType", action?.payload?.data?.accountType);
  try {
    const res = yield INTERVIEW.put(
      `admin/interviewers/bankDetails/${action?.payload?.interviewerId}`,
      formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
    if (res.status === 201 || res.status === 200) {
      yield put(addOrEditInterviewerAccountDetailSuccess());
      yield toast.success(<Toast msg="Account Details updated Successfully" />);
    }
  } catch (e) {
    yield put(addOrEditInterviewerAccountDetailFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* updateInterviewerUserInformationRequest(action) {
  if (action?.payload?.data?.linkedInProfile === "") {
    delete action.payload.data.linkedInProfile;
  }
  if (action?.payload?.data?.companyName === "") {
    delete action.payload.data.companyName;
  }
  try {
    const res = yield INTERVIEW.put(
      `admin/interviewers/userInfo/${action?.payload?.interviewerId}`,
      action.payload.data
    );
    if (res.status === 201 || res.status === 200) {
      yield put(updateInterviewerUserInformationSuccess());
      yield toast.success(
        <Toast msg="User Information updated Successfully" />
      );
    }
  } catch (e) {
    yield put(updateInterviewerUserInformationFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* allotAndReallotInterviewerRequest(action) {
  try {
    const res = yield INTERVIEW.post(
      `/admin/incserve/interviews/allotReallotInterviewer`,
      action.payload.data
    );
    if (res.status === 201 || res.status === 200) {
      yield put(incserveAllotAndReallotInterviewerSuccess());
      yield toast.success(<Toast msg="Alloted Successfully" />);
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(incserveAllotAndReallotInterviewerFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* getInterviewerAccountDetailsRequest(action) {
  try {
    const res = yield INTERVIEW.get(
      `admin/interviewers/bankDetails/${action?.payload?.interviewerId}`
    );
    if (res.status === 200 || res.status === 201) {
      yield put(getInterviewerAccountDetailSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getInterviewerAccountDetailFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* getIncserveInterviewersDetailsRequest(action) {
  try {
    const res = yield INTERVIEW.get(
      `/admin/interviewers/info-table/${action?.payload?.interviewerId}`
    );
    if (res.status === 200 || res.status === 201) {
      yield put(getIncserveInterviewersDetailsSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getIncserveInterviewersDetailsFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* getInterviewerUserInformationRequest(action) {
  try {
    const res = yield INTERVIEW.get(
      `admin/interviewers/info/${action?.payload?.interviewerId}`
    );
    if (res.status === 200 || res.status === 201) {
      yield put(getInterviewerUserInformationSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getInterviewerUserInformationFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* getInterviewerBankNameListRequest(action) {
  try {
    const res = yield INTERVIEW.get("admin/interviewers/bankAccount/list");
    if (res.status === 200 || res.status === 201) {
      yield put(getInterviewerBankNameListSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getInterviewerBankNameListFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* getInterviewersRequest(action) {
  const isPageAndPageSize =
    action.payload.page && action.payload.pageSize
      ? `?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
      : "";
  const isSearch = action.payload.search
    ? `&search=${encodeURIComponent(action.payload.search)}`
    : "";
  const status = action.payload.status
    ? `&status=${action.payload.status}`
    : "";
  const searchKeyword = action.payload.searchKeyword
    ? `&searchKeyword=${action.payload.searchKeyword}`
    : "";
  try {
    const res = yield INTERVIEW.get(
      `admin/interviewers/list${isPageAndPageSize}${isSearch}${status}${searchKeyword}`
    );
    if (res.status === 200) {
      if (action?.payload?.page === "all") {
        yield put(getInterviewerListFailure());
        yield call(action.payload.callback, res.data.result);
      } else {
        yield put(getInterviewerListSuccess(res.data.result));
      }
    }
  } catch (e) {
    yield put(getInterviewerListFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* getInvoiceDetailsRequest(action) {
  let url;
  if (action?.payload?.type === "incserve") {
    url = yield INTERVIEW.get(
      `/admin/interviewers/invoice/incserve/${action?.payload?.interviewerId}`
    );
  } else {
    url = yield INTERVIEW.get(
      `/admin/interviewers/invoice/incexit/${action?.payload?.interviewerId}`
    );
  }
  try {
    const res = url;
    if (res.status === 200) {
      yield put(getInvoiceDetailsSuccess(res?.data?.result));
      yield call(action?.payload?.callback(res));
    }
  } catch (e) {
    yield put(getInvoiceDetailsFailure());
    // yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* getInvoiceDetailsByMonthRequest(action) {
  let url;
  try {
    if (action?.payload?.data?.year) {
      url = `/admin/interviewers/invoiceByMonth/incserve/${action?.payload?.data?.interviewerId}?month=${action?.payload?.data?.month}&year=${action?.payload?.data?.year}`;
    } else {
      url = `/admin/interviewers/invoiceByMonth/incserve/${action?.payload?.data?.interviewerId}?month=${action?.payload?.data?.month}`;
    }
    const res = yield INTERVIEW.get(url);
    if (res.status === 200) {
      yield put(getInvoiceDetailsByMonthSuccess(res?.data?.result));
      // yield toast.success(<Toast msg={"Invoice successfully downloaded."} />);
      yield call(action?.payload?.callback(res));
    }
  } catch (e) {
    yield put(getInvoiceDetailsByMonthFailure());
    // yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

// INTERVIEW
function* getInterviewRequest(action) {
  let sort;
  const isSearch = action.payload.search
    ? `&search=${encodeURIComponent(action.payload.search)}`
    : "";
  const isPageAndPageSize =
    action.payload.page && action.payload.pageSize
      ? `?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
      : "";
  const isProduct = action.payload.product
    ? `&productId=${action.payload.product}`
    : "";
  sort = action.payload.sort;
  const isfilter = action.payload.filters
    ? (action.payload.filters.sort
        ? `&sort=${action.payload.filters.sort}`
        : "") +
      (action.payload.filters.status
        ? `&status=${action.payload.filters.status}`
        : "") +
      (action.payload.filters.from
        ? action.payload.filters.to
          ? `&from=${action.payload.filters.from}T00:00:00&to=${action.payload.filters.to}T23:59:59`
          : `&from=${action.payload.filters.from}T00:00:00&to=3000-12-31T23:59:59`
        : action.payload.filters.to
        ? `&from=2000-01-01T00:00:00&to=${action.payload.filters.to}T23:59:59`
        : "") +
      (action.payload.filters.feedback
        ? `&feedback=${action.payload.filters.feedback}`
        : "") +
      (action.payload.filters.experience
        ? `&experience=${action.payload.filters.experience}`
        : `&experience=0`)
    : "";
  const isSort = sort ? `&sort=${sort}` : "";
  try {
    const res = yield INTERVIEW.get(
      `admin/interviews${isPageAndPageSize}${isSearch}${isfilter}${isProduct}${isSort}`
    );
    if (res.status === 200) {
      yield put(getInterviewSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getInterviewFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* getDashboardStatsRequest(action) {
  const product = action.payload.productId
    ? `?productId=${action.payload.productId}`
    : "";
  try {
    const res = yield INTERVIEW.get(
      `admin/interviews/dashboard-report${product}`
    );
    if (res.status === 200) {
      yield put(getDashboardStatsSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getDashboardStatsFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* getFeedbackReportRequest(action) {
  try {
    const res = yield INTERVIEW.get(
      `admin/interviews/shortlist-feedback-report?interviewId=${action.payload.id}`
    );
    if (res.status === 200) {
      yield put(getFeedbackReportSuccess(res.data?.result));
      yield call(action.payload.callback, res.data?.result);
    }
  } catch (e) {
    yield put(getFeedbackReportFailure());
    yield e?.response?.data?.message &&
      toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* getPanelistRequest(action) {
  const isPage = action.payload.page ? `?page=${action.payload.page}` : "";
  const isProductId = action.payload.productId
    ? `&product=${action.payload.productId}`
    : "";
  const isSort = action.payload.sort ? `&sort=${action.payload.sort}` : "";
  const isSearch = action.payload.search
    ? `&search=${encodeURIComponent(action.payload.search)}`
    : "";
  const isPageSize = action.payload.pageSize
    ? `&pageSize=${action.payload.pageSize}`
    : "";
  try {
    const res = yield INTERVIEW.get(
      `admin/interviews/panelist${isPage}${isPageSize}${isSort}${isSearch}${isProductId}`
    );
    if (res.status) {
      yield put(getPanelistSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getPanelistFailure());
    yield e?.response?.data?.message &&
      toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* updatePanelistRequest(action) {
  const formData = {
    name: action.payload.data.name,
    email: action.payload.data.email,
    product: action.payload.productId,
    department: action.payload.data.department,
    phone: action.payload.data.phone,
    designation: action.payload.data.designation,
    designation: action.payload.data.designation,
    experience: action.payload.data.experience,
  };
  try {
    const res = yield INTERVIEW.post(
      `admin/interviews/panelist/${action?.payload?.data?._id}`,
      formData
    );
    if (res.status === 200 || res.status === 201) {
      yield put(updatePanelistSuccess(res?.data?.result));
      yield toast.success("Panelist updated successfully.");
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(updatePanelistFailure());
    yield e?.response?.data?.message &&
      toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* notifyPanelistRequest(action) {
  try {
    const res = yield CALENDAR.post(
      `calendar/requestSlot/interviewer`,
      action.payload.data
    );
    if (res.status === 201) {
      yield put(notifyPanelistSuccess(res.data?.result));
      yield toast.success(<Toast msg="Panelist notified successfully." />);
    }
  } catch (e) {
    yield put(notifyPanelistFailure());
  }
}
function* updatePositionListRequest(action) {
  try {
    let res;
    action.payload.data.domain = "6231ce3477c3bfeada4c62de";
    if (action?.payload?.id) {
      res = yield INTERVIEW.put(
        `admin/positionlists/${action?.payload?.id}`,
        action.payload.data
      );
    } else {
      res = yield INTERVIEW.post("admin/positionlists", action.payload.data);
    }
    if (res.status === 201 || res.status === 200) {
      yield put(updatePositionListSuccess());
      yield call(action.payload.callback);
      yield toast.success(
        <Toast
          msg={`Position ${
            action?.payload?.id ? "edited" : "created"
          } successfully.`}
        />
      );
    }
  } catch (e) {
    yield put(updatePositionListFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* createPositionRequest(action) {
  try {
    let res;
    const formData = new FormData();
    formData.append("title", action.payload.data.title);
    if (action?.payload?.data?.positionType === "Client Hiring") {
      formData.append("clientName", action.payload.data.clientName);
    }
    formData.append("domain", action.payload.data.domain);
    formData.append("expRange", JSON.stringify(action.payload.data.expRange));
    formData.append("jobDescription", action.payload.data.jobDescription);
    if (action.payload.data.feedbackFile) {
      formData.append(
        "feedbackFile",
        action.payload.data.feedbackFile ? action.payload.data.feedbackFile : ""
      );
    }
    formData.append("remarks", action.payload.data.remarks);
    formData.append("user", action.payload.data.user);
    formData.append("positionType", action.payload.data.positionType);
    formData.append("feedbackType", action.payload.data.feedbackType);
    formData.append("skills", JSON.stringify(action.payload.data.skills));
    formData.append(
      "customSkills",
      JSON.stringify(action.payload.data.customSkills)
    );
    formData.append(
      "screeningQuestions",
      JSON.stringify(action.payload.data.screeningQuestions)
    );
    formData.append(
      "customScreeningQuestions",
      JSON.stringify(action.payload.data.customScreeningQuestions)
    );
    formData.append(
      "customField",
      JSON.stringify(
        action.payload.data.customField ? action.payload.data.customField : []
      )
    );
    if (action?.payload?.id) {
      res = yield INTERVIEW.put(
        `admin/incserve/positions/${action?.payload?.id}`,
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      );
    } else {
      res = yield INTERVIEW.post("admin/incserve/positions", formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });
    }
    if (res.status === 201 || res.status === 200) {
      yield put(createOrUpdatePositionSuccess());
      yield call(action.payload.callback);
      yield toast.success(<Toast msg="Position created successfully." />);
    }
  } catch (e) {
    yield put(createOrUpdatePositionFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* getPositionRequest(action) {
  const isSearch = action.payload.search
    ? `&search=${encodeURIComponent(action.payload.search)}`
    : "";
  const isSort = action.payload.sort ? `&sort=${action.payload.sort}` : "";
  const isPageAndPageSize =
    action.payload.page && action.payload.pageSize
      ? `?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
      : "";
  const productId = action.payload.productId
    ? `&product=${action.payload.productId}`
    : "";
  try {
    const res = yield INTERVIEW.get(
      `admin/positions${isPageAndPageSize}${isSort}${isSearch}${productId}`
    );
    if (res.status === 200) {
      yield put(getPositionSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getPositionFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* getPositionListRequest(action) {
  const isFilter = action.payload.filters
    ? (action.payload.filters.from
        ? `&from=${action.payload.filters.from}T00:00:00`
        : "") +
      (action.payload.filters.to
        ? `&to=${action.payload.filters.to}T23:59:59`
        : "")
    : "";
  const isSearch = action?.payload?.search
    ? `&search=${encodeURIComponent(action.payload.search)}`
    : "";
  const isSort = action?.payload?.sort ? `&sort=${action?.payload?.sort}` : "";
  const isPageAndPageSize =
    action?.payload?.page && action?.payload?.pageSize
      ? `?page=${action?.payload?.page}&pageSize=${action?.payload?.pageSize}`
      : "";
  try {
    const res = yield INTERVIEW.get(
      `admin/positionlists/positionlist${isPageAndPageSize}${isFilter}${isSort}${isSearch}`
    );
    if (res.status === 200) {
      yield put(getPositionListSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getPositionListFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* getDropdownPositionRequest(action) {
  try {
    const res = yield INTERVIEW.get(
      `admin/incserve/positions?user=${action?.payload?._id}`
    );
    if (res.status === 200) {
      yield put(getDropdownPositionListSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getDropdownPositionListFailure());
    yield e?.response?.data?.message &&
      toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* getPredefinedPositionListRequest(action) {
  try {
    const res = yield INTERVIEW.get(`admin/positionlists`);
    if (res.status === 200) {
      yield put(getPredefinedPositionListSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getPredefinedPositionListFailure());
  }
}
function* deletePositionRequest(action) {
  try {
    const res = yield INTERVIEW.delete(
      `admin/positionlists/${action.payload.deleteId}`
    );
    if (res.status === 204) {
      yield put(deletePositionSuccess());
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(deletePositionFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* getEmployerInterviewDetailsReuest(action) {
  const isProductId = action?.payload?.productId
    ? `&productId=${action?.payload?.productId}`
    : "";
  const isUserId = action?.payload?.userId
    ? `&user=${action?.payload?.userId}`
    : "";
  const isSearch = action.payload.search
    ? `&search=${action.payload.search}`
    : "";
  const isPageAndPageSize =
    action.payload.page && action.payload.pageSize
      ? `?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
      : "";
  const isSort = action.payload.filters
    ? action.payload.filters.sort
      ? `&sort=${action.payload.filters.sort}`
      : action.payload.sort
      ? `&sort=${action.payload.sort}`
      : ""
    : action.payload.sort
    ? `&sort=${action.payload.sort}`
    : "";
  const isFilter = action.payload.filters
    ? (action.payload.filters.status
        ? `&status=${action.payload.filters.status}`
        : "") +
      (action.payload.filters.from
        ? action.payload.filters.to
          ? `&from=${action.payload.filters.from}T00:00:00&to=${action.payload.filters.to}T23:59:59`
          : `&from=${action.payload.filters.from}T00:00:00&to=3000-12-31T23:59:59`
        : action.payload.filters.to
        ? `&from=2000-01-01T00:00:00&to=${action.payload.filters.to}T23:59:59`
        : "") +
      (action.payload.filters.feedback
        ? `&feedback=${action.payload.filters.feedback}`
        : "") +
      (action.payload.filters.experience
        ? `&experience=${action.payload.filters.experience}`
        : `&experience=0`) +
      (action.payload.filters.createdBy
        ? `&createdBy=${action.payload.filters.createdBy}`
        : "") +
      (action.payload.filters.requestedSlots
        ? `&requestedSlots=${action.payload.filters.requestedSlots}`
        : "")
    : "";
  try {
    const res = yield INTERVIEW.get(
      `admin/interviews${isPageAndPageSize}${isProductId}${isUserId}${isSearch}${isFilter}${isSort}`
    );
    if (res.status === 200) {
      yield put(getEmployerInterviewDetailsSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getEmployerInterviewDetailsFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* getEmployerPositionDetailsRequest(action) {
  const isProductId = action.payload.productId
    ? `&product=${action.payload.productId}`
    : "";
  const isUserId = action.payload.userId
    ? `&user=${action.payload.userId}`
    : "";
  const isSearch = action.payload.search
    ? `&search=${encodeURIComponent(action.payload.search)}`
    : "";
  // const isSort = action.payload.filters
  //   ? action.payload.filters.sort
  //     ? `&sort=${action.payload.filters.sort}`
  //     : action.payload.sort
  //     ? `&sort=${action.payload.sort}`
  //     : ""
  //   : action.payload.sort
  //   ? `&sort=${action.payload.sort}`
  //   : "";
  const isSort = action.payload.sort ? `&sort=${action.payload.sort}` : "";
  const isPageAndPageSize =
    action.payload.page && action.payload.pageSize
      ? `?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
      : "";
  const isfilter = action.payload.filters.from
    ? action.payload.filters.to
      ? `&from=${action.payload.filters.from}T00:00:00&to=${action.payload.filters.to}T23:59:59`
      : `&from=${action.payload.filters.from}T00:00:00&to=3000-12-31T23:59:59`
    : action.payload.filters.to
    ? `&from=2000-01-01T00:00:00&to=${action.payload.filters.to}T23:59:59`
    : "" +
      (action.payload.filters.createdBy
        ? `&createdBy=${action.payload.filters.createdBy}`
        : "") +
      (action.payload.filters.experience
        ? `&experience=${action.payload.filters.experience}`
        : "");
  try {
    const res = yield INTERVIEW.get(
      `admin/positions${isPageAndPageSize}${isProductId}${isUserId}${isSearch}${isfilter}${isSort}`
    );
    if (res.status === 200) {
      yield put(getEmployerPositionDetailsSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getEmployerPositionDetailsFailure());
    yield e?.response?.data?.message &&
      e?.response?.data?.message !==
        "Your session has been expired, please login again!" &&
      toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* incserveCalendarInviteRequest(action) {
  try {
    let res;
    delete action.payload.data.edit;
    res = yield INTERVIEW.post(
      `admin/incserve/interviews/${action?.payload?.id}/IncServeCalendar-invite`,
      action.payload.data
    );
    if (res.status === 200 || res.status === 201) {
      // yield toast.success(
      //   <Toast msg={"Calendar Invite Request Sent Successfully"} />
      // );
      localStorageEncrypt(
        "activeMeetLink",
        res?.data?.result?.meetInviteDetails?.meetingLink
      );
      localStorageRemove("interviewId");
      yield put(incserveCalendarInviteSuccess());
      yield call(action.payload.callback);
    }
  } catch (e) {
    if (e?.response?.status === 500) {
      yield call(action.payload.callBack, e?.response?.status);
    } else if (e?.response?.status === 400) {
      yield toast.error(
        <Toast msg={"There is some issue. Please try again."} />
      );
    } else {
      yield put(incserveCalendarInviteFailure());
      yield e?.response?.data?.message &&
        toast.error(<Toast msg={e?.response?.data?.message} />);
    }
  }
}

function* incexitCalendarInviteRequest(action) {
  try {
    let res;
    delete action.payload.data.edit;
    res = yield INTERVIEW.post(
      `admin/incexit/interviews/${action?.payload?.id}/IncExitCalendar-invite`,
      action.payload.data
    );
    if (res.status === 200 || res.status === 201) {
      localStorageEncrypt(
        "activeMeetLink",
        res?.data?.result?.meetInviteDetails?.meetingLink
      );
      localStorageRemove("interviewId");
      yield put(incexitCalendarInviteSuccess());
      yield call(action.payload.callback);
      yield toast.success(
        <Toast msg={"Calendar Invite Request Sent Successfully"} />
      );
    }
  } catch (e) {
    if (e?.response?.status === 500) {
      yield call(action.payload.callBack, e?.response?.status);
    } else {
      yield put(incexitCalendarInviteFailure());
      yield e?.response?.data?.message &&
        toast.error(<Toast msg={e?.response?.data?.message} />);
    }
  }
}

function* getCustomFileDetailsUsingResumeRequest(action) {
  let formData = new FormData();
  formData.append("file", action.payload.file);
  try {
    const res = yield INTERVIEW.post(
      `interviews/customField/fileUpload`,
      formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
    if (res.status === 201 || res.status === 200) {
      let result = { ...res.data.result, index: action.payload.index };
      yield put(addCustomFileSuccess(result));
    }
  } catch (e) {
    yield put(addCustomFileFailure());
    yield e?.response?.data?.message &&
      e?.response?.data?.message !==
        "Your session has been expired, please login again!" &&
      toast.error(
        <Toast msg={"Could not read file, Please try uploading a PDF file."} />
      );
  }
}

function* getIncserveInterviewsCountRequest(action) {
  try {
    let res;
    if (action.payload.type === "incexit") {
      res = yield INTERVIEW.get(`/admin/incexit/interviews/list/count`);
    } else if (action.payload.type === "incServe") {
      res = yield INTERVIEW.get(`/admin/incserve/interviews/list/count`);
    }
    if (res.status === 200) {
      yield put(getInterviewsCountSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getInterviewsCountFailure());
  }
}

function* getCalendarEventRequest(action) {
  const Date = action?.payload?.data?.date ? action?.payload?.data?.date : "";
  const Interviewer = action?.payload?.data?.interviewer
    ? action?.payload?.data?.interviewer
    : "";
  try {
    let res;
    res = yield INTERVIEW.get(
      `/admin/interviewers/availableTimeSlots?interviewer=${Interviewer}&date=${Date}T00:00:00.000Z`
    );

    if (res.status === 200) {
      yield put(getCalendarEventSuccess(res.data.result));
      yield call(action?.payload?.callback(res));
    }
  } catch (e) {
    yield put(getCalendarEventFailure());
  }
}

function* getEventsListRequest(action) {
  const from = action.payload.filterStates.from
    ? `&from=${action.payload.filterStates.from}T00:00:00`
    : "";
  const to = action.payload.filterStates.to
    ? `&to=${action.payload.filterStates.to}T23:59:59`
    : "";
  const employer = action.payload.filterStates.user
    ? `&employer=${action.payload.filterStates.user}`
    : "";
  const isSearch = action.payload.search
    ? `&search=${action.payload.search}`
    : "";

  const isPageAndPageSize =
    action.payload.page && action.payload.pageSize
      ? `?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
      : "";
  const isSort = action.payload.sort ? `&sort=${action.payload.sort}` : "";
  try {
    const res = yield INTERVIEW.get(
      `/admin/incserve/interviews/eventTracking${isPageAndPageSize}${isSort}${isSearch}${from}${to}${employer}`
    );
    if (res.status === 200 || res.status === 201) {
      if (action?.payload?.page === "all") {
        yield put(getEventsListFailure());
        yield call(action.payload.callback, res.data.result);
      } else {
        yield put(getEventsListSuccess(res.data.result));
      }
    }
  } catch (e) {
    yield put(getEventsListFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* updateFeedbackRequest(action) {
  let updateFeedback = action?.payload?.data;
  if (!updateFeedback?.feedback?.resumeSummary)
    delete updateFeedback?.feedback?.resumeSummary;
  if (!updateFeedback?.feedback?.remarks)
    delete updateFeedback?.feedback?.remarks;
  delete updateFeedback?.feedback?.transcript;
  try {
    const res = yield INTERVIEW.put(
      `/admin/incbot/updateFeedback/${updateFeedback?._id}`,
      { feedback: { ...updateFeedback?.feedback } }
    );
    if (res.status === 200) {
      yield put(updateFeedbackSuccess());
      yield call(action?.payload?.onSuccess, res?.data?.result?._id);
    }
  } catch (e) {
    yield put(updateFeedbackFailure());
  }
}

function* fetchResumeSummaryRequest(action) {
  try {
    const res = yield INTERVIEW.post(`/admin/incbot/resumeSummary`, {
      resume: action?.payload?.resume,
    });
    if (res.status === 200) {
      yield put(fetchResumeSummarySuccess());
      yield call(action?.payload?.onSuccess, res?.data);
    }
  } catch (e) {
    yield put(fetchResumeSummaryFailure());
  }
}

function* fetchTranscriptRequest(action) {
  try {
    const res = yield AI.post("/incbot/trancribe-url", {
      url: action?.payload?.url,
    });
    if (res.status === 201) {
      yield put(fetchResumeSummarySuccess());
      yield call(action?.payload?.onSuccess, res?.data?.result?.transcript);
    }
  } catch (e) {
    yield put(fetchResumeSummaryFailure());
  }
}

function* fetchAiCommentRequest(action) {
  try {
    const res = yield AI.post("/incbot/evaluatorResponse", action.payload.data);
    if (res.status === 201) {
      yield put(fetchAiCommentSuccess());
      yield call(action?.payload?.onSuccess, res?.data?.result);
    }
  } catch (e) {
    yield put(fetchAiCommentFailure());
  }
}

function* fetchAiCommentForCodeRequest(action) {
  try {
    const res = yield INTERVIEW.post(
      "/admin/incbot/performCodeEvaluation",
      action.payload.data
    );
    if (res.status === 201) {
      yield put(fetchAiCommentForCodeSuccess());
      yield call(action?.payload?.onSuccess, res?.data?.result);
    }
  } catch (e) {
    yield put(fetchAiCommentForCodeFailure());
  }
}

function* fetchFinalRemarksRequest(action) {
  try {
    const res = yield INTERVIEW.post(
      "/admin/incbot/getFinalRemark",
      action.payload.data
    );
    if (res.status === 201) {
      yield put(fetchAiCommentSuccess());
      yield call(action?.payload?.onSuccess, res?.data?.result?.data);
    }
  } catch (e) {
    yield put(fetchAiCommentFailure());
  }
}

function* setNetworkDisconnectedRequest(action) {
  const { id, callback } = action?.payload;
  try {
    const res = yield INTERVIEW.patch(
      `/admin/incbot/changeInterviewStatus/${id}`
    );
    if (res.status === 201) {
      yield put(setNetworkDisconnectedSuccess());
      yield call(callback);
      yield toast.success(
        <Toast
          msg={`Interview status changed to "Network Disconnected" successfully`}
        />
      );
    }
  } catch (e) {
    yield put(setNetworkDisconnectedFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

export function* watchSetNetworkDisconnectedAPI() {
  yield takeEvery(SET_NETWORK_DISCONNECTED, setNetworkDisconnectedRequest);
}

export function* watchFeedbackApprovalRequestAPI() {
  yield takeEvery(FEEDBACK_APPROVAL, feedbackApprovalRequest);
}
export function* watchGetEventsListRequestAPI() {
  yield takeEvery(GET_EVENTS_LIST, getEventsListRequest);
}

export function* watchGetEmployerPositionDetailsRequestAPI() {
  yield takeEvery(
    GET_EMPLOYER_POSITION_DETAILS,
    getEmployerPositionDetailsRequest
  );
}
export function* watchGetIncserveInterviewsCountRequestAPI() {
  yield takeEvery(GET_INTERVIEWS_COUNT, getIncserveInterviewsCountRequest);
}
export function* watchGetCalendarEventRequestAPI() {
  yield takeEvery(GET_CALENDAR_EVENT, getCalendarEventRequest);
}
export function* watchGetEmployerInterviewDetailsRequestAPI() {
  yield takeEvery(
    GET_EMPLOYER_INTERVIEW_DETAILS,
    getEmployerInterviewDetailsReuest
  );
}

export function* watchVerifyInterviewerRequestAPI() {
  yield takeEvery(VERIFY_INTERVIEWER, verifyInterviewerRequest);
}

export function* watchInterviewerAccountDetailsListRequestAPI() {
  yield takeEvery(
    INTERVIEWER_ACCOUNT_DETAILS_LIST,
    interviewerAccountDetailsListRequest
  );
}

export function* watchGetDropdownPositionRequestAPI() {
  yield takeEvery(GET_POSITIONS_DROPDOWN, getDropdownPositionRequest);
}

export function* watchGetApiCallLogsRequestAPI() {
  yield takeEvery(GET_API_CALL_LOGS, getApiCallLogsRequest);
}

export function* watchAllowInterviewerRequestAPI() {
  yield takeEvery(ALLOW_INTERVIEWER, allowInterviewerRequest);
}
export function* watchChangePlatformRequestAPI() {
  yield takeEvery(CHANGE_PLATFORM, changePlatformRequest);
}

export function* watchCallInterviewerAndCandidateRequestAPI() {
  yield takeEvery(
    CALL_INTERVIEWER_AND_CANDIDATE,
    callInterviewerAndCandidateRequest
  );
}

export function* watchChangeInterviewerStatusRequestAPI() {
  yield takeEvery(CHANGE_INTERVIEWER_STATUS, changeInterviewerStatusRequest);
}
export function* watchResendEmailVerificationRequestAPI() {
  yield takeEvery(RESEND_EMAIL_VERIFICATION, resendEmailVerificationRequest);
}
export function* watchGetIncservePositionRequestAPI() {
  yield takeEvery(GET_INCSERVE_POSITIONS, getIncservePositionRequest);
}
export function* watchGetIncbotPositionRequestAPI() {
  yield takeEvery(GET_INCBOT_POSITIONS, getIncbotPositionRequest);
}
function* watchGetIncbotInterviewsRequestAPI() {
  yield takeEvery(GET_INCBOT_INTERVIEWS, getIncbotInterviewsRequest);
}
export function* watchGetIncserveRequirementsRequestAPI() {
  yield takeEvery(GET_INCSERVE_REQUIREMENTS, getIncserveRequirementsRequest);
}
export function* watchGetIncserveSingleDeleteRequirementsRequestAPI() {
  yield takeEvery(
    GET_INCSERVE_SINGLE_DELETE_LIST,
    getIncserveSingleDeleteRequirementsRequest
  );
}
export function* watchGetIncserveBulkDeleteRequirementsRequestAPI() {
  yield takeEvery(
    GET_INCSERVE_BULK_DELETE_LIST,
    getIncserveBulkDeleteRequirementsRequest
  );
}

export function* watchUpdateInterviewerEduAndSkillSetAPI() {
  yield takeEvery(
    UPDATE_INTERVIEWER_EDU_AND_SKILL_SET,
    updateInterviewerEduAndSkillSetRequest
  );
}
export function* watchGetInterviewerAdminRemarksInfoAPI() {
  yield takeEvery(
    GET_INTERVIEWER_ADMIN_REMARKS_INFO,
    getInterviewerAdminRemarksInfoRequest
  );
}

export function* watchGetMeetingDetailsAPI() {
  yield takeEvery(GET_MEETING_DETAILS, getMeetingDetailsInfoRequest);
}
export function* watchPostMeetingDetailsAPI() {
  yield takeEvery(POST_MEETING_DETAILS, postMeetingDetailsInfoRequest);
}

export function* watchPutInterviewerMockRemarksInfoAPI() {
  yield takeEvery(
    PUT_INTERVIEWER_MOCK_REMARKS_INFO,
    putInterviewerMockRemarksInfoRequest
  );
}
export function* watchGetInterviewerAudioLogsRequestAPI() {
  yield takeEvery(GET_INTERVIEWER_AUDIO_LOGS, getInterviewerAudioLogsRequest);
}

export function* watchGetInterviewerRatingsRequestAPI() {
  yield takeEvery(GET_INTERVIEWER_RATINGS, getInterviewerRatingsRequest);
}
export function* watchUpdateInterviewerRatingsRequestAPI() {
  yield takeEvery(UPDATE_INTERVIEWER_RATINGS, updateInterviewerRatingsRequest);
}

export function* watchUpdateInterviewerAdminRemarksInfoRequestAPI() {
  yield takeEvery(
    UPDATE_INTERVIEWER_ADMIN_REMARKS_INFO,
    updateInterviewerAdminRemarksInfoRequest
  );
}
export function* watchGetInterviewerUserInformationAPI() {
  yield takeEvery(
    GET_INTERVIEWER_USER_INFORMATION,
    getInterviewerUserInformationRequest
  );
}
export function* watchUpdateInterviewerUserInformationAPI() {
  yield takeEvery(
    UPDATE_INTERVIEWER_USER_INFORMATION,
    updateInterviewerUserInformationRequest
  );
}
export function* watchInterviewerBankNameListAPI() {
  yield takeEvery(
    GET_INTERVIEWER_BANK_NAME_LIST,
    getInterviewerBankNameListRequest
  );
}
export function* watchUpdateIncExitInterviewsAPI() {
  yield takeEvery(UPDATE_INCEXIT_INTERVIEW, updateIncExitInterviewsRequest);
}
export function* watchCreateIncExitInterviewsAPI() {
  yield takeEvery(CREATE_INCEXIT_INTERVIEW, createIncExitInterviewsRequest);
}
export function* watchAddIncExitFeedbackAPI() {
  yield takeEvery(ADD_INCEXIT_FEEDBACK, addIncExitFeedbackRequest);
}
export function* watchAllotIncExitInterviewerAPI() {
  yield takeEvery(ALLOT_INCEXIT_INTERVIEWER, allotIncExitInterviewerRequest);
}
export function* watchScheduleIncExitInterviewAPI() {
  yield takeEvery(SCHEDULE_INCEXIT_INTERVIEW, scheduleIncExitInterviewRequest);
}
export function* watchReScheduleIncExitInterviewAPI() {
  yield takeEvery(
    RESCHEDULE_INCEXIT_INTERVIEW,
    reScheduleIncExitInterviewRequest
  );
}
export function* watchRespondReScheduleIncExitInterviewAPI() {
  yield takeEvery(
    RESPOND_RESCHEDULE_INCEXIT_INTERVIEW,
    respondReScheduleIncExitInterviewRequest
  );
}
export function* watchBulkIncExitInterviewAPI() {
  yield takeEvery(BULK_INCEXIT_INTERVIEW, getBulkInterviewListRequest);
}
export function* watchGetIncserveBulkInterviewListRequestAPI() {
  yield takeEvery(
    BULK_INCSERVE_REQUIREMENT,
    getIncserveBulkInterviewListRequest
  );
}
export function* watchAcceptBulkIncExitInterviewAPI() {
  yield takeEvery(ACCEPT_BULK_INCEXIT_INTERVIEW, acceptBulkRequirementsRequest);
}
export function* watchAcceptBulkIncserveRequirementsRequestAPI() {
  yield takeEvery(
    ACCEPT_BULK_INCSERVE_REQUIREMENT,
    acceptBulkIncserveRequirementsRequest
  );
}
export function* watchCloseBulkIncserveRequirementsRequestAPI() {
  yield takeEvery(
    CLOSE_BULK_INCSERVE_REQUIREMENT,
    closeBulkIncserveRequirementsRequest
  );
}

export function* watchCloseBulkIncExitInterviewAPI() {
  yield takeEvery(CLOSE_BULK_INCEXIT_INTERVIEW, closeBulkRequirementsRequest);
}
export function* watchUpdateBulkIncExitInterviewAPI() {
  yield takeEvery(UPDATE_BULK_INCEXIT_INTERVIEW, updateBulkRequirementsRequest);
}

export function* watchAddOrEditInterviewerAccountDetailsAPI() {
  yield takeEvery(
    ADD_OR_EDIT_INTERVIEWER_ACCOUNT_DETAILS,
    addOrEditInterviewerAccountDetailsRequest
  );
}

export function* watchInterviewerAccountDetailsAPI() {
  yield takeEvery(
    GET_INTERVIEWER_ACCOUNT_DETAILS,
    getInterviewerAccountDetailsRequest
  );
}
export function* watchGetInterviewAPI() {
  yield takeEvery(GET_INTERVIEW, getInterviewRequest);
}
export function* watchGetInterviewerAPI() {
  yield takeEvery(GET_INTERVIEWER_LIST, getInterviewersRequest);
}

export function* watchGetInvoiceDetailsRequestAPI() {
  yield takeEvery(GET_INVOICE_DETAILS, getInvoiceDetailsRequest);
}

export function* watchGetInvoiceDetailsByMonthRequestAPI() {
  yield takeEvery(
    GET_INVOICE_DETAILS_BY_MONTH,
    getInvoiceDetailsByMonthRequest
  );
}

export function* watchGetDashboardStatsAPI() {
  yield takeEvery(DASHBOARD_STATS, getDashboardStatsRequest);
}
export function* watchGetFeedbackReportAPI() {
  yield takeEvery(GET_FEEDBACK_REPORT, getFeedbackReportRequest);
}
export function* watchGetPanelistRequest() {
  yield takeEvery(GET_PANELIST, getPanelistRequest);
}
export function* watchNotifyPanelistRequest() {
  yield takeEvery(NOTIFY_PANELIST, notifyPanelistRequest);
}
export function* watchUpdatePanelistRequest() {
  yield takeEvery(UPDATE_PANELIST, updatePanelistRequest);
}
export function* watchCreateOrUpdatePositionAPI() {
  yield takeEvery(CREATE_OR_UPDATE_POSITION, createPositionRequest);
}
export function* watchCreateOrUpdatePositionListAPI() {
  yield takeEvery(UPDATE_POSITION_LIST, updatePositionListRequest);
}
export function* watchGetPositionAPI() {
  yield takeEvery(GET_POSITION, getPositionRequest);
}
export function* watchGetPredefinedPositionAPI() {
  yield takeEvery(
    GET_PREDEFINED_POSITION_LIST,
    getPredefinedPositionListRequest
  );
}
export function* watchGetPositionListAPI() {
  yield takeEvery(GET_POSITION_LIST, getPositionListRequest);
}
export function* watchDeletePositionAPI() {
  yield takeEvery(DELETE_POSITION, deletePositionRequest);
}
export function* watchDeleteIncservePositionAPI() {
  yield takeEvery(DELETE_INCSERVE_POSITIONS, deleteIncservePositionRequest);
}
export function* watchCreateOrUpdateIncservePositionRequestAPI() {
  yield takeEvery(
    CREATE_OR_UPDATE_INCSERVE_POSITIONS,
    createOrUpdateIncservePositionRequest
  );
}
export function* watchMappedIncserveInterviewersRequestAPI() {
  yield takeEvery(MAPPED_INTERVIEWERS, mappedIncserveInterviewersRequest);
}
export function* watchGetIncserveInterviewsRequestAPI() {
  yield takeEvery(GET_INCSERVE_INTERVIEWS, getIncserveInterviewsRequest);
}
export function* watchGetIncserveInterviewsSearchListRequestAPI() {
  yield takeEvery(
    GET_INCSERVE_INTERVIEWS_SEARCH_LIST,
    getIncserveInterviewsSearchListRequest
  );
}

export function* watchGetIncbotInterviewsSearchListRequestAPI() {
  yield takeEvery(
    GET_INCBOT_INTERVIEWS_SEARCH_LIST,
    getIncbotInterviewsSearchListRequest
  );
}

export function* watchGetIncexitInterviewsSearchListRequestAPI() {
  yield takeEvery(
    GET_INCEXIT_INTERVIEWS_SEARCH_LIST,
    getIncexitInterviewsSearchListRequest
  );
}

export function* watchGetIncExitInterviewsRequestAPI() {
  yield takeEvery(GET_INCEXIT_INTERVIEW, getIncExitInterviewsRequest);
}

export function* watchGetIncserveInterviewByIdRequestAPI() {
  yield takeEvery(
    GET_INCSERVE_INTERVIEWS_BY_ID,
    getIncserveInterviewByIdRequest
  );
}

export function* watchGetIncserveIncexitPreviouslyAllotedInterviewerListRequestAPI() {
  yield takeEvery(
    INCSERVE_INCEXIT_PREVIOUSLY_ALLOTED_INTERVIEWER_LIST,
    getIncserveIncexitPreviouslyAllotedInterviewerListRequest
  );
}
export function* watchGetCallLogsRequestAPI() {
  yield takeEvery(GET_CALL_LOGS, getCallLogsRequest);
}

export function* watchGetInterviewerCallLogsRequestAPI() {
  yield takeEvery(GET_INTERVIEWER_CALL_LOGS, getInterviewerCallLogsRequest);
}

export function* watchGetPreviouslyAllotedInterviewersListRequestAPI() {
  yield takeEvery(
    PREVIOUSLY_ALLOTED_INTERVIEWER_LIST,
    getPreviouslyAllotedInterviewerListRequest
  );
}
export function* watchGetDesignationListRequestAPI() {
  yield takeEvery(DESIGNATION_LIST, getDesignatiomListRequest);
}

export function* watchCreateOrUpdateIncserveInterviewsRequestAPI() {
  yield takeEvery(
    CREATE_OR_UPDATE_INCSERVE_INTERVIEWS,
    createOrUpdateIncserveInterviewsRequest
  );
}

export function* watchGetIncservePositionByIdRequestAPI() {
  yield takeEvery(GET_INCSERVE_POSITIONS_BY_ID, getIncservePositionByIdRequest);
}
export function* watchAcceptIncExitInterviewAPI() {
  yield takeEvery(ACCEPT_INCEXIT_INTERVIEW, acceptIncExitInterviewRequest);
}

export function* watchCancelIncExitInterviewAPI() {
  yield takeEvery(CANCEL_INCEXIT_INTERVIEW, cancelIncexitInterviewRequest);
}

export function* watchGetAllInterviewersListRequestAPI() {
  yield takeEvery(GET_ALL_INTERVIEWERS, getAllInterviewersRequest);
}
export function* watchIncserveApproveFeedbackRequestAPI() {
  yield takeEvery(INCSERVE_APPROVE_FEEDBACK, approveIncserveFeedbackRequest);
}
export function* watchIncserveGiveFeedbackRequestAPI() {
  yield takeEvery(INCSERVE_GIVE_FEEDBACK, giveIncserveFeedbackRequest);
}

export function* watchPostIncserveReviewRequestRequestAPI() {
  yield takeEvery(
    INCSERVE_POST_REVIEW_REQUEST,
    postIncserveReviewRequestRequest
  );
}

export function* watchIncserveCancelInterviewkRequestAPI() {
  yield takeEvery(INCSERVE_CANCEL_INTERVIEW, cancelIncserveInterviewRequest);
}
export function* watchIncserveHoldInterviewRequestAPI() {
  yield takeEvery(INCSERVE_HOLD_INTERVIEW, holdIncserveInterviewRequest);
}

export function* watchIncexitFeedbackApprovekRequestAPI() {
  yield takeEvery(APPROVE_INCEXIT_FEEDBACK, approveIncExitFeedbackRequest);
}

export function* watchIncserveUpdatePayPerInterviewRequestAPI() {
  yield takeEvery(
    INCSERVE_UPDATE_PAY_PER_INTERVIEW,
    updatePayPerIncserveInterviewRequest
  );
}

export function* watchIncserveAcceptIncserveInterviewRequestAPI() {
  yield takeEvery(INCSERVE_ACCEPT_INTERVIEW, acceptIncserveInterviewRequest);
}

export function* watchIncserveAcceptRescheduleRequestRequestAPI() {
  yield takeEvery(
    INCSERVE_RESCHEDULED_ACCEPT_REQUEST,
    acceptRescheduleRequestRequest
  );
}
export function* watchUpdateAddQuestionRequestAPI() {
  yield takeEvery(UPDATE_ADD_QUESTION, updateAddQuestionRequest);
}
export function* watchVerifyLinkedinUrlRequestAPI() {
  yield takeEvery(VERIFY_LINKEDIN_URL, verifyLinkedinUrl);
}

export function* watchSendConversionReportRequestAPI() {
  yield takeEvery(SEND_CONVERSION_REPORT, sendConversionReport);
}

export function* watchUpdateIncserveAddQuestionRequestAPI() {
  yield takeEvery(
    UPDATE_INCSERVE_ADD_QUESTION,
    updateIncserveAddQuestionRequest
  );
}
export function* watchRequestForRescheduledRequestAPI() {
  yield takeEvery(
    INCSERVE_REQUEST_FOR_RESCHEDULED,
    requestForRescheduledRequest
  );
}

export function* watchRescheduleIncserveInterviewRequestAPI() {
  yield takeEvery(
    INCSERVE_RESCHEDULE_INTERVIEW,
    rescheduleIncserveInterviewRequest
  );
}
export function* watchGetSimilarWebsiteRequestAPI() {
  yield takeEvery(GET_SIMILAR_WEBSITE_LIST, getSimilarWebsiteRequest);
}
export function* watchAllotAndReallotInterviewerRequestAPI() {
  yield takeEvery(
    ALLOT_AND_REALLOT_INTERVIEWER,
    allotAndReallotInterviewerRequest
  );
}
export function* watchCreateOrUpdateIncserveBulkRequirementsRequestRequestAPI() {
  yield takeEvery(
    ADD_UPDATE_BULK_INCSERVE_REQUIREMENT,
    createOrUpdateIncserveBulkRequirementsRequest
  );
}
export function* watchUpdateIncserveInterviewFeedbackRatingRequestAPI() {
  yield takeEvery(
    UPDATE_INTERVIEW_FEEDBACK_RATING,
    updateIncserveInterviewFeedbackRatingRequest
  );
}
export function* watchGetIncserveInterviewersDetailsRequestAPI() {
  yield takeEvery(
    INCSERVE_INTERVIEWER_DETAILS,
    getIncserveInterviewersDetailsRequest
  );
}
export function* watchIncserveCalendarInviteRequestAPI() {
  yield takeEvery(INCSERVE_CALENDAR_INVITE, incserveCalendarInviteRequest);
}
export function* watchIncexitCalendarInviteRequestAPI() {
  yield takeEvery(INCEXIT_CALENDAR_INVITE, incexitCalendarInviteRequest);
}
export function* watchGetCustomFileDetailsUsingResumeRequestAPI() {
  yield takeEvery(ADD_CUSTOM_FILE, getCustomFileDetailsUsingResumeRequest);
}
export function* watchCreateEmployerPositionRequestAPI() {
  yield takeEvery(CREATE_EMPLOYER_POSITION, createEmployerPositionRequest);
}
export function* watchGetActiveIncbotEmployersRequestAPI() {
  yield takeEvery(GET_ACTIVE_INCBOT_EMPLOYER, getActiveIncbotEmployersRequest);
}

export function* watchUpdateCandidateRatingsRequestAPI() {
  yield takeEvery(UPDATE_CANDIDATE_RATINGS, updateCandidateRatingsRequest);
}
export function* watchUpdateApiCallLimitRequestAPI() {
  yield takeEvery(UPDATE_API_CALL_LOGS, updateApiCallLimitRequest);
}
export function* watchUpdateFeedbackRequestAPI() {
  yield takeEvery(UPDATE_FEEDBACK, updateFeedbackRequest);
}
export function* watchFetchResumeSummary() {
  yield takeEvery(FETCH_RESUME_SUMMARY, fetchResumeSummaryRequest);
}
export function* watchFetchTranscript() {
  yield takeEvery(FETCH_TRANSCRIPT, fetchTranscriptRequest);
}
export function* watchFetchAiCommentRequest() {
  yield takeEvery(FETCH_AI_COMMENT, fetchAiCommentRequest);
}

export function* watchFetchAiCommentRequestForCode() {
  yield takeEvery(FETCH_AI_COMMENT_FOR_CODE, fetchAiCommentForCodeRequest);
}

export function* watchFetchFinalRemarksRequest() {
  yield takeEvery(FETCH_FINAL_REMARKS, fetchFinalRemarksRequest);
}

export default function* rootSaga() {
  yield all([
    watchGetEventsListRequestAPI(),
    watchUpdateApiCallLimitRequestAPI(),
    watchGetApiCallLogsRequestAPI(),
    watchGetMeetingDetailsAPI(),
    watchPostMeetingDetailsAPI(),
    watchPutInterviewerMockRemarksInfoAPI(),
    watchUpdateCandidateRatingsRequestAPI(),
    watchInterviewerAccountDetailsListRequestAPI(),
    watchGetCalendarEventRequestAPI(),
    watchCreateEmployerPositionRequestAPI(),
    watchGetInvoiceDetailsByMonthRequestAPI(),
    watchGetIncserveInterviewsCountRequestAPI(),
    watchGetCustomFileDetailsUsingResumeRequestAPI(),
    watchRequestForRescheduledRequestAPI(),
    watchGetIncserveInterviewersDetailsRequestAPI(),
    watchUpdateIncserveInterviewFeedbackRatingRequestAPI(),
    watchMappedIncserveInterviewersRequestAPI(),
    watchCreateOrUpdateIncserveBulkRequirementsRequestRequestAPI(),
    watchCloseBulkIncserveRequirementsRequestAPI(),
    watchAcceptBulkIncserveRequirementsRequestAPI(),
    watchGetIncserveBulkInterviewListRequestAPI(),
    watchGetIncserveSingleDeleteRequirementsRequestAPI(),
    watchGetIncserveBulkDeleteRequirementsRequestAPI(),
    watchIncserveGiveFeedbackRequestAPI(),
    watchPostIncserveReviewRequestRequestAPI(),
    watchIncserveAcceptRescheduleRequestRequestAPI(),
    watchGetInvoiceDetailsRequestAPI(),
    watchAllotAndReallotInterviewerRequestAPI(),
    watchChangePlatformRequestAPI(),
    watchGetSimilarWebsiteRequestAPI(),
    watchCallInterviewerAndCandidateRequestAPI(),
    watchGetInterviewerRatingsRequestAPI(),
    watchUpdateInterviewerRatingsRequestAPI(),
    watchUpdateInterviewerAdminRemarksInfoRequestAPI(),
    watchRescheduleIncserveInterviewRequestAPI(),
    watchIncserveAcceptIncserveInterviewRequestAPI(),
    watchIncserveUpdatePayPerInterviewRequestAPI(),
    watchIncserveCancelInterviewkRequestAPI(),
    watchIncserveApproveFeedbackRequestAPI(),
    watchGetAllInterviewersListRequestAPI(),
    watchGetIncservePositionByIdRequestAPI(),
    watchCreateOrUpdateIncserveInterviewsRequestAPI(),
    watchGetIncserveInterviewByIdRequestAPI(),
    watchGetIncserveInterviewsRequestAPI(),
    watchGetIncserveInterviewsSearchListRequestAPI(),
    watchGetIncExitInterviewsRequestAPI(),
    watchCreateOrUpdateIncservePositionRequestAPI(),
    watchGetInterviewAPI(),
    watchGetInterviewerAPI(),
    watchGetDashboardStatsAPI(),
    watchGetFeedbackReportAPI(),
    watchGetPanelistRequest(),
    watchNotifyPanelistRequest(),
    watchUpdatePanelistRequest(),
    watchCreateOrUpdatePositionAPI(),
    watchGetPositionAPI(),
    watchGetPositionListAPI(),
    watchDeleteIncservePositionAPI(),
    watchDeletePositionAPI(),
    watchGetPredefinedPositionAPI(),
    watchCreateOrUpdatePositionListAPI(),
    watchAddOrEditInterviewerAccountDetailsAPI(),
    watchInterviewerAccountDetailsAPI(),
    watchInterviewerBankNameListAPI(),
    watchGetInterviewerUserInformationAPI(),
    watchUpdateInterviewerUserInformationAPI(),
    watchUpdateInterviewerEduAndSkillSetAPI(),
    watchGetInterviewerAdminRemarksInfoAPI(),
    watchGetIncservePositionRequestAPI(),
    watchGetIncserveRequirementsRequestAPI(),
    watchResendEmailVerificationRequestAPI(),
    watchChangeInterviewerStatusRequestAPI(),
    watchAllowInterviewerRequestAPI(),
    watchVerifyInterviewerRequestAPI(),
    watchGetDropdownPositionRequestAPI(),
    watchGetEmployerInterviewDetailsRequestAPI(),
    watchGetEmployerPositionDetailsRequestAPI(),
    watchAcceptIncExitInterviewAPI(),
    watchGetPreviouslyAllotedInterviewersListRequestAPI(),
    watchGetDesignationListRequestAPI(),
    watchUpdateIncExitInterviewsAPI(),
    watchCreateIncExitInterviewsAPI(),
    watchCancelIncExitInterviewAPI(),
    watchAllotIncExitInterviewerAPI(),
    watchScheduleIncExitInterviewAPI(),
    watchGetIncexitInterviewsSearchListRequestAPI(),
    watchBulkIncExitInterviewAPI(),
    watchAcceptBulkIncExitInterviewAPI(),
    watchAddIncExitFeedbackAPI(),
    watchCloseBulkIncExitInterviewAPI(),
    watchReScheduleIncExitInterviewAPI(),
    watchUpdateBulkIncExitInterviewAPI(),
    watchRespondReScheduleIncExitInterviewAPI(),
    watchIncserveCalendarInviteRequestAPI(),
    watchIncexitCalendarInviteRequestAPI(),
    watchIncexitFeedbackApprovekRequestAPI(),
    watchGetInterviewerAudioLogsRequestAPI(),
    watchGetIncserveIncexitPreviouslyAllotedInterviewerListRequestAPI(),
    watchGetCallLogsRequestAPI(),
    watchUpdateAddQuestionRequestAPI(),
    watchUpdateIncserveAddQuestionRequestAPI(),
    watchGetInterviewerCallLogsRequestAPI(),
    watchVerifyLinkedinUrlRequestAPI(),
    watchSendConversionReportRequestAPI(),
    watchGetIncbotInterviewsSearchListRequestAPI(),
    watchGetIncbotPositionRequestAPI(),
    watchGetIncbotInterviewsRequestAPI(),
    watchGetActiveIncbotEmployersRequestAPI(),
    watchIncserveHoldInterviewRequestAPI(),
    watchFeedbackApprovalRequestAPI(),
    watchUpdateFeedbackRequestAPI(),
    watchFetchResumeSummary(),
    watchFetchTranscript(),
    watchFetchAiCommentRequest(),
    watchFetchFinalRemarksRequest(),
    watchFetchAiCommentRequestForCode(),
    watchSetNetworkDisconnectedAPI(),
  ]);
}
