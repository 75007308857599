import React, { memo } from "react";
import onImage from "../../assets/images/permission-switch-on.svg";
import offImage from "../../assets/images/permission-switch-off.svg";

const PermissionsToggleSwitch = memo(
  ({ className, checked, onChange, disabled }) => {
    return (
      <label className={`permission-toggle-switch ${className}`}>
        {checked ? (
          <img src={onImage} alt="switch on" className="switch-on" />
        ) : (
          <img src={offImage} alt="switch off" className="switch-off" />
        )}
        <input
          type="checkbox"
          checked={checked}
          onChange={onChange}
          disabled={disabled}
        />
        <span className="switch" />
      </label>
    );
  }
);
export default PermissionsToggleSwitch;
