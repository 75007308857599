import React from "react";
import { all, call, takeEvery, put } from "redux-saga/effects";
import { toast } from "react-toastify";
import { persistor } from "../store";

import {
  convertIntoQueryString,
  Toast,
  localStorageEncrypt,
  secretKey,
  localStorageRemove,
  clearPersistData,
} from "../../utils/helper";
import {
  LOGIN_USER,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_TOKEN_VERIFY,
  RESET_PASSWORD,
  GET_EMPLOYER_LIST,
  SEND_EMAIL_VERIFICATION,
  GET_EMPLOYER_ROLE_LIST,
  DELETE_EMPLOYER_PROFILE,
  DELETE_EMPLOYER,
  RESTORE_PROFILE,
  EMPLOYER_SUBSCRIPTION,
  GET_CREDIT_HISTORY,
  UPDATE_CREDIT_HISTORY,
  UPDATE_SUBSCRIPTION,
  GET_ALL_EMPLOYERS,
  GET_SUB_USER,
  ADD_OR_EDIT_SUB_USER,
  DELETE_SUB_USER,
  RESEND_LINK,
  ACTIVE_INACTIVE_SUBUSER,
  GET_ROLES,
  ADD_OR_EDIT_ROLE,
  DELETE_ROLES,
  ACTIVE_INACTIVE_ROLE,
  GET_PERMISSION,
  ADD_ROLES,
  GET_ROLES_RESOURCES,
  GET_ALL_SUB_USER,
  ADD_OR_EDIT_EMPLOYER_SUB_USER,
  DELETE_EMPLOYER_SUB_USER,
  GET_RESOURCE_ACTIONS,
  GET_CONTACT_INQUIRY,
  GET_ANNOUNCEMENT_LIST,
  SEND_ANNOUNCEMENT_ITEM,
  DELETE_ANNOUNCEMENT,
  GET_SUPPORT_TICKET,
  UPDATE_SUPPORT_TICKET_STATUS,
  MANUALLY_VERIFY_EMAIL,
  SIGNUP_USER,
  GET_COMPANY_DETAILS,
  UPDATE_COMPANY_DETAILS,
  GET_USER_INFORMATION,
  UPDATE_USER_INFORMATION,
  GET_INDUSTRY_TYPE,
  GET_USER_LIST,
  EDIT_STATUS_CONTACT_INQUIRY,
  EDIT_USER_CONTACT_INQUIRY,
  EDIT_USER_SUPPORT,
  GET_REFER_AND_EARN_LIST,
  ADD_SETTLE_REFER,
  GET_REFER_AND_EARN_FILTER_EMPLOYER_LIST,
  SEND_SUBUSER_VERIFICATION_MAIL,
  GET_EMPLOYER_PERMISSION,
  GET_FAQ_LIST,
  GET_TAG_LIST,
  ADD_OR_EDIT_FAQ,
  DELETE_FAQ_LIST,
  GET_ACTIVE_EMPLOYER,
  CHANGE_PASSWORD,
  GET_WHITE_LABEL_DETAILS,
  LOGOUT_USER,
  GET_PRODUCT_PERMISSION,
  GET_PRODUCT_PERMISSION_CREDIT,
  UPDATE_PRODUCT_PERMISSION_CREDIT,
  GET_PRODUCT_PERMISSION_CREDIT_BY_ID,
  UPDATE_PRODUCT_PERMISSION,
  NOTIFY_CLIENT,
  GET_EMPLOYER_SUBUSER_DETAILS,
  ADD_PRODUCT_PERMISSION,
  NOTIFY_INCSERVE_CLIENT,
  DELETE_FAQS_LIST,
  GET_ALL_NOTIFICATIONS,
  READ_ALL_NOTIFICATIONS,
  DELETE_NOTIFICATION,
  CHANGE_EMPLOYER_STATUS,
  RESTORE_SUBUSER,
  GET_ACTIVE_INCBOT_EMPLOYER,
  SUB_ADMIN_PASSWORD_RESET,
  INCSERVE_EMPLOYER_ACCOUNT_LOGS,
  GENERATE_TWO_FACTOR_AUTHENTICATION_URI,
  VERIFY_TWO_FACTOR_AUTHENTICATION_URI,
  DISABLE_TWO_FACTOR_AUTHENTICATION_URI,
  TWO_FACTOR_AUTHENTICATION_STATUS,
  CHECK_TWO_FACTOR_AUTHENTICATION_STATUS,
  GET_MOCK_INTERVIEWS,
} from "../action/types";
import {
  getEmployerRoleListSuccess,
  getEmployerRoleListFailure,
  loginUserFailure,
  loginUserSuccess,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  forgotPasswordTokenVerifySuccess,
  forgotPasswordTokenVerifyFailure,
  resetPasswordSuccess,
  resetPasswordFailure,
  getEmployerListSuccess,
  getEmployerListFailure,
  getAllEmployersSuccess,
  getAllEmployersFailure,
  sendEmailVerificationSuccess,
  sendEmailVerificationFailure,
  deleteEmployerProfileSuccess,
  deleteEmployerProfileFailure,
  restoreEmployerProfileSuccess,
  restoreEmployerProfileFailure,
  permanentDeleteEmployerProfileSuccess,
  permanentDeleteEmployerProfileFailure,
  getEmployerSubscriptionsSuccess,
  getEmployerSubscriptionsFailure,
  getCreditHistorySuccess,
  getCreditHistoryFailure,
  updateCreditHistorySuccess,
  updateCreditHistoryFailure,
  putSubscriptionSuccess,
  putSubscriptionFailure,
  getSubUserSuccess,
  getSubUserFailure,
  addOrEditSubUserSuccess,
  addOrEditSubUserFailure,
  deleteSubUserSuccess,
  deleteSubUserFailure,
  getSupportTicketSuccess,
  getSupportTicketFailure,
  updateSupportTicketSuccess,
  updateSupportTicketFailure,
  resendLinkSuccess,
  resendLinkFailure,
  activeInactiveSubUserSuccess,
  activeInactiveSubUserFailure,
  getRolesSuccess,
  getRolesFailure,
  addOrEditRoleSuccess,
  addOrEditRoleFailure,
  deleteRoleSuccess,
  deleteRoleFailure,
  activeInactiveRoleSuccess,
  activeInactiveRoleFailure,
  getPermissionSuccess,
  getPermissionFailure,
  addRolesSuccess,
  addRolesFailure,
  getRolesResourcesFailure,
  getRolesResourcesSuccess,
  getAllSubusersFailure,
  getAllSubusersSuccess,
  deleteEmploySubUserSuccess,
  deleteEmploySubUserFailure,
  getResourceActionsSuccess,
  getResourceActionsFailure,
  addOrEditEmployerSubUserSuccess,
  addOrEditEmployerSubUserFailure,
  getContactInquirySuccess,
  getContactInquiryFailure,
  getAnnouncementListFailure,
  getAnnouncementListSuccess,
  sendAnnouncementItemSuccess,
  deleteAnnouncementSuccess,
  deleteAnnouncementFailure,
  signupUserSuccess,
  signupUserFailure,
  manuallyVerifyEmailSuccess,
  manuallyVerifyEmailFailure,
  getCompanyDetailsSuccess,
  getCompanyDetailsFailure,
  updateCompanyDetailsSuccess,
  updateCompanyDetailsFailure,
  getUserInformationSuccess,
  getUserInformationFailure,
  updateUserInformationSuccess,
  updateUserInformationFailure,
  getIndustryTypeSuccess,
  getIndustryTypeFailure,
  getUserListSuccess,
  getUserListFailure,
  editStatusContactInquirySuccess,
  editStatusContactInquiryFailure,
  editUserContactInquirySuccess,
  editUserContactInquiryFailure,
  editUserSupportSuccess,
  editUserSupportFailure,
  getReferAndEarnSuccess,
  getReferAndEarnFailure,
  addReferSettleRewardSuccess,
  addReferSettleRewardFailure,
  getReferAndEarnFilterSuccess,
  getReferAndEarnFilterFailure,
  sendSubuserVerificationMailSuccess,
  sendSubuserVerificationMailFailure,
  getEmployerPermissionSuccess,
  getEmployerPermissionFailure,
  getFaqListSuccess,
  getFaqListFailure,
  getTagListSuccess,
  getTagListFailure,
  addOrEditFaqSuccess,
  addOrEditFaqFailure,
  deleteFaqListSuccess,
  deleteFaqListFailure,
  getActiveEmployersSuccess,
  getActiveEmployersFailure,
  changePasswordSuccess,
  changePasswordFailure,
  getWhiteLabelDetailsSuccess,
  getWhiteLabelDetailsFailure,
  logoutUserSuccess,
  logoutUserFailure,
  getProductPermissionFailure,
  getProductPermissionSuccess,
  getProductPermissionCreditSuccess,
  getProductPermissionCreditFailure,
  updateProductPermissionCreditSuccess,
  updateProductPermissionCreditFailure,
  getProductPermissionCreditByIdSuccess,
  getProductPermissionCreditByIdFailure,
  updateProductPermissionSuccess,
  updateProductPermissionFailure,
  notifyClientSuccess,
  notifyClientFailure,
  getEmployerSubuserDetailsSuccess,
  getEmployerSubuserDetailsFailure,
  addProductPermissionSuccess,
  addProductPermissionFailure,
  notifyIncserveClientSuccess,
  notifyIncserveClientFailure,
  deleteFaqsListSuccess,
  deleteFaqsListFailure,
  getAllNotificationsSuccess,
  getAllNotificationsFailure,
  readAllNotificationsSuccess,
  readAllNotificationsFailure,
  deleteNotificationSuccess,
  deleteNotificationFailure,
  changeEmployerStatusSuccess,
  changeEmployerStatusFailure,
  restoreSubuserSuccess,
  restoreSubuserFailure,
  getActiveIncbotEmployersSuccess,
  getActiveIncbotEmployersFailure,
  subAdminPasswordResetFailure,
  subAdminPasswordResetSuccess,
  getEmployerAccountLogsSuccess,
  getEmployerAccountLogsFailure,
  generateTwoFactorAuthenticationUriSuccess,
  generateTwoFactorAuthenticationUriFailure,
  verifyTwoFactorAuthenticationUriSuccess,
  verifyTwoFactorAuthenticationUriFailure,
  disableTwoFactorAuthenticationUriSuccess,
  disableTwoFactorAuthenticationUriFailure,
  twoFactorAuthenticationStatusSuccess,
  twoFactorAuthenticationStatusFailure,
  checkTwoFactorAuthenticationStatusSuccess,
  checkTwoFactorAuthenticationStatusFailure,
  getMockInterviewsSuccess,
  getMockInterviewsFailure,
} from "../action";
import USERAUTH from "../../utils/api/userAuth";
import SUBSCRIPTION from "../../utils/api/subscription";
import { useNavigate } from "react-router-dom";
import { persistStore } from "redux-persist";

function* getEmployerRoleListRequest(action) {
  try {
    const res = yield USERAUTH.get(
      `admin/users/employer-roles?user=${action?.payload?.user}`
    );
    if (res.status === 200) {
      yield put(getEmployerRoleListSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getEmployerRoleListFailure());
    yield e?.response?.data?.message &&
      toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* getEmployerAccountLogsRequest(action) {
  try {
    const res = yield USERAUTH.get(
      `admin/users/getUserLogs/${action?.payload?.user}`
    );
    if (res.status === 200) {
      yield put(getEmployerAccountLogsSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getEmployerAccountLogsFailure());
    yield e?.response?.data?.message &&
      toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* getWhiteLabelDetailsRequest(action) {
  try {
    const res = yield USERAUTH.get(
      `employers/checkWhiteLabelledLogo/${action.payload.productId}`
    );
    if (res.status === 200) {
      yield put(getWhiteLabelDetailsSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getWhiteLabelDetailsFailure());
    yield e?.response?.data?.message &&
      toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

// REFER AND EARN
function* getReferAndEarnListRequest(action) {
  const isPageAndPageSize =
    action.payload.page && action.payload.pageSize
      ? `?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
      : "";
  const isSort = action.payload.sort ? `&sort=${action.payload.sort}` : "";
  const isStatus =
    action.payload.filterData.status !== ""
      ? `&status=${action.payload.filterData.status}`
      : "";
  const isUser =
    action.payload.filterData.user !== ""
      ? `&user=${action.payload.filterData.user}`
      : "";
  const isReferredBy =
    action.payload.filterData.referredBy !== ""
      ? `&referredBy=${action.payload.filterData.referredBy}`
      : "";
  const isInterviewsConducted =
    action.payload.filterData.interviewsConducted !== ""
      ? `&interviewsConducted=${action.payload.filterData.interviewsConducted}`
      : "";
  try {
    const res = yield USERAUTH.get(
      `admin/users/userReferral/list${isPageAndPageSize}${isSort}${isStatus}${isUser}${isReferredBy}${isInterviewsConducted}`
    );
    if (res.status === 200 || res.status === 201) {
      yield put(getReferAndEarnSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getReferAndEarnFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* getReferAndEarnFilterEmployerListRequest(action) {
  try {
    const res = yield USERAUTH.get(`admin/users/userReferral/dropdown`);
    if (res.status === 200 || res.status === 201) {
      yield put(getReferAndEarnFilterSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getReferAndEarnFilterFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* addReferAndEarnRequest(action) {
  try {
    const res = yield USERAUTH.patch(
      `admin/users/userReferral/settleReward`,
      action.payload.data
    );
    if (res.status === 201 || res.status === 200) {
      yield put(addReferSettleRewardSuccess());
      yield toast.success(<Toast msg={"Reward Settled Successfully"} />);
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(addReferSettleRewardFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

// LOGIN

function* logoutUserRequest(action) {
  try {
    const res = yield USERAUTH.patch(`admin/auth/logout`);
    if (res.status === 201 || res.status === 200) {
      yield all([call(persistor.purge), put(logoutUserSuccess())]);
      localStorage.clear();
      persistor.pause();
      persistor.flush().then(() => {
        return persistor.purge();
      });
      yield toast.success(<Toast msg="Logged out successfully." />);
      window.location.reload();
      action.payload.navigate(-1);
    }
  } catch (e) {
    yield put(logoutUserFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* loginUserRequest(action) {
  let location;
  try {
    const data = convertIntoQueryString(action.payload?.form);
    const token = action.payload?.token;
    let res;
    if (token) {
      res = yield USERAUTH.get("admin/auth/oauth/verify-token", {
        Authorization: `Bearer ${token}`,
      });
    } else {
      res = yield USERAUTH.post("admin/auth/oauth/token", data, {
        headers: {
          Authorization: `Basic ${btoa(secretKey)}`,
        },
      });
    }
    if (res.status === 201 || res.status === 200) {
      if (
        res.data.result.user?.userType === "admin" ||
        res.data.result.user?.userType === "subadmin"
      ) {
        yield put(loginUserSuccess(res.data.result.user));
        localStorageEncrypt("userData", JSON.stringify(res.data.result.user));

        localStorageEncrypt("role", JSON.stringify(res.data.result.role));

        localStorageEncrypt(
          "token",
          JSON.stringify(res.data.result.accessToken)
        );
        localStorageEncrypt(
          "refreshToken",
          JSON.stringify(res.data.result.refreshToken)
        );
        localStorageEncrypt(
          "expiryTime",
          JSON.stringify(res.data.result.expiryTime)
        );
        yield toast.success(<Toast msg="Logged in successfully." />);
        window.location.reload();
      } else {
        yield put(
          loginUserFailure({
            data: { message: "Authorisation Denied" },
          })
        );
      }
    } else {
      yield put(
        loginUserFailure({
          data: { message: "Invalid Credential" },
        })
      );
    }
  } catch (e) {
    yield put(
      loginUserFailure(
        e?.response
          ? e?.response
          : {
              data: { message: "Invalid Credential" },
            }
      )
    );
  }
}

// FORGET PASSWORD
function* forgotPasswordRequest(action) {
  try {
    const res = yield USERAUTH.get(`admin/auth/email/${action.payload.email}`);
    if (res.status === 200) {
      yield put(forgotPasswordSuccess());
      yield call(action.payload.callback);
      yield toast.success(
        <Toast msg="Email has been sent to your email address. please check your email and reset your password." />
      );
    }
  } catch (e) {
    yield put(forgotPasswordFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* forgotPasswordTokenVerifyRequest(action) {
  try {
    const res = yield USERAUTH.get(
      `admin/auth/email/${action.payload.email}/code/${action.payload.token}`
    );
    if (res.status === 200) {
      yield put(forgotPasswordTokenVerifySuccess());
      yield toast.success(
        <Toast msg="Token has been verified. you can reset your password." />
      );
    }
  } catch (e) {
    yield put(forgotPasswordTokenVerifyFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* resetPasswordRequest(action) {
  try {
    const res = yield USERAUTH.post(`admin/auth/reset`, {
      email: action.payload.email,
      code: action.payload.code,
      password: action.payload.password,
    });
    if (res.status === 200 || res.status === 201) {
      yield put(resetPasswordSuccess());
      yield call(action.payload.callback);
      yield toast.success(
        <Toast msg="Your password has been reset successfully. please login again with new password" />
      );
    }
  } catch (e) {
    yield put(resetPasswordFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

// EMPLOYER SAGA
function* getEmployerListRequest(action) {
  const isSearch = action.payload.search
    ? `${
        action.payload.page && action.payload.pageSize ? "&search" : "?search"
      }=${encodeURIComponent(action.payload.search)}`
    : "";
  const isPageAndPageSize =
    action.payload.page && action.payload.pageSize
      ? `?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
      : "";
  const isVerified =
    action.payload.isVerified !== undefined
      ? `&isVerified=${action.payload.isVerified}`
      : "";
  const status = action.payload.status
    ? `&status=${action.payload.status}`
    : "";
  const products = action?.payload?.products
    ? `&products=${action?.payload?.products}`
    : "";
  const isSort =
    action.payload.sort && action.payload.order
      ? `&sort=${action.payload.sort}&order=${action.payload.order}`
      : "";
  try {
    const res = yield USERAUTH.get(
      `admin/users/employers${isPageAndPageSize}${isSearch}${status}${isSort}${products}`
    );
    if (res.status === 200) {
      yield put(getEmployerListSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getEmployerListFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* sendEmailVerificationRequest(action) {
  try {
    const res = yield USERAUTH.post(
      `admin/auth/oauth/re-send-verification`,
      action.payload
    );
    if (res.status === 201) {
      yield put(sendEmailVerificationSuccess());
      yield toast.success(
        <Toast msg={"Verification Link sent successfully"} />
      );
    }
  } catch (e) {
    yield put(sendEmailVerificationFailure());
    yield toast.error(
      <Toast
        msg={"Oops. Some error occurred while sending verification email"}
      />
    );
  }
}
function* sendSubuserVerificationMailRequest(action) {
  const isId = action?.payload?.id ? action?.payload?.id : "";
  try {
    const res = yield USERAUTH.patch(
      `admin/users/subusers/newuser/resend/${isId}`
    );
    if (res.status === 201) {
      yield put(sendSubuserVerificationMailSuccess());
      yield toast.success(
        <Toast msg={"Verification mail sent successfully"} />
      );
    }
  } catch (e) {
    yield put(sendSubuserVerificationMailFailure());
    yield toast.error(
      <Toast msg={"Oops there was error while sending the verification mail"} />
    );
  }
}
function* getUserListRequest(action) {
  try {
    const res = yield USERAUTH.get(`admin/users/adminSubusers/list`);
    if (res.status === 200) {
      yield put(getUserListSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getUserListFailure());
  }
}
function* editStatusContactInquiryRequest(action) {
  try {
    const res = yield USERAUTH.put(
      `admin/users/contact-inquiry/${action.payload.id}/${action.payload.status}`,
      action.payload
    );
    if (res.status === 200 || res.status === 201) {
      yield call(action.payload.callback);
      yield put(editStatusContactInquirySuccess());
      yield toast.success(<Toast msg={"Status updated successfully."} />);
    }
  } catch (e) {
    yield put(editStatusContactInquiryFailure());
    yield toast.error(
      <Toast msg={"Oops. Some error occurred while updating status."} />
    );
  }
}
function* editUserContactInquiryRequest(action) {
  try {
    const res = yield USERAUTH.post(
      `admin/users/contact-inquiry/assignUser`,
      action.payload.data
    );
    if (res.status === 201) {
      yield call(action.payload.callback);
      yield put(editUserContactInquirySuccess());
      yield toast.success(<Toast msg={"Assigned user successfully."} />);
    }
  } catch (e) {
    yield put(editUserContactInquiryFailure());
    yield toast.error(
      <Toast msg={"Oops. Some error occurred while assigning user."} />
    );
  }
}
function* editUserSupportRequest(action) {
  try {
    const res = yield USERAUTH.post(
      `admin/users/support/assignUser`,
      action.payload.data
    );
    if (res.status === 201) {
      yield call(action.payload.callback);
      yield put(editUserSupportSuccess());
      yield toast.success(<Toast msg={"Assigned user successfully."} />);
    }
  } catch (e) {
    yield put(editUserSupportFailure());
    yield toast.error(
      <Toast msg={"Oops. Some error occurred while assigning user."} />
    );
  }
}
function* getAllEmployersRequest(action) {
  try {
    const res = yield USERAUTH.get(`admin/users/allemployers`);
    if (res.status === 200) {
      yield put(getAllEmployersSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getAllEmployersFailure());
  }
}
function* getActiveEmployersRequest(action) {
  try {
    const res = yield USERAUTH.get(`admin/users/activeEmployers/list`);
    if (res.status === 200) {
      yield put(getActiveEmployersSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getActiveEmployersFailure());
  }
}

function* deleteEmployerProfileRequest(action) {
  try {
    const res = yield USERAUTH.patch(
      `admin/employers/${action.payload.id}`,
      action?.payload?.data
    );
    if (res.status === 201 || res.status === 200) {
      yield put(deleteEmployerProfileSuccess());
      yield toast.success(<Toast msg={"Profile suspended successfully"} />);
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(deleteEmployerProfileFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* permanentDeleteEmployerProfileRequest(action) {
  try {
    const res = yield USERAUTH.patch(
      `admin/employers/delete/${action.payload.id}`,
      action?.payload?.data
    );
    if (res.status === 201 || res.status === 200) {
      yield put(permanentDeleteEmployerProfileSuccess());
      yield toast.success(<Toast msg="Profile deleted successfully" />);
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(permanentDeleteEmployerProfileFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* restoreEmployerProfileRequest(action) {
  try {
    const res = yield USERAUTH.put(
      `admin/users/employers/restore-profile`,
      action.payload
    );
    if (res.status === 200) {
      yield put(restoreEmployerProfileSuccess());
      yield toast.success(<Toast msg="Profile restored successfully" />);
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(restoreEmployerProfileFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* getEmployerSubscriptionRequest(action) {
  try {
    const res = yield USERAUTH.get(
      `admin/subscriptions/employer/user/${action.payload}`
    );
    if (res.status === 200) {
      yield put(getEmployerSubscriptionsSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getEmployerSubscriptionsFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* updateSubscriptionRequest(action) {
  try {
    const res = yield USERAUTH.put(
      `admin/employers/subscription/${action.payload.id}`,
      action.payload.body
    );
    if (res.status === 200) {
      yield put(putSubscriptionSuccess());
      yield toast.success(<Toast msg="Subscription Updated successfully" />);
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(putSubscriptionFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* getCreditHistoryRequest(action) {
  const isSearch = action.payload.search
    ? `${
        action.payload.page && action.payload.pageSize ? "&search" : "?search"
      }=${encodeURIComponent(action.payload.search)}`
    : "";
  const isPageAndPageSize =
    action.payload.page && action.payload.pageSize
      ? `?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
      : "";
  try {
    const res = yield SUBSCRIPTION.get(
      `admin/subscriptions/getCreditHistory/${action?.payload?.subscriptionId}${isPageAndPageSize}${isSearch}`
    );
    if (res.status === 200) {
      yield put(getCreditHistorySuccess(res.data.result));
    }
  } catch (e) {
    yield put(getCreditHistoryFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* updateCreditHistoryRquest(action) {
  try {
    const res = yield USERAUTH.put(
      `admin/employers/subscription/credit-history/${action.payload.id}`,
      action.payload.interviewCredits
    );
    if (res.status === 200) {
      yield put(updateCreditHistorySuccess(res.data.result));
      yield toast.success(<Toast msg="Credit Added Successfully" />);
    }
  } catch (e) {
    yield put(updateCreditHistoryFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
// ROLES AND SUBUSER
function* getSubUserRequest(action) {
  const isSearch = action?.payload?.search
    ? `&search=${encodeURIComponent(action.payload.search)}`
    : "";
  const status = action?.payload?.status
    ? `&status=${encodeURIComponent(action.payload.status)}`
    : "";
  const isSort = action?.payload?.sort ? `&sort=${action.payload.sort}` : "";
  const isPageAndPageSize =
    action.payload.page && action.payload.pageSize
      ? `?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
      : "";
  // const isOrder = action?.payload?.order
  //   ? `&order=${action?.payload?.order}`
  //   : "";
  try {
    let res;
    if (action.payload?.userId) {
      res = yield USERAUTH.get(
        `admin/users/subusers/${action.payload.userId}${isPageAndPageSize}${isSort}${isSearch}`
      );
    } else {
      res = yield USERAUTH.get(
        `admin/users/subusers${isPageAndPageSize}${isSort}${isSearch}${status}`
      );
    }
    if (res.status === 200) {
      yield put(getSubUserSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getSubUserFailure());
    yield e?.response?.data?.message &&
      toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* getAllSubUserRequest(action) {
  const isSearch = action.payload.search
    ? `&search=${encodeURIComponent(action.payload.search)}`
    : "";
  const isPageAndPageSize =
    action.payload.page && action.payload.pageSize
      ? `?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
      : "";
  const isSort =
    action.payload.sort && action.payload.order
      ? `&sort=${action.payload.sort}&order=${action.payload.order}`
      : "";
  try {
    const res = yield USERAUTH.get(
      `admin/users/subusers/admin/employerSubusers${isPageAndPageSize}${isSearch}${isSort}`
    );
    if (res.status === 200 || res.status === 201) {
      yield put(getAllSubusersSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getAllSubusersFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* addOrEditSubUserRequest(action) {
  try {
    let res;
    if (action.payload.id) {
      res = yield USERAUTH.put(
        `admin/users/subusers/${action.payload.id}`,
        action.payload.data
      );
    } else {
      res = yield USERAUTH.post(
        "admin/users/subusers/newuser",
        action.payload.data
      );
    }
    if (res.status === 201 || res.status === 200) {
      yield toast.success(
        <Toast
          msg={
            action.payload.id
              ? "Subuser Edit Successfully."
              : "Subuser Added Successfully."
          }
        />
      );
      yield put(addOrEditSubUserSuccess());
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(addOrEditSubUserFailure());
    yield e?.response?.data?.message &&
      toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* addOrEditEmployerSubUserRequest(action) {
  const id = action.payload.id;
  try {
    let res;
    if (action.payload.id) {
      delete action.payload.data.parentUserId;
      delete action.payload.data.id;
      res = yield USERAUTH.put(
        `admin/users/subusers/${id}`,
        action.payload.data
      );
    } else {
      res = yield USERAUTH.post(
        "admin/users/subusers/admin/employerSubuser",
        action.payload.data
      );
    }
    if (res.status === 201 || res.status === 200) {
      yield toast.success(
        <Toast
          msg={
            action.payload.id
              ? "Subuser Edit Successfully."
              : "Subuser Added Successfully."
          }
        />
      );
      yield put(addOrEditEmployerSubUserSuccess());
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(addOrEditEmployerSubUserFailure());
    yield e?.response?.data?.message &&
      toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* deleteSubUserRequest(action) {
  try {
    const res = yield USERAUTH.delete(
      `admin/users/subusers/${action.payload.deleteId}`
    );
    if (res.status === 204) {
      yield put(deleteSubUserSuccess());
      yield call(action.payload.callback);
      yield toast.success(<Toast msg="Subuser Deleted Successfully." />);
    }
  } catch (e) {
    yield put(deleteSubUserFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* restoreSubuserRequest(action) {
  try {
    const res = yield USERAUTH.patch(
      `admin/users/subusers/restore/${action.payload.subuserId}`
    );
    if (res.status === 201 || res.status === 200) {
      yield put(restoreSubuserSuccess());
      yield call(action.payload.callback);
      yield toast.success(<Toast msg="Subuser restored Successfully." />);
    }
  } catch (e) {
    yield put(restoreSubuserFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* deleteEmployerSubUserRequest(action) {
  try {
    const res = yield USERAUTH.delete(
      `admin/users/subusers/admin/employerSubuser?subUserId=${action.payload.subuserId}&parentUserId=${action.payload.userId}`
    );
    if (res.status === 204) {
      yield put(deleteEmploySubUserSuccess());
      yield call(action.payload.callback);
      yield toast.success(<Toast msg="Subuser Deleted Successfully." />);
    }
  } catch (e) {
    yield put(deleteEmploySubUserFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* resendLinkRequest(action) {
  try {
    const res = yield USERAUTH.delete(`admin/users/subusers/${action.payload}`);
    if (res.status === 204) {
      yield put(resendLinkSuccess());
    }
  } catch (e) {
    yield put(resendLinkFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* activeInactiveSubUserRequest(action) {
  try {
    const res = yield USERAUTH.patch(
      `admin/users/subusers/${action.payload.id}/status/${action.payload.status}`
    );
    if (res.status === 201) {
      yield put(activeInactiveSubUserSuccess());
      yield toast.success(
        <Toast
          msg={`Subuser ${
            action.payload.status === "active" ? "Activated" : "Deactivated"
          } successfully`}
        />
      );
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(activeInactiveSubUserFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* getRolesRequest(action) {
  try {
    const isPageAndPageSize =
      action.payload.page && action.payload.pageSize
        ? `?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
        : "";
    const isSort = action?.payload?.sort
      ? `&sort=${action?.payload?.sort}`
      : "";
    const isSearch = action?.payload?.search
      ? `?search=${encodeURIComponent(action.payload.search)}`
      : "";
    const res = yield USERAUTH.get(`admin/users/adminRoles${isSearch}`);
    if (res.status === 200) {
      yield put(getRolesSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getRolesFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* deleteRoleRequest(action) {
  try {
    const res = yield USERAUTH.delete(
      `admin/users/roles/${action.payload.deleteId}`
    );
    if (res.status === 204) {
      yield put(deleteRoleSuccess());
      yield call(action.payload.callback);
      yield toast.success(<Toast msg="Role Deleted Successfully." />);
    }
  } catch (e) {
    yield put(deleteRoleFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* activeInactiveRoleRequest(action) {
  try {
    const res = yield USERAUTH.patch(
      `admin/users/adminRoles/${action.payload.id}/${
        action.payload.status ? action.payload.status : ""
      }`
    );
    if (res.status === 201) {
      yield put(activeInactiveRoleSuccess());
      yield call(action.payload.callback);
      if (action.payload.status === "active") {
        yield toast.success(<Toast msg="Role Activated Successfully." />);
      } else {
        yield toast.success(<Toast msg="Role Deactivated Successfully." />);
      }
    }
  } catch (e) {
    yield put(activeInactiveRoleFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* getPermissionRequest() {
  try {
    const res = yield USERAUTH.get(`admin/users/permissions`);
    if (res.status === 200) {
      yield put(getPermissionSuccess(res.data.result));
      localStorageEncrypt("permissions", JSON.stringify(res.data.result));
    }
  } catch (e) {
    yield put(getPermissionFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* getEmployerPermissionRequest() {
  try {
    const res = yield USERAUTH.get(`admin/users/uacl/resource`);
    if (res.status === 200) {
      yield put(getEmployerPermissionSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getEmployerPermissionFailure());
    yield e?.response?.data?.message &&
      toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* addOrEditRoleRequest(action) {
  try {
    let res;
    action.payload.data["roleType"] = action?.payload?.data?.roleType;
    if (action?.payload?.id) {
      delete action.payload.data.status;
      delete action.payload.data.user;
      res = yield USERAUTH.put(
        `admin/users/adminRoles/${action?.payload?.id}`,
        action?.payload?.data
      );
    } else {
      delete action.payload.id;
      delete action.payload.data.status;
      res = yield USERAUTH.post("admin/users/adminRoles", action.payload.data);
    }
    if (res.status === 200 || res.status === 201) {
      yield put(addOrEditRoleSuccess());
      yield call(action.payload.callback);
      if (action?.payload?.data?.id) {
        yield toast.success(<Toast msg={"Role edited successfully"} />);
      } else {
        yield toast.success(<Toast msg={"Role added successfully"} />);
      }
    }
  } catch (e) {
    yield put(addOrEditRoleFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* addrolesRequest(action) {
  try {
    const res = yield USERAUTH.post(`admin/users/roles`, action.payload.data);
    if (res.status === 201) {
      yield put(addRolesSuccess(res.data.result));
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(addRolesFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* getRolesResourcesRequest(action) {
  try {
    const UserType = action.payload.userType
      ? `/${action.payload.userType}`
      : ``;
    const UserId = action.payload.userId ? `/${action.payload.userId}` : ``;
    const res = yield USERAUTH.get(
      `admin/users/permissions${UserType}${UserId}`
    );
    if (res.status === 200) {
      yield put(getRolesResourcesSuccess(res.data));
    }
  } catch (e) {
    yield put(getRolesResourcesFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* getResourceActionsRequest(action) {
  try {
    const res = yield USERAUTH.get(`admin/users/getResourses`);
    if (res.status === 200) {
      yield put(getResourceActionsSuccess(res.data));
    }
  } catch (e) {
    yield put(getResourceActionsFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

// USERS
function* getContactInquiryRequest(action) {
  const isSearch = action.payload.search
    ? `&search=${encodeURIComponent(action.payload.search)}`
    : "";
  const isPageAndPageSize =
    action.payload.page && action.payload.pageSize
      ? `?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
      : "";
  const isSort =
    action.payload.sort && action.payload.order
      ? `&sort=${action.payload.sort}&order=${action.payload.order}`
      : "";
  try {
    const res = yield USERAUTH.get(
      `admin/users/contact-inquiry${isPageAndPageSize}${isSort}${isSearch}`
    );
    if (res.status === 200) {
      yield put(getContactInquirySuccess(res.data.result));
    }
  } catch (e) {
    yield put(getContactInquiryFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* getAnnouncementListRequest(action) {
  const isSearch = action.payload.search
    ? `&search=${encodeURIComponent(action.payload.search)}`
    : "";
  const isPageAndPageSize =
    action.payload.page && action.payload.pageSize
      ? `?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
      : "";
  const isSort =
    action.payload.sort && action.payload.order
      ? `&sort=${action.payload.sort}&order=${action.payload.order}`
      : "";
  try {
    const res = yield USERAUTH.get(
      `admin/users/announcement${isPageAndPageSize}${isSearch}${isSort}`
    );
    if (res.status === 200) {
      yield put(getAnnouncementListSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getAnnouncementListFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* sendAnnouncementRequest(action) {
  try {
    const res = yield USERAUTH.post(
      `admin/users/announcement/send`,
      action.payload
    );
    if (res.status === 201) {
      yield put(sendAnnouncementItemSuccess(res.data.result));
      yield toast.success(<Toast msg={"Announcement successfully sent"} />);
    }
  } catch (e) {
    yield put(getAnnouncementListFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* deleteAnnouncementRequest(action) {
  try {
    const res = yield USERAUTH.delete(
      `admin/users/announcement/${action.payload.id}`
    );
    if (res.status === 204) {
      yield put(deleteAnnouncementSuccess());
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(deleteAnnouncementFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* getUserInformationRequest(action) {
  try {
    const res = yield USERAUTH.get(`admin/users/${action.payload.id}`);
    if (res.status === 200) {
      yield call(action.payload.callback, res.data.result);
      yield put(getUserInformationSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getUserInformationFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* updateUserInformationRequest(action) {
  try {
    const formData = new FormData();
    formData.append("name", action?.payload?.data?.fullName);
    formData.append("email", action?.payload?.data?.email);
    formData.append("phone", action?.payload?.data?.contact);
    formData.append("countryCode", action?.payload?.data?.countryCode);
    formData.append("photo", action?.payload?.photo);
    formData.append("whatsapp", action?.payload?.data?.whatsapp);
    const res = yield USERAUTH.put(
      `admin/users/${action?.payload?.id}`,
      formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
    if (res.status === 200) {
      yield put(updateUserInformationSuccess());
      yield toast.success(
        <Toast msg="User information updated Successfully." />
      );
    }
  } catch (e) {
    yield put(updateUserInformationFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* getSupportTicketsRequest(action) {
  const isSearch = action.payload.search
    ? `&search=${encodeURIComponent(action.payload.search)}`
    : "";
  const isPageAndPageSize =
    action.payload.page && action.payload.pageSize
      ? `?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
      : "";
  const isSort =
    action.payload.sort && action.payload.order
      ? `&sort=${action.payload.sort}&order=${action.payload.order}`
      : "";
  try {
    const res = yield USERAUTH.get(
      `admin/users/support${isPageAndPageSize}${isSort}${isSearch}`
    );
    if (res.status === 200) {
      yield put(getSupportTicketSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getSupportTicketFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* updateSupportTicketStatusRequest(action) {
  try {
    if (action?.payload?.data.status === "closed") {
      delete action.payload.data.status;
    }
    const res = yield USERAUTH.put(
      `admin/users/support/status/${action.payload.id}`,
      action?.payload?.data
    );
    if (res.status === 200) {
      yield put(updateSupportTicketSuccess());
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(updateSupportTicketFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* signupRequest(action) {
  try {
    const res = yield USERAUTH.post("admin/auth/signup", action.payload.data);
    if (res.status === 201) {
      yield put(signupUserSuccess());
      yield call(action.payload.callback);
      yield toast.success(
        <Toast msg="Your account has been successfully created." />
      );
    } else {
      yield put(signupUserFailure());
      yield toast.error(<Toast msg="Something went wrong." />);
    }
  } catch (e) {
    yield put(signupUserFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* manuallyVerifyEmailRequest(action) {
  try {
    const res = yield USERAUTH.put(
      "admin/users/verifyEmailByAdmin",
      action.payload.data
    );
    if (res.status === 201 || res.status === 200) {
      yield put(manuallyVerifyEmailSuccess());
      yield toast.success(
        <Toast msg="The account has been successfully verified." />
      );
      action.payload.navigate(-1);
    } else {
      yield put(signupUserFailure());
      yield toast.error(<Toast msg="Something went wrong." />);
    }
  } catch (e) {
    yield put(manuallyVerifyEmailFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* getCompanyDetailsRequest(action) {
  try {
    const res = yield USERAUTH.get(`admin/employers/${action.payload}`);
    if (res.status === 200) {
      yield put(getCompanyDetailsSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getCompanyDetailsFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* updateCompanyDetailsRequest(action) {
  const formData = new FormData();
  formData.append("name", action.payload.data?.name);
  formData.append("email", action.payload.data?.email);
  formData.append("address", JSON.stringify(action.payload.data?.address));
  formData.append("companyFoundedIn", action.payload.data?.companyFoundedIn);
  formData.append("industryType", action.payload.data?.industryType);
  formData.append("aboutUs", action.payload.data?.aboutUs);
  formData.append("logo", action.payload.data?.logo);
  if (action.payload.data?.linkedInUrl) {
    formData.append("linkedInUrl", action.payload.data?.linkedInUrl);
  }
  if (action.payload.data?.instagramUrl) {
    formData.append("instagramUrl", action.payload.data?.instagramUrl);
  }
  if (action.payload.data?.facebookUrl) {
    formData.append("facebookUrl", action.payload.data?.facebookUrl);
  }
  try {
    const res = yield USERAUTH.put(
      `admin/employers/${action?.payload.id}/company`,
      formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
    if (res.status === 200) {
      yield put(updateCompanyDetailsSuccess());
      yield toast.success(<Toast msg="Company Updated Successfully." />);
    }
  } catch (e) {
    yield put(updateCompanyDetailsFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* getIndustryTypeRequest() {
  try {
    const res = yield USERAUTH.get("industries");
    if (res.status === 200) {
      yield put(getIndustryTypeSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getIndustryTypeFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* changeEmployerStatusRequest(action) {
  try {
    const res = yield USERAUTH.patch(
      `admin/employers/approveProfile/${action?.payload?.userID}`
    );
    if (res.status === 200 || res.status === 201) {
      yield toast.success(
        <Toast msg="Employer account has been approved successfully." />
      );
      yield put(changeEmployerStatusSuccess());
    }
  } catch (e) {
    yield put(changeEmployerStatusFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* getFaqListRequest(action) {
  const isSearch = action?.payload?.search
    ? `&search=${encodeURIComponent(action?.payload?.search)}`
    : "";
  const isPageAndPageSize =
    action.payload.page && action.payload.pageSize
      ? `?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
      : "";
  const isSort = action.payload.sort ? `&sort=${action.payload.sort}` : "";
  try {
    const res = yield USERAUTH.get(
      `admin/users/faq/list${isPageAndPageSize}${isSort}${isSearch}&status=active`
    );
    if (res.status === 200) {
      yield put(getFaqListSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getFaqListFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* addOrEditFaqRequest(action) {
  try {
    let res;
    if (action.payload.id) {
      res = yield USERAUTH.put(
        `admin/users/faq/${action.payload.id}`,
        action.payload.data
      );
    } else {
      res = yield USERAUTH.post(`admin/users/faq`, action.payload.data);
    }
    if (res.status === 201 || res.status === 200) {
      yield put(addOrEditFaqSuccess(res.data.result));
      yield call(action.payload.callback);
      yield toast.success(
        <Toast
          msg={`Faq ${action.payload.id ? "edited" : "added"} successfuly`}
        />
      );
    }
  } catch (e) {
    yield put(addOrEditFaqFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* deleteFaqListRequest(action) {
  try {
    let res;
    if (action.payload.id) {
      res = yield USERAUTH.delete(
        `admin/users/faq/${action.payload.id}`,
        action.payload.data
      );
    }
    if (res.status === 204) {
      yield put(deleteFaqListSuccess(res.data.result));
      yield call(action.payload.callback);
      yield toast.success(<Toast msg={`Faq deleted successfuly`} />);
    }
  } catch (e) {
    yield put(deleteFaqListFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* getTagListRequest() {
  try {
    const res = yield USERAUTH.get(`faqTags/list`);
    if (res.status === 200) {
      yield put(getTagListSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getTagListFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* deleteFaqsListRequest(action) {
  try {
    let res;
    if (action.payload.ids && action.payload.ids.length > 0) {
      res = yield USERAUTH.put(`admin/users/faq/bulkDelete`, action.payload);
    }
    if (res.status === 200) {
      yield put(deleteFaqsListSuccess(res.data.result));
      yield call(action.payload.callback);
      yield toast.success(<Toast msg={`Bulk Faq deleted successfully`} />);
    }
  } catch (e) {
    yield put(deleteFaqsListFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* changePasswordRequest(action) {
  try {
    const res = yield USERAUTH.post(`admin/auth/change-password`, {
      oldPassword: action.payload.oldPassword,
      newPassword: action.payload.password,
    });
    if (res.status === 201) {
      yield put(changePasswordSuccess());
      yield toast.success(
        <Toast msg="Your password has been changed successfully." />
      );
      yield call(action.payload.callback());
    }
  } catch (e) {
    yield put(changePasswordFailure());
    yield e?.response?.data?.message &&
      toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* getProductPermissionRequest(action) {
  const isPageAndPageSize =
    action.payload.page && action.payload.pageSize
      ? `?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
      : "";
  try {
    const res = yield USERAUTH.get(
      `/admin/permissions/productPermission/${action?.payload?.product}?user=${action?.payload?.user}${isPageAndPageSize}`
    );
    if (res.status === 200) {
      yield put(getProductPermissionSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getProductPermissionFailure());
    yield e?.response?.data?.message &&
      toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* getProductPermissionCreditByIdRequest(action) {
  const employerId = "64157f31ae2f5f86ed891dbc";

  try {
    const res = yield USERAUTH.get(
      `admin/permissions/productPermission/credits/${employerId}`
    );
    if (res.status === 200) {
      yield put(getProductPermissionCreditByIdSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getProductPermissionCreditByIdFailure());
    yield e?.response?.data?.message &&
      toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* getProductPermissionCreditRequest(action) {
  const user = action?.payload?.user;
  const product = action?.payload?.product;
  const isSearch = action.payload.search
    ? `&search=${action.payload.search}`
    : "";
  const isPageAndPageSize =
    action.payload.page && action.payload.pageSize
      ? `?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
      : "";
  try {
    const res = yield USERAUTH.get(
      `admin/permissions/productPermission/credits/list${isPageAndPageSize}${isSearch}&user=${user}&product=${product}`
    );
    if (res.status === 200) {
      yield put(getProductPermissionCreditSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getProductPermissionCreditFailure());
    yield e?.response?.data?.message &&
      toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* updateProductPermissionCreditRequest(action) {
  const subscriptionId = action.payload.subscriptionId;
  if (
    action?.payload?.type !== "InCServe" &&
    action?.payload?.type !== "InCExit"
  ) {
    if (action?.payload?.form.noShowPercenatge === "") {
      delete action?.payload?.form.noShowPercenatge;
    }
    if (action?.payload?.form.payPerCredit === "") {
      delete action?.payload?.form.payPerCredit;
    }
    if (action?.payload?.form.payPerHirePercentage === "") {
      delete action?.payload?.form.payPerHirePercentage;
    }
  } else {
    if (action?.payload?.form.addOnPrice === "") {
      delete action?.payload?.form.addOnPrice;
    }
    if (action?.payload?.form?.positionCredits === "") {
      delete action?.payload?.form?.positionCredits;
    }
    if (action?.payload?.form.subUserCredits === "") {
      delete action?.payload?.form.subUserCredits;
    }
  }

  if (action?.payload.creditId) {
    action.payload.form.creditId = action?.payload?.creditId;
  }
  delete action?.payload?.form.type;
  try {
    const res = yield USERAUTH.post(
      `admin/permissions/productPermission/Addcredits/${subscriptionId}`,
      action.payload.form
    );
    if (res.status === 201) {
      yield put(updateProductPermissionCreditSuccess(res.data.result));
      if (action.payload.callback) {
        yield call(action.payload.callback);
      }
    }
  } catch (e) {
    yield put(updateProductPermissionCreditFailure());
    if (e?.response?.data?.message) {
      toast.error(<Toast msg={e?.response?.data?.message} />);
    }
  }
}

function* updateProductPermissionRequest(action) {
  const id = action.payload?.employerId;
  const form = action.payload.form;
  const formData = new FormData();
  formData.append("ratingSystemType", form.ratingSystemType);
  formData.append("completedCsvType", form.completedCsvType);
  formData.append("feedbackReportType", form.feedbackReportType);
  formData.append("scheduledReportStatus", form.scheduledReportStatus);
  formData.append("interviewSystem", form.interviewSystem);
  formData.append("logoUrl", form.logoUrl);
  formData.append("whiteLabel", form.whiteLabel);
  formData.append("recording", form.recording);
  formData.append("subclientName", form.subclientName);
  formData.append("candidateVerification", form.candidateVerification);
  formData.append("creditSystem", form.creditSystem);
  formData.append("integerations", form.integerations);
  formData.append("productPermission", form.productPermission);
  formData.append("experience", JSON.stringify(form.experience));
  formData.append("creditType", form.creditType);
  try {
    const res = yield USERAUTH.post(
      `admin/permissions/productPermission/${id}`,
      formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );

    if (res.status === 201) {
      yield put(updateProductPermissionSuccess(res.data.result));
      yield call(action.payload.callback());
    }
  } catch (e) {
    yield put(updateProductPermissionFailure());
    yield e?.response?.data?.message &&
      toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* addProductPermissionRequest(action) {
  const subscriptionId = action?.payload?.Id;
  const data = action?.payload?.data;
  let res;
  try {
    res = yield SUBSCRIPTION.put(
      `admin/subscriptions/updateSubscription/${subscriptionId}`,
      data
    );
    if (res.status === 200) {
      yield put(addProductPermissionSuccess());
      yield toast.success(<Toast msg="Subscription updated successfully." />);
    }
  } catch (e) {
    yield put(addProductPermissionFailure());
    yield e?.response?.data?.message &&
      toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* notifyClientRequest(action) {
  let id = action?.payload?.id;
  try {
    const res = yield USERAUTH.post(
      `admin/employers/notifyProfileUpdate/${id}`
    );
    if (res.status === 201) {
      yield put(notifyClientSuccess());
      yield toast.success(
        <Toast msg={"Profile update notification sent successfully"} />
      );
    }
  } catch (e) {
    yield put(notifyClientFailure());
    yield e?.response?.data?.message &&
      toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* notifyIncserveClientRequest(action) {
  let id = action?.payload?.id;
  try {
    const res = yield USERAUTH.post(`/admin/users/notifyProfileUpdate/${id}`);
    if (res.status === 201) {
      yield put(notifyIncserveClientSuccess());
      yield toast.success(<Toast msg={"Notify email sent successfully"} />);
    }
  } catch (e) {
    yield put(notifyIncserveClientFailure());
    yield e?.response?.data?.message &&
      toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* getEmployerSubuserDetailsRequest(action) {
  const isUserId = action.payload?.userId
    ? `&user=${action.payload?.userId}`
    : "";
  const isSearch = action.payload?.search
    ? `&search=${encodeURIComponent(action.payload.search)}`
    : "";
  const isSort = action.payload.sort ? `&sort=${action.payload.sort}` : "";
  const isPageAndPageSize =
    action.payload.page && action.payload.pageSize
      ? `?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
      : "";
  const isFilter = action.payload.filters
    ? action.payload.filters.from
      ? action.payload.filters.to
        ? `&from=${action.payload.filters.from}T00:00:00&to=${action.payload.filters.to}T23:59:59`
        : `&from=${action.payload.filters.from}T00:00:00&to=3000-12-31T23:59:59`
      : action.payload.filters.to
      ? `&from=2000-01-01T00:00:00&to=${action.payload.filters.to}T23:59:59`
      : ""
    : "";
  try {
    const res = yield USERAUTH.get(
      `admin/users/subusers${isPageAndPageSize}${isSort}${isSearch}${isUserId}${isFilter}`
    );
    if (res.status === 200) {
      yield put(getEmployerSubuserDetailsSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getEmployerSubuserDetailsFailure());
    yield e?.response?.data?.message &&
      e?.response?.data?.message !==
        "Your session has been expired, please login again!" &&
      toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* getAllNotificationsRequest(action) {
  const isPageAndPageSize =
    action?.payload?.page && action?.payload?.pageSize
      ? `?page=${action?.payload?.page}&pageSize=${action?.payload?.pageSize}`
      : "";
  const userType = action?.payload?.userType
    ? `&userType=${action?.payload?.userType}`
    : "";
  try {
    const res = yield USERAUTH.get(
      `admin/users/notification${isPageAndPageSize}${userType}`
    );
    if (res?.status === 200 || res?.status === 201) {
      yield put(getAllNotificationsSuccess(res?.data?.result));
    }
  } catch (e) {
    yield put(getAllNotificationsFailure());
    yield e?.response?.data?.message &&
      e?.response?.data?.message !==
        "Your session has been expired, please login again!" &&
      toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* readAllNotificationsRequest() {
  try {
    const res = yield USERAUTH.put(`admin/users/notification/readAll`);
    if (res?.status === 200 || res?.status === 201) {
      yield put(readAllNotificationsSuccess(res?.data?.result));
    }
  } catch (e) {
    yield put(readAllNotificationsFailure());
    yield e?.response?.data?.message &&
      e?.response?.data?.message !==
        "Your session has been expired, please login again!" &&
      toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* deleteNotificationRequest(action) {
  const Id = action?.payload?.id ? action?.payload?.id : "";
  let res;
  try {
    if (action?.payload?.clear) {
      res = yield USERAUTH.delete(`admin/users/notification`);
    } else {
      res = yield USERAUTH.delete(`admin/users/notification?id=${Id}`);
    }
    if (res?.status === 204) {
      yield put(deleteNotificationSuccess());
      yield call(action?.payload?.callback);
    }
  } catch (e) {
    yield put(deleteNotificationFailure());
    yield e?.response?.data?.message &&
      e?.response?.data?.message !==
        "Your session has been expired, please login again!" &&
      toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* subAdminPasswordRequest(action) {
  try {
    const Id = action?.payload?.id ?? "";
    const res = yield USERAUTH.get(`admin/auth/subadmin/${Id}`);
    if (res.status === 200) {
      yield put(subAdminPasswordResetSuccess());
      yield toast.success(
        <Toast msg={"Password reset mail sent successfully"} />
      );
    }
  } catch (e) {
    yield put(subAdminPasswordResetFailure());
    yield e?.response?.data?.message &&
      e?.response?.data?.message !==
        "Your session has been expired, please login again!" &&
      toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* generateTwoFactorUriRequest(action) {
  try {
    const res = yield USERAUTH.get(`admin/auth/2fa/generate-qr-code`);
    if (res.status === 200 || res.status === 201) {
      yield put(generateTwoFactorAuthenticationUriSuccess(res.data.result));
    }
  } catch (e) {
    yield put(generateTwoFactorAuthenticationUriFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* twoFactorAuthenticationStatusRequest(action) {
  try {
    const res = yield USERAUTH.get(`admin/auth/2fa/check-status`);
    if (res.status === 200 || res.status === 201) {
      yield put(twoFactorAuthenticationStatusSuccess(res.data.result));
    }
  } catch (e) {
    yield put(twoFactorAuthenticationStatusFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* verifyTwoFactorUriRequest(action) {
  try {
    const res = yield USERAUTH.post(
      `admin/auth/2fa/verify-token`,
      action?.payload?.data
    );
    if (res.status === 200 || res.status === 201) {
      yield put(verifyTwoFactorAuthenticationUriSuccess(res.data.result));
      toast.success(<Toast msg={"Account verified Successfully."} />);
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(verifyTwoFactorAuthenticationUriFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* disableTwoFactorUriRequest(action) {
  try {
    const res = yield USERAUTH.patch(
      `admin/auth/2fa/disable`,
      action?.payload?.data
    );
    if (res.status === 200 || res.status === 201) {
      yield put(disableTwoFactorAuthenticationUriSuccess(res.data.result));
      toast.success(<Toast msg={"Account disabled successfully."} />);
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(disableTwoFactorAuthenticationUriFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* checkTwoFactorStatusRequest(action) {
  try {
    const res = yield USERAUTH.post(
      `admin/auth/oauth/token/check2fa`,
      action?.payload?.data
    );
    if (res.status === 200 || res.status === 201) {
      yield put(checkTwoFactorAuthenticationStatusSuccess(res.data.result));
      // toast.success(<Toast msg={"Account disabled successfully."} />);
      yield call(action.payload.callback(res?.data?.result));
    }
  } catch (e) {
    yield put(checkTwoFactorAuthenticationStatusFailure());
    // yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* getMockInterviewsRequest(action) {
  const isSearch = action.payload?.search
    ? `&search=${encodeURIComponent(action.payload.search)}`
    : "";
  const isSort = action.payload.sort ? `&sort=${action.payload.sort}` : "";
  const isPageAndPageSize =
    action.payload.page && action.payload.pageSize
      ? `?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
      : "";
  try {
    const res = yield USERAUTH.get(
      `admin/users/mock-interview/list${isPageAndPageSize}${isSort}${isSearch}`
    );
    if (res.status === 200) {
      yield put(getMockInterviewsSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getMockInterviewsFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
export function* watchGetMockInterviewsRequestAPI() {
  yield takeEvery(GET_MOCK_INTERVIEWS, getMockInterviewsRequest);
}

export function* watchGenerateTwoFactorUriRequestAPI() {
  yield takeEvery(
    GENERATE_TWO_FACTOR_AUTHENTICATION_URI,
    generateTwoFactorUriRequest
  );
}

export function* watchCheckTwoFactorStatusRequestAPI() {
  yield takeEvery(
    CHECK_TWO_FACTOR_AUTHENTICATION_STATUS,
    checkTwoFactorStatusRequest
  );
}

export function* watchTwoFactorAuthenticationStatusRequestAPI() {
  yield takeEvery(
    TWO_FACTOR_AUTHENTICATION_STATUS,
    twoFactorAuthenticationStatusRequest
  );
}
export function* watchVerifyTwoFactorUriRequestAPI() {
  yield takeEvery(
    VERIFY_TWO_FACTOR_AUTHENTICATION_URI,
    verifyTwoFactorUriRequest
  );
}

export function* watchDisableTwoFactorUriRequestAPI() {
  yield takeEvery(
    DISABLE_TWO_FACTOR_AUTHENTICATION_URI,
    disableTwoFactorUriRequest
  );
}

export function* watchSubAdminPasswordRequestPasswordRestAPI() {
  yield takeEvery(SUB_ADMIN_PASSWORD_RESET, subAdminPasswordRequest);
}

export function* watchGetEmployerSubuserDetailsAPI() {
  yield takeEvery(
    GET_EMPLOYER_SUBUSER_DETAILS,
    getEmployerSubuserDetailsRequest
  );
}

export function* watchNotifyClientAPI() {
  yield takeEvery(NOTIFY_CLIENT, notifyClientRequest);
}

export function* watchNotifyIncserveClientAPI() {
  yield takeEvery(NOTIFY_INCSERVE_CLIENT, notifyIncserveClientRequest);
}

export function* watchLogoutAPI() {
  yield takeEvery(LOGOUT_USER, logoutUserRequest);
}
export function* watchGetWhiteLabelDetailsAPI() {
  yield takeEvery(GET_WHITE_LABEL_DETAILS, getWhiteLabelDetailsRequest);
}
export function* watchGetEmployerRoleListRequestAPI() {
  yield takeEvery(GET_EMPLOYER_ROLE_LIST, getEmployerRoleListRequest);
}
export function* watchGetEmployerAccountLogsRequestAPI() {
  yield takeEvery(
    INCSERVE_EMPLOYER_ACCOUNT_LOGS,
    getEmployerAccountLogsRequest
  );
}
export function* watchGetUserListRequestAPI() {
  yield takeEvery(GET_USER_LIST, getUserListRequest);
}
export function* watchGetActiveEmployersRequestAPI() {
  yield takeEvery(GET_ACTIVE_EMPLOYER, getActiveEmployersRequest);
}

export function* watchAddReferAndEarnRequestAPI() {
  yield takeEvery(ADD_SETTLE_REFER, addReferAndEarnRequest);
}
export function* watchGetReferAndEarnEmployerListRequestAPI() {
  yield takeEvery(
    GET_REFER_AND_EARN_FILTER_EMPLOYER_LIST,
    getReferAndEarnFilterEmployerListRequest
  );
}
export function* watchEditStatusContactInquiryAPI() {
  yield takeEvery(EDIT_STATUS_CONTACT_INQUIRY, editStatusContactInquiryRequest);
}
export function* watchEditUserContactInquiryAPI() {
  yield takeEvery(EDIT_USER_CONTACT_INQUIRY, editUserContactInquiryRequest);
}
export function* watchEditUserSupportAPI() {
  yield takeEvery(EDIT_USER_SUPPORT, editUserSupportRequest);
}
export function* watchGetSupportTicketsAPI() {
  yield takeEvery(GET_SUPPORT_TICKET, getSupportTicketsRequest);
}
export function* watchUpdateSupportTicketStatusAPI() {
  yield takeEvery(
    UPDATE_SUPPORT_TICKET_STATUS,
    updateSupportTicketStatusRequest
  );
}

export function* watchGetReferAndEarnAPI() {
  yield takeEvery(GET_REFER_AND_EARN_LIST, getReferAndEarnListRequest);
}
export function* watchGetUserInformationAPI() {
  yield takeEvery(GET_USER_INFORMATION, getUserInformationRequest);
}
export function* watchUpdateUserInformationAPI() {
  yield takeEvery(UPDATE_USER_INFORMATION, updateUserInformationRequest);
}
export function* watchGetContactInquiryAPI() {
  yield takeEvery(GET_CONTACT_INQUIRY, getContactInquiryRequest);
}
export function* watchGetAnnouncementListAPI() {
  yield takeEvery(GET_ANNOUNCEMENT_LIST, getAnnouncementListRequest);
}
export function* watchSendAnnouncementItemAPI() {
  yield takeEvery(SEND_ANNOUNCEMENT_ITEM, sendAnnouncementRequest);
}
export function* watchDeleteAnnouncementAPI() {
  yield takeEvery(DELETE_ANNOUNCEMENT, deleteAnnouncementRequest);
}
export function* watchGetSubUserAPI() {
  yield takeEvery(GET_SUB_USER, getSubUserRequest);
}
export function* watchAddOrEditSubUserAPI() {
  yield takeEvery(ADD_OR_EDIT_SUB_USER, addOrEditSubUserRequest);
}
export function* watchAddOrEditEmployeeSubUserAPI() {
  yield takeEvery(
    ADD_OR_EDIT_EMPLOYER_SUB_USER,
    addOrEditEmployerSubUserRequest
  );
}
export function* watchRestoreSubuserRequestAPI() {
  yield takeEvery(RESTORE_SUBUSER, restoreSubuserRequest);
}

export function* watchDeleteSubUserAPI() {
  yield takeEvery(DELETE_SUB_USER, deleteSubUserRequest);
}
export function* watchDeleteEmploySubUserAPI() {
  yield takeEvery(DELETE_EMPLOYER_SUB_USER, deleteEmployerSubUserRequest);
}
export function* watchResendLinkAPI() {
  yield takeEvery(RESEND_LINK, resendLinkRequest);
}
export function* watchActiveInActiveSubUserAPI() {
  yield takeEvery(ACTIVE_INACTIVE_SUBUSER, activeInactiveSubUserRequest);
}
export function* watchDeleteRoleAPI() {
  yield takeEvery(DELETE_ROLES, deleteRoleRequest);
}
export function* watchGetRolesAPI() {
  yield takeEvery(GET_ROLES, getRolesRequest);
}
export function* watchActiveInActiveRoleAPI() {
  yield takeEvery(ACTIVE_INACTIVE_ROLE, activeInactiveRoleRequest);
}
export function* watchGetPermissionAPI() {
  yield takeEvery(GET_PERMISSION, getPermissionRequest);
}
export function* watchGetEmployerPermissionAPI() {
  yield takeEvery(GET_EMPLOYER_PERMISSION, getEmployerPermissionRequest);
}
export function* watchAddOrEditRoleAPI() {
  yield takeEvery(ADD_OR_EDIT_ROLE, addOrEditRoleRequest);
}
export function* watchAddRolesAPI() {
  yield takeEvery(ADD_ROLES, addrolesRequest);
}
export function* watchGetRolesResourcesAPI() {
  yield takeEvery(GET_ROLES_RESOURCES, getRolesResourcesRequest);
}
export function* watchGetAllSubusersAPI() {
  yield takeEvery(GET_ALL_SUB_USER, getAllSubUserRequest);
}
export function* watchGetResourceActionsAPI() {
  yield takeEvery(GET_RESOURCE_ACTIONS, getResourceActionsRequest);
}
export function* watchLoginUserAPI() {
  yield takeEvery(LOGIN_USER, loginUserRequest);
}
export function* watchForgotPasswordAPI() {
  yield takeEvery(FORGOT_PASSWORD, forgotPasswordRequest);
}
export function* watchForgotPasswordTokenVerifyAPI() {
  yield takeEvery(
    FORGOT_PASSWORD_TOKEN_VERIFY,
    forgotPasswordTokenVerifyRequest
  );
}
export function* watchResetPasswordAPI() {
  yield takeEvery(RESET_PASSWORD, resetPasswordRequest);
}
export function* watchGetEmployerListAPI() {
  yield takeEvery(GET_EMPLOYER_LIST, getEmployerListRequest);
}
export function* watchGetAllEmployersAPI() {
  yield takeEvery(GET_ALL_EMPLOYERS, getAllEmployersRequest);
}
export function* watchSendEmailVerificationAPI() {
  yield takeEvery(SEND_EMAIL_VERIFICATION, sendEmailVerificationRequest);
}
export function* watchSendSubuserVerificationMailAPI() {
  yield takeEvery(
    SEND_SUBUSER_VERIFICATION_MAIL,
    sendSubuserVerificationMailRequest
  );
}
export function* watchDeleteEmployerProfileAPI() {
  yield takeEvery(DELETE_EMPLOYER_PROFILE, deleteEmployerProfileRequest);
}
export function* watchPermanentDeleteEmployerProfileAPI() {
  yield takeEvery(DELETE_EMPLOYER, permanentDeleteEmployerProfileRequest);
}
export function* watchRestoreEmployerProfileAPI() {
  yield takeEvery(RESTORE_PROFILE, restoreEmployerProfileRequest);
}
export function* watchEmployerSubscriptionAPI() {
  yield takeEvery(EMPLOYER_SUBSCRIPTION, getEmployerSubscriptionRequest);
}
export function* watchUpdateSubscriptionAPI() {
  yield takeEvery(UPDATE_SUBSCRIPTION, updateSubscriptionRequest);
}
export function* watchGetCreditHistoryAPI() {
  yield takeEvery(GET_CREDIT_HISTORY, getCreditHistoryRequest);
}
export function* watchUpdateCreditHistoryAPI() {
  yield takeEvery(UPDATE_CREDIT_HISTORY, updateCreditHistoryRquest);
}
export function* watchSignupAPI() {
  yield takeEvery(SIGNUP_USER, signupRequest);
}
export function* watchManuallyVerifyEmailAPI() {
  yield takeEvery(MANUALLY_VERIFY_EMAIL, manuallyVerifyEmailRequest);
}
export function* watchGetCompanyDetailsAPI() {
  yield takeEvery(GET_COMPANY_DETAILS, getCompanyDetailsRequest);
}
export function* watchUpdateCompanyDetailsAPI() {
  yield takeEvery(UPDATE_COMPANY_DETAILS, updateCompanyDetailsRequest);
}
export function* watchGetIndustryTypeAPI() {
  yield takeEvery(GET_INDUSTRY_TYPE, getIndustryTypeRequest);
}
export function* watchGetTagListRequestAPI() {
  yield takeEvery(GET_TAG_LIST, getTagListRequest);
}
export function* watchGetFaqListRequestAPI() {
  yield takeEvery(GET_FAQ_LIST, getFaqListRequest);
}
export function* watchAddOrEditFaqRequestAPI() {
  yield takeEvery(ADD_OR_EDIT_FAQ, addOrEditFaqRequest);
}
export function* watchDeleteFaqListRequestAPI() {
  yield takeEvery(DELETE_FAQ_LIST, deleteFaqListRequest);
}
export function* watchDeleteFaqsListRequestAPI() {
  yield takeEvery(DELETE_FAQS_LIST, deleteFaqsListRequest);
}
export function* watchChangePasswordAPI() {
  yield takeEvery(CHANGE_PASSWORD, changePasswordRequest);
}
export function* watchGetProductPermissionRequestAPI() {
  yield takeEvery(GET_PRODUCT_PERMISSION, getProductPermissionRequest);
}
export function* watchGetProductPermissionCreditByIdRequestAPI() {
  yield takeEvery(
    GET_PRODUCT_PERMISSION_CREDIT_BY_ID,
    getProductPermissionCreditByIdRequest
  );
}
export function* watchGetProductPermissionCreditRequestAPI() {
  yield takeEvery(
    GET_PRODUCT_PERMISSION_CREDIT,
    getProductPermissionCreditRequest
  );
}
export function* watchUpdateProductPermissionCreditRequestAPI() {
  yield takeEvery(
    UPDATE_PRODUCT_PERMISSION_CREDIT,
    updateProductPermissionCreditRequest
  );
}

export function* watchUpdateProductPermissionRequestAPI() {
  yield takeEvery(UPDATE_PRODUCT_PERMISSION, updateProductPermissionRequest);
}
export function* watchAddProductPermissionRequestAPI() {
  yield takeEvery(ADD_PRODUCT_PERMISSION, addProductPermissionRequest);
}

export function* getAllNotificationsRequestAPI() {
  yield takeEvery(GET_ALL_NOTIFICATIONS, getAllNotificationsRequest);
}

export function* readAllNotificationsRequestAPI() {
  yield takeEvery(READ_ALL_NOTIFICATIONS, readAllNotificationsRequest);
}

export function* deleteNotificationRequestAPI() {
  yield takeEvery(DELETE_NOTIFICATION, deleteNotificationRequest);
}

export function* changeEmployerStatusRequestAPI() {
  yield takeEvery(CHANGE_EMPLOYER_STATUS, changeEmployerStatusRequest);
}

export default function* rootSaga() {
  yield all([
    watchGetMockInterviewsRequestAPI(),
    watchCheckTwoFactorStatusRequestAPI(),
    watchTwoFactorAuthenticationStatusRequestAPI(),
    watchGenerateTwoFactorUriRequestAPI(),
    watchVerifyTwoFactorUriRequestAPI(),
    watchDisableTwoFactorUriRequestAPI(),
    watchGetEmployerAccountLogsRequestAPI(),
    watchGetEmployerRoleListRequestAPI(),
    watchNotifyIncserveClientAPI(),
    watchSubAdminPasswordRequestPasswordRestAPI(),
    watchGetUserListRequestAPI(),
    watchEditStatusContactInquiryAPI(),
    watchEditUserContactInquiryAPI(),
    watchEditUserSupportAPI(),
    watchGetSubUserAPI(),
    watchAddOrEditSubUserAPI(),
    watchDeleteSubUserAPI(),
    watchResendLinkAPI(),
    watchActiveInActiveSubUserAPI(),
    watchGetRolesAPI(),
    watchDeleteRoleAPI(),
    watchActiveInActiveRoleAPI(),
    watchGetPermissionAPI(),
    watchGetEmployerPermissionAPI(),
    watchAddOrEditRoleAPI(),
    watchAddRolesAPI(),
    watchGetRolesResourcesAPI(),
    watchGetAllSubusersAPI(),
    watchAddOrEditEmployeeSubUserAPI(),
    watchDeleteEmploySubUserAPI(),
    watchGetResourceActionsAPI(),
    watchLoginUserAPI(),
    watchForgotPasswordAPI(),
    watchForgotPasswordTokenVerifyAPI(),
    watchResetPasswordAPI(),
    watchGetEmployerListAPI(),
    watchGetAllEmployersAPI(),
    watchSendEmailVerificationAPI(),
    watchDeleteEmployerProfileAPI(),
    watchPermanentDeleteEmployerProfileAPI(),
    watchRestoreEmployerProfileAPI(),
    watchEmployerSubscriptionAPI(),
    watchGetCreditHistoryAPI(),
    watchUpdateCreditHistoryAPI(),
    watchUpdateSubscriptionAPI(),
    watchGetContactInquiryAPI(),
    watchGetAnnouncementListAPI(),
    watchSendAnnouncementItemAPI(),
    watchDeleteAnnouncementAPI(),
    watchGetUserInformationAPI(),
    watchUpdateUserInformationAPI(),
    watchGetSupportTicketsAPI(),
    watchUpdateSupportTicketStatusAPI(),
    watchSignupAPI(),
    watchManuallyVerifyEmailAPI(),
    watchGetCompanyDetailsAPI(),
    watchUpdateCompanyDetailsAPI(),
    watchGetIndustryTypeAPI(),
    watchGetReferAndEarnAPI(),
    watchAddReferAndEarnRequestAPI(),
    watchGetReferAndEarnEmployerListRequestAPI(),
    watchSendSubuserVerificationMailAPI(),
    watchGetFaqListRequestAPI(),
    watchGetTagListRequestAPI(),
    watchAddOrEditFaqRequestAPI(),
    watchDeleteFaqListRequestAPI(),
    watchDeleteFaqsListRequestAPI(),
    watchGetActiveEmployersRequestAPI(),
    watchChangePasswordAPI(),
    watchGetWhiteLabelDetailsAPI(),
    watchLogoutAPI(),
    watchGetProductPermissionRequestAPI(),
    watchGetProductPermissionCreditRequestAPI(),
    watchUpdateProductPermissionCreditRequestAPI(),
    watchGetProductPermissionCreditByIdRequestAPI(),
    watchUpdateProductPermissionRequestAPI(),
    watchAddProductPermissionRequestAPI(),
    watchNotifyClientAPI(),
    watchGetEmployerSubuserDetailsAPI(),
    getAllNotificationsRequestAPI(),
    readAllNotificationsRequestAPI(),
    deleteNotificationRequestAPI(),
    changeEmployerStatusRequestAPI(),
    watchRestoreSubuserRequestAPI(),
  ]);
}
