import React, { memo } from "react";
import excel from "../../assets/images/excel-btn.svg";
import excel_icon_btn from "../../assets/images/excel_icon_btn.svg";

const ExcelBtn = memo(({ onClick, excelImg, excelBtnClassName }) => {
  return (
    <>
      {excelImg ? (
        <img
          src={excel_icon_btn}
          alt="excel"
          className="excel-img img-fluid cursor-pointer"
          onClick={onClick}
        />
      ) : (
        <button
          className={`excel-btn d-center ms-2 rounded ${excelBtnClassName}`}
          onClick={onClick}
        >
          <span className="f-l f-med no-wrap">Export To Excel</span>
          <img src={excel} alt="excel" className="excel-img img-fluid" />
        </button>
      )}
    </>
  );
});

export default ExcelBtn;
