import React from "react";
import clockFilter from "../../assets/images/clock-filter.svg";
const ClockFilterBtn = ({ initialForm, setForm, form }) => {
  return (
    <div className="d-center cursor-pointer">
      <img
        src={clockFilter}
        alt="clock-filter"
        className="clock-filter-logo ms-2"
        onClick={() => {
          setForm({
            ...initialForm,
          });
        }}
      />
    </div>
  );
};

export default ClockFilterBtn;
