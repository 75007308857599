import React, { memo } from "react";

const Select = memo(
  ({
    labelClass,
    selectClass,
    optionClass,
    options,
    disabled,
    setValue,
    error,
    value,
    name,
    onChange,
    label,
    isRequired,
    optionFirst,
  }) => {
    return (
      <>
        {label && (
          <label htmlFor={name} className={`c-label ${labelClass}`}>
            {label} {isRequired && <span className="asterisk f-12">&#42;</span>}
          </label>
        )}
        <div className="position-relative">
          <select
            className={`border ${selectClass} form-select ${
              error ? "error" : ""
            }`}
            disabled={disabled}
            onChange={onChange}
            value={value}
            name={name}
            data-live-search="true">
            <option disabled className={`${optionClass} ${optionFirst}`}>
              Please Select
            </option>
            {options &&
              options?.map((option, index) => (
                <option
                  key={index}
                  data-token={option.label}
                  value={option.value}
                  className={optionClass}>
                  {option.label}
                </option>
              ))}
          </select>
          {error && <div className="invalid">{error}</div>}
        </div>
      </>
    );
  }
);

export default Select;
