import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import {
  Input,
  Loader,
  SearchSelect,
  Button,
} from "../../component/commonComponent";

import { useSelector, useDispatch } from "react-redux";
import { getCalendarEvent, getCalenderEvent } from "../../redux/action";
import moment from "moment";
import { displayTimeSlots } from "../../utils/constant";

const Calender = () => {
  const [isCalenderOpen, setIsCalenderOpen] = useState(false);
  const [interviewersList, setInterviewersList] = useState([]);
  const [error, setError] = useState({});
  const [data, setData] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const allInterviewerList = useSelector(
    (state) => state.Interview.AllInterviewers
  );

  const [form, setForm] = useState({
    date: "",
    interviewer: "",
  });

  const dispatch = useDispatch();
  useEffect(() => {
    const tmpArray = [
      {
        value: "",
        label: "Select interviewer",
      },
    ];
    allInterviewerList &&
      allInterviewerList.length > 0 &&
      allInterviewerList.map((element) => {
        let x = element?.code ? element?.code : "";
        tmpArray.push({
          value: element?._id,
          label: x + " " + " " + element?.name,
        });
      });
    setInterviewersList(tmpArray);
  }, [allInterviewerList]);

  const handleInput = (e) => {
    setIsSearch(false);
    setForm((prev) => ({
      ...prev,
      date: e.target.value,
    }));
    setError((prev) => ({
      ...prev,
      date: "",
    }));
  };
  const handleSearch = (e) => {
    setIsSearch(false);
    setForm((prev) => ({
      ...prev,
      interviewer: e.value,
    }));
    setError((prev) => ({
      ...prev,
      interviewer: "",
    }));
  };
  const handleSubmit = (e) => {
    setIsSearch(true);
    e.preventDefault();
    if (form?.date === "" || !form?.date) {
      setError((prev) => ({
        ...prev,
        date: "Select your date.",
      }));
    }
    if (form?.interviewer === "" || !form?.interviewer) {
      setError((prev) => ({
        ...prev,
        interviewer: "Select your interviewer.",
      }));
    } else {
      dispatch(
        getCalendarEvent({
          data: form,
          callback: (res) => {
            setData(res?.data?.result);
          },
        })
      );
    }
  };

  return (
    <div className="interviewer-schedule-model">
      <div
        className="calender-icon"
        onClick={() => {
          setForm((prev) => ({}));
          setData("");
          setIsCalenderOpen(!isCalenderOpen);
        }}
      >
        <FontAwesomeIcon icon={faCalendarAlt} />
      </div>
      {isCalenderOpen && (
        <div className="delete-popup">
          <div className="calender-popup p-3">
            <div className="d-flex f-16 justify-content-between">
              <span className="f-18 f-sem-bol">Interview Slots</span>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={() => setIsCalenderOpen(false)}
              ></button>
            </div>
            <div>
              <Input
                label="Date"
                labelClass="my-2 f-12 f-bol"
                inputClass="f-12 f-reg rounded input-shadow"
                className={"common-input"}
                name="date"
                type="date"
                value={form?.date}
                error={error?.date}
                onChange={handleInput}
                borderNone
              />
            </div>
            <div>
              <SearchSelect
                name="mode"
                value={form?.interviewer}
                error={error?.interviewer}
                label="Interviewer"
                onChange={handleSearch}
                options={interviewersList}
                labelClass="my-2 f-l f-bol"
                inputClass="f-l f-reg rounded input-shadow"
                placeholder={"Please Select Interviewer"}
              />
            </div>
            <div className="d-flex justify-content-center mt-2">
              <Button
                text="Search"
                className={`f-12 f-sem-bol rounded addOrCancel add-btn me-3 common-btn`}
                onClick={handleSubmit}
              />
            </div>
            {/* time slots */}
            {data ? (
              <>
                {data?.customDays && data?.customDays[0].length > 0 ? (
                  <>
                    <div className="f-16 f-bol text-theme-blue mt-1">
                      Custom Days
                    </div>
                    {data?.customDays &&
                      data?.customDays[0].map((data, index) => (
                        <>
                          <div className="d-flex flex-wrap flex-column">
                            <div className="d-flex flex-wrap">
                              <span className="d-flex justify-content-between">
                                <div key={index} className="f-12 f-sem-bol">
                                  {displayTimeSlots[data?.from]} -{" "}
                                  {displayTimeSlots[data?.to]}{" "}
                                </div>
                              </span>
                            </div>
                          </div>
                        </>
                      ))}
                  </>
                ) : (
                  ""
                )}
                {data?.sevenDays && data?.sevenDays[0].length > 0 ? (
                  <>
                    <div className="f-16 f-bol text-theme-blue mt-1">
                      Seven Days
                    </div>
                    {data?.sevenDays &&
                      data?.sevenDays[0].map((data, index) => (
                        <>
                          <div className="d-flex flex-wrap flex-column">
                            <div className="d-flex flex-wrap">
                              <span className="d-flex justify-content-between">
                                <div key={index} className="f-12 f-sem-bol">
                                  {displayTimeSlots[data?.from]} -{" "}
                                  {displayTimeSlots[data?.to]}{" "}
                                </div>
                              </span>
                            </div>
                          </div>
                        </>
                      ))}
                  </>
                ) : (
                  ""
                )}
                {data?.thirtyDays && data?.thirtyDays[0].length > 0 ? (
                  <>
                    <div className="f-16 f-bol text-theme-blue mt-1">
                      Thirty Days
                    </div>
                    {data?.thirtyDays &&
                      data?.thirtyDays[0].map((data, index) => (
                        <>
                          <div className="d-flex flex-wrap flex-column">
                            <div className="d-flex flex-wrap">
                              <span className="d-flex justify-content-between">
                                <div key={index} className="f-12 f-sem-bol">
                                  {displayTimeSlots[data?.from]} -{" "}
                                  {displayTimeSlots[data?.to]}{" "}
                                </div>
                              </span>
                            </div>
                          </div>
                        </>
                      ))}
                  </>
                ) : (
                  ""
                )}
              </>
            ) : form?.date !== "" &&
              form?.interviewer !== "" &&
              data &&
              data?.length <= 0 ? (
              <div className="my-2 f-14 f-bol">No time slots available</div>
            ) : (
              <div className="my-2 f-14 f-bol"></div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Calender;
