import { toast } from "react-toastify";
import validator from "validator";
import { Toast } from "../utils/helper";

function validateEditIncservePosition(data) {
  const errors = {};
  let message = "";
  if (data?.form?.customField && data?.form?.customField.length > 0) {
    data?.form?.customField.forEach((itm, i) => {
      if (itm.required && itm.fieldValue === "") {
        errors.customField = message = `${itm.fieldName} can not be empty`;
      }
    });
  }
  if (data?.form?.customField && data?.form?.customField.length > 0) {
    let valueArr = data?.form?.customField.map((customField) => {
      return customField?.fieldName.toUpperCase();
    });
    let isDuplicate = valueArr.some((item, idx) => {
      return valueArr.indexOf(item) !== idx;
    });
    if (isDuplicate) {
      errors.customField = message = `Duplicate input fields is not allowed`;
    }
  }
  if (validator.isEmpty(data?.form?.title.trim()))
    errors.title = message = "Please enter the position title.";
  else if (!validator.isLength(data?.form?.title.trim(), { min: 5, max: 50 }))
    errors.title = message =
      "The minimum length required is 5 and the maximum length allowed is 50 characters.";
  if (data?.form?.positionType === "Client Hiring") {
    if (validator.isEmpty(data?.form?.clientName.trim()))
      errors.clientName = message = "Please enter the client name.";
  }
  if (validator.isEmpty(data?.form?.domain.trim()))
    errors.domain = message = "Please select the domain.";
  if (data?.form?.jobDescription === "")
    errors.jobDescription = message =
      "Please select a file for the job description.";
  if (validator.isEmpty(data?.form?.maxExp.toString()))
    errors.maxExp = message = "Please select the required maximum experience.";
  if (validator.isEmpty(data?.form?.minExp.toString()))
    errors.minExp = message = "Please select the required minimum experience.";
  if (
    data?.form?.mandatorySkills.length === 0 &&
    data?.customMandatorySkill?.length === 0
  )
    errors.mandatorySkills = message = "Please select mandatory skills.";
  if (message) {
    toast.error(<Toast msg={message} />, {autoClose:5000, toastId: message});
  }
  return { errors, isValid: Object.keys(errors).length <= 0 };
}

export default validateEditIncservePosition;
