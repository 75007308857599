import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";
import reducers from "../reducer";
import RootSaga from "../saga";
const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);
const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];
const configureStore = (initialState) => {
  const store = createStore(
    persistedReducer,
    initialState,
    applyMiddleware(...middlewares)
  );
  sagaMiddleware.run(RootSaga);
  return store;
};
export const store = configureStore();
export const persistor = persistStore(store);
