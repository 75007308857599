import React, { memo, useState, useEffect, useMemo, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgressbar } from "react-circular-progressbar";
import {
  ExcelBtn,
  RangeDropdown,
  Pagination,
  MenuButton,
  NoRecordFound,
  SearchBar,
  Button,
  Loader,
  CloseButton,
  BackButton,
  Input,
  CreatableSelect,
  ConfirmDeletePopUp,
  CommonMenu,
} from "../../../component/commonComponent";
import doubleArrow from "../../../assets/admin Images/2arrows.svg";
import threeDot from "../../../assets/admin Images/threedot.svg";
import download from "../../../assets/images/download-cloud.svg";
import {
  getIncservePositions,
  getPosition,
  getPositionList,
} from "../../../redux/action";
import eye from "../../../assets/images/blue-eye.svg";
import { PageSize } from "../../../utils/constant";
import {
  downloadFile,
  exportToCSV,
  capitalize,
  localStorageEncrypt,
} from "../../../utils/helper";
import moment from "moment";
import feedback from "../../../assets/images/feedback.svg";
import { Link } from "react-router-dom";
import InfoTable from "../../../component/commonComponent/InfoTable";
import { EditPosition } from "./EditPosition";

const Tracker = memo(() => {
  const productInfo = useSelector((state) => state.Subscription.productInfo);
  const loading = useSelector((state) => state.Interview.loading);
  const positionList = useSelector(
    (state) => state.Interview.incservePositions
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesLimit, setEntriesLimit] = useState(PageSize);
  const [inputWord, setInputWord] = useState("");
  const [sort, setSort] = useState("");
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [order, setOrder] = useState(true);
  const [isSideSectionOpen, setIsSideSectionOpen] = useState(false);
  const [sideSectionData, setSideSectionData] = useState([]);
  const [isEditable, setIsEditable] = useState(false);
  const [editableData, setEditableData] = useState("");

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [isMapInterviewerVisible, setIsMapInterviewerVisible] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getIncservePositions({
        sort: sortBy,
        search: search,
        page: currentPage,
        pageSize: entriesLimit,
      })
    );
  }, [dispatch, search, currentPage, entriesLimit, sortBy, confirmDelete]);

  const filterBySearch = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };
  const handleChangeSort = (sort, sortOrder) => {
    setSortBy(sort + " " + (sortOrder ? "asc" : "desc"));
    setCurrentPage(1);
    setOrder(!order);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleEntriesLimit = (limit) => {
    setEntriesLimit(limit);
    setCurrentPage(1);
  };
  const PositionListForXLSXFile = useMemo(() => {
    const tmpArray = [];
    positionList &&
      positionList.data &&
      positionList.data.length > 0 &&
      positionList.data.forEach((element) => {
        tmpArray.push({
          "Position ID": element?.positionId,
          "Position Title": element?.title,
          Domain: element?.domain?.name,
          "Employer Name": element?.employer?.company?.name,
          "No of Positions": `${element?.shorlistedCandidates?.length} / ${element?.noOfPositions}`,
          "Created On": new Date(element?.createdAt)
            .toLocaleString()
            .split(", ")[0],
          "Created Time": new Date(element?.createdAt)
            .toLocaleString()
            .split(", ")[1],
        });
      });
    return tmpArray;
  }, [positionList]);

  const handleEdit = (data) => {
    setIsEditable(!isEditable);
    setEditableData(data);
  };
  const handleActiveInactive = () => {
    setIsMapInterviewerVisible(!isMapInterviewerVisible);
  };

  const handleDelete = (value) => {
    setConfirmDelete(true);
    // setDeleteId(value);
  };

  return (
    <>
      {!isEditable ? (
        <>
          {loading && <Loader />}
          <div className="page-container eep">
            <div className="eep-title-container d-flex justify-content-between align-items-center">
              <div className="eep-title f-sem-bol">Live Interview Tracking</div>
            </div>
            <div className="eep-search-exl d-flex align-items-center justify-content-between">
              <RangeDropdown handleEntriesLimit={handleEntriesLimit} />
              <div className="eep-search-exl d-flex align-items-center me-2">
                <SearchBar
                  onChange={(e) => filterBySearch(e)}
                  value={search}
                  containerClass={"search-bar-container-type1 me-2"}
                  inputClass={"search-input-type1"}
                />
              </div>
            </div>
            <div className="sp-list">
              <div className="header d-flex f-12 f-sem-bol rounded-3">
                <div className="header-content w-15">
                  <span>ID</span>
                  <img
                    src={doubleArrow}
                    alt="double"
                    className="img-fluid"
                    onClick={() => handleChangeSort("positionId", order)}
                  />
                </div>
                <div className="header-content w-30">
                  <span>Scheduled By</span>
                  <img
                    src={doubleArrow}
                    alt="double"
                    className="img-fluid"
                    onClick={() => handleChangeSort("title", order)}
                  />
                </div>
                <div className="header-content w-10">
                  <span>Employer Name</span>
                  <img
                    src={doubleArrow}
                    alt="double"
                    className="img-fluid"
                    onClick={() => handleChangeSort("domain.name", order)}
                  />
                </div>
                <div className="header-content w-30">
                  <span>Candidate</span>
                  <img
                    src={doubleArrow}
                    alt="double"
                    className="img-fluid"
                    onClick={() => handleChangeSort("employerName", order)}
                  />
                </div>
                <div className="header-content w-10">
                  <span>Interviewer Name</span>
                  <img
                    src={doubleArrow}
                    alt="double"
                    className="img-fluid"
                    onClick={() => handleChangeSort("employerName", order)}
                  />
                </div>
                <div className="header-content w-25">
                  <span>Scheduled On</span>
                  <img
                    src={doubleArrow}
                    alt="double"
                    className="img-fluid"
                    onClick={() => handleChangeSort("employerName", order)}
                  />
                </div>
                <div className="header-content w-20">
                  <span>Users Joined</span>
                </div>
                <div className="header-content w-20">
                  <span>Recording Started</span>
                </div>
                <div className="header-content w-15">
                  <span>Action</span>
                </div>
              </div>
              <div className="row-container">
                {positionList &&
                positionList.data &&
                positionList.data.length > 0 ? (
                  positionList.data?.map((data, index) => (
                    <div
                      className="support-row d-flex f-12 f-sem-bol rounded-3"
                      key={index}
                    >
                      <div className="row-content w-15">
                        <span>{data?.positionId}</span>
                      </div>
                      <div className="row-content w-25 text-center">
                        <span>{data?.title}</span>
                      </div>
                      <div className="row-content w-10">
                        <span className="px-1 text-center">
                          {data?.domain?.name}
                        </span>
                      </div>
                      <div className="row-content w-25">
                        <span className="px-1 text-center">
                          {data?.employer}
                        </span>
                      </div>
                      <div className="row-content w-15">
                        {data?.shorlistedCandidates?.length} /{" "}
                        {data?.noOfPositions}
                        {/* <span
                          className="text-center hyperlink"
                          onClick={() => {
                            setIsSideSectionOpen(!isSideSectionOpen);
                            setSideSectionData(data);
                          }}
                        >
                          {data?.shorlistedCandidates?.length} /{" "}
                          {data?.noOfPositions}
                        </span> */}
                      </div>
                      <div className="row-content w-25">
                        <span className="px-1 text-center">
                          {data?.feedbackSystem}
                        </span>
                      </div>
                      <div className="row-content w-15 text-center">
                        {moment(data?.createdAt).format("DD/MM/YYYY")}
                        <br />
                        {
                          new Date(data?.createdAt)
                            .toLocaleString()
                            .split(", ")[1]
                        }
                      </div>
                      <div className="row-content w-15 text-center">
                        {/* <MenuButton
                                                    img={threeDot}
                                                    wrapperClass={"position-relative three-dot-btn"}
                                                    handleDelete={() => handleDelete()}
                                                    handleEdit={() => handleEdit(data)}
                                                    isThirdOptionVisible={true}
                                                    isDelete={true}
                                                    thirdOptionText={"Map Interviewer"}
                                                    handleThirdOption={() => handleActiveInactive()}
                                                /> */}
                        <CommonMenu
                          options={[
                            {
                              name: "Join Interview",
                              action: () => {
                                // console.log("Join Interview");
                              },
                            },
                            {
                              name: "View Requirements",
                              action: () => {
                                // console.log("View Requirements");
                              },
                            },
                            {
                              name: "Re-Allot Interviewer",
                              action: () => {
                                // console.log("Re-Allot Interviewer");
                              },
                            },
                            {
                              name: "Re-Schedule Interview",
                              action: () => {
                                // console.log("Re-Schedule Interview");
                              },
                            },
                            {
                              name: "Edit",
                              action: () => {
                                // console.log("Edit");
                              },
                            },
                            {
                              name: "Replicate",
                              action: () => {
                                // console.log("Replicate");
                              },
                            },
                            {
                              name: "Edit Pay per Interview",
                              action: () => {
                                // console.log("Edit Pay per Interview");
                              },
                            },
                            {
                              name: "Change platform",
                              action: () => {
                                // console.log("Change platform");
                              },
                            },
                            {
                              name: "Candidate Verification",
                              action: () => {
                                // console.log("Candidate Verification");
                              },
                            },
                            {
                              name: "Give Feedback",
                              action: () => {
                                // console.log("Give Feedback");
                              },
                            },
                            {
                              name: "Cancel Interview",
                              action: () => {
                                // console.log("Cancel Interview");
                              },
                            },
                          ]}
                        />
                      </div>
                    </div>
                  ))
                ) : (
                  <NoRecordFound />
                )}
              </div>
              {isMapInterviewerVisible ? (
                <>
                  <MapInterviewerPopup
                    setIsMapInterviewerVisible={setIsMapInterviewerVisible}
                  />
                </>
              ) : (
                ""
              )}
              {confirmDelete && (
                <ConfirmDeletePopUp
                  firstFunction={() => setConfirmDelete(false)}
                  // secondFunction={() => {
                  //   if (deleteId) {
                  //     dispatch(
                  //       deleteFaqList({
                  //         id: deleteId,
                  //         callback: () => {
                  //           dispatch(
                  //             getFaqList({
                  //               page: currentPage,
                  //               pageSize: entriesLimit,
                  //             })
                  //           );
                  //           dispatch(getTagList());
                  //           setDeleteId("");
                  //           setConfirmDelete(false);
                  //         },
                  //       })
                  //     );
                  //   }
                  // }}
                  firstButtonName={"Cancel"}
                  firstButtonClass={"confirm-grey-btn"}
                  secondButtonName={"Delete"}
                  secondButtonClass={"confirm-red-btn"}
                  Message={
                    <>
                      Do you really want to delete this record?
                      <br />
                      This process cannot be undone.
                    </>
                  }
                />
              )}
            </div>
            {positionList && positionList.data && (
              <Pagination
                currentPage={currentPage}
                totalCount={positionList.total}
                pageSize={entriesLimit}
                onPageChange={handlePageChange}
              />
            )}
          </div>
        </>
      ) : (
        <EditPosition
          setIsEditable={setIsEditable}
          editableData={editableData}
        />
      )}
    </>
  );
});

export default Tracker;

export const MapInterviewerPopup = memo(({ setIsMapInterviewerVisible }) => {
  const [addQuestion, setAddQuestion] = useState("");

  const dummyInterviwer = [
    {
      id: "INT1234",
      name: "Mitesh Pokar",
    },
    {
      id: "INT1234",
      name: "Mitesh Pokar",
    },
    {
      id: "INT1234",
      name: "Mitesh Pokar",
    },
    {
      id: "INT1234",
      name: "Mitesh Pokar",
    },
    {
      id: "INT1234",
      name: "Mitesh Pokar",
    },
    {
      id: "INT1234",
      name: "Mitesh Pokar",
    },
    {
      id: "INT1234",
      name: "Mitesh Pokar",
    },
    {
      id: "INT1234",
      name: "Mitesh Pokar",
    },
    {
      id: "INT1234",
      name: "Mitesh Pokar",
    },
    {
      id: "INT1234",
      name: "Mitesh Pokar",
    },
    {
      id: "INT1234",
      name: "Mitesh Pokar",
    },
    {
      id: "INT1234",
      name: "Mitesh Pokar",
    },
    {
      id: "INT1234",
      name: "Mitesh Pokar",
    },
    {
      id: "INT1234",
      name: "Mitesh Pokar",
    },
  ];

  return (
    <div className="map-interviewer-page">
      <div className="map-interviewer-container">
        <div className="title d-flex align-items-center justify-content-between">
          <h4 className="f-sem-bol f-24"></h4>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={() => {
              setIsMapInterviewerVisible(false);
            }}
          ></button>
        </div>
        <div className="content-container">
          <div className="left me-1">
            <span className="f-bol f-18">PID12345</span>
            <div className="f-bol f-16 mb-1">Map Interviewers</div>
            <SearchBar
              containerClass={"search-bar-container-type1 me-2"}
              inputClass={"search-input-type1"}
              placeHolderText={"Search Interviewer or Skillset"}
            />
            <div className="map-interviewer-subheading f-14 p-1 text-center mt-2">
              Interviewers
            </div>
            <div className="map-interviewer-list-wrapper">
              {dummyInterviwer.map((data, index) => (
                <div className="map-interviewer-list mt-2 p-2" key={index}>
                  <input
                    type="checkbox"
                    id="vehicle1"
                    name="vehicle1"
                    value="Bike"
                  />
                  <span
                    className="f-12 f-reg map-interviewer-list-name"
                    style={{
                      marginLeft: "40px",
                    }}
                  >
                    {data?.id}
                  </span>{" "}
                  -{" "}
                  <span className="f-12 f-reg map-interviewer-list-name">
                    {data?.name}
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div className="right ms-1 mt-5">
            <div className="f-bol f-16 mb-1">Mapped Interviewers</div>
            <SearchBar
              containerClass={"search-bar-container-type1 me-2"}
              inputClass={"search-input-type1"}
              placeHolderText={"Search Interviewer or Skillset"}
            />
            <div className="map-interviewer-subheading f-14 p-1 text-center mt-2">
              Interviewers
            </div>
          </div>
        </div>
        <div className="title d-flex align-items-center justify-content-center">
          <div className="tcc-btn-container d-center">
            <Button
              text={"Save"}
              className={"common-btn rounded mail-veri-btn f-16"}
              type="button"
              onClick={() => {
                setIsMapInterviewerVisible(false);
              }}
              // onClick={handleSave}
            />
          </div>
        </div>
      </div>
    </div>
  );
});
