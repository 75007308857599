import React, { memo } from "react";
import removeField from "../../assets/images/remove-field.svg";
import { capitalizeAll } from "../../utils/helper";

const CustomInput = memo(
  ({
    className,
    name,
    value,
    onChange,
    placeholder,
    type,
    labelClass,
    inputClass,
    errorClass,
    error,
    label,
    isRequired,
    borderNone,
    disabled,
    onFocus,
    onBlur,
    onKeyUp,
    deleteField,
  }) => {
    return (
      <>
        <div className="d-flex align-items-center justify-content-between">
          {label && (
            <label htmlFor={name} className={`c-label ${labelClass}`}>
              {label}{" "}
              {isRequired && <span className="asterisk f-12">&#42;</span>}
            </label>
          )}
          <img
            src={removeField}
            alt="delete custom field"
            className="remove-field cursor-pointer"
            onClick={deleteField}
            title="remove field"
          />
        </div>
        <div
          className={`position-relative ${inputClass} d-flex align-items-center c-input-container ${
            borderNone ? "border-0" : ""
          }`}
        >
          <input
            type={type}
            className={`${className} ${error ? "error" : ""}`}
            name={name}
            placeholder={capitalizeAll(placeholder)}
            value={value}
            onChange={onChange}
            disabled={disabled}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyUp={onKeyUp}
          />
        </div>
        {error && <div className={`invalid ${errorClass}`}>{error}</div>}
      </>
    );
  }
);

export default CustomInput;
