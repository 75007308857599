import React, { memo } from "react";
import back from "../../assets/images/back-arrow.svg";

const BackButton = memo(({ onClick, className }) => {
  return (
    <div
      className={`back-btn-container f-sem-bol f-12 ${className}`}
      onClick={onClick}
    >
      <img src={back} alt="back-arrow" className="back-arrow img-fluid" />
      <span className="back">Back</span>
    </div>
  );
});

export default BackButton;
