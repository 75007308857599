import React, { memo, useState } from "react";
import { Button } from "../commonComponent";
import { useNavigate } from "react-router-dom";
import subcardLogo from "../../assets/admin Images/edit-black.svg";
import usageBtn from "../../assets/admin Images/usage-img.svg";
import greyRing from "../../assets/admin Images/grey-ring.svg";
import blueDot from "../../assets/admin Images/blue-dot.svg";
import { capitalize } from "../../utils/helper";
import pencil from "../..//assets/admin Images/pencil.svg";
import moment from "moment";
import timeRange from "../../assets/admin Images/time-range.svg";

const SubscriptionCard = memo(
  ({ data, setCurrentSubscriptionData, className, status, employerName }) => {
    const navigate = useNavigate();
    const handleViewUsage = (data) => {
      navigate("/usage-dashboard", {
        state: {
          data: data,
          employerName: employerName,
        },
      });
    };

    return (
      <div className={`subscription-card ${className}`}>
        <div className="sub-card-head">
          <div className="sub-card-label f-med">
            {data?.orderId?.paymentMode === "online"
              ? "Online Payment"
              : `Manually Added - ${data?.orderId?.createdBy?.name}`}
          </div>
          {status && (
            <div className={`sub-card-label f-med ms-1 ${status}`}>
              {status}
            </div>
          )}
        </div>
        <div className="sub-card-body justify-content-between">
          <div className="sub-card-body-part-1">
            <img
              src={data?.product?.icon}
              alt="product-logo"
              className="sub-card-logo"
            />
            <div className="sub-card-plan-details">
              <div
                className={`sub-card-plan-status f-sem-bol ${
                  data?.status === "Subscribed"
                    ? "status-ongoing"
                    : data?.status === "Expired"
                    ? "status-expired"
                    : data?.status === "Cancelled"
                    ? "status-canceled"
                    : ""
                }`}
              >
                <span>
                  {data?.status === "Subscribed" ? "Ongoing" : data?.status}
                </span>
              </div>
              <div className="sub-card-product-name f-16 f-sem-bol mt-2">
                {data?.product?.name}
              </div>
              <div className="sub-card-product-name f-16 f-sem-bol">
                {data?.product?.label}
              </div>
              <div className="sub-card-plan-name f-16 f-bol">
                {data?.plan?.name}
              </div>
              <div className="sub-card-plan-price f-24 f-bol text-theme-blue">
                INR {data?.orderId?.planPrice}.00
              </div>
            </div>
          </div>
          <div className="sub-card-divider"></div>
          <div className="sub-card-body-part-2">
            <div className="time-range-container">
              <img
                src={timeRange}
                alt="time-range"
                className="time-range-img"
              />
              <span className="f-sem-bol f-10 time-range-start">
                Purchased On <br />
                {moment(data?.createdAt).format("DD/MM/YYYY")}
              </span>
              <span className="f-sem-bol f-10 time-range-end">
                Next Billing On <br />
                {moment(data?.endAt).format("DD/MM/YYYY")}
              </span>
            </div>
            <div className="sub-card-btn-container">
              <button
                className="f-sem-bol sub-card-btn rounded-pill sub-card-btn-1 no-wrap"
                onClick={() => handleViewUsage(data)}
              >
                View Usage
              </button>
              <button
                className="f-sem-bol sub-card-btn rounded-pill sub-card-btn-2 no-wrap"
                onClick={() => {
                  setCurrentSubscriptionData(data);
                }}
              >
                Manage Subscription
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default SubscriptionCard;
