import React, { useEffect, useState, memo, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import transSum from "../assets/admin Images/trans_sum.svg";
import plus from "../assets/admin Images/plus-icon.svg";
import video from "../assets/admin Images/video-cam-blue.svg";
import recording from "../assets/admin Images/recording.png";

import {
  Button,
  Input,
  Loader,
  Pagination,
  RangeDropdown,
  ConfirmDeletePopUp,
  SearchBar,
  NoRecordFound,
} from "../component/commonComponent";

import MeetingLinkBtn from "../component/commonComponent/MeetingLinkBtn";
import { getMeetingDetails, postMeetingDetails } from "../redux/action";
import moment from "moment";

const Meetings = () => {
  const dispatch = useDispatch();

  const MeetingList = useSelector((state) => state.Interview.meetingDetails);

  const loading = useSelector((state) => state.UserAuth.loading);

  const [popUp, setPopup] = useState(false);
  const [inputWord, setInputWord] = useState("");
  const [entriesLimit, setEntriesLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const headerContent = [
    {
      name: "#",
      width: "w-10",
    },
    {
      name: "Title",
      width: "w-25",
    },
    {
      name: "Meeting Link",
      width: "w-25",
    },
    {
      name: "Created On",
      width: "w-25",
    },
  ];

  useEffect(() => {
    dispatch(
      getMeetingDetails({
        page: currentPage,
        pageSize: entriesLimit,
        search: inputWord,
      })
    );
  }, [dispatch, entriesLimit, currentPage, inputWord]);

  const handleEntriesLimit = (limit) => {
    setEntriesLimit(limit);
    setCurrentPage(1);
  };
  const filterBySearch = (e) => {
    const value = e.target.value;
    const isValidValue = /^[a-zA-Z0-9!.@#$%^&*_+\-={};':"\s*]*$/.test(value);
    if (isValidValue) {
      setInputWord(value);
      setCurrentPage(1);
    }
    // setInputWord(e.target.value);

    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      {loading && <Loader />}
      <div className="page-container eep">
        <div className="mpl-title-container d-flex justify-content-between align-items-center rounded-3">
          <div className="text-theme-blue f-sem-bol f-20">Meetings</div>
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <RangeDropdown handleEntriesLimit={handleEntriesLimit} />
          <div className="d-flex align-items-center">
            <SearchBar
              onChange={(e) => filterBySearch(e)}
              value={inputWord}
              containerClass={"search-bar-container-type1"}
              inputClass={"search-input-type1"}
            />
            <Button
              text={"Create Meeting"}
              className={"common-btn f-12 f-sem-bol ms-2"}
              isImage={true}
              image={plus}
              onClick={() => setPopup(true)}
            />
          </div>
        </div>
        <div className="sp-list">
          <div className="header d-flex f-12 f-sem-bol rounded-3">
            {headerContent.map((data, index) => (
              <div className={`header-content ${data.width}`} key={index}>
                <span>{data.name}</span>
              </div>
            ))}
          </div>
          <div className="row-container">
            {MeetingList && MeetingList?.data?.length > 0 ? (
              MeetingList?.data?.map((data, index) => (
                <div
                  className="support-row d-flex f-12 f-sem-bol rounded-3"
                  key={index}
                >
                  <div className="row-content w-10 no-wrap">
                    <span>{index + 1}</span>
                  </div>
                  <div className="row-content w-25">
                    <span className="t-clip">{data?.title}</span>
                  </div>

                  <div className="row-content w-25 d-flex flex-wrap">
                    <div className="d-flex">
                      <a
                        href={data?.interviewLink}
                        title="Join Meeting"
                        target="_blank"
                        rel="noreferrer"
                        className="invite-div-join-meet d-center f-10 f-sem-bol rounded"
                        style={{ height: "25px", width: "99px" }}
                      >
                        <img
                          src={video}
                          alt="Join Meeting"
                          className="img-fluid join-meet-img me-2"
                        />
                        <span
                          className="f-8"
                          style={{
                            fontWeight: "700",
                            lineHeight: "10.93px",
                            color: "#000",
                          }}
                        >
                          Join Interview
                        </span>
                      </a>

                      <MeetingLinkBtn
                        // className={"me-0"}
                        link={data?.interviewLink}
                        mode={``}
                        type={``}
                        style={{ height: "25px", width: "25px" }}
                      />
                      {/* transcript and summary button */}
                      <a
                        title={`Transcript and AI Summary`}
                        className="meeting-link-btn rounded d-center"
                        style={{ height: "25px", width: "25px" }}
                        href={data?.transcriptLink}
                      >
                        <img
                          src={transSum}
                          alt="copy link"
                          className="copy-link"
                        />
                      </a>
                      {/* recording button */}
                      <a
                        title={`Recording`}
                        className="meeting-link-btn rounded d-center me-0"
                        style={{ height: "25px", width: "25px" }}
                        href={data?.recordingLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={recording}
                          alt="copy link"
                          className="copy-link"
                        />
                      </a>
                    </div>
                  </div>

                  <div className="text-center row-content w-25">
                    <span>{`${moment(data?.createdAt).format(
                      "Do MMM, YYYY h:mm A"
                    )}`}</span>
                  </div>
                </div>
              ))
            ) : (
              <NoRecordFound />
            )}
          </div>
        </div>
        {MeetingList && MeetingList?.data && (
          <Pagination
            currentPage={currentPage}
            totalCount={MeetingList?.total}
            pageSize={entriesLimit}
            onPageChange={handlePageChange}
          />
        )}
        {popUp && (
          <CreateMeetingPopup
            setPopup={setPopup}
            dispatch={dispatch}
            currentPage={currentPage}
            pageSize={entriesLimit}
          />
        )}
      </div>
    </>
  );
};

export default Meetings;

const CreateMeetingPopup = memo(
  ({ setPopup, dispatch, currentPage, pageSize }) => {
    const [form, setForm] = useState({
      title: "",
    });
    const [error, setError] = useState({});

    const handleChange = (e) => {
      setForm({
        ...form,
        [e.target.name]: e.target.value,
      });
      setError({
        ...error,
        [e.target.name]: "",
      });
    };

    const handleSumbit = (e) => {
      e.preventDefault();

      if (form?.title === "") {
        setError((prev) => ({
          ...prev,
          title: "Please enter your title",
        }));
      } else {
        dispatch(
          postMeetingDetails({
            data: form,
            callback: () => {
              dispatch(
                getMeetingDetails({
                  page: currentPage,
                  pageSize: pageSize,
                })
              );

              setForm("");
              setPopup(false);
            },
          })
        );
      }
    };

    return (
      <div className="sp-popup">
        <div className="popup-box p-3 new-meeting-popup">
          <div className="titile d-flex justify-content-between align-items-center">
            <h6>Create Meeting</h6>
            <Button
              type="button"
              className={"btn-close"}
              label={"Close"}
              onClick={() => {
                setPopup(false);
                setForm("");
              }}
            />
          </div>
          <div className="">
            <div className="input-field">
              <Input
                label="Title"
                name="title"
                isRequired={true}
                labelClass="my-1 f-12 f-bol"
                inputClass="f-12 f-med rounded input-shadow"
                className={"common-input"}
                placeholder={"Enter Title of meeting"}
                value={form?.title}
                onChange={(e) => handleChange(e)}
                error={error?.title}
                borderNone
              />
            </div>

            <div className="delete-box-buttons d-flex justify-content-evenly w-100 mt-4">
              <Button
                text={"Close"}
                className={`cancel-btn rounded`}
                onClick={() => {
                  setPopup(false);
                  setForm("");
                }}
              />
              <Button
                text={"Save"}
                className={`save-btn rounded`}
                onClick={(e) => handleSumbit(e)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
);
