import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import datePickerArrow from "../../assets/admin Images/date-picker-arrow.svg";

const CommonDatePicker = ({ value, placeholderText, onChange, name }) => {
  return (
    <div className="common-date-picker">
      <DatePicker
        name={name}
        selected={value}
        className={`date-picker f-sem-bol rounded`}
        onChange={onChange}
        placeholderText={placeholderText}
      />
      <img
        src={datePickerArrow}
        alt="date-picker-arrow"
        className="date-picker-arrow"
      />
    </div>
  );
};

export default CommonDatePicker;
