import React, { memo, useEffect, useState } from "react";

const TabsContainer = memo(({ tabs, setTabName, setPage, setActiveTab }) => {
  const [index, setIndex] = useState(0);
  useEffect(() => {
    document.getElementById("underline").style.marginLeft = `${index * 280}px`;
  }, [index]);
  return (
    <div className="tabs-container d-flex position-relative f-sem-bol f-18">
      {tabs.map((tab, index) => (
        <div
          key={index}
          className="tab"
          onClick={() => {
            setIndex(index);
            setTabName(tab.setTab);
            setActiveTab && setActiveTab(tab.name);
            setPage(tab.page);
          }}
        >
          {tab.name}
        </div>
      ))}
      <div className="tab-underline position-absolute" id="underline"></div>
    </div>
  );
});

export default TabsContainer;
