import React from "react";
import { memo, useState, useEffect } from "react";
import linkImg from "../../assets/images/link_icon.svg";

import { Toast } from "../../utils/helper";
import { toast } from "react-toastify";

const MeetingLinkBtn = memo(({ link, type, mode, className, style }) => {
  const [iscopied, setIsCopied] = useState(false);
  useEffect(() => {
    setIsCopied(false);
  }, []);
  return (
    <button
      title={`Copy Candidate Meeting Link`}
      className={`meeting-link-btn rounded d-center ${className}`}
      onClick={() => {
        navigator.clipboard.writeText(link);
        setIsCopied(true);
        toast.success(<Toast msg="Meet Link Copied Successfully." />);
      }}
      style={style}
    >
      <img src={linkImg} alt="copy link" className="copy-link" />
    </button>
  );
});

export default MeetingLinkBtn;
