import React, { memo, useState, useRef } from "react";
import { capitalize, useOutsideAlerter } from "../../utils/helper";
import filter from "../../assets/images/filter.svg";

const QuestionBankFilter = memo(
  ({ FilterSkillsList, filterData, setFilterData }) => {
    const [showFilter, setShowFilter] = useState(false);
    const ref = useRef();
    useOutsideAlerter(ref, () => setShowFilter(false));
    const difficultyLevelOptions = ["select", "easy", "medium", "hard"];
    const questionTypeOptions = ["select", "theoretical", "coding", "syntax"];
    const skillsOptions = FilterSkillsList ? FilterSkillsList : [];
    const handleShowFilter = () => {
      setShowFilter(!showFilter);
    };
    const handleFilterData = (e, name) => {
      setFilterData((prev) => {
        return { ...prev, [name]: e.target.value, page: 1 };
      });
    };

    const handleClearFilter = () => {
      setFilterData((prev) => {
        return {
          ...prev,
          difficultyLevel: "",
          questionType: "",
          language: "",
          page: 1,
        };
      });
      setShowFilter(false);
    };

    return (
      <div className="filter-btn-container position-relative ms-2">
        <button className="filter-btn d-center" onClick={handleShowFilter}>
          <img src={filter} alt="filter-btn" className="filter-img img-fluid" />
        </button>
        {showFilter && (
          <div
            className="position-absolute question-filter-btn-popup input-shadow rounded"
            ref={ref}
          >
            <div className="my-2">
              <div className="f-12 f-sem-bol mb-1">Difficulty Level</div>
              <select
                className="f-12 f-med p-1 qfb-select"
                onChange={(e) => handleFilterData(e, "difficultyLevel")}
                value={
                  filterData?.difficultyLevel ? filterData?.difficultyLevel : ""
                }
              >
                {difficultyLevelOptions.map((option, index) => (
                  <option
                    key={index}
                    value={option === "select" ? "" : option}
                    className={"f-12 f-med qfb-option"}
                  >
                    {capitalize(option)}
                  </option>
                ))}
              </select>
            </div>
            <div className="my-2">
              <div className="f-12 f-sem-bol mb-1">Question Type</div>
              <select
                className="f-12 f-med p-1 qfb-select"
                onChange={(e) => handleFilterData(e, "questionType")}
                value={filterData?.questionType ? filterData?.questionType : ""}
              >
                {questionTypeOptions.map((option, index) => (
                  <option
                    key={index}
                    value={option === "select" ? "" : option}
                    className={"f-12 f-med qfb-option"}
                  >
                    {capitalize(option)}
                  </option>
                ))}
              </select>
            </div>
            <div className="my-2">
              <div className="f-12 f-sem-bol mb-1">Skills</div>
              <select
                className="f-12 f-med p-1 qfb-select"
                onChange={(e) => handleFilterData(e, "language")}
                value={filterData?.language ? filterData?.language : ""}
              >
                {skillsOptions.map((option, index) => (
                  <option
                    key={index}
                    value={option === "select" ? "" : option}
                    className={"f-12 f-med qfb-option"}
                  >
                    {capitalize(option)}
                  </option>
                ))}
              </select>
            </div>
            <button
              className="filter-clear-btn d-flex align-items-center justify-content-center"
              onClick={handleClearFilter}
            >
              Clear Filter
            </button>
          </div>
        )}
      </div>
    );
  }
);

export default QuestionBankFilter;
