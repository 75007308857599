import {
  GET_ORDERS_LIST,
  GET_ORDERS_LIST_SUCCESS,
  GET_ORDERS_LIST_FAILURE,
  GET_PRODUCT_ID,
  GET_PRODUCT_LIST,
  GET_PRODUCT_LIST_SUCCESS,
  GET_PRODUCT_LIST_FAILURE,
  GET_PLANS_AND_PRICING,
  GET_PLANS_AND_PRICING_SUCCESS,
  GET_PLANS_AND_PRICING_FAILURE,
  GET_PRODUCT_HIGHLIGHT,
  GET_PRODUCT_HIGHLIGHT_SUCCESS,
  GET_PRODUCT_HIGHLIGHT_FAILURE,
  ADD_PRODUCT_HIGHLIGHT,
  ADD_PRODUCT_HIGHLIGHT_SUCCESS,
  ADD_PRODUCT_HIGHLIGHT_FAILURE,
  DELETE_PRODUCT_HIGHLIGHT,
  DELETE_PRODUCT_HIGHLIGHT_SUCCESS,
  DELETE_PRODUCT_HIGHLIGHT_FAILURE,
  ADD_EDIT_PRODUCT,
  ADD_EDIT_PRODUCT_SUCCESS,
  ADD_EDIT_PRODUCT_FAILURE,
  DELETE_PRODUCT,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAILURE,
  GET_PRODUCTS_PLANS,
  DELETE_PLAN,
  DELETE_PLAN_SUCCESS,
  DELETE_PLAN_FAILURE,
  CHANGE_PLAN_STATUS,
  CHANGE_PLAN_STATUS_SUCCESS,
  CHANGE_PLAN_STATUS_FAILURE,
  ADD_EDIT_PLAN,
  ADD_EDIT_PLAN_SUCCESS,
  ADD_EDIT_PLAN_FAILURE,
  GET_CUSTOM_PLAN,
  GET_CUSTOM_PLAN_SUCCESS,
  GET_CUSTOM_PLAN_FAILURE,
  GET_PLAN_SERVICES,
  GET_PLAN_SERVICES_SUCCESS,
  GET_PLAN_SERVICES_FAILURE,
  GET_PLAN_ADDONS,
  GET_PLAN_ADDONS_SUCCESS,
  GET_PLAN_ADDONS_FAILURE,
  ADD_PLAN_SERVICES,
  ADD_PLAN_SERVICES_SUCCESS,
  ADD_PLAN_SERVICES_FAILURE,
  DELETE_PLAN_SERVICES,
  DELETE_PLAN_SERVICES_SUCCESS,
  DELETE_PLAN_SERVICES_FAILURE,
  UPDATE_PRODUCT_VISIBILITY_FOR_USER,
  UPDATE_PRODUCT_VISIBILITY_FOR_USER_SUCCESS,
  UPDATE_PRODUCT_VISIBILITY_FOR_USER_FAILURE,
  GET_ALL_SUBSCRIPTION_LIST,
  GET_ALL_SUBSCRIPTION_LIST_SUCCESS,
  GET_ALL_SUBSCRIPTION_LIST_FAILURE,
  GET_SUBSCRIPTION_LIST,
  GET_SUBSCRIPTION_LIST_SUCCESS,
  GET_SUBSCRIPTION_LIST_FAILURE,
  ADD_SUBSCRIPTION,
  ADD_SUBSCRIPTION_SUCCESS,
  ADD_SUBSCRIPTION_FAILURE,
  CHANGE_PLAN_ADDONS_STATUS,
  CHANGE_PLAN_ADDONS_STATUS_SUCCESS,
  CHANGE_PLAN_ADDONS_STATUS_FAILURE,
  ADD_EDIT_PLAN_ADDONS,
  ADD_EDIT_PLAN_ADDONS_SUCCESS,
  ADD_EDIT_PLAN_ADDONS_FAILURE,
  GET_ADDON_SERVICE,
  GET_ADDON_SERVICE_SUCCESS,
  GET_ADDON_SERVICE_FAILURE,
  GET_EMPLOYER_ADDON_LIST,
  GET_EMPLOYER_ADDON_LIST_SUCCESS,
  GET_EMPLOYER_ADDON_LIST_FAILURE,
  GET_EMPLOYER_ACTIVE_PRODUCT_LIST,
  GET_EMPLOYER_ACTIVE_PRODUCT_LIST_SUCCESS,
  GET_EMPLOYER_ACTIVE_PRODUCT_LIST_FAILURE,
  GET_ADDON_TITLE_LIST,
  GET_ADDON_TITLE_LIST_SUCCESS,
  GET_ADDON_TITLE_LIST_FAILURE,
  DELETE_EMPLOYER_ADDON,
  DELETE_EMPLOYER_ADDON_SUCCESS,
  DELETE_EMPLOYER_ADDON_FAILURE,
  UPDATE_EMPLOYER_ADDON,
  UPDATE_EMPLOYER_ADDON_SUCCESS,
  UPDATE_EMPLOYER_ADDON_FAILURE,
  EDIT_SUBSCRIPTION_CREDITS,
  EDIT_SUBSCRIPTION_CREDITS_SUCCESS,
  EDIT_SUBSCRIPTION_CREDITS_FAILURE,
  SHOW_OR_HIDE_PRODUCT_PLAN_FOR_EMPLOYER,
  SHOW_OR_HIDE_PRODUCT_PLAN_FOR_EMPLOYER_FAILURE,
  SHOW_OR_HIDE_PRODUCT_PLAN_FOR_EMPLOYER_SUCCESS,
  GET_PLANS_AND_PRICING_WITH_USER,
  GET_PLANS_AND_PRICING_WITH_USER_SUCCESS,
  GET_PLANS_AND_PRICING_WITH_USER_FAILURE,
  FETCH_SUBSCRIPTION_DATA,
  FETCH_SUBSCRIPTION_DATA_SUCCESS,
  FETCH_SUBSCRIPTION_DATA_FAILURE,
  ADD_EDIT_CREDITS,
  ADD_EDIT_CREDITS_SUCCESS,
  ADD_EDIT_CREDITS_FAILURE,
} from "./types";

export const editSubscriptionCredit = (payload) => ({
  type: EDIT_SUBSCRIPTION_CREDITS,
  payload,
});
export const editSubscriptionCreditSuccess = () => ({
  type: EDIT_SUBSCRIPTION_CREDITS_SUCCESS,
});
export const editSubscriptionCreditFailure = () => ({
  type: EDIT_SUBSCRIPTION_CREDITS_FAILURE,
});

export const getEmployerActiveProduct = (payload) => ({
  type: GET_EMPLOYER_ACTIVE_PRODUCT_LIST,
  payload,
});
export const getEmployerActiveProductListSuccess = (payload) => ({
  type: GET_EMPLOYER_ACTIVE_PRODUCT_LIST_SUCCESS,
  payload,
});
export const getEmployerActiveProductListFailure = () => ({
  type: GET_EMPLOYER_ACTIVE_PRODUCT_LIST_FAILURE,
});

export const getAddonTitleList = (payload) => ({
  type: GET_ADDON_TITLE_LIST,
  payload,
});
export const getAddonTitleListSuccess = (payload) => ({
  type: GET_ADDON_TITLE_LIST_SUCCESS,
  payload,
});
export const getAddonTitleListFailure = () => ({
  type: GET_ADDON_TITLE_LIST_FAILURE,
});

export const deleteEmployerAddon = (payload) => ({
  type: DELETE_EMPLOYER_ADDON,
  payload,
});
export const deleteEmployerAddonSuccess = () => ({
  type: DELETE_EMPLOYER_ADDON_SUCCESS,
});
export const deleteEmployerAddonFailure = () => ({
  type: DELETE_EMPLOYER_ADDON_FAILURE,
});

export const updateEmployerAddon = (payload) => ({
  type: UPDATE_EMPLOYER_ADDON,
  payload,
});
export const updateEmployerAddonSuccess = () => ({
  type: UPDATE_EMPLOYER_ADDON_SUCCESS,
});
export const updateEmployerAddonFailure = () => ({
  type: UPDATE_EMPLOYER_ADDON_FAILURE,
});

// ORDERS
export const getOrderList = (payload) => ({
  type: GET_ORDERS_LIST,
  payload,
});
export const getOrderListSuccess = (payload) => ({
  type: GET_ORDERS_LIST_SUCCESS,
  payload,
});
export const getOrderListFailure = () => ({
  type: GET_ORDERS_LIST_FAILURE,
});

//PRODUCT
export const getProductId = (payload) => {
  return {
    type: GET_PRODUCT_ID,
    payload,
  };
};
export const getProductList = () => ({
  type: GET_PRODUCT_LIST,
});
export const getProductListSuccess = (payload) => ({
  type: GET_PRODUCT_LIST_SUCCESS,
  payload,
});
export const getProductListFailure = () => ({
  type: GET_PRODUCT_LIST_FAILURE,
});
export const getPlansAndPricingList = (payload) => ({
  type: GET_PLANS_AND_PRICING,
  payload,
});
export const getPlansAndPricingListSuccess = (payload) => ({
  type: GET_PLANS_AND_PRICING_SUCCESS,
  payload,
});
export const getPlansAndPricingListFailure = () => ({
  type: GET_PLANS_AND_PRICING_FAILURE,
});

export const getPlansAndPricingWithUserList = (payload) => ({
  type: GET_PLANS_AND_PRICING_WITH_USER,
  payload,
});
export const getPlansAndPricingWithUserSuccess = (payload) => ({
  type: GET_PLANS_AND_PRICING_WITH_USER_SUCCESS,
  payload,
});
export const getPlansAndPricingWithUserFailure = () => ({
  type: GET_PLANS_AND_PRICING_WITH_USER_FAILURE,
});

export const getPlanServices = (payload) => ({
  type: GET_PLAN_SERVICES,
  payload,
});
export const getPlanServicesSuccess = (payload) => ({
  type: GET_PLAN_SERVICES_SUCCESS,
  payload,
});
export const getPlanServicesFailure = () => ({
  type: GET_PLAN_SERVICES_FAILURE,
});
export const getAddonServices = (payload) => ({
  type: GET_ADDON_SERVICE,
  payload,
});
export const getAddonServicesSuccess = (payload) => ({
  type: GET_ADDON_SERVICE_SUCCESS,
  payload,
});
export const getAddonServicesFailure = () => ({
  type: GET_ADDON_SERVICE_FAILURE,
});
export const addPlanServices = (payload) => ({
  type: ADD_PLAN_SERVICES,
  payload,
});
export const addPlanServicesSuccess = () => ({
  type: ADD_PLAN_SERVICES_SUCCESS,
});
export const addPlanServicesFailure = () => ({
  type: ADD_PLAN_SERVICES_FAILURE,
});
export const deletePlanServices = (payload) => ({
  type: DELETE_PLAN_SERVICES,
  payload,
});
export const deletePlanServicesSuccess = () => ({
  type: DELETE_PLAN_SERVICES_SUCCESS,
});
export const deletePlanServicesFailure = () => ({
  type: DELETE_PLAN_SERVICES_FAILURE,
});
export const getPlanAddons = (payload) => ({
  type: GET_PLAN_ADDONS,
  payload,
});
export const getPlanAddonsSuccess = (payload) => ({
  type: GET_PLAN_ADDONS_SUCCESS,
  payload,
});
export const getPlanAddonsFailure = () => ({
  type: GET_PLAN_ADDONS_FAILURE,
});
export const getProductHighlight = () => ({
  type: GET_PRODUCT_HIGHLIGHT,
});
export const getProductHighlightSuccess = (payload) => ({
  type: GET_PRODUCT_HIGHLIGHT_SUCCESS,
  payload,
});
export const getProductHighlightFailure = () => ({
  type: GET_PRODUCT_HIGHLIGHT_FAILURE,
});
export const addProductHighlight = (payload) => ({
  type: ADD_PRODUCT_HIGHLIGHT,
  payload,
});
export const addProductHighlightSuccess = () => ({
  type: ADD_PRODUCT_HIGHLIGHT_SUCCESS,
});
export const addProductHighlightFailure = () => ({
  type: ADD_PRODUCT_HIGHLIGHT_FAILURE,
});
export const deleteProductHighlight = (payload) => ({
  type: DELETE_PRODUCT_HIGHLIGHT,
  payload,
});
export const deleteProductHighlightSuccess = () => ({
  type: DELETE_PRODUCT_HIGHLIGHT_SUCCESS,
});
export const deleteProductHighlightFailure = () => ({
  type: DELETE_PRODUCT_HIGHLIGHT_FAILURE,
});
export const addOrEditProduct = (payload) => ({
  type: ADD_EDIT_PRODUCT,
  payload,
});
export const addOrEditProductSuccess = () => ({
  type: ADD_EDIT_PRODUCT_SUCCESS,
});
export const addOrEditProductFailure = () => ({
  type: ADD_EDIT_PRODUCT_FAILURE,
});
export const deleteProduct = (payload) => ({
  type: DELETE_PRODUCT,
  payload,
});
export const deleteProductSuccess = () => ({
  type: DELETE_PRODUCT_SUCCESS,
});
export const deleteProductFailure = () => ({
  type: DELETE_PRODUCT_FAILURE,
});
export const getProductsPlans = (payload) => ({
  type: GET_PRODUCTS_PLANS,
  payload,
});
export const deletePlan = (payload) => ({
  type: DELETE_PLAN,
  payload,
});
export const deletePlanSuccess = () => ({
  type: DELETE_PLAN_SUCCESS,
});
export const deletePlanFailure = () => ({
  type: DELETE_PLAN_FAILURE,
});
export const changePlanStatus = (payload) => ({
  type: CHANGE_PLAN_STATUS,
  payload,
});
export const changePlanStatusSuccess = () => ({
  type: CHANGE_PLAN_STATUS_SUCCESS,
});
export const changePlanStatusFailure = () => ({
  type: CHANGE_PLAN_STATUS_FAILURE,
});
export const changePlanAddOnsStatus = (payload) => ({
  type: CHANGE_PLAN_ADDONS_STATUS,
  payload,
});
export const changePlanAddOnsStatusSuccess = () => ({
  type: CHANGE_PLAN_ADDONS_STATUS_SUCCESS,
});
export const changePlanAddOnsStatusFailure = () => ({
  type: CHANGE_PLAN_ADDONS_STATUS_FAILURE,
});
export const addOrEditPlan = (payload) => ({
  type: ADD_EDIT_PLAN,
  payload,
});
export const addOrEditPlanSuccess = () => ({
  type: ADD_EDIT_PLAN_SUCCESS,
});
export const addOrEditPlanFailure = () => ({
  type: ADD_EDIT_PLAN_FAILURE,
});
export const addEditPlanAddons = (payload) => ({
  type: ADD_EDIT_PLAN_ADDONS,
  payload,
});
export const addOrEditPlanAddonsSuccess = () => ({
  type: ADD_EDIT_PLAN_ADDONS_SUCCESS,
});
export const addOrEditPlanAddonsFailure = () => ({
  type: ADD_EDIT_PLAN_ADDONS_FAILURE,
});
export const getCustomPlan = (payload) => ({
  type: GET_CUSTOM_PLAN,
  payload,
});
export const getCustomPlanSuccess = (payload) => ({
  type: GET_CUSTOM_PLAN_SUCCESS,
  payload,
});
export const getCustomPlanFailure = () => ({
  type: GET_CUSTOM_PLAN_FAILURE,
});
export const updateProductVisibilityForUser = (payload) => ({
  type: UPDATE_PRODUCT_VISIBILITY_FOR_USER,
  payload,
});
export const updateProductVisibilityForUserSuccess = () => ({
  type: UPDATE_PRODUCT_VISIBILITY_FOR_USER_SUCCESS,
});
export const updateProductVisibilityForUserFailure = () => ({
  type: UPDATE_PRODUCT_VISIBILITY_FOR_USER_FAILURE,
});
export const getAllSubscriptionList = (payload) => ({
  type: GET_ALL_SUBSCRIPTION_LIST,
  payload,
});
export const getAllSubscriptionListSuccess = (payload) => ({
  type: GET_ALL_SUBSCRIPTION_LIST_SUCCESS,
  payload,
});
export const getAllSubscriptionListFailure = (payload) => ({
  type: GET_ALL_SUBSCRIPTION_LIST_FAILURE,
  payload,
});
export const getSubscriptionList = (payload) => ({
  type: GET_SUBSCRIPTION_LIST,
  payload,
});
export const getSubscriptionListSuccess = (payload) => ({
  type: GET_SUBSCRIPTION_LIST_SUCCESS,
  payload,
});
export const getSubscriptionListFailure = () => ({
  type: GET_SUBSCRIPTION_LIST_FAILURE,
});
export const postSubscription = (payload) => ({
  type: ADD_SUBSCRIPTION,
  payload,
});
export const postSubscriptionSuccess = () => ({
  type: ADD_SUBSCRIPTION_SUCCESS,
});
export const postSubscriptionFailure = () => ({
  type: ADD_SUBSCRIPTION_FAILURE,
});
export const getEmployerAddonList = (payload) => ({
  type: GET_EMPLOYER_ADDON_LIST,
  payload,
});
export const getEmployerAddonListSuccess = (payload) => ({
  type: GET_EMPLOYER_ADDON_LIST_SUCCESS,
  payload,
});
export const getEmployerAddonListFailure = () => ({
  type: GET_EMPLOYER_ADDON_LIST_FAILURE,
});

export const showOrHideProductPlanForEmployer = (payload) => ({
  type: SHOW_OR_HIDE_PRODUCT_PLAN_FOR_EMPLOYER,
  payload,
});

export const showOrHideProductPlanForEmployerSuccess = () => ({
  type: SHOW_OR_HIDE_PRODUCT_PLAN_FOR_EMPLOYER_SUCCESS,
});

export const showOrHideProductPlanForEmployerFailure = () => ({
  type: SHOW_OR_HIDE_PRODUCT_PLAN_FOR_EMPLOYER_FAILURE,
});

export const fetchSubscriptionData = (payload) => ({
  type: FETCH_SUBSCRIPTION_DATA,
  payload,
});

export const fetchSubscriptionDataSuccess = (payload) => ({
  type: FETCH_SUBSCRIPTION_DATA_SUCCESS,
  payload,
});

export const fetchSubscriptionDataFailure = () => ({
  type: FETCH_SUBSCRIPTION_DATA_FAILURE,
});

export const addEditCredits = (payload) => ({
  type: ADD_EDIT_CREDITS,
  payload,
});

export const addEditCreditsSuccess = (payload) => ({
  type: ADD_EDIT_CREDITS_SUCCESS,
  payload,
});

export const addEditCreditsFailure = () => ({
  type: ADD_EDIT_CREDITS_FAILURE,
});
