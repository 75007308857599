import React, { memo } from "react";
import { capitalizeAll } from "../../utils/helper";

const Textarea = memo(
  ({
    name,
    value,
    onChange,
    placeholder,
    textClass,
    labelClass,
    error,
    disabled,
    isRequired,
    label,
    style,
  }) => {
    return (
      <>
        {/* div */}
        {label && (
          <div>
            <label htmlFor={name} className={`c-label ${labelClass}`}>
              {label}{" "}
              {isRequired && <span className="asterisk f-12">&#42;</span>}
            </label>
          </div>
        )}
        <div>
          <textarea
            className={`common-input text-area h-auto ${textClass} ${
              error ? "error" : ""
            }`}
            name={name}
            placeholder={capitalizeAll(placeholder)}
            value={value}
            rows="3"
            cols="50"
            onChange={onChange}
            // maxLength="255"
            disabled={disabled}
            style={style}
          />
        </div>
        {error && <div className="invalid">{error}</div>}
      </>
    );
  }
);

export default Textarea;
