// =====================Login===========================
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILURE = "LOGIN_USER_FAILURE";

export const LOGOUT_USER = "LOGOUT_USER";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const LOGOUT_USER_FAILURE = "LOGOUT_USER_FAILURE";

export const CLEAR_AUTH_ERRORS = "CLEAR_AUTH_ERRORS";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const FORGOT_PASSWORD_TOKEN_VERIFY = "FORGOT_PASSWORD_TOKEN_VERIFY";
export const FORGOT_PASSWORD_TOKEN_VERIFY_SUCCESS =
  "FORGOT_PASSWORD_TOKEN_VERIFY_SUCCESS";
export const FORGOT_PASSWORD_TOKEN_VERIFY_FAILURE =
  "FORGOT_PASSWORD_TOKEN_VERIFY_FAILURE";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

// =====================SignUp===========================
export const SIGNUP_USER = "SIGNUP_USER";
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS";
export const SIGNUP_USER_FAILURE = "SIGNUP_USER_FAILURE";
export const MANUALLY_VERIFY_EMAIL = "MANUALLY_VERIFY_EMAIL";
export const MANUALLY_VERIFY_EMAIL_SUCCESS = "MANUALLY_VERIFY_EMAIL_SUCCESS";
export const MANUALLY_VERIFY_EMAIL_FAILURE = "MANUALLY_VERIFY_EMAIL_FAILURE";

// =====================My Profile===========================

export const GET_COMPANY_DETAILS = "GET_COMPANY_DETAILS";
export const GET_COMPANY_DETAILS_SUCCESS = "GET_COMPANY_DETAILS_SUCCESS";
export const GET_COMPANY_DETAILS_FAILURE = "GET_COMPANY_DETAILS_FAILURE";

export const UPDATE_COMPANY_DETAILS = "UPDATE_COMPANY_DETAILS";
export const UPDATE_COMPANY_DETAILS_SUCCESS = "UPDATE_COMPANY_DETAILS_SUCCESS";
export const UPDATE_COMPANY_DETAILS_FAILURE = "UPDATE_COMPANY_DETAILS_FAILURE";

export const GET_USER_INFORMATION = "GET_USER_INFORMATION";
export const GET_USER_INFORMATION_SUCCESS = "GET_USER_INFORMATION_SUCCESS";
export const GET_USER_INFORMATION_FAILURE = "GET_USER_INFORMATION_FAILURE";

export const UPDATE_USER_INFORMATION = "UPDATE_USER_INFORMATION";
export const UPDATE_USER_INFORMATION_SUCCESS =
  "UPDATE_USER_INFORMATION_SUCCESS";
export const UPDATE_USER_INFORMATION_FAILURE =
  "UPDATE_USER_INFORMATION_FAILURE";

export const GET_INDUSTRY_TYPE = "GET_INDUSTRY_TYPE";
export const GET_INDUSTRY_TYPE_SUCCESS = "GET_INDUSTRY_TYPE_SUCCESS";
export const GET_INDUSTRY_TYPE_FAILURE = "GET_INDUSTRY_TYPE_FAILURE";

export const GET_SUBSCRIPTION_LIST = "GET_SUBSCRIPTION_LIST";
export const GET_SUBSCRIPTION_LIST_SUCCESS = "GET_SUBSCRIPTION_LIST_SUCCESS";
export const GET_SUBSCRIPTION_LIST_FAILURE = "GET_SUBSCRIPTION_LIST_FAILURE";

export const GET_ORDERS_LIST = "GET_ORDERS_LIST";
export const GET_ORDERS_LIST_SUCCESS = "GET_ORDERS_LIST_SUCCESS";
export const GET_ORDERS_LIST_FAILURE = "GET_ORDERS_LIST_FAILURE";

// =====================Create Position===========================
export const GET_DOMAIN = "GET_DOMAIN";
export const GET_DOMAIN_SUCCESS = "GET_DOMAIN_SUCCESS";
export const GET_DOMAIN_FAILURE = "GET_DOMAIN_FAILURE";

export const GET_MANDATORY_SKILL = "GET_MANDATORY_SKILL";
export const GET_MANDATORY_SKILL_SUCCESS = "GET_MANDATORY_SKILL_SUCCESS";
export const GET_MANDATORY_SKILL_FAILURE = "GET_MANDATORY_SKILL_FAILURE";

export const GET_OPTIONAL_SKILL = "GET_OPTIONAL_SKILL";
export const GET_OPTIONAL_SKILL_SUCCESS = "GET_OPTIONAL_SKILL_SUCCESS";
export const GET_OPTIONAL_SKILL_FAILURE = "GET_OPTIONAL_SKILL_FAILURE";

export const GET_SCREENING_QUESTIONS = "GET_SCREENING_QUESTIONS";
export const GET_SCREENING_QUESTIONS_SUCCESS =
  "GET_SCREENING_QUESTIONS_SUCCESS";
export const GET_SCREENING_QUESTIONS_FAILURE =
  "GET_SCREENING_QUESTIONS_FAILURE";

//INCSERVE CREATE OR UPDATE POSITION

export const CREATE_OR_UPDATE_POSITION = "CREATE_OR_UPDATE_POSITION";
export const CREATE_OR_UPDATE_POSITION_SUCCESS =
  "CREATE_OR_UPDATE_POSITION_SUCCESS";
export const CREATE_OR_UPDATE_POSITION_FAILURE =
  "CREATE_OR_UPDATE_POSITION_FAILURE";

export const INCSERVE_EMPLOYER_ACCOUNT_LOGS = "INCSERVE_EMPLOYER_ACCOUNT_LOGS";
export const INCSERVE_EMPLOYER_ACCOUNT_LOGS_SUCCESS =
  "INCSERVE_EMPLOYER_ACCOUNT_LOGS_SUCCESS";
export const INCSERVE_EMPLOYER_ACCOUNT_LOGS_FAILURE =
  "INCSERVE_EMPLOYER_ACCOUNT_LOGS_FAILURE";

export const INCSERVE_INTERVIEWER_ACCOUNT_LOGS =
  "INCSERVE_INTERVIEWER_ACCOUNT_LOGS";
export const INCSERVE_INTERVIEWER_ACCOUNT_LOGS_SUCCESS =
  "INCSERVE_INTERVIEWER_ACCOUNT_LOGS_SUCCESS";
export const INCSERVE_INTERVIEWER_ACCOUNT_LOGS_FAILURE =
  "INCSERVE_INTERVIEWER_ACCOUNT_LOGS_FAILURE";

export const DELETE_POSITION = "DELETE_POSITION";
export const DELETE_POSITION_SUCCESS = "DELETE_POSITION_SUCCESS";
export const DELETE_POSITION_FAILURE = "DELETE_POSITION_FAILURE";

export const GET_POSITION_LIST = "GET_POSITION_LIST";
export const GET_POSITION_LIST_SUCCESS = "GET_POSITION_LIST_SUCCESS";
export const GET_POSITION_LIST_FAILURE = "GET_POSITION_LIST_FAILURE";

export const GET_PREDEFINED_POSITION_LIST = "GET_PREDEFINED_POSITION_LIST";
export const GET_PREDEFINED_POSITION_LIST_SUCCESS =
  "GET_PREDEFINED_POSITION_LIST_SUCCESS";
export const GET_PREDEFINED_POSITION_LIST_FAILURE =
  "GET_PREDEFINED_POSITION_LIST_FAILURE";

export const UPDATE_POSITION_LIST = "PUT_POSITION_LIST";
export const UPDATE_POSITION_LIST_SUCCESS = "PUT_POSITION_LIST_SUCCESS";
export const UPDATE_POSITION_LIST_FAILURE = "PUT_POSITION_LIST_FAILURE";

//=====================Product List and Plans & Pricing=================

export const GET_PRODUCT_ID = "GET_PRODUCT_ID";

export const GET_PRODUCT_LIST = "GET_PRODUCT_LIST";
export const GET_PRODUCT_LIST_SUCCESS = "GET_PRODUCT_LIST_SUCCESS";
export const GET_PRODUCT_LIST_FAILURE = "GET_PRODUCT_LIST_FAILURE";

export const GET_PLANS_AND_PRICING = "GET_PLANS_AND_PRICING";
export const GET_PLANS_AND_PRICING_SUCCESS = "GET_PLANS_AND_PRICING_SUCCESS";
export const GET_PLANS_AND_PRICING_FAILURE = "GET_PLANS_AND_PRICING_FAILURE";

export const GET_PLANS_AND_PRICING_WITH_USER =
  "GET_PLANS_AND_PRICING_WITH_USER";
export const GET_PLANS_AND_PRICING_WITH_USER_SUCCESS =
  "GET_PLANS_AND_PRICING_WITH_USER_SUCCESS";
export const GET_PLANS_AND_PRICING_WITH_USER_FAILURE =
  "GET_PLANS_AND_PRICING_WITH_USER_FAILURE";

export const SHOW_OR_HIDE_PRODUCT_PLAN_FOR_EMPLOYER =
  "SHOW_OR_HIDE_PRODUCT_PLAN_FOR_EMPLOYER";
export const SHOW_OR_HIDE_PRODUCT_PLAN_FOR_EMPLOYER_SUCCESS =
  "SHOW_OR_HIDE_PRODUCT_PLAN_FOR_EMPLOYER_SUCCESS";
export const SHOW_OR_HIDE_PRODUCT_PLAN_FOR_EMPLOYER_FAILURE =
  "SHOW_OR_HIDE_PRODUCT_PLAN_FOR_EMPLOYER_FAILURE";

export const GET_PRODUCT_HIGHLIGHT = "GET_PRODUCT_HIGHLIGHT";
export const GET_PRODUCT_HIGHLIGHT_SUCCESS = "GET_PRODUCT_HIGHLIGHT_SUCCESS";
export const GET_PRODUCT_HIGHLIGHT_FAILURE = "GET_PRODUCT_HIGHLIGHT_FAILURE";

export const DELETE_PRODUCT_HIGHLIGHT = "DELETE_PRODUCT_HIGHLIGHT";
export const DELETE_PRODUCT_HIGHLIGHT_SUCCESS =
  "DELETE_PRODUCT_HIGHLIGHT_SUCCESS";
export const DELETE_PRODUCT_HIGHLIGHT_FAILURE =
  "DELETE_PRODUCT_HIGHLIGHT_FAILURE";

export const GET_EMPLOYER_ACTIVE_PRODUCT_LIST =
  "GET_EMPLOYER_ACTIVE_PRODUCT_LIST";
export const GET_EMPLOYER_ACTIVE_PRODUCT_LIST_SUCCESS =
  "GET_EMPLOYER_ACTIVE_PRODUCT_LIST_SUCCESS";
export const GET_EMPLOYER_ACTIVE_PRODUCT_LIST_FAILURE =
  "GET_EMPLOYER_ACTIVE_PRODUCT_LIST_FAILURE";

export const ADD_PRODUCT_HIGHLIGHT = "ADD_PRODUCT_HIGHLIGHT";
export const ADD_PRODUCT_HIGHLIGHT_SUCCESS = "ADD_PRODUCT_HIGHLIGHT_SUCCESS";
export const ADD_PRODUCT_HIGHLIGHT_FAILURE = "ADD_PRODUCT_HIGHLIGHT_FAILURE";

export const ADD_EDIT_PRODUCT = "ADD_EDIT_PRODUCT";
export const ADD_EDIT_PRODUCT_SUCCESS = "ADD_EDIT_PRODUCT_SUCCESS";
export const ADD_EDIT_PRODUCT_FAILURE = "ADD_EDIT_PRODUCT_FAILURE";

export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAILURE = "DELETE_PRODUCT_FAILURE";

export const GET_PRODUCTS_PLANS = "GET_PRODUCTS_PLANS";

export const GET_PLAN_SERVICES = "GET_PLAN_SERVICES";
export const GET_PLAN_SERVICES_SUCCESS = "GET_PLAN_SERVICES_SUCCESS";
export const GET_PLAN_SERVICES_FAILURE = "GET_PLAN_SERVICES_FAILURE";

export const ADD_PLAN_SERVICES = "ADD_PLAN_SERVICES";
export const ADD_PLAN_SERVICES_SUCCESS = "ADD_PLAN_SERVICES_SUCCESS";
export const ADD_PLAN_SERVICES_FAILURE = "ADD_PLAN_SERVICES_FAILURE";

export const DELETE_PLAN_SERVICES = "DELETE_PLAN_SERVICES";
export const DELETE_PLAN_SERVICES_SUCCESS = "DELETE_PLAN_SERVICES_SUCCESS";
export const DELETE_PLAN_SERVICES_FAILURE = "DELETE_PLAN_SERVICES_FAILURE";

export const GET_PLAN_ADDONS = "GET_PLAN_ADDONS";
export const GET_PLAN_ADDONS_SUCCESS = "GET_PLAN_ADDONS_SUCCESS";
export const GET_PLAN_ADDONS_FAILURE = "GET_PLAN_ADDONS_FAILURE";

export const DELETE_PLAN = "DELETE_PLAN";
export const DELETE_PLAN_SUCCESS = "DELETE_PLAN_SUCCESS";
export const DELETE_PLAN_FAILURE = "DELETE_PLAN_FAILURE";

export const CHANGE_PLAN_STATUS = "CHANGE_PLAN_STATUS";
export const CHANGE_PLAN_STATUS_SUCCESS = "CHANGE_PLAN_STATUS_SUCCESS";
export const CHANGE_PLAN_STATUS_FAILURE = "CHANGE_PLAN_STATUS_FAILURE";

export const CHANGE_PLAN_ADDONS_STATUS = "CHANGE_PLAN_ADDONS_STATUS";
export const CHANGE_PLAN_ADDONS_STATUS_SUCCESS =
  "CHANGE_PLAN_ADDONS_STATUS_SUCCESS";
export const CHANGE_PLAN_ADDONS_STATUS_FAILURE =
  "CHANGE_PLAN_ADDONS_STATUS_FAILURE";

export const ADD_EDIT_PLAN = "ADD_EDIT_PLAN";
export const ADD_EDIT_PLAN_SUCCESS = "ADD_EDIT_PLAN_SUCCESS";
export const ADD_EDIT_PLAN_FAILURE = "ADD_EDIT_PLAN_FAILURE";

export const ADD_EDIT_PLAN_ADDONS = "ADD_EDIT_PLAN_ADDONS";
export const ADD_EDIT_PLAN_ADDONS_SUCCESS = "ADD_EDIT_PLAN_ADDONS_SUCCESS";
export const ADD_EDIT_PLAN_ADDONS_FAILURE = "ADD_EDIT_PLAN_ADDONS_FAILURE";

export const GET_CUSTOM_PLAN = "GET_CUSTOM_PLAN";
export const GET_CUSTOM_PLAN_SUCCESS = "GET_CUSTOM_PLAN_SUCCESS";
export const GET_CUSTOM_PLAN_FAILURE = "GET_CUSTOM_PLAN_FAILURE";

export const GET_ADDON_SERVICE = "GET_ADDON_SERVICE";
export const GET_ADDON_SERVICE_SUCCESS = "GET_ADDON_SERVICE_SUCCESS";
export const GET_ADDON_SERVICE_FAILURE = "GET_ADDON_SERVICE_FAILURE";

export const UPDATE_PRODUCT_VISIBILITY_FOR_USER =
  "UPDATE_PRODUCT_VISIBILITY_FOR_USER";
export const UPDATE_PRODUCT_VISIBILITY_FOR_USER_SUCCESS =
  "UPDATE_PRODUCT_VISIBILITY_FOR_USER_SUCCESS";
export const UPDATE_PRODUCT_VISIBILITY_FOR_USER_FAILURE =
  "UPDATE_PRODUCT_VISIBILITY_FOR_USER_FAILURE";

//==================== Post Interview ==================================

export const GET_POSITION = "GET_POSITION";
export const GET_POSITION_SUCCESS = "GET_POSITION_SUCCESS";
export const GET_POSITION_FAILURE = "GET_POSITION_FAILURE";

export const GET_INTERVIEW = "GET_INTERVIEW";
export const GET_INTERVIEW_SUCCESS = "GET_INTERVIEW_SUCCESS";
export const GET_INTERVIEW_FAILURE = "GET_INTERVIEW_FAILURE";

//==================== Roles and Sub User ==================================

export const ADD_OR_EDIT_SUB_USER = "ADD_OR_EDIT_SUB_USER";
export const ADD_OR_EDIT_SUB_USER_SUCCESS = "ADD_OR_EDIT_SUB_USER_SUCCESS";
export const ADD_OR_EDIT_SUB_USER_FAILURE = "ADD_OR_EDIT_SUB_USER_FAILURE";

export const ADD_OR_EDIT_EMPLOYER_SUB_USER = "ADD_OR_EDIT_EMPLOYER_SUB_USER";
export const ADD_OR_EDIT_EMPLOYER_SUB_USER_SUCCESS =
  "ADD_OR_EDIT_EMPLOYER_SUB_USER_SUCCESS";
export const ADD_OR_EDIT_EMPLOYER_SUB_USER_FAILURE =
  "ADD_OR_EDIT_EMPLOYER_SUB_USER_FAILURE";

export const GET_SUB_USER = "GET_SUB_USER";
export const GET_SUB_USER_SUCCESS = "GET_SUB_USER_SUCCESS";
export const GET_SUB_USER_FAILURE = "GET_SUB_USER_FAILURE";

export const RESTORE_SUBUSER = "RESTORE_SUBUSER";
export const RESTORE_SUBUSER_SUCCESS = "RESTORE_SUBUSER_SUCCESS";
export const RESTORE_SUBUSER_FAILURE = "RESTORE_SUBUSER_FAILURE";

export const GET_ALL_SUB_USER = "GET_ALL_SUB_USER";
export const GET_ALL_SUB_USER_SUCCESS = "GET_ALL_SUB_USER_SUCCESS";
export const GET_ALL_SUB_USER_FAILURE = "GET_ALL_SUB_USER_FAILURE";

export const DELETE_SUB_USER = "DELETE_SUB_USER";
export const DELETE_SUB_USER_SUCCESS = "DELETE_SUB_USER_SUCCESS";
export const DELETE_SUB_USER_FAILURE = "DELETE_SUB_USER_FAILURE";

export const DELETE_EMPLOYER_SUB_USER = "DELETE_EMPLOYER_SUB_USER";
export const DELETE_EMPLOYER_SUB_USER_SUCCESS =
  "DELETE_EMPLOYER_SUB_USER_SUCCESS";
export const DELETE_EMPLOYER_SUB_USER_FAILURE =
  "DELETE_EMPLOYER_SUB_USER_FAILURE";

export const RESEND_LINK = "RESEND_LINK";
export const RESEND_LINK_SUCCESS = "RESEND_LINK_SUCCESS";
export const RESEND_LINK_FAILURE = "RESEND_LINK_FAILURE";

export const ACTIVE_INACTIVE_SUBUSER = "ACTIVE_INACTIVE_SUBUSER";
export const ACTIVE_INACTIVE_SUBUSER_SUCCESS =
  "ACTIVE_INACTIVE_SUBUSER_SUCCESS";
export const ACTIVE_INACTIVE_SUBUSER_FAILURE =
  "ACTIVE_INACTIVE_SUBUSER_FAILURE";

export const GET_ROLES = "GET_ROLES";
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS";
export const GET_ROLES_FAILURE = "GET_ROLES_FAILURE";

export const ADD_OR_EDIT_ROLE = "ADD_OR_EDIT_ROLE";
export const ADD_OR_EDIT_ROLE_SUCCESS = "ADD_OR_EDIT_ROLE_SUCCESS";
export const ADD_OR_EDIT_ROLE_FAILURE = "ADD_OR_EDIT_ROLE_FAILURE";

export const DELETE_ROLES = "DELETE_ROLES";
export const DELETE_ROLES_SUCCESS = "DELETE_ROLES_SUCCESS";
export const DELETE_ROLES_FAILURE = "DELETE_ROLES_FAILURE";

export const ACTIVE_INACTIVE_ROLE = "ACTIVE_INACTIVE_ROLE";
export const ACTIVE_INACTIVE_ROLE_SUCCESS = "ACTIVE_INACTIVE_ROLE_SUCCESS";
export const ACTIVE_INACTIVE_ROLE_FAILURE = "ACTIVE_INACTIVE_ROLE_FAILURE";

export const GET_PERMISSION = "GET_PERMISSION";
export const GET_PERMISSION_SUCCESS = "GET_PERMISSION_SUCCESS";
export const GET_PERMISSION_FAILURE = "GET_PERMISSION_FAILURE";

export const GET_PRODUCT_PERMISSION = "GET_PRODUCT_PERMISSION";
export const GET_PRODUCT_PERMISSION_SUCCESS = "GET_PRODUCT_PERMISSION_SUCCESS";
export const GET_PRODUCT_PERMISSION_FAILURE = "GET_PRODUCT_PERMISSION_FAILURE";

export const GET_PRODUCT_PERMISSION_CREDIT = "GET_PRODUCT_PERMISSION_CREDIT";
export const GET_PRODUCT_PERMISSION_CREDIT_SUCCESS =
  "GET_PRODUCT_PERMISSION_CREDIT_SUCCESS";
export const GET_PRODUCT_PERMISSION_CREDIT_FAILURE =
  "GET_PRODUCT_PERMISSION_CREDIT_FAILURE";

export const GET_PRODUCT_PERMISSION_CREDIT_BY_ID =
  "GET_PRODUCT_PERMISSION_CREDIT_BY_ID";
export const GET_PRODUCT_PERMISSION_CREDIT_BY_ID_SUCCESS =
  "GET_PRODUCT_PERMISSION_CREDIT_BY_ID_SUCCESS";
export const GET_PRODUCT_PERMISSION_CREDIT_BY_ID_FAILURE =
  "GET_PRODUCT_PERMISSION_CREDIT_BY_ID_FAILURE";

export const UPDATE_PRODUCT_PERMISSION_CREDIT =
  "UPDATE_PRODUCT_PERMISSION_CREDIT";
export const UPDATE_PRODUCT_PERMISSION_CREDIT_SUCCESS =
  "UPDATE_PRODUCT_PERMISSION_CREDIT_SUCCESS";
export const UPDATE_PRODUCT_PERMISSION_CREDIT_FAILURE =
  "UPDATE_PRODUCT_PERMISSION_CREDIT_FAILURE";

export const UPDATE_PRODUCT_PERMISSION = "UPDATE_PRODUCT_PERMISSION";
export const UPDATE_PRODUCT_PERMISSION_SUCCESS =
  "UPDATE_PRODUCT_PERMISSION_SUCCESS";
export const UPDATE_PRODUCT_PERMISSION_FAILURE =
  "UPDATE_PRODUCT_PERMISSION_FAILURE";

export const ADD_PRODUCT_PERMISSION = "ADD_PRODUCT_PERMISSION";
export const ADD_PRODUCT_PERMISSION_SUCCESS = "ADD_PRODUCT_PERMISSION_SUCCESS";
export const ADD_PRODUCT_PERMISSION_FAILURE = "ADD_PRODUCT_PERMISSION_FAILURE";

export const GET_EMPLOYER_PERMISSION = "GET_EMPLOYER_PERMISSION";
export const GET_EMPLOYER_PERMISSION_SUCCESS =
  "GET_EMPLOYER_PERMISSION_SUCCESS";
export const GET_EMPLOYER_PERMISSION_FAILURE =
  "GET_EMPLOYER_PERMISSION_FAILURE";

export const ADD_ROLES = "ADD_ROLES";
export const ADD_ROLES_SUCCESS = "ADD_ROLES_SUCCESS";
export const ADD_ROLES_FAILURE = "AADD_ROLES_FAILURE";

export const GET_ROLES_RESOURCES = "GET_ROLES_RESOURCES";
export const GET_ROLES_RESOURCES_SUCCESS = "GET_ROLES_RESOURCES_SUCCESS";
export const GET_ROLES_RESOURCE_FAILURE = "GET_ROLES_RESOURCE_FAILURE";

export const GET_RESOURCE_ACTIONS = "GET_RESOURCE_ACTIONS";
export const GET_RESOURCE_ACTIONS_SUCCESS = "GET_RESOURCE_ACTIONS_SUCCESS";
export const GET_RESOURCE_ACTIONS_FAILURE = "GET_RESOURCE_ACTIONS_FAILURE";

//==================== Support ==================================

export const GET_SUPPORT_TICKET = "GET_SUPPORT_TICKET";
export const GET_SUPPORT_TICKET_SUCCESS = "GET_SUPPORT_TICKET_SUCCESS";
export const GET_SUPPORT_TICKET_FAILURE = "GET_SUPPORT_TICKET_FAILURE";

export const UPDATE_SUPPORT_TICKET_STATUS = "UPDATE_SUPPORT_TICKET_STATUS";
export const UPDATE_SUPPORT_TICKET_STATUS_SUCCESS =
  "UPDATE_SUPPORT_TICKET_STATUS_SUCCESS";
export const UPDATE_SUPPORT_TICKET_STATUS_FAILURE =
  "UPDATE_SUPPORT_TICKET_STATUS_FAILURE";

export const UPDATE_CANDIDATE_RATINGS = "UPDATE_CANDIDATE_RATINGS";
export const UPDATE_CANDIDATE_RATINGS_SUCCESS =
  "UPDATE_CANDIDATE_RATINGS_SUCCESS";
export const UPDATE_CANDIDATE_RATINGS_FAILURE =
  "UPDATE_SUPPORT_TICKET_STATUS_FAILURE";

//=================== Employer List ==============================
export const GET_EMPLOYER_ROLE_LIST = "GET_EMPLOYER_ROLE_LIST";
export const GET_EMPLOYER_ROLE_LIST_SUCCESS = "GET_EMPLOYER_ROLE_LIST_SUCCESS";
export const GET_EMPLOYER_ROLE_LIST_FAILURE = "GET_EMPLOYER_ROLE_LIST_FAILURE";

export const GET_EMPLOYER_LIST = "GET_EMPLOYER_LIST";
export const GET_EMPLOYER_LIST_SUCCESS = "GET_EMPLOYER_LIST_SUCCESS";
export const GET_EMPLOYER_LIST_FAILURE = "GET_EMPLOYER_LIST_FAILURE";

export const GET_ALL_EMPLOYERS = "GET_ALL_EMPLOYERS";
export const GET_ALL_EMPLOYERS_SUCCESS = "GET_ALL_EMPLOYERS_SUCCESS";
export const GET_ALL_EMPLOYERS_FAILURE = "GET_ALL_EMPLOYERS_FAILURE";

export const SEND_EMAIL_VERIFICATION = "SEND_EMAIL_VERIFICATION";
export const SEND_EMAIL_VERIFICATION_SUCCESS =
  "SEND_EMAIL_VERIFICATION_SUCCESS";
export const SEND_EMAIL_VERIFICATION_FAILURE =
  "SEND_EMAIL_VERIFICATION_FAILURE";

export const DELETE_EMPLOYER_PROFILE = "DELETE_EMPLOYER_PROFILE";
export const DELETE_EMPLOYER_PROFILE_SUCCESS =
  "DELETE_EMPLOYER_PROFILE_SUCCESS";
export const DELETE_EMPLOYER_PROFILE_FAILURE =
  "DELETE_EMPLOYER_PROFILE_FAILURE";

export const DELETE_EMPLOYER = "DELETE_EMPLOYER";
export const DELETE_EMPLOYER_SUCCESS = "DELETE_EMPLOYER_SUCCESS";
export const DELETE_EMPLOYER_FAILURE = "DELETE_EMPLOYER_FAILURE";

export const GET_ACTIVE_EMPLOYER = "GET_ACTIVE_EMPLOYER";
export const GET_ACTIVE_EMPLOYER_SUCCESS = "GET_ACTIVE_EMPLOYER_SUCCESS";
export const GET_ACTIVE_EMPLOYER_FAILURE = "GET_ACTIVE_EMPLOYER_FAILURE";

export const GET_ACTIVE_INCBOT_EMPLOYER = "GET_ACTIVE_INCBOT_EMPLOYER";
export const GET_ACTIVE_INCBOT_EMPLOYER_SUCCESS =
  "GET_ACTIVE_INCBOT_EMPLOYER_SUCCESS";
export const GET_ACTIVE_INCBOT_EMPLOYER_FAILURE =
  "GET_ACTIVE_INCBOT_EMPLOYER_FAILURE";

export const RESTORE_PROFILE = "RESTORE_PROFILE";
export const RESTORE_PROFILE_SUCCESS = "RESTORE_PROFILE_SUCCESS";
export const RESTORE_PROFILE_FAILURE = "RESTORE_PROFILE_FAILURE";

export const EMPLOYER_SUBSCRIPTION = "EMPLOYER_SUBSCRIPTION";
export const EMPLOYER_SUBSCRIPTION_SUCCESS = "EMPLOYER_SUBSCRIPTION_SUCCESS";
export const EMPLOYER_SUBSCRIPTION_FAILURE = "EMPLOYER_SUBSCRIPTION_FAILURE";

export const GET_ALL_SUBSCRIPTION_LIST = "GET_ALL_SUBSCRIPTION_LIST";
export const GET_ALL_SUBSCRIPTION_LIST_SUCCESS =
  "GET_ALL_SUBSCRIPTION_LIST_SUCCESS";
export const GET_ALL_SUBSCRIPTION_LIST_FAILURE =
  "GET_ALL_SUBSCRIPTION_LIST_FAILURE";

export const ADD_SUBSCRIPTION = "ADD_SUBSCRIPTION";
export const ADD_SUBSCRIPTION_SUCCESS = "ADD_SUBSCRIPTION_SUCCESS";
export const ADD_SUBSCRIPTION_FAILURE = "ADD_SUBSCRIPTION_FAILURE";

export const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION";
export const UPDATE_SUBSCRIPTION_SUCCESS = "UPDATE_SUBSCRIPTION_SUCCESS";
export const UPDATE_SUBSCRIPTION_FAILURE = "UPDATE_SUBSCRIPTION_FAILURE";

//=================== Credits ===================================

export const GET_CREDIT_HISTORY = "GET_CREDIT_HISTORY";
export const GET_CREDIT_HISTORY_SUCCESS = "GET_CREDIT_HISTORY_SUCCESS";
export const GET_CREDIT_HISTORY_FAILURE = "GET_CREDIT_HISTORY_FAILURE";

export const UPDATE_CREDIT_HISTORY = "UPDATE_CREDIT_HISTORY";
export const UPDATE_CREDIT_HISTORY_SUCCESS = "UPDATE_CREDIT_HISTORY_SUCCESS";
export const UPDATE_CREDIT_HISTORY_FAILURE = "UPDATE_CREDIT_HISTORY_FAILURE";

//==================== Inquiry ================================
export const GET_CONTACT_INQUIRY = "GET_CONTACT_INQUIRY";
export const GET_CONTACT_INQUIRY_SUCCESS = "GET_CONTACT_INQUIRY_SUCCESS";
export const GET_CONTACT_INQUIRY_FAILURE = "GET_CONTACT_INQUIRY_FAILURE";

export const GET_USER_LIST = "GET_USER_LIST";
export const GET_USER_LIST_SUCCESS = "GET_USER_LIST_SUCCESS";
export const GET_USER_LIST_FAILURE = "GET_USER_LIST_FAILURE";

export const EDIT_STATUS_CONTACT_INQUIRY = "EDIT_STATUS_CONTACT_INQUIRY";
export const EDIT_STATUS_CONTACT_INQUIRY_SUCCESS =
  "EDIT_STATUS_CONTACT_INQUIRY_SUCCESS";
export const EDIT_STATUS_CONTACT_INQUIRY_FAILURE =
  "EDIT_STATUS_CONTACT_INQUIRY_FAILURE";

export const EDIT_USER_CONTACT_INQUIRY = "EDIT_USER_CONTACT_INQUIRY";
export const EDIT_USER_CONTACT_INQUIRY_SUCCESS =
  "EDIT_USER_CONTACT_INQUIRY_SUCCESS";
export const EDIT_USER_CONTACT_INQUIRY_FAILURE =
  "EDIT_USER_CONTACT_INQUIRY_FAILURE";

export const EDIT_USER_SUPPORT = "EDIT_USER_SUPPORT";
export const EDIT_USER_SUPPORT_SUCCESS = "EDIT_USER_SUPPORT_SUCCESS";
export const EDIT_USER_SUPPORT_FAILURE = "EDIT_USER_SUPPORT_FAILURE";

export const GET_ANNOUNCEMENT_LIST = "GET_ANNOUNCEMENT_LIST";
export const GET_ANNOUNCEMENT_LIST_SUCCESS = "GET_ANNOUNCEMENT_LIST_SUCCESS";
export const GET_ANNOUNCEMENT_LIST_FAILURE = "GET_ANNOUNCEMENT_LIST_FAILURE";

export const SEND_ANNOUNCEMENT_ITEM = "SEND_ANNOUNCEMENT_ITEM";
export const SEND_ANNOUNCEMENT_ITEM_SUCCESS = "SEND_ANNOUNCEMENT_ITEM_SUCCESS";
export const SEND_ANNOUNCEMENT_ITEM_FAILURE = "SEND_ANNOUNCEMENT_ITEM_FAILURE";

export const DASHBOARD_STATS = "DASHBOARD_STATS";
export const DASHBOARD_STATS_SUCCESS = "DASHBOARD_STATS_SUCCESS";
export const DASHBOARD_STATS_FAILURE = "DASHBOARD_STATS_FAILURE";
export const DELETE_ANNOUNCEMENT = "DELETE_ANNOINCEMENT";
export const DELETE_ANNOUNCEMENT_SUCCESS = "DELETE_ANNOUNCEMENT_SUCCESS";
export const DELETE_ANNOUNCEMENT_FAILURE = "DELETE_ANNOUNCEMENT_FAILURE";

//==================== Careers ================================

export const GET_CAREERS_LIST = "GET_CAREERS_LIST";
export const GET_CAREERS_LIST_SUCCESS = "GET_CAREERS_LIST_SUCCESS";
export const GET_CAREERS_LIST_FAILURE = "GET_CAREERS_LIST_FAILURE";

export const GET_CAREERS_POSITION = "GET_CAREERS_POSITION";
export const GET_CAREERS_POSITION_SUCCESS = "GET_CAREERS_POSITION_SUCCESS";
export const GET_CAREERS_POSITION_FAILURE = "GET_CAREERS_POSITION_FAILURE";

export const GET_EXPORT_TO_EXCEL_CAREERS_POSITION =
  "GET_EXPORT_TO_EXCEL_CAREERS_POSITION";
export const GET_EXPORT_TO_EXCEL_CAREERS_POSITION_SUCCESS =
  "GET_EXPORT_TO_EXCEL_CAREERS_POSITION_SUCCESS";
export const GET_EXPORT_TO_EXCEL_CAREERS_POSITION_FAILURE =
  "GET_EXPORT_TO_EXCEL_CAREERS_POSITION_FAILURE";

export const CREATE_CAREERS_POSITION = "CREATE_CAREERS_POSITION";
export const CREATE_CAREERS_POSITION_SUCCESS =
  "CREATE_CAREERS_POSITION_SUCCESS";
export const CREATE_CAREERS_POSITION_FAILURE =
  "CREATE_CAREERS_POSITION_FAILURE";

export const UPDATE_CAREERS_POSITION = "UPDATE_CAREERS_POSITION";
export const UPDATE_CAREERS_POSITION_SUCCESS =
  "UPDATE_CAREERS_POSITION_SUCCESS";
export const UPDATE_CAREERS_POSITION_FAILURE =
  "UPDATE_CAREERS_POSITION_FAILURE";

export const DELETE_CAREERS = "DELETE_CAREERS";
export const DELETE_CAREERS_SUCCESS = "DELETE_CAREERS_SUCCESS";
export const DELETE_CAREERS_FAILURE = "DELETE_CAREERS_FAILURE";

export const DELETE_CAREERS_LIST = "DELETE_CAREERS_LIST";
export const DELETE_CAREERS_LIST_SUCCESS = "DELETE_CAREERS_LIST_SUCCESS";
export const DELETE_CAREERS_LIST_FAILURE = "DELETE_CAREERS_LIST_FAILURE";

//======================panelist===============================

export const GET_PANELIST = "GET_PANELIST";
export const GET_PANELIST_SUCCESS = "GET_PANELIST_SUCCESS";
export const GET_PANELIST_FAILURE = "GET_PANELIST_FAILURE";

export const UPDATE_PANELIST = "UPDATE_PANELIST";
export const UPDATE_PANELIST_SUCCESS = "UPDATE_PANELIST_SUCCESS";
export const UPDATE_PANELIST_FAILURE = "UPDATE_PANELIST_FAILURE";

export const NOTIFY_PANELIST = "NOTIFY_PANELIST";
export const NOTIFY_PANELIST_SUCCESS = "NOTIFY_PANELIST_SUCCESS";
export const NOTIFY_PANELIST_FAILURE = "NOTIFY_PANELIST_FAILURE";

//======================feedback_report===============================

export const GET_FEEDBACK_REPORT = "GET_FEEDBACK_REPORT";
export const GET_FEEDBACK_REPORT_SUCCESS = "GET_FEEDBACK_REPORT_SUCCESS";
export const GET_FEEDBACK_REPORT_FAILURE = "GET_FEEDBACK_REPORT_FAILURE";

//======================Question Bank=================================

export const GET_QUESTIONS = "GET_QUESTIONS";
export const GET_QUESTIONS_SUCCESS = "GET_QUESTIONS_SUCCESS";
export const GET_QUESTIONS_FAILURE = "GET_QUESTIONS_FAILURE";

export const POST_QUESTIONS = "POST_QUESTIONS";
export const POST_QUESTIONS_SUCCESS = "POST_QUESTIONS_SUCCESS";
export const POST_QUESTIONS_FAILURE = "POST_QUESTIONS_FAILURE";

export const DELETE_QUESTIONS = "DELETE_QUESTIONS";
export const DELETE_QUESTIONS_SUCCESS = "DELETE_QUESTIONS_SUCCESS";
export const DELETE_QUESTIONS_FAILURE = "DELETE_QUESTIONS_FAILURE";

export const DELETE_BULK_QUESTIONS = "DELETE_BULK_QUESTIONS";
export const DELETE_BULK_QUESTIONS_SUCCESS = "DELETE_BULK_QUESTIONS_SUCCESS";
export const DELETE_BULK_QUESTIONS_FAILURE = "DELETE_BULK_QUESTIONS_FAILURE";

export const ADD_OR_EDIT_QUESTIONS = "ADD_OR_EDIT_QUESTIONS";
export const ADD_OR_EDIT_QUESTIONS_SUCCESS = "ADD_OR_EDIT_QUESTIONS_SUCCESS";
export const ADD_OR_EDIT_QUESTIONS_FAILURE = "ADD_OR_EDIT_QUESTIONS_FAILURE";

export const GET_QUESTION_SKILLS = "GET_QUESTION_SKILLS";
export const GET_QUESTION_SKILLS_SUCCESS = "GET_QUESTION_SKILLS_SUCCESS";
export const GET_QUESTION_SKILLS_FAILURE = "GET_QUESTION_SKILLS_FAILURE";

//======================Refer And Earn =================================

export const GET_REFER_AND_EARN_LIST = "GET_REFER_AND_EARN_LIST";
export const GET_REFER_AND_EARN_LIST_SUCCESS =
  "GET_REFER_AND_EARN_LIST_SUCCESS";
export const GET_REFER_AND_EARN_LIST_FAILURE =
  "GET_REFER_AND_EARN_LIST_FAILURE";

export const ADD_SETTLE_REFER = "ADD_SETTLE_REFER";
export const ADD_SETTLE_REFER_SUCCESS = "ADD_SETTLE_REFER_SUCCESS";
export const ADD_SETTLE_REFER_FAILURE = "ADD_SETTLE_REFER_FAILURE";

export const GET_REFER_AND_EARN_FILTER_EMPLOYER_LIST =
  "GET_REFER_AND_EARN_FILTER_EMPLOYER_LIST";
export const GET_REFER_AND_EARN_FILTER_EMPLOYER_LIST_SUCCESS =
  "GET_REFER_AND_EARN_FILTER_EMPLOYER_LIST_SUCCESS";
export const GET_REFER_AND_EARN_FILTER_EMPLOYER_LIST_FAILURE =
  "GET_REFER_AND_EARN_FILTER_EMPLOYER_LIST_FAILURE";

//==========================subuser verification mail ===========================

export const SEND_SUBUSER_VERIFICATION_MAIL = "SEND_VERIFICATION_MAIL";
export const SEND_SUBUSER_VERIFICATION_MAIL_SUCCESS =
  "SEND_VERIFICATION_MAIL_SUCCESS";
export const SEND_SUBUSER_VERIFICATION_MAIL_FAILURE =
  "SEND_VERIFICATION_MAIL_FAILURE";

//==========================employer addon list===========================

export const GET_EMPLOYER_ADDON_LIST = "GET_EMPLOYER_ADDON_LIST";
export const GET_EMPLOYER_ADDON_LIST_SUCCESS =
  "GET_EMPLOYER_ADDON_LIST_SUCCESS";
export const GET_EMPLOYER_ADDON_LIST_FAILURE =
  "GET_EMPLOYER_ADDON_LIST_FAILURE";

export const GET_ADDON_TITLE_LIST = "GET_ADDON_TITLE_LIST";
export const GET_ADDON_TITLE_LIST_SUCCESS = "GET_ADDON_TITLE_LIST_SUCCESS";
export const GET_ADDON_TITLE_LIST_FAILURE = "GET_ADDON_TITLE_LIST_FAILURE";

export const DELETE_EMPLOYER_ADDON = "DELETE_EMPLOYER_ADDON";
export const DELETE_EMPLOYER_ADDON_SUCCESS = "DELETE_EMPLOYER_ADDON_SUCCESS";
export const DELETE_EMPLOYER_ADDON_FAILURE = "DELETE_EMPLOYER_ADDON_FAILURE";

export const UPDATE_EMPLOYER_ADDON = "UPDATE_EMPLOYER_ADDON";
export const UPDATE_EMPLOYER_ADDON_SUCCESS = "UPDATE_EMPLOYER_ADDON_SUCCESS";
export const UPDATE_EMPLOYER_ADDON_FAILURE = "UPDATE_EMPLOYER_ADDON_FAILURE";

//================================ faq list =================================//
export const GET_FAQ_LIST = "GET_FAQ_LIST";
export const GET_FAQ_LIST_SUCCESS = "GET_FAQ_LIST_SUCCESS";
export const GET_FAQ_LIST_FAILURE = "GET_FAQ_LIST_FAILURE";

export const GET_TAG_LIST = "GET_TAG_LIST";
export const GET_TAG_LIST_SUCCESS = "GET_TAG_LIST_SUCCESS";
export const GET_TAG_LIST_FAILURE = "GET_TAG_LIST_FAILURE";

export const ADD_OR_EDIT_FAQ = "ADD_OR_EDIT_FAQ";
export const ADD_OR_EDIT_FAQ_SUCCESS = "ADD_OR_EDIT_FAQ_SUCCESS";
export const ADD_OR_EDIT_FAQ_FAILURE = "ADD_OR_EDIT_FAQ_FAILURE";

export const DELETE_FAQ_LIST = "DELETE_FAQ_LIST";
export const DELETE_FAQ_LIST_SUCCESS = "DELETE_FAQ_LIST_SUCCESS";
export const DELETE_FAQ_LIST_FAILURE = "DELETE_FAQ_LIST_FAILURE";

export const DELETE_FAQS_LIST = "DELETE_FAQS_LIST";
export const DELETE_FAQS_LIST_SUCCESS = "DELETE_FAQS_LIST_SUCCESS";
export const DELETE_FAQS_LIST_FAILURE = "DELETE_FAQS_LIST_FAILURE";

//============================check white label =====================
export const GET_WHITE_LABEL_DETAILS = "GET_WHITE_LABEL_DETAILS";
export const GET_WHITE_LABEL_DETAILS_SUCCESS =
  "GET_WHITE_LABEL_DETAILS_SUCCESS";
export const GET_WHITE_LABEL_DETAILS_FAILURE = "CHECK_WHITE_LABEL_FAILURE";

//============================Subscription =====================
export const EDIT_SUBSCRIPTION_CREDITS = "EDIT_SUBSCRIPTION_CREDITS";
export const EDIT_SUBSCRIPTION_CREDITS_SUCCESS =
  "EDIT_SUBSCRIPTION_CREDITS_SUCCESS";
export const EDIT_SUBSCRIPTION_CREDITS_FAILURE =
  "EDIT_SUBSCRIPTION_CREDITS_FAILURE";

//============================INCSERVE =====================

//======================IncServe interviewer List =================================

//INCSERVE INTERVIEWER LIST

export const GET_INTERVIEWER_LIST = "GET_INTERVIEWER_LIST";
export const GET_INTERVIEWER_LIST_SUCCESS = "GET_INTERVIEWER_LIST_SUCCESS";
export const GET_INTERVIEWER_LIST_FAILURE = "GET_INTERVIEWER_LIST_FAILURE";

// INCSERVE INVOICE DETAILS

export const GET_INVOICE_DETAILS = "GET_INVOICE_DETAILS";
export const GET_INVOICE_DETAILS_SUCCESS = "GET_INVOICE_DETAILS_SUCCESS";
export const GET_INVOICE_DETAILS_FAILURE = "GET_INVOICE_DETAILS_FAILURE";

// INCSERVE INVOICE DETAILS BY MONTH

export const GET_INVOICE_DETAILS_BY_MONTH = "GET_INVOICE_DETAILS_BY_MONTH";
export const GET_INVOICE_DETAILS_BY_MONTH_SUCCESS =
  "GET_INVOICE_DETAILS_BY_MONTH_SUCCESS";
export const GET_INVOICE_DETAILS_BY_MONTH_FAILURE =
  "GET_INVOICE_DETAILS_BY_MONTH_FAILURE";

//INCSERVE ACCOUNT DETAILS

export const GET_INTERVIEWER_ACCOUNT_DETAILS =
  "GET_INTERVIEWER_ACCOUNT_DETAILS";
export const GET_INTERVIEWER_ACCOUNT_DETAILS_SUCCESS =
  "GET_INTERVIEWER_ACCOUNT_DETAILS_SUCCESS";
export const GET_INTERVIEWER_ACCOUNT_DETAILS_FAILURE =
  "GET_INTERVIEWER_ACCOUNT_DETAILS_FAILURE";

// ADD OR EDIT INTERVIEWER ACCOUNT DETAILS

export const ADD_OR_EDIT_INTERVIEWER_ACCOUNT_DETAILS =
  "ADD_OR_EDIT_INTERVIEWER_ACCOUNT_DETAILS";
export const ADD_OR_EDIT_INTERVIEWER_ACCOUNT_DETAILS_SUCCESS =
  "ADD_OR_EDIT_INTERVIEWER_ACCOUNT_DETAILS_SUCCESS";
export const ADD_OR_EDIT_INTERVIEWER_ACCOUNT_DETAILS_FAILURE =
  "ADD_OR_EDIT_INTERVIEWER_ACCOUNT_DETAILS_FAILURE";

// INCSERVE BANK LIST

export const GET_INTERVIEWER_BANK_NAME_LIST = "GET_INTERVIEWER_BANK_NAME_LIST";
export const GET_INTERVIEWER_BANK_NAME_LIST_SUCCESS =
  "GET_INTERVIEWER_BANK_NAME_LIST_SUCCESS";
export const GET_INTERVIEWER_BANK_NAME_LIST_FAILURE =
  "GET_INTERVIEWER_BANK_NAME_LIST_FAILURE";

// INCSERVE USER INFORMATION

export const GET_INTERVIEWER_USER_INFORMATION =
  "GET_INTERVIEWER_USER_INFORMATION";
export const GET_INTERVIEWER_USER_INFORMATION_SUCCESS =
  "GET_INTERVIEWER_USER_INFORMATION_SUCCESS";
export const GET_INTERVIEWER_USER_INFORMATION_FAILURE =
  "GET_INTERVIEWER_USER_INFORMATION_FAILURE";

// UPDATE INCSERVE INTERVIEWER USER INFORMATION

export const UPDATE_INTERVIEWER_USER_INFORMATION =
  "UPDATE_INTERVIEWER_USER_INFORMATION";
export const UPDATE_INTERVIEWER_USER_INFORMATION_SUCCESS =
  "UPDATE_INTERVIEWER_USER_INFORMATION_SUCCESS";
export const UPDATE_INTERVIEWER_USER_INFORMATION_FAILURE =
  "UPDATE_INTERVIEWER_USER_INFORMATION_FAILURE";

// UPDATE INCSERVE USER EDU AND SKILLS

export const UPDATE_INTERVIEWER_EDU_AND_SKILL_SET =
  "UPDATE_INTERVIEWER_EDU_AND_SKILL_SET";
export const UPDATE_INTERVIEWER_EDU_AND_SKILL_SET_SUCCESS =
  "UPDATE_INTERVIEWER_EDU_AND_SKILL_SET_SUCCESS";
export const UPDATE_INTERVIEWER_EDU_AND_SKILL_SET_FAILURE =
  "UPDATE_INTERVIEWER_EDU_AND_SKILL_SET_FAILURE";

//INCSERVE ADMIN REMARKS

export const GET_INTERVIEWER_ADMIN_REMARKS_INFO =
  "GET_INTERVIEWER_ADMIN_REMARKS_INFO";
export const GET_INTERVIEWER_ADMIN_REMARKS_INFO_SUCCESS =
  "GET_INTERVIEWER_ADMIN_REMARKS_INFO_SUCCESS";
export const GET_INTERVIEWER_ADMIN_REMARKS_INFO_FAILURE =
  "GET_INTERVIEWER_ADMIN_REMARKS_INFO_FAILURE";

//INCSERVE UPDATE MOCK REMARKS
export const PUT_INTERVIEWER_MOCK_REMARKS_INFO =
  "PUT_INTERVIEWER_MOCK_REMARKS_INFO";
export const PUT_INTERVIEWER_MOCK_REMARKS_INFO_SUCCESS =
  "PUT_INTERVIEWER_MOCK_REMARKS_INFO_SUCCESS";
export const PUT_INTERVIEWER_MOCK_REMARKS_INFO_FAILURE =
  "PUT_INTERVIEWER_MOCK_REMARKS_INFO_FAILURE";

//INCSERVE INTERVIEWER AUDIO LOGS

export const GET_INTERVIEWER_AUDIO_LOGS = "GET_INTERVIEWER_AUDIO_LOGS";
export const GET_INTERVIEWER_AUDIO_LOGS_SUCCESS =
  "GET_INTERVIEWER_AUDIO_LOGS_SUCCESS";
export const GET_INTERVIEWER_AUDIO_LOGS_FAILURE =
  "GET_INTERVIEWER_AUDIO_LOGS_FAILURE";

// INCSERVE UPDATE ADMIN REMARKS

export const UPDATE_INTERVIEWER_ADMIN_REMARKS_INFO =
  "UPDATE_INTERVIEWER_ADMIN_REMARKS_INFO";
export const UPDATE_INTERVIEWER_ADMIN_REMARKS_INFO_SUCCESS =
  "UPDATE_INTERVIEWER_ADMIN_REMARKS_INFO_SUCCESS";
export const UPDATE_INTERVIEWER_ADMIN_REMARKS_INFO_FAILURE =
  "UPDATE_INTERVIEWER_ADMIN_REMARKS_INFO_FAILURE";

//INCSERVE INTERVIEWERS RATINGS

export const GET_INTERVIEWER_RATINGS = "GET_INTERVIEWER_RATINGS";
export const GET_INTERVIEWER_RATINGS_SUCCESS =
  "GET_INTERVIEWER_RATINGS_SUCCESS";
export const GET_INTERVIEWER_RATINGS_FAILURE =
  "GET_INTERVIEWER_RATINGS_FAILURE";

//INCSERVE UPDATE INTERVIEWER RATINGS

export const UPDATE_INTERVIEWER_RATINGS = "UPDATE_INTERVIEWER_RATINGS";
export const UPDATE_INTERVIEWER_RATINGS_SUCCESS =
  "UPDATE_INTERVIEWER_RATINGS_SUCCESS";
export const UPDATE_INTERVIEWER_RATINGS_FAILURE =
  "UPDATE_INTERVIEWER_RATINGS_FAILURE";

// INCSERVE GET POSITION
export const GET_INCSERVE_POSITIONS = "GET_INCSERVE_POSITIONS";
export const GET_INCSERVE_POSITIONS_SUCCESS = "GET_INCSERVE_POSITIONS_SUCCESS";
export const GET_INCSERVE_POSITIONS_FAILURE = "GET_INCSERVE_POSITIONS_FAILURE";

// INCBOT GET POSITION
export const GET_INCBOT_POSITIONS = "GET_INCBOT_POSITIONS";
export const GET_INCBOT_POSITIONS_SUCCESS = "GET_INCBOT_POSITIONS_SUCCESS";
export const GET_INCBOT_POSITIONS_FAILURE = "GET_INCBOT_POSITIONS_FAILURE";

// INCBOT GET Interviews
export const GET_INCBOT_INTERVIEWS = "GET_INCBOT_INTERVIEWS";
export const GET_INCBOT_INTERVIEWS_SUCCESS = "GET_INCBOT_INTERVIEWS_SUCCESS";
export const GET_INCBOT_INTERVIEWS_FAILURE = "GET_INCBOT_INTERVIEWS_FAILURE";

// INCSERVE GET POSITION BY ID

export const GET_INCSERVE_POSITIONS_BY_ID = "GET_INCSERVE_POSITIONS_BY_ID";
export const GET_INCSERVE_POSITIONS_BY_ID_SUCCESS =
  "GET_INCSERVE_POSITIONS_BY_ID_SUCCESS";
export const GET_INCSERVE_POSITIONS_BY_ID_FAILURE =
  "GET_INCSERVE_POSITIONS_BY_ID_FAILURE";

// INCSERVE CREATE AND UPDATE POSITION

export const CREATE_OR_UPDATE_INCSERVE_POSITIONS =
  "CREATE_OR_UPDATE_INCSERVE_POSITIONS";
export const CREATE_OR_UPDATE_INCSERVE_POSITIONS_SUCCESS =
  "CREATE_OR_UPDATE_INCSERVE_POSITIONS_SUCCESS";
export const CREATE_OR_UPDATE_INCSERVE_POSITIONS_FAILURE =
  "CREATE_OR_UPDATE_INCSERVE_POSITIONS_FAILURE";

// INCSERVE MAPPED INTERVIEWERS

export const MAPPED_INTERVIEWERS = "MAPPED_INTERVIEWERS";
export const MAPPED_INTERVIEWERS_SUCCESS = "MAPPED_INTERVIEWERS_SUCCESS";
export const MAPPED_INTERVIEWERS_FAILURE = "MAPPED_INTERVIEWERS_FAILURE";

// INCSERVE DELETE POSITION

export const DELETE_INCSERVE_POSITIONS = "DELETE_INCSERVE_POSITIONS";
export const DELETE_INCSERVE_POSITIONS_SUCCESS =
  "DELETE_INCSERVE_POSITIONS_SUCCESS";
export const DELETE_INCSERVE_POSITIONS_FAILURE =
  "DELETE_INCSERVE_POSITIONS_FAILURE";

// INCSERVE POSITION DROPDOWN

export const GET_POSITIONS_DROPDOWN = "GET_POSITIONS_DROPDOWN";
export const GET_POSITIONS_DROPDOWN_SUCCESS = "GET_POSITIONS_DROPDOWN_SUCCESS";
export const GET_POSITIONS_DROPDOWN_FAILURE = "GET_POSITIONS_DROPDOWN_FAILURE";

// INCSERVE GET REQUIREMNETS

export const GET_INCSERVE_REQUIREMENTS = "GET_INCSERVE_REQUIREMENTS";
export const GET_INCSERVE_REQUIREMENTS_SUCCESS =
  "GET_INCSERVE_REQUIREMENTS_SUCCESS";
export const GET_INCSERVE_REQUIREMENTS_FAILURE =
  "GET_INCSERVE_REQUIREMENTS_FAILURE";

// INCSERVE SINGLE DELETE LIST

export const GET_INCSERVE_SINGLE_DELETE_LIST =
  "GET_INCSERVE_SINGLE_DELETE_LIST";
export const GET_INCSERVE_SINGLE_DELETE_LIST_SUCCESS =
  "GET_INCSERVE_SINGLE_DELETE_LIST_SUCCESS";
export const GET_INCSERVE_SINGLE_DELETE_LIST_FAILURE =
  "GET_INCSERVE_SINGLE_DELETE_LIST_FAILURE";

// INCSERVE BULK DELETE LIST

export const GET_INCSERVE_BULK_DELETE_LIST = "GET_INCSERVE_BULK_DELETE_LIST";
export const GET_INCSERVE_BULK_DELETE_LIST_SUCCESS =
  "GET_INCSERVE_BULK_DELETE_LIST_SUCCESS";
export const GET_INCSERVE_BULK_DELETE_LIST_FAILURE =
  "GET_INCSERVE_BULK_DELETE_LIST_FAILURE";

// INCSERVE RESCHEDULE ACCEPT

export const INCSERVE_RESCHEDULED_ACCEPT_REQUEST =
  "INCSERVE_RESCHEDULED_ACCEPT_REQUEST";
export const INCSERVE_RESCHEDULED_ACCEPT_REQUEST_SUCCESS =
  "INCSERVE_RESCHEDULED_ACCEPT_REQUEST_SUCCESS";
export const INCSERVE_RESCHEDULED_ACCEPT_REQUEST_FAILURE =
  "INCSERVE_RESCHEDULED_ACCEPT_REQUEST_FAILURE";

// INCSERVE REQUEST FOR RESCHEDULE

export const INCSERVE_REQUEST_FOR_RESCHEDULED =
  "INCSERVE_REQUEST_FOR_RESCHEDULED";
export const INCSERVE_REQUEST_FOR_RESCHEDULED_SUCCESS =
  "INCSERVE_REQUEST_FOR_RESCHEDULED_SUCCESS";
export const INCSERVE_REQUEST_FOR_RESCHEDULED_FAILURE =
  "INCSERVE_REQUEST_FOR_RESCHEDULED_FAILURE";

//CUSTOM FEILD

export const ADD_CUSTOM_FILE = "ADD_CUSTOM_FILE";
export const ADD_CUSTOM_FILE_SUCCESS = "ADD_CUSTOM_FILE_SUCCESS";
export const ADD_CUSTOM_FILE_FAILURE = "ADD_CUSTOM_FILE_FAILURE";

//INCSERVE INTERVIEWS COUNT

export const GET_INTERVIEWS_COUNT = "GET_INTERVIEWS_COUNT";
export const GET_INTERVIEWS_COUNT_SUCCESS = "GET_INTERVIEWS_COUNT_SUCCESS";
export const GET_INTERVIEWS_COUNT_FAILURE = "GET_INTERVIEWS_COUNT_FAILURE";

// INCSERVE APPROVE FEEDBACK

export const INCSERVE_APPROVE_FEEDBACK = "INCSERVE_APPROVE_FEEDBACK";
export const INCSERVE_APPROVE_FEEDBACK_SUCCESS =
  "INCSERVE_APPROVE_FEEDBACK_SUCCESS";
export const INCSERVE_APPROVE_FEEDBACK_FAILURE =
  "INCSERVE_APPROVE_FEEDBACK_FAILURE";

//INCSERVE GIVE FEEDBACK

export const INCSERVE_GIVE_FEEDBACK = "INCSERVE_GIVE_FEEDBACK";
export const INCSERVE_GIVE_FEEDBACK_SUCCESS = "INCSERVE_GIVE_FEEDBACK_SUCCESS";
export const INCSERVE_GIVE_FEEDBACK_FAILURE = "INCSERVE_GIVE_FEEDBACK_FAILURE";

//INCSERVE POST REVIEW REQUEST

export const INCSERVE_POST_REVIEW_REQUEST = "INCSERVE_POST_REVIEW_REQUEST";
export const INCSERVE_POST_REVIEW_REQUEST_SUCCESS =
  "INCSERVE_POST_REVIEW_REQUEST_SUCCESS";
export const INCSERVE_POST_REVIEW_REQUEST_FAILURE =
  "INCSERVE_POST_REVIEW_REQUEST_FAILURE";

// INCSERVE CANCEL INTERVIEW

export const INCSERVE_CANCEL_INTERVIEW = "INCSERVE_CANCEL_INTERVIEW";
export const INCSERVE_CANCEL_INTERVIEW_SUCCESS =
  "INCSERVE_CANCEL_INTERVIEW_SUCCESS";
export const INCSERVE_CANCEL_INTERVIEW_FAILURE =
  "INCSERVE_CANCEL_INTERVIEW_FAILURE";

// INCSERVE hold INTERVIEW

export const INCSERVE_HOLD_INTERVIEW = "INCSERVE_HOLD_INTERVIEW";
export const INCSERVE_HOLD_INTERVIEW_SUCCESS =
  "INCSERVE_HOLD_INTERVIEW_SUCCESS";
export const INCSERVE_HOLD_INTERVIEW_FAILURE =
  "INCSERVE_HOLD_INTERVIEW_FAILURE";

// INCSERVE UPDATE PAY PER INTERVIEW

export const INCSERVE_UPDATE_PAY_PER_INTERVIEW =
  "INCSERVE_UPDATE_PAY_PER_INTERVIEW";
export const INCSERVE_UPDATE_PAY_PER_INTERVIEW_SUCCESS =
  "INCSERVE_UPDATE_PAY_PER_INTERVIEW_SUCCESS";
export const INCSERVE_UPDATE_PAY_PER_INTERVIEW_FAILURE =
  "INCSERVE_UPDATE_PAY_PER_INTERVIEW_FAILURE";

//INCSERVE ACCEPT INTERVIEW

export const INCSERVE_ACCEPT_INTERVIEW = "INCSERVE_ACCEPT_INTERVIEW";
export const INCSERVE_ACCEPT_INTERVIEW_SUCCESS =
  "INCSERVE_ACCEPT_INTERVIEW_SUCCESS";
export const INCSERVE_ACCEPT_INTERVIEW_FAILURE =
  "INCSERVE_UPDATE_PAY_PER_INTERVIEW_FAILURE";

//INCSERVE RESHEDULED INTERVIEW

export const INCSERVE_RESCHEDULE_INTERVIEW = "INCSERVE_RESCHEDULE_INTERVIEW";
export const INCSERVE_RESCHEDULE_INTERVIEW_SUCCESS =
  "INCSERVE_RESCHEDULE_INTERVIEW_SUCCESS";
export const INCSERVE_RESCHEDULE_INTERVIEW_FAILURE =
  "INCSERVE_UPDATE_PAY_PER_INTERVIEW_FAILURE";

// INCSERVE INTERVIEWS
export const GET_INCSERVE_INTERVIEWS = "GET_INCSERVE_INTERVIEWS";
export const GET_INCSERVE_INTERVIEWS_SUCCESS =
  "GET_INCSERVE_INTERVIEWS_SUCCESS";
export const GET_INCSERVE_INTERVIEWS_FAILURE =
  "GET_INCSERVE_INTERVIEWS_FAILURE";

//INCSERVE INTERVIEW SEARCH LIST

export const GET_INCSERVE_INTERVIEWS_SEARCH_LIST =
  "GET_INCSERVE_INTERVIEWS_SEARCH_LIST";
export const GET_INCSERVE_INTERVIEWS_SEARCH_LIST_SUCCESS =
  "GET_INCSERVE_INTERVIEWS_SEARCH_LIST_SUCCESS";
export const GET_INCSERVE_INTERVIEWS_SEARCH_LIST_FAILURE =
  "GET_INCSERVE_INTERVIEWS_SEARCH_LIST_FAILURE";

//INCBOT INTERVIEW SEARCH LIST

export const GET_INCBOT_INTERVIEWS_SEARCH_LIST =
  "GET_INCBOT_INTERVIEWS_SEARCH_LIST";
export const GET_INCBOT_INTERVIEWS_SEARCH_LIST_SUCCESS =
  "GET_INCBOT_INTERVIEWS_SEARCH_LIST_SUCCESS";
export const GET_INCBOT_INTERVIEWS_SEARCH_LIST_FAILURE =
  "GET_INCBOT_INTERVIEWS_SEARCH_LIST_FAILURE";

// INCSERVE INTERVIEWS BY ID

export const GET_INCSERVE_INTERVIEWS_BY_ID = "GET_INCSERVE_INTERVIEWS_BY_ID";
export const GET_INCSERVE_INTERVIEWS_BY_ID_SUCCESS =
  "GET_INCSERVE_INTERVIEWS_BY_ID_SUCCESS";
export const GET_INCSERVE_INTERVIEWS_BY_ID_FAILURE =
  "GET_INCSERVE_INTERVIEWS_BY_ID_FAILURE";

// INCSERVE CREATE AND UPDATE INTERVIEWS

export const CREATE_OR_UPDATE_INCSERVE_INTERVIEWS =
  "CREATE_OR_UPDATE_INCSERVE_INTERVIEWS";
export const CREATE_OR_UPDATE_INCSERVE_INTERVIEWS_SUCCESS =
  "CREATE_OR_UPDATE_INCSERVE_INTERVIEWS_SUCCESS";
export const CREATE_OR_UPDATE_INCSERVE_INTERVIEWS_FAILURE =
  "CREATE_OR_UPDATE_INCSERVE_INTERVIEWS_FAILURE";

export const GET_INCEXIT_INTERVIEWS_SEARCH_LIST =
  "GET_INCEXIT_INTERVIEWS_SEARCH_LIST";
export const GET_INCEXIT_INTERVIEWS_SEARCH_LIST_SUCCESS =
  "GET_INCEXIT_INTERVIEWS_SEARCH_LIST_SUCCESS";
export const GET_INCEXIT_INTERVIEWS_SEARCH_LIST_FAILURE =
  "GET_INCEXIT_INTERVIEWS_SEARCH_LIST_FAILURE";

export const GET_ALL_INTERVIEWERS = "GET_ALL_INTERVIEWERS";
export const GET_ALL_INTERVIEWERS_SUCCESS = "GET_ALL_INTERVIEWERS_SUCCESS";
export const GET_ALL_INTERVIEWERS_FAILURE = "GET_ALL_INTERVIEWERS_FAILURE";

//INCSERVE SKILLS LIST

export const GET_SKILLS = "GET_SKILLS";
export const GET_SKILLS_SUCCESS = "GET_SKILLS_SUCCESS";
export const GET_SKILLS_FAILURE = "GET_SKILLS_FAILURE";

//INCSERVE RESEND EMAIL VERIFICATION

export const RESEND_EMAIL_VERIFICATION = "RESEND_EMAIL_VERIFICATION";
export const RESEND_EMAIL_VERIFICATION_SUCCESS =
  "RESEND_EMAIL_VERIFICATION_SUCCESS";
export const RESEND_EMAIL_VERIFICATION_FAILURE =
  "RESEND_EMAIL_VERIFICATION_FAILURE";

//INCSERVE CHANGE STATUS

export const CHANGE_INTERVIEWER_STATUS = "CHANGE_INTERVIEWER_STATUS";
export const CHANGE_INTERVIEWER_STATUS_SUCCESS =
  "CHANGE_INTERVIEWER_STATUS_SUCCESS";
export const CHANGE_INTERVIEWER_STATUS_FAILURE =
  "CHANGE_INTERVIEWER_STATUS_FAILURE";

// INCSERVE ALLOW INTERVIEWER

export const ALLOW_INTERVIEWER = "ALLOW_INTERVIEWER";
export const ALLOW_INTERVIEWER_SUCCESS = "ALLOW_INTERVIEWER_SUCCESS";
export const ALLOW_INTERVIEWER_FAILURE = "ALLOW_INTERVIEWER_FAILURE";

//INCSERVE ALLOT AND REALLOT INTERVIEWER

export const ALLOT_AND_REALLOT_INTERVIEWER = "ALLOT_AND_REALLOT_INTERVIEWER";
export const ALLOT_AND_REALLOT_INTERVIEWER_SUCCESS =
  "ALLOT_AND_REALLOT_INTERVIEWER_SUCCESS";
export const ALLOT_AND_REALLOT_INTERVIEWER_FAILURE =
  "ALLOT_AND_REALLOT_INTERVIEWER_FAILURE";

//INCSERVE VERIFY INTERVIEWER

export const VERIFY_INTERVIEWER = "VERIFY_INTERVIEWER";
export const VERIFY_INTERVIEWER_SUCCESS = "VERIFY_INTERVIEWER_SUCCESS";
export const VERIFY_INTERVIEWER_FAILURE = "VERIFY_INTERVIEWER_FAILURE";

//INCSERVE CHANGE PLATFORM

export const CHANGE_PLATFORM = "CHANGE_PLATFORM";
export const CHANGE_PLATFORM_SUCCESS = "CHANGE_PLATFORM_SUCCESS";
export const CHANGE_PLATFORM_FAILURE = "CHANGE_PLATFORM_FAILURE";

//INCSERVE NOTIFY CLIENT
export const NOTIFY_INCSERVE_CLIENT = "NOTIFY_INCSERVE_CLIENT";
export const NOTIFY_INCSERVE_CLIENT_SUCCESS = "NOTIFY_INCSERVE_CLIENT_SUCCESS";
export const NOTIFY_INCSERVE_CLIENT_FAILURE = "NOTIFY_INCSERVE_CLIENT_FAILURE";

//INCSERVE NOTIFY CLIENT
export const INCSERVE_INTERVIEWER_DETAILS = "INCSERVE_INTERVIEWER_DETAILS";
export const INCSERVE_INTERVIEWER_DETAILS_SUCCESS =
  "INCSERVE_INTERVIEWER_DETAILS_SUCCESS";
export const INCSERVE_INTERVIEWER_DETAILS_FAILURE =
  "INCSERVE_INTERVIEWER_DETAILS_FAILURE";

//INCSERVE CALL

export const CALL_INTERVIEWER_AND_CANDIDATE = "CALL_INTERVIEWER_AND_CANDIDATE";
export const CALL_INTERVIEWER_AND_CANDIDATE_SUCCESS =
  "CALL_INTERVIEWER_AND_CANDIDATE_SUCCESS";
export const CALL_INTERVIEWER_AND_CANDIDATE_FAILURE =
  "CALL_INTERVIEWER_AND_CANDIDATE_FAILURE";

//INCSERVE INTERVIEW DETAILS

export const GET_EMPLOYER_INTERVIEW_DETAILS = "GET_EMPLOYER_INTERVIEW_DETAILS";
export const GET_EMPLOYER_INTERVIEW_DETAILS_SUCCESS =
  "GET_EMPLOYER_INTERVIEW_DETAILS_SUCCESS";
export const GET_EMPLOYER_INTERVIEW_DETAILS_FAILURE =
  "GET_EMPLOYER_INTERVIEW_DETAILS_FAILURE";

//INCSERVE EMPLOYER POSITION DETAILS

export const GET_EMPLOYER_POSITION_DETAILS = "GET_EMPLOYER_POSITION_DETAILS";
export const GET_EMPLOYER_POSITION_DETAILS_SUCCESS =
  "GET_EMPLOYER_POSITION_DETAILS_SUCCESS";
export const GET_EMPLOYER_POSITION_DETAILS_FAILURE =
  "GET_EMPLOYER_POSITION_DETAILS_FAILURE";

//INCSERVE SIMILAR WEBSITES

export const GET_SIMILAR_WEBSITE_LIST = "GET_SIMILAR_WEBSITE_LIST";
export const GET_SIMILAR_WEBSITE_LIST_SUCCESS =
  "GET_SIMILAR_WEBSITE_LIST_SUCCESS";
export const GET_SIMILAR_WEBSITE_LIST_FAILURE =
  "GET_SIMILAR_WEBSITE_LIST_FAILURE";

// INCSERVE BULK REQUIREMENT

export const BULK_INCSERVE_REQUIREMENT = "BULK_INCSERVE_REQUIREMENT";
export const BULK_INCSERVE_REQUIREMENT_SUCCESS =
  "BULK_INCSERVE_REQUIREMENT_SUCCESS";
export const BULK_INCSERVE_REQUIREMENT_FAILURE =
  "BULK_INCSERVE_REQUIREMENT_FAILURE";

//INCSERVE ACCEPT BULK  INTERVIEW
export const ACCEPT_BULK_INCSERVE_REQUIREMENT =
  "ACCEPT_BULK_INCSERVE_REQUIREMENT";
export const ACCEPT_BULK_INCSERVE_REQUIREMENT_SUCCESS =
  "ACCEPT_BULK_INCSERVE_REQUIREMENT_SUCCESS";
export const ACCEPT_BULK_INCSERVE_REQUIREMENT_FAILURE =
  "ACCEPT_BULK_INCSERVE_REQUIREMENT_FAILURE";

//INCSERVE CLOSE BULK  INTERVIEW
export const CLOSE_BULK_INCSERVE_REQUIREMENT =
  "CLOSE_BULK_INCSERVE_REQUIREMENT";
export const CLOSE_BULK_INCSERVE_REQUIREMENT_SUCCESS =
  "CLOSE_BULK_INCSERVE_REQUIREMENT_SUCCESS";
export const CLOSE_BULK_INCSERVE_REQUIREMENT_FAILURE =
  "CLOSE_BULK_INCSERVE_REQUIREMENT_FAILURE";

//INCSERVE UPDATE AND ADD BULK  INTERVIEW
export const ADD_UPDATE_BULK_INCSERVE_REQUIREMENT =
  "ADD_UPDATE_BULK_INCSERVE_REQUIREMENT";
export const ADD_UPDATE_BULK_INCSERVE_REQUIREMENT_SUCCESS =
  "ADD_UPDATE_BULK_INCSERVE_REQUIREMENT_SUCCESS";
export const ADD_UPDATE_BULK_INCSERVE_REQUIREMENT_FAILURE =
  "ADD_UPDATE_BULK_INCSERVE_REQUIREMENT_FAILURE";

export const NOTIFY_CLIENT = "NOTIFY_CLIENT";
export const NOTIFY_CLIENT_SUCCESS = "NOTIFY_CLIENT_SUCCESS";
export const NOTIFY_CLIENT_FAILURE = "NOTIFY_CLIENT_FAILURE";

//get credit description
export const GET_CREDIT_DESCRIPTION = "GET_CREDIT_DESCRIPTION";
export const GET_CREDIT_DESCRIPTION_SUCCESS = "GET_CREDIT_DESCRIPTION_SUCCESS";
export const GET_CREDIT_DESCRIPTION_FAILURE = "GET_CREDIT_DESCRIPTION_FAILURE";
export const GET_EMPLOYER_SUBUSER_DETAILS = "GET_EMPLOYER_SUBUSER_DETAILS";
export const GET_EMPLOYER_SUBUSER_DETAILS_SUCCESS =
  "GET_EMPLOYER_SUBUSER_DETAILS_SUCCESS";
export const GET_EMPLOYER_SUBUSER_DETAILS_FAILURE =
  "GET_EMPLOYER_POSITION_DETAILS_FAILURE";

// INCEXIT
export const GET_INCEXIT_INTERVIEW = "GET_INCEXIT_INTERVIEW";
export const GET_INCEXIT_INTERVIEW_SUCCESS = "GET_INCEXIT_INTERVIEW_SUCCESS";
export const GET_INCEXIT_INTERVIEW_FAILURE = "GET_INCEXIT_INTERVIEW_FAILURE";

// IncExit Accept interview
export const ACCEPT_INCEXIT_INTERVIEW = "ACCEPT_INCEXIT_INTERVIEW";
export const ACCEPT_INCEXIT_INTERVIEW_SUCCESS =
  "ACCEPT_INCEXIT_INTERVIEW_SUCCESS";
export const ACCEPT_INCEXIT_INTERVIEW_FAILURE =
  "ACCEPT_INCEXIT_INTERVIEW_FAILURE";

// IncExit Cancel Interview
export const CANCEL_INCEXIT_INTERVIEW = "CANCEL_INCEXIT_INTERVIEW";
export const CANCEL_INCEXIT_INTERVIEW_SUCCESS =
  "CANCEL_INCEXIT_INTERVIEW_SUCCESS";
export const CANCEL_INCEXIT_INTERVIEW_FAILURE =
  "CANCEL_INCEXIT_INTERVIEW_FAILURE";

// Previously Alloted Interviewer
export const PREVIOUSLY_ALLOTED_INTERVIEWER_LIST =
  "PREVIOUSLY_ALLOTED_INTERVIEWER_LIST";
export const PREVIOUSLY_ALLOTED_INTERVIEWER_LIST_SUCCESS =
  "PREVIOUSLY_ALLOTED_INTERVIEWER_LIST_SUCCESS";
export const PREVIOUSLY_ALLOTED_INTERVIEWER_LIST_FAILURE =
  "PREVIOUSLY_ALLOTED_INTERVIEWER_LIST_FAILURE";

// incserve incexit Previously Alloted Interviewer
export const INCSERVE_INCEXIT_PREVIOUSLY_ALLOTED_INTERVIEWER_LIST =
  "INCSERVE_INCEXIT_PREVIOUSLY_ALLOTED_INTERVIEWER_LIST";
export const INCSERVE_INCEXIT_PREVIOUSLY_ALLOTED_INTERVIEWER_LIST_SUCCESS =
  "INCSERVE_INCEXIT_PREVIOUSLY_ALLOTED_INTERVIEWER_LIST_SUCCESS";
export const INCSERVE_INCEXIT_PREVIOUSLY_ALLOTED_INTERVIEWER_LIST_FAILURE =
  "INCSERVE_INCEXIT_PREVIOUSLY_ALLOTED_INTERVIEWER_LIST_FAILURE";

// Designation List
export const DESIGNATION_LIST = "DESIGNATION_LIST";
export const DESIGNATION_LIST_SUCCESS = "DESIGNATION_LIST_SUCCESS";
export const DESIGNATION_LIST_FAILURE = "DESIGNATION_LIST_FAILURE";

// UPDATE INCEXIT INTERVIEW
export const UPDATE_INCEXIT_INTERVIEW = "UPDATE_INCEXIT_INTERVIEW";
export const UPDATE_INCEXIT_INTERVIEW_SUCCESS =
  "UPDATE_INCEXIT_INTERVIEW_SUCCESS";
export const UPDATE_INCEXIT_INTERVIEW_FAILURE =
  "UPDATE_INCEXIT_INTERVIEW_FAILURE";

// CREATE INCEXIT INTERVIEW
export const CREATE_INCEXIT_INTERVIEW = "CREATE_INCEXIT_INTERVIEW";
export const CREATE_INCEXIT_INTERVIEW_SUCCESS =
  "CREATE_INCEXIT_INTERVIEW_SUCCESS";
export const CREATE_INCEXIT_INTERVIEW_FAILURE =
  "CREATE_INCEXIT_INTERVIEW_FAILURE";

// ALLOT AND REALLOT INCEXIT INTERVIEW
export const ALLOT_INCEXIT_INTERVIEWER = "ALLOT_INCEXIT_INTERVIEWER";
export const ALLOT_INCEXIT_INTERVIEWER_SUCCESS =
  "ALLOT_INCEXIT_INTERVIEWER_SUCCESS";
export const ALLOT_INCEXIT_INTERVIEWER_FAILURE =
  "ALLOT_INCEXIT_INTERVIEWER_FAILURE";

// SCHEDULE INCEXIT INTERVIEW
export const SCHEDULE_INCEXIT_INTERVIEW = "SCHEDULE_INCEXIT_INTERVIEW";
export const SCHEDULE_INCEXIT_INTERVIEW_SUCCESS =
  "SCHEDULE_INCEXIT_INTERVIEW_SUCCESS";
export const SCHEDULE_INCEXIT_INTERVIEW_FAILURE =
  "SCHEDULE_INCEXIT_INTERVIEW_FAILURE";

// RE-SCHEDULE INCEXIT INTERVIEW
export const RESCHEDULE_INCEXIT_INTERVIEW = "RESCHEDULE_INCEXIT_INTERVIEW";
export const RESCHEDULE_INCEXIT_INTERVIEW_SUCCESS =
  "RESCHEDULE_INCEXIT_INTERVIEW_SUCCESS";
export const RESCHEDULE_INCEXIT_INTERVIEW_FAILURE =
  "RESCHEDULE_INCEXIT_INTERVIEW_FAILURE";

// RESPOND RE-SCHEDULE REQUEST
export const RESPOND_RESCHEDULE_INCEXIT_INTERVIEW =
  "RESPOND_RESCHEDULE_INCEXIT_INTERVIEW";
export const RESPOND_RESCHEDULE_INCEXIT_INTERVIEW_SUCCESS =
  "RESPOND_RESCHEDULE_INCEXIT_INTERVIEW_SUCCESS";
export const RESPOND_RESCHEDULE_INCEXIT_INTERVIEW_FAILURE =
  "RESPOND_RESCHEDULE_INCEXIT_INTERVIEW_FAILURE";

// BULK INCEXIT INTERVIEW
export const BULK_INCEXIT_INTERVIEW = "BULK_INCEXIT_INTERVIEW";
export const BULK_INCEXIT_INTERVIEW_SUCCESS = "BULK_INCEXIT_INTERVIEW_SUCCESS";
export const BULK_INCEXIT_INTERVIEW_FAILURE = "BULK_INCEXIT_INTERVIEW_FAILURE";

//UPDATE BULK INCEXIT INTERVIEW
export const UPDATE_BULK_INCEXIT_INTERVIEW = "UPDATE_BULK_INCEXIT_INTERVIEW";
export const UPDATE_BULK_INCEXIT_INTERVIEW_SUCCESS =
  "UPDATE_BULK_INCEXIT_INTERVIEW_SUCCESS";
export const UPDATE_BULK_INCEXIT_INTERVIEW_FAILURE =
  "UPDATE_BULK_INCEXIT_INTERVIEW_FAILURE";

//UPDATE INCSERVE FEEDBACK RATINGS
export const UPDATE_INTERVIEW_FEEDBACK_RATING =
  "UPDATE_INTERVIEW_FEEDBACK_RATING";
export const UPDATE_INTERVIEW_FEEDBACK_RATING_SUCCESS =
  "UPDATE_INTERVIEW_FEEDBACK_RATING_SUCCESS";
export const UPDATE_INTERVIEW_FEEDBACK_RATING_FAILURE =
  "UPDATE_INTERVIEW_FEEDBACK_RATING_FAILURE";

// ACCEPT BULK INCEXIT INTERVIEW
export const ACCEPT_BULK_INCEXIT_INTERVIEW = "ACCEPT_BULK_INCEXIT_INTERVIEW";
export const ACCEPT_BULK_INCEXIT_INTERVIEW_SUCCESS =
  "ACCEPT_BULK_INCEXIT_INTERVIEW_SUCCESS";
export const ACCEPT_BULK_INCEXIT_INTERVIEW_FAILURE =
  "ACCEPT_BULK_INCEXIT_INTERVIEW_FAILURE";

// CLOSE BULK INCEXIT INTERVIEW
export const CLOSE_BULK_INCEXIT_INTERVIEW = "CLOSE_BULK_INCEXIT_INTERVIEW";
export const CLOSE_BULK_INCEXIT_INTERVIEW_SUCCESS =
  "CLOSE_BULK_INCEXIT_INTERVIEW_SUCCESS";
export const CLOSE_BULK_INCEXIT_INTERVIEW_FAILURE =
  "CLOSE_BULK_INCEXIT_INTERVIEW_FAILURE";

// GIVE INCEXIT INTERVIEW FEEDBACK
export const ADD_INCEXIT_FEEDBACK = "ADD_INCEXIT_FEEDBACK";
export const ADD_INCEXIT_FEEDBACK_SUCCESS = "ADD_INCEXIT_FEEDBACK_SUCCESS";
export const ADD_INCEXIT_FEEDBACK_FAILURE = "ADD_INCEXIT_FEEDBACK_FAILURE";

// INCEXIT APPROVE FEEDBACK
export const APPROVE_INCEXIT_FEEDBACK = "APPROVE_INCEXIT_FEEDBACK";
export const APPROVE_INCEXIT_FEEDBACK_SUCCESS =
  "APPROVE_INCEXIT_FEEDBACK_SUCCESS";
export const APPROVE_INCEXIT_FEEDBACK_FAILURE =
  "APPROVE_INCEXIT_FEEDBACK_FAILURE";

// INCSERVE CALENDAR INVITE
export const INCSERVE_CALENDAR_INVITE = "INCSERVE_CALENDAR_INVITE";
export const INCSERVE_CALENDAR_INVITE_SUCCESS =
  "INCSERVE_CALENDAR_INVITE_SUCCESS";
export const INCSERVE_CALENDAR_INVITE_FAILURE =
  "INCSERVE_CALENDAR_INVITE_FAILURE";

// INCEXIT CALENDAR INVITE
export const INCEXIT_CALENDAR_INVITE = "INCEXIT_CALENDAR_INVITE";
export const INCEXIT_CALENDAR_INVITE_SUCCESS =
  "INCEXIT_CALENDAR_INVITE_SUCCESS";
export const INCEXIT_CALENDAR_INVITE_FAILURE =
  "INCEXIT_CALENDAR_INVITE_FAILURE";

export const CREATE_JD = "CREATE_JD";
export const CREATE_JD_SUCCESS = "CREATE_JD_SUCCESS";
export const CREATE_JD_FAILURE = "CREATE_JD_FAILURE";

export const CREATE_EMPLOYER_POSITION = "CREATE_EMPLOYER_POSITION";
export const CREATE_EMPLOYER_POSITION_SUCCESS =
  "CREATE_EMPLOYER_POSITION_SUCCESS";
export const CREATE_EMPLOYER_POSITION_FAILURE =
  "CREATE_EMPLOYER_POSITION_FAILURE";

// Update Incfeed permission
export const UPDATE_INCFEED_PERMISSION = "UPDATE_INCFEED_PERMISSION";
export const UPDATE_INCFEED_PERMISSION_SUCCESS =
  "UPDATE_INCFEED_PERMISSION_SUCCESS";
export const UPDATE_INCFEED_PERMISSION_FAILURE =
  "UPDATE_INCFEED_PERMISSION_FAILURE";

export const GET_CALENDAR_EVENT = "GET_CALENDAR_EVENT ";
export const GET_CALENDAR_EVENT_SUCCESS = "GET_CALENDAR_EVENT_SUCCESS";
export const GET_CALENDAR_EVENT_FAILURE = "GET_CALENDAR_EVENT_FAILURE";

// CALL Logs
export const GET_CALL_LOGS = "GET_CALL_LOGS";
export const GET_CALL_LOGS_SUCCESS = "GET_CALL_LOGS_SUCCESS";
export const GET_CALL_LOGS_FAILURE = "GET_CALL_LOGS_FAILURE";

// CALL Logs
export const GET_INTERVIEWER_CALL_LOGS = "GET_INTERVIEWER_CALL_LOGS";
export const GET_INTERVIEWER_CALL_LOGS_SUCCESS =
  "GET_INTERVIEWER_CALL_LOGS_SUCCESS";
export const GET_INTERVIEWER_CALL_LOGS_FAILURE =
  "GET_INTERVIEWER_CALL_LOGS_FAILURE";

// add questions
export const UPDATE_ADD_QUESTION = "UPDATE_ADD_QUESTION";
export const UPDATE_ADD_QUESTION_SUCCESS = "UPDATE_ADD_QUESTION_SUCCESS";
export const UPDATE_ADD_QUESTION_FAILURE = "UPDATE_ADD_QUESTION_FAILURE";

// add questions
export const CHANGE_EMPLOYER_STATUS = "CHANGE_EMPLOYER_STATUS";
export const CHANGE_EMPLOYER_STATUS_SUCCESS = "CHANGE_EMPLOYER_STATUS_SUCCESS";
export const CHANGE_EMPLOYER_STATUS_FAILURE = "CHANGE_EMPLOYER_STATUS_FAILURE";

// verify linkdiean url
export const VERIFY_LINKEDIN_URL = "VERIFY_LINKEDIN_URL";
export const VERIFY_LINKEDIN_URL_SUCCESS = "VERIFY_LINKEDIN_URL_SUCCESS";
export const VERIFY_LINKEDIN_URL_FAILURE = "VERIFY_LINKEDIN_URL_FAILURE";

// send conversion report
export const SEND_CONVERSION_REPORT = "SEND_CONVERSION_REPORT";
export const SEND_CONVERSION_REPORT_SUCCESS = "SEND_CONVERSION_REPORT_SUCCESS";
export const SEND_CONVERSION_REPORT_FAILURE = "SEND_CONVERSION_REPORT_FAILURE";

// incserve add question
export const UPDATE_INCSERVE_ADD_QUESTION = "UPDATE_INCSERVE_ADD_QUESTION";
export const UPDATE_INCSERVE_ADD_QUESTION_SUCCESS =
  "UPDATE_INCSERVE_ADD_QUESTION_SUCCESS";
export const UPDATE_INCSERVE_ADD_QUESTION_FAILURE =
  "UPDATE_INCSERVE_ADD_QUESTION_FAILURE";

//admin notification
export const GET_ALL_NOTIFICATIONS = "GET_ALL_NOTIFICATIONS";
export const GET_ALL_NOTIFICATIONS_SUCCESS = "GET_ALL_NOTIFICATIONS_SUCCESS";
export const GET_ALL_NOTIFICATIONS_FAILURE = "GET_ALL_NOTIFICATIONS_FAILURE";

export const READ_ALL_NOTIFICATIONS = "READ_ALL_NOTIFICATIONS";
export const READ_ALL_NOTIFICATIONS_SUCCESS = "READ_ALL_NOTIFICATIONS_SUCCESS";
export const READ_ALL_NOTIFICATIONS_FAILURE = "READ_ALL_NOTIFICATIONS_FAILURE";

export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
export const DELETE_NOTIFICATION_SUCCESS = "DELETE_NOTIFICATION_SUCCESS";
export const DELETE_NOTIFICATION_FAILURE = "DELETE_NOTIFICATION_FAILURE";

//Active Interviewer Account

export const INTERVIEWER_ACCOUNT_DETAILS_LIST =
  "INTERVIEWER_ACCOUNT_DETAILS_LIST";
export const INTERVIEWER_ACCOUNT_DETAILS_LIST_SUCCESS =
  "INTERVIEWER_ACCOUNT_DETAILS_LIST_SUCCESS";
export const INTERVIEWER_ACCOUNT_DETAILS_LIST_FAILURE =
  "INTERVIEWER_ACCOUNT_DETAILS_LIST_FAILURE ";

export const SUB_ADMIN_PASSWORD_RESET = "SUB_ADMIN_PASSWORD_RESET";
export const SUB_ADMIN_PASSWORD_RESET_SUCCESS =
  "SUB_ADMIN_PASSWORD_RESET_SUCCESS";
export const SUB_ADMIN_PASSWORD_RESET_FAILURE =
  "SUB_ADMIN_PASSWORD_RESET_FAILURE";

// ================================ new article list =================================//

export const GET_NEWS_ARTICLE_LIST = "GET_NEWS_ARTICLE_LIST";
export const GET_NEWS_ARTICLE_LIST_SUCCESS = "GET_NEWS_ARTICLE_LIST_SUCCESS";
export const GET_NEWS_ARTICLE_LIST_FAILURE = "GET_NEWS_ARTICLE_LIST_FAILURE";

export const ADD_OR_EDIT_NEWS_ARTICLE = "ADD_OR_EDIT_NEWS_ARTICLE";
export const ADD_OR_EDIT_NEWS_ARTICLE_SUCCESS =
  "ADD_OR_EDIT_NEWS_ARTICLE_SUCCESS";
export const ADD_OR_EDIT_NEWS_ARTICLE_FAILURE =
  "ADD_OR_EDIT_NEWS_ARTICLE_FAILURE";

export const DELETE_NEWS_ARTICLE = "DELETE_NEWS_ARTICLE";
export const DELETE_NEWS_ARTICLE_SUCCESS = "DELETE_NEWS_ARTICLE_SUCCESS";
export const DELETE_NEWS_ARTICLE_FAILURE = "DELETE_NEWS_ARTICLE_FAILURE";

//Get Api Call logs

export const GET_API_CALL_LOGS = "GET_API_CALL_LOGS";
export const GET_API_CALL_LOGS_SUCCESS = "GET_API_CALL_LOGS_SUCCESS";
export const GET_API_CALL_LOGS_FAILURE = "GET_API_CALL_LOGS_FAILURE";

//Get Mock Interviews

export const GET_MOCK_INTERVIEWS = "GET_MOCK_INTERVIEWS";
export const GET_MOCK_INTERVIEWS_SUCCESS = "GET_MOCK_INTERVIEWS_SUCCESS";
export const GET_MOCK_INTERVIEWS_FAILURE = "GET_MOCK_INTERVIEWS_FAILURE";

export const UPDATE_API_CALL_LOGS = "UPDATE_API_CALL_LOGS";
export const UPDATE_API_CALL_LOGS_SUCCESS = "UPDATE_API_CALL_LOGS_SUCCESS";
export const UPDATE_API_CALL_LOGS_FAILURE = "UPDATE_API_CALL_LOGS_FAILURE";
// Get Meeting Details
export const GET_MEETING_DETAILS = "GET_MEETING_DETAILS ";
export const GET_MEETING_DETAILS_SUCCESS = "GET_MEETING_DETAILS_SUCCESS ";
export const GET_MEETING_DETAILS_FAILURE = "GET_MEETING_DETAILS_FAILURE ";

//Post Meeting Details
export const POST_MEETING_DETAILS = "POST_MEETING_DETAILS ";
export const POST_MEETING_DETAILS_SUCCESS = "POST_MEETING_DETAILS_SUCCESS ";
export const POST_MEETING_DETAILS_FAILURE = "POST_MEETING_DETAILS_FAILURE ";

//Countries
export const GET_COUNTRIES_LIST = "GET_COUNTRIES_LIST";
export const GET_COUNTRIES_LIST_SUCCESS = "GET_COUNTRIES_LIST_SUCCESS ";
export const GET_COUNTRIES_LIST_FAILURE = "GET_COUNTRIES_LIST_FAILURE ";

export const POST_COUNTRIES_LIST = "POST_COUNTRIES_LIST";
export const POST_COUNTRIES_LIST_SUCCESS = "POST_COUNTRIES_LIST_SUCCESS ";
export const POST_COUNTRIES_LIST_FAILURE = "POST_COUNTRIES_LIST_FAILURE ";

export const UPDATE_COUNTRIES_LIST = "UPDATE_COUNTRIES_LIST";
export const UPDATE_COUNTRIES_LIST_SUCCESS = "UPDATE_COUNTRIES_LIST_SUCCESS ";
export const UPDATE_COUNTRIES_LIST_FAILURE = "UPDATE_COUNTRIES_LIST_FAILURE ";

export const DELETE_COUNTRIES_LIST = "DELETE_COUNTRIES_LIST";
export const DELETE_COUNTRIES_LIST_SUCCESS = "DELETE_COUNTRIES_LIST_SUCCESS ";
export const DELETE_COUNTRIES_LIST_FAILURE = "DELETE_COUNTRIES_LIST_FAILURE ";

//states
export const GET_STATES_LIST = "GET_STATES_LIST";
export const GET_STATES_LIST_SUCCESS = "GET_STATES_LIST_SUCCESS ";
export const GET_STATES_LIST_FAILURE = "GET_STATES_LIST_FAILURE ";

export const POST_STATES_LIST = "POST_STATES_LIST";
export const POST_STATES_LIST_SUCCESS = "POST_STATES_LIST_SUCCESS ";
export const POST_STATES_LIST_FAILURE = "POST_STATES_LIST_FAILURE ";

export const UPDATE_STATES_LIST = "UPDATE_STATES_LIST";
export const UPDATE_STATES_LIST_SUCCESS = "UPDATE_STATES_LIST_SUCCESS ";
export const UPDATE_STATES_LIST_FAILURE = "UPDATE_STATES_LIST_FAILURE ";

export const DELETE_STATES_LIST = "DELETE_STATES_LIST";
export const DELETE_STATES_LIST_SUCCESS = "DELETE_STATES_LIST_SUCCESS ";
export const DELETE_STATES_LIST_FAILURE = "DELETE_STATES_LIST_FAILURE ";

export const GET_EVENTS_LIST = "GET_EVENTS_LIST";
export const GET_EVENTS_LIST_SUCCESS = "GET_EVENTS_LIST_SUCCESS ";
export const GET_EVENTS_LIST_FAILURE = "GET_EVENTS_LIST_FAILURE ";

export const GENERATE_TWO_FACTOR_AUTHENTICATION_URI =
  "GENERATE_TWO_FACTOR_AUTHENTICATION_URI";
export const GENERATE_TWO_FACTOR_AUTHENTICATION_URI_SUCCESS =
  "GENERATE_TWO_FACTOR_AUTHENTICATION_URI_SUCCESS";
export const GENERATE_TWO_FACTOR_AUTHENTICATION_URI_FAILURE =
  "GENERATE_TWO_FACTOR_AUTHENTICATION_URI_FAILURE";

export const VERIFY_TWO_FACTOR_AUTHENTICATION_URI =
  "VERIFY_TWO_FACTOR_AUTHENTICATION_URI";
export const VERIFY_TWO_FACTOR_AUTHENTICATION_URI_SUCCESS =
  "VERIFY_TWO_FACTOR_AUTHENTICATION_URI_SUCCESS";
export const VERIFY_TWO_FACTOR_AUTHENTICATION_URI_FAILURE =
  "VERIFY_TWO_FACTOR_AUTHENTICATION_URI_FAILURE";

export const DISABLE_TWO_FACTOR_AUTHENTICATION_URI =
  "DISABLE_TWO_FACTOR_AUTHENTICATION_URI";
export const DISABLE_TWO_FACTOR_AUTHENTICATION_URI_SUCCESS =
  "DISABLE_TWO_FACTOR_AUTHENTICATION_URI_SUCCESS";
export const DISABLE_TWO_FACTOR_AUTHENTICATION_URI_FAILURE =
  "DISABLE_TWO_FACTOR_AUTHENTICATION_URI_FAILURE";

export const TWO_FACTOR_AUTHENTICATION_STATUS =
  "TWO_FACTOR_AUTHENTICATION_STATUS";
export const TWO_FACTOR_AUTHENTICATION_STATUS_SUCCESS =
  "TWO_FACTOR_AUTHENTICATION_STATUS_SUCCESS";
export const TWO_FACTOR_AUTHENTICATION_STATUS_FAILURE =
  "TWO_FACTOR_AUTHENTICATION_STATUS_FAILURE";

export const CHECK_TWO_FACTOR_AUTHENTICATION_STATUS =
  "CHECK_TWO_FACTOR_AUTHENTICATION_STATUS";
export const CHECK_TWO_FACTOR_AUTHENTICATION_STATUS_SUCCESS =
  "CHECK_TWO_FACTOR_AUTHENTICATION_STATUS_SUCCESS";
export const CHECK_TWO_FACTOR_AUTHENTICATION_STATUS_FAILURE =
  "CHECK_TWO_FACTOR_AUTHENTICATION_STATUS_FAILURE";

export const FEEDBACK_APPROVAL = "FEEDBACK_APPROVAL";
export const FEEDBACK_APPROVAL_SUCCESS = "FEEDBACK_APPROVAL_SUCCESS";
export const FEEDBACK_APPROVAL_FAILURE = "FEEDBACK_APPROVAL_FAILURE";

export const FETCH_RESUME_SUMMARY = "FETCH_RESUME_SUMMARY";
export const FETCH_RESUME_SUMMARY_SUCCESS = "FETCH_RESUME_SUMMARY_SUCCESS";
export const FETCH_TRANSCRIPT_FAILURE = "FETCH_TRANSCRIPT_FAILURE";

export const FETCH_TRANSCRIPT = "FETCH_TRANSCRIPT";
export const FETCH_TRANSCRIPT_SUCCESS = "FETCH_TRANSCRIPT_SUCCESS";
export const FETCH_RESUME_SUMMARY_FAILURE = "FETCH_RESUME_SUMMARY_FAILURE";

export const FETCH_AI_COMMENT = "FETCH_AI_COMMENT";
export const FETCH_AI_COMMENT_SUCCESS = "FETCH_AI_COMMENT_SUCCESS";
export const FETCH_AI_COMMENT_FAILURE = "FETCH_AI_COMMENT_FAILURE";

export const FETCH_AI_COMMENT_FOR_CODE = "FETCH_AI_COMMENT_FOR_CODE";
export const FETCH_AI_COMMENT_FOR_CODE_SUCCESS =
  "FETCH_AI_COMMENT_FOR_CODE_SUCCESS";
export const FETCH_AI_COMMENT_FOR_CODE_FAILURE =
  "FETCH_AI_COMMENT_FOR_CODE_FAILURE";

export const FETCH_FINAL_REMARKS = "FETCH_FINAL_REMARKS";
export const FETCH_FINAL_REMARKS_SUCCESS = "FETCH_FINAL_REMARKS_SUCCESS";
export const FETCH_FINAL_REMARKS_FAILURE = "FETCH_FINAL_REMARKS_FAILURE";

export const UPDATE_FEEDBACK = "UPDATE_FEEDBACK";
export const UPDATE_FEEDBACK_SUCCESS = "UPDATE_FEEDBACK_SUCCESS";
export const UPDATE_FEEDBACK_FAILURE = "UPDATE_FEEDBACK_FAILURE";

export const FETCH_SUBSCRIPTION_DATA = "FETCH_SUBSCRIPTION_DATA";
export const FETCH_SUBSCRIPTION_DATA_SUCCESS =
  "FETCH_SUBSCRIPTION_DATA_SUCCESS";
export const FETCH_SUBSCRIPTION_DATA_FAILURE =
  "FETCH_SUBSCRIPTION_DATA_FAILURE";

export const ADD_EDIT_CREDITS = "ADD_EDIT_CREDITS";
export const ADD_EDIT_CREDITS_SUCCESS = "ADD_EDIT_CREDITS_SUCCESS";
export const ADD_EDIT_CREDITS_FAILURE = "ADD_EDIT_CREDITS_FAILURE";

export const SET_NETWORK_DISCONNECTED = "SET_NETWORK_DISCONNECTED";
export const SET_NETWORK_DISCONNECTED_SUCCESS =
  "SET_NETWORK_DISCONNECTED_SUCCESS";
export const SET_NETWORK_DISCONNECTED_FAILURE =
  "SET_NETWORK_DISCONNECTED_FAILURE";
