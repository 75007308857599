import {
  GET_SCREENING_QUESTIONS,
  GET_SCREENING_QUESTIONS_SUCCESS,
  GET_SCREENING_QUESTIONS_FAILURE,
  GET_QUESTIONS,
  GET_QUESTIONS_SUCCESS,
  GET_QUESTIONS_FAILURE,
  ADD_OR_EDIT_QUESTIONS,
  ADD_OR_EDIT_QUESTIONS_SUCCESS,
  ADD_OR_EDIT_QUESTIONS_FAILURE,
  DELETE_QUESTIONS,
  DELETE_QUESTIONS_SUCCESS,
  DELETE_QUESTIONS_FAILURE,
  POST_QUESTIONS,
  POST_QUESTIONS_SUCCESS,
  POST_QUESTIONS_FAILURE,
  GET_QUESTION_SKILLS,
  GET_QUESTION_SKILLS_SUCCESS,
  GET_QUESTION_SKILLS_FAILURE,
  GET_MANDATORY_SKILL,
  GET_MANDATORY_SKILL_SUCCESS,
  GET_MANDATORY_SKILL_FAILURE,
  GET_OPTIONAL_SKILL,
  GET_OPTIONAL_SKILL_SUCCESS,
  GET_OPTIONAL_SKILL_FAILURE,
  GET_DOMAIN,
  GET_DOMAIN_SUCCESS,
  GET_DOMAIN_FAILURE,
  GET_INDUSTRY_TYPE,
  GET_INDUSTRY_TYPE_SUCCESS,
  GET_INDUSTRY_TYPE_FAILURE,
  GET_SKILLS,
  GET_SKILLS_SUCCESS,
  GET_SKILLS_FAILURE,
  GET_SKILLS_EXPERTISE,
  GET_SKILLS_EXPERTISE_SUCCESS,
  GET_SKILLS_EXPERTISE_FAILURE,
  DELETE_BULK_QUESTIONS,
  DELETE_BULK_QUESTIONS_SUCCESS,
  DELETE_BULK_QUESTIONS_FAILURE,
  CREATE_JD,
  CREATE_JD_SUCCESS,
  CREATE_JD_FAILURE,
  GET_NEWS_ARTICLE_LIST,
  GET_NEWS_ARTICLE_LIST_SUCCESS,
  GET_NEWS_ARTICLE_LIST_FAILURE,
  ADD_OR_EDIT_NEWS_ARTICLE,
  ADD_OR_EDIT_NEWS_ARTICLE_SUCCESS,
  ADD_OR_EDIT_NEWS_ARTICLE_FAILURE,
  DELETE_NEWS_ARTICLE,
  DELETE_NEWS_ARTICLE_SUCCESS,
  DELETE_NEWS_ARTICLE_FAILURE,
} from "../action/types";

const INIT_STATE = {
  loading: false,
  domain: null,
  mandatorySkill: null,
  optionalSkill: null,
  screeningQuestions: null,
  questionsList: null,
  questionSkillsList: null,
  industryType: null,
  skills: null,
  expertiseSkills: null,
  newsArticleList: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    //DOMAIN
    case GET_DOMAIN:
      return { ...state, loading: true };
    case GET_DOMAIN_SUCCESS:
      return {
        ...state,
        loading: false,
        domain: action.payload,
      };
    case GET_DOMAIN_FAILURE:
      return { ...state, loading: false };

    // SKILLS
    case GET_SKILLS:
      return { ...state, loading: true };
    case GET_SKILLS_SUCCESS:
      return {
        ...state,
        loading: false,
        skills: action.payload,
      };
    case GET_SKILLS_FAILURE:
      return { ...state, loading: false };

    case CREATE_JD:
      return { ...state, jDLoading: true };
    case CREATE_JD_SUCCESS:
      return {
        ...state,
        jDLoading: false,
        jDData: action.payload,
      };
    case CREATE_JD_FAILURE:
      return { ...state, jDLoading: false };

    // SCREENING QUESTION
    case GET_SCREENING_QUESTIONS:
      return { ...state, loading: true };
    case GET_SCREENING_QUESTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        screeningQuestions: action.payload,
      };
    case GET_SCREENING_QUESTIONS_FAILURE:
      return { ...state, loading: false };

    // SKILLS
    case GET_QUESTION_SKILLS:
      return { ...state };
    case GET_QUESTION_SKILLS_SUCCESS:
      return {
        ...state,
        questionSkillsList: action.payload,
      };
    case GET_QUESTION_SKILLS_FAILURE:
      return { ...state };
    case GET_QUESTIONS:
      return { ...state, loading: true };
    case GET_QUESTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        questionsList: action.payload,
      };
    case GET_QUESTIONS_FAILURE:
      return { ...state, loading: false };
    case POST_QUESTIONS:
      return { ...state, loading: true };
    case POST_QUESTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case POST_QUESTIONS_FAILURE:
      return { ...state, loading: false };
    case ADD_OR_EDIT_QUESTIONS:
      return { ...state, loading: true };
    case ADD_OR_EDIT_QUESTIONS_SUCCESS:
      return { ...state, loading: false };
    case ADD_OR_EDIT_QUESTIONS_FAILURE:
      return { ...state, loading: false };
    case DELETE_QUESTIONS:
      return { ...state, loading: true };
    case DELETE_QUESTIONS_SUCCESS:
      return { ...state, loading: false };
    case DELETE_QUESTIONS_FAILURE:
      return { ...state, loading: false };
    case DELETE_BULK_QUESTIONS:
      return { ...state, loading: true };
    case DELETE_BULK_QUESTIONS_SUCCESS:
      return { ...state, loading: false };
    case DELETE_BULK_QUESTIONS_FAILURE:
      return { ...state, loading: false };
    case GET_QUESTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        questionsList: action.payload,
      };
    case GET_QUESTIONS_FAILURE:
      return { ...state, loading: false };
    case GET_MANDATORY_SKILL:
      return { ...state, loading: true };
    case GET_MANDATORY_SKILL_SUCCESS:
      return {
        ...state,
        loading: false,
        mandatorySkill: action.payload,
      };
    case GET_MANDATORY_SKILL_FAILURE:
      return { ...state, loading: false };
    case GET_OPTIONAL_SKILL:
      return { ...state, loading: true };
    case GET_OPTIONAL_SKILL_SUCCESS:
      return {
        ...state,
        loading: false,
        optionalSkill: action.payload,
      };
    case GET_OPTIONAL_SKILL_FAILURE:
      return { ...state, loading: false };

    //INDUSTRY
    case GET_INDUSTRY_TYPE:
      return { ...state, loading: true };
    case GET_INDUSTRY_TYPE_SUCCESS:
      return { ...state, loading: false, industryType: action.payload };
    case GET_INDUSTRY_TYPE_FAILURE:
      return { ...state, loading: false };
    case GET_NEWS_ARTICLE_LIST:
      return { ...state, loading: true };
    case GET_NEWS_ARTICLE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        timeStamp:
          state.timeStamp > action.payload.timeStamp
            ? state.timeStamp
            : action.payload.timeStamp,
        newsArticleList:
          state.timeStamp > action.payload.timeStamp
            ? state.employerList
            : action.payload,
      };
    case GET_NEWS_ARTICLE_LIST_FAILURE:
      return { ...state, loading: false };

    case ADD_OR_EDIT_NEWS_ARTICLE:
      return { ...state, loading: true };
    case ADD_OR_EDIT_NEWS_ARTICLE_SUCCESS:
      return { ...state, loading: false };
    case ADD_OR_EDIT_NEWS_ARTICLE_FAILURE:
      return { ...state, loading: false };

    case DELETE_NEWS_ARTICLE:
      return { ...state, loading: true };
    case DELETE_NEWS_ARTICLE_SUCCESS:
      return { ...state, loading: false };
    case DELETE_NEWS_ARTICLE_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};
