import { all } from "redux-saga/effects";
import candidateSaga from "./candidateSaga";
import interviewSaga from "./interviewSaga";
import skillsSaga from "./skillsSaga";
import subscriptionSaga from "./subscriptionSaga";
import userAuthSaga from "./userAuthSaga";
import productPermissionSaga from "./productPermissionSaga";

export default function* rootSaga() {
  yield all([
    candidateSaga(),
    interviewSaga(),
    skillsSaga(),
    subscriptionSaga(),
    userAuthSaga(),
    productPermissionSaga(),
  ]);
}
