import React from "react";
import CreatableSelect from "react-select/creatable";

const CreatableSelectComponent = ({
  name,
  value,
  onChange,
  labelClass,
  inputClass,
  errorClass,
  error,
  label,
  isRequired,
  options,
  isMulti,
  placeholder,
}) => {
  const colorStyles = {
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#8780ab",
      };
    },
    singleValue: (provided, state) => {
      const color = state.data.value === "" ? "#8780AB" : "black";
      return { ...provided, color };
    },
  };

  return (
    <>
      {label && (
        <label htmlFor={name} className={`c-label ${labelClass}`}>
          {label} {isRequired && <span className="asterisk f-12">&#42;</span>}
        </label>
      )}
      <div className={`position-relative`}>
        <CreatableSelect
          className={`${inputClass} ${error ? "error" : ""}`}
          isMulti={isMulti}
          classNamePrefix="select"
          isSearchable={true}
          options={options}
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          maxMenuHeight={200}
          styles={colorStyles}
        />
      </div>
      {error && <div className={`invalid ${errorClass}`}>{error}</div>}
    </>
  );
};

export default CreatableSelectComponent;
