import {
  GET_CREDIT_DESCRIPTION,
  GET_CREDIT_DESCRIPTION_FAILURE,
  GET_CREDIT_DESCRIPTION_SUCCESS,
  UPDATE_INCFEED_PERMISSION,
  UPDATE_INCFEED_PERMISSION_FAILURE,
  UPDATE_INCFEED_PERMISSION_SUCCESS,
} from "./types";

//get credit description
export const getCreditDescription = (payload) => ({
  type: GET_CREDIT_DESCRIPTION,
  payload,
});
export const getCreditDescriptionSuccess = (payload) => ({
  type: GET_CREDIT_DESCRIPTION_SUCCESS,
  payload,
});
export const getCreditDescriptionFailure = () => ({
  type: GET_CREDIT_DESCRIPTION_FAILURE,
});

// update incfeed permission
export const updateIncfeedPermission = (payload) => ({
  type: UPDATE_INCFEED_PERMISSION,
  payload,
});
export const updateIncfeedPermissionSuccess = (payload) => ({
  type: UPDATE_INCFEED_PERMISSION_SUCCESS,
  payload,
});
export const updateIncfeedPermissionFailure = () => ({
  type: UPDATE_INCFEED_PERMISSION_FAILURE,
});
