import React, { memo, Suspense } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { ToastContainer, Slide } from "react-toastify";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import RouteList from "./RouteList";
import "./libcss";
import { Footer, Loader } from "./component/commonComponent";
import ErrorBoundary from "./component/ErrorBoundary";
const CloseButton = ({ closeToast }) => (
  <i
    className="fa fa-times-circle align-self-center f-18 text-white"
    onClick={closeToast}
  />
);

const MainApp = memo(() => {
  return (
    <Router>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ToastContainer
            autoClose={2000}
            draggable={false}
            transition={Slide}
            closeButton={CloseButton}
            hideProgressBar={true}
            toastClassName="toast-notification"
          />
          <ErrorBoundary>
            <Suspense fallback={<Loader />}>
              <Routes>
                <Route path="/*" element={<RouteList />} />
              </Routes>
            </Suspense>
          </ErrorBoundary>
        </PersistGate>
      </Provider>
    </Router>
  );
});

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(<MainApp />);
