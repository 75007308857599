import React, { memo } from "react";
import noDataImg from "../../assets/admin Images/no-data-found.svg";

const NoRecordFound = memo(() => {
  return (
    <div className="no-record-found d-center flex-column w-100 h-100">
      <img
        src={noDataImg}
        alt="no data"
        style={{ height: "200px", width: "auto" }}
      />
      <div className="f-20 f-bol">No Record Found</div>
    </div>
  );
});

export default NoRecordFound;
