import axios from "axios";
import { checkLoginTime, generateUrl, getToken, removeToken } from "../helper";

const userAuth = axios.create({
  baseURL: generateUrl("user_backend"),
});

userAuth.interceptors.request.use(function (config) {
  let token = getToken();
  if (token && token !== null) {
    config.headers.Authorization = `Bearer ${token}`;
    let checkStatus = checkLoginTime();
    if (checkStatus) {
      token = getToken();
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      // removeToken();
      // window.location.href = "/login";
    }
  }
  return config;
});
userAuth.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      removeToken();
      window.location.href = "/login";
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);
export default userAuth;
