import React, { memo } from "react";


const CloseButton = memo(({ className, onClick }) => {
  return (
    <button className={`${className} btn-close`} onClick={onClick}>
      {/* <img src={close} alt="close-btn" className="img-fluid" /> */}
    </button>
  );
});

export default CloseButton;
