import React, { memo } from "react";
import { capitalizeAll } from "../../utils/helper";

const InputDate = memo(
  ({
    className,
    name,
    value,
    onChange,
    placeholder,
    type,
    labelClass,
    inputClass,
    errorClass,
    error,
    label,
    isRequired,
    borderNone,
    disabled,
    onFocus,
    onBlur,
    onKeyUp,
    min,
  }) => {
    return (
      <>
        {label && (
          <label htmlFor={name} className={`c-label ${labelClass}`}>
            {label} {isRequired && <span className="asterisk f-12">&#42;</span>}
          </label>
        )}
        <div
          className={`position-relative ${inputClass} d-flex align-items-center c-input-container ${
            borderNone ? "border-0" : ""
          } input-shadow`}
        >
          <input
            type={type}
            className={`${className} ${error ? "error" : ""}`}
            name={name}
            placeholder={capitalizeAll(placeholder)}
            value={value}
            min={min}
            onChange={(e) => onChange(e)}
            disabled={disabled}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyUp={onKeyUp}
          ></input>
        </div>
        {error && <div className={`invalid ${errorClass}`}>{error}</div>}
      </>
    );
  }
);
export default InputDate;
