import CloseButton from "./CloseButton";
import NoRecordFound from "./NoRecordFound";
import {
  Toast,
  capitalize,
  capitalizeAll,
  checkPermissions,
  downloadFile,
  localStorageJsonParseDecrypt,
  useOutsideAlerter,
} from "../../utils/helper";
import moment from "moment";
import downloadImg from "../../assets/images/download_icon.svg";
import Button from "./Button";
import CALL_ICON from "../../assets/images/call_icon.svg";
import { useRef, useState } from "react";
import IncServeAddQuestionPopUp from "./IncServeAddQuestionPopUp";
import { toast } from "react-toastify";
import Pencil from "../../assets/admin Images/pencil.svg";
const InfoTable = ({
  setIsSideSectionOpen,
  setInitiateCall,
  setCallPopUp,
  setIsEditable,
  tab,
  sideSectionData,
  sideSectionAction,
  setSideSectionsAction,
  setApproveInterviewData,
  setAudioLogs,
  setInterviewFeedbackPopup,
  type,
  status,
  currentPage,
  entriesLimit,
  search,
  sortBy,
  isFilterBtnData,
  isReportApproved,
  setCandidateRatingPopup,
}) => {
  const infoRef = useRef();
  const [addQuestionPopUp, setAddQuestionPopUp] = useState(false);
  useOutsideAlerter(infoRef, () => setIsSideSectionOpen(false));

  return (
    <div className="info-container p-2">
      <CloseButton
        onClick={() => {
          setIsSideSectionOpen(false);
        }}
      />
      <div className="list">
        <div className="title f-sem-bol text-left mb-2">
          {sideSectionData?.code
            ? sideSectionData?.code
            : sideSectionData?.bulkCode}{" "}
          -{" "}
          {sideSectionData?.employer
            ? capitalize(sideSectionData?.employer[0]?.company?.name)
            : "Not Available"}
        </div>
        <div className="position-table-container">
          {sideSectionData ? (
            <>
              {sideSectionData?.bulkCode ? (
                <table className="table-list f-l f-reg">
                  <tr className="info-tabel">
                    <td>ID</td>
                    <td>
                      {sideSectionData?.bulkCode
                        ? sideSectionData?.bulkCode
                        : "Not Available"}
                    </td>
                  </tr>
                  <tr className="info-tabel">
                    <td>Job Title</td>
                    <td>
                      {sideSectionData?.position?.title
                        ? capitalize(sideSectionData?.position?.title)
                        : "Not Available"}
                    </td>
                  </tr>
                  <tr className="info-tabel">
                    <td>Domain</td>
                    <td>
                      {sideSectionData?.domain?.name
                        ? capitalize(sideSectionData?.domain?.name)
                        : "Not Available"}
                    </td>
                  </tr>
                  <tr className="info-tabel">
                    <td>Mandatory Skills</td>
                    <td>
                      {sideSectionData?.mandatorySkills &&
                      sideSectionData?.mandatorySkills.length > 0
                        ? sideSectionData?.mandatorySkills?.map(
                            (skill, index) =>
                              `${skill.name} ${
                                sideSectionData?.mandatorySkills.length - 1 ===
                                index
                                  ? "."
                                  : ","
                              }`
                          )
                        : "Not Available"}
                    </td>
                  </tr>
                  <tr className="info-tabel">
                    <td>Good to have Skills</td>
                    <td>
                      {sideSectionData?.optionalSkills &&
                      sideSectionData?.optionalSkills.length > 0
                        ? sideSectionData?.optionalSkills?.map(
                            (skill, index) =>
                              `${skill.name} ${
                                sideSectionData?.optionalSkills.length - 1 ===
                                index
                                  ? "."
                                  : ","
                              }`
                          )
                        : "Not Available"}
                    </td>
                  </tr>
                  <tr className="info-tabel">
                    <td>Resume</td>
                    {sideSectionData?.resumezipURL ? (
                      <td
                        onClick={() =>
                          downloadFile(
                            sideSectionData?.resumezipURL,
                            `candidateCSVUploadedName`
                          )
                        }
                        className="text-primary f-sem-bol cursor-pointer"
                      >
                        Download Resume
                        <img
                          src={downloadImg}
                          style={{
                            height: "12px",
                            width: "auto",
                            marginLeft: "10px",
                          }}
                          alt=""
                        />
                      </td>
                    ) : (
                      "Not Available"
                    )}
                  </tr>
                  <tr className="info-tabel">
                    <td>Job Description</td>
                    {sideSectionData?.position
                      ?.jobDescriptionUploadedFileName ? (
                      <td
                        onClick={() =>
                          downloadFile(
                            sideSectionData?.position
                              ?.jobDescriptionUploadedFileName,
                            `${sideSectionData?.position?.title} - JD`
                          )
                        }
                        className="text-primary f-sem-bol cursor-pointer"
                      >
                        Download Job Description
                        <img
                          src={downloadImg}
                          alt=""
                          style={{
                            height: "12px",
                            width: "auto",
                            marginLeft: "10px",
                          }}
                        />
                      </td>
                    ) : (
                      "Not Available"
                    )}
                  </tr>
                  <tr className="info-tabel">
                    <td>CSV File</td>
                    {sideSectionData?.candidateCSVURL ? (
                      <td
                        onClick={() =>
                          downloadFile(
                            sideSectionData?.candidateCSVURL,
                            `${sideSectionData?.candidateCSVUploadedName} - CSV`
                          )
                        }
                        className="text-primary f-sem-bol cursor-pointer"
                      >
                        Download CSV
                        <img
                          src={downloadImg}
                          alt=""
                          style={{
                            height: "12px",
                            width: "auto",
                            marginLeft: "10px",
                          }}
                        />
                      </td>
                    ) : (
                      "Not Available"
                    )}
                  </tr>
                  <tr className="info-tabel">
                    <td>Interview Instructions</td>
                    <td>
                      {sideSectionData?.remarks
                        ? sideSectionData?.remarks
                        : "Not Available"}
                    </td>
                  </tr>
                  <tr className="info-tabel">
                    <td>Questions</td>
                    <td>
                      {sideSectionData?.screeningQuestions &&
                      sideSectionData?.screeningQuestions.length > 0
                        ? sideSectionData?.screeningQuestions.map(
                            (qs, index) => (
                              <>
                                <div>
                                  {index + 1}. {qs?.question}
                                </div>
                                <br />
                              </>
                            )
                          )
                        : "Not Available"}
                    </td>
                  </tr>
                  <tr className="info-tabel">
                    <td>Posted By</td>
                    <td>
                      {sideSectionData?.createdBy
                        ? capitalize(sideSectionData?.createdBy?.name)
                        : "Not Available"}
                      <br />
                      {moment(sideSectionData?.createdAt).format("Do MMM YYYY")}
                      , {moment(sideSectionData?.createdAt).format("LT")}
                    </td>
                  </tr>
                  {sideSectionAction && sideSectionAction.length > 0 && (
                    <tr className="info-tabel">
                      <td colSpan={2} className="f-sem-bol">
                        <div className="d-flex flex-wrap justify-content-center gap-1 action-btn-container">
                          {sideSectionAction.map((btn) => (
                            <button
                              className="abc-type-2"
                              onClick={() => {
                                btn?.action();
                                setIsSideSectionOpen(false);
                              }}
                            >
                              {btn?.name}
                            </button>
                          ))}
                        </div>
                      </td>
                    </tr>
                  )}
                </table>
              ) : (
                <table className="table-list f-l f-reg">
                  <tr className="info-tabel">
                    <td>ID</td>
                    <td>
                      {sideSectionData?.code
                        ? sideSectionData?.code
                        : sideSectionData?.bulkCode
                        ? sideSectionData?.bulkCode
                        : "Not Available"}
                    </td>
                  </tr>
                  <tr className="info-tabel">
                    <td>Job Title</td>
                    <td>
                      {sideSectionData?.position?.title
                        ? capitalize(sideSectionData?.position?.title)
                        : "Not Available"}
                    </td>
                  </tr>
                  <tr className="info-tabel">
                    <td>Domain</td>
                    <td>
                      {sideSectionData?.domain?.name
                        ? capitalize(sideSectionData?.domain?.name)
                        : "Not Available"}
                    </td>
                  </tr>
                  <tr className="info-tabel">
                    <td>Client Name</td>
                    <td>
                      {sideSectionData?.position?.clientName
                        ? capitalize(sideSectionData?.position?.clientName)
                        : "Not Available"}
                    </td>
                  </tr>
                  <tr className="info-tabel">
                    <td>Mandatory Skills</td>
                    <td>
                      {sideSectionData?.mandatorySkills &&
                      sideSectionData?.mandatorySkills.length > 0
                        ? sideSectionData?.mandatorySkills?.map(
                            (skill, index) =>
                              `${skill.name} ${
                                sideSectionData?.mandatorySkills.length - 1 ===
                                index
                                  ? "."
                                  : ","
                              }`
                          )
                        : "Not Available"}
                    </td>
                  </tr>
                  <tr className="info-tabel">
                    <td>Good to have Skills</td>
                    <td>
                      {sideSectionData?.optionalSkills &&
                      sideSectionData?.optionalSkills.length > 0
                        ? sideSectionData?.optionalSkills?.map(
                            (skill, index) =>
                              `${skill.name} ${
                                sideSectionData?.optionalSkills.length - 1 ===
                                index
                                  ? "."
                                  : ","
                              }`
                          )
                        : "Not Available"}
                    </td>
                  </tr>
                  <tr className="info-tabel">
                    <td>Candidate Name</td>
                    <td>
                      {sideSectionData?.candidate?.name
                        ? capitalize(sideSectionData?.candidate?.name)
                        : "Not Available"}
                    </td>
                  </tr>
                  <tr className="info-tabel">
                    <td>Candidate Email</td>
                    <td>
                      {sideSectionData?.candidate?.email
                        ? capitalize(sideSectionData?.candidate?.email)
                        : "Not Available"}
                    </td>
                  </tr>

                  <tr className="info-tabel">
                    <td>Candidate Number</td>
                    <td>
                      {sideSectionData?.candidate ? (
                        <>
                          {`${sideSectionData?.candidate?.countryCode} -
                          ${sideSectionData?.candidate?.phone}`}
                        </>
                      ) : (
                        "Not Available"
                      )}
                    </td>
                  </tr>
                  <tr className="info-tabel">
                    <td>Candidate Experience</td>
                    <td>
                      {sideSectionData?.candidate?.experience
                        ? `${sideSectionData.candidate.experience.year} years ${sideSectionData.candidate.experience.month} months`
                        : "Not Available"}
                    </td>
                  </tr>
                  <tr className="info-tabel">
                    <td>Candidate Resume</td>
                    {sideSectionData?.candidate?.uploadedResumeFileName ? (
                      <td
                        onClick={() =>
                          downloadFile(
                            sideSectionData?.candidate?.uploadedResumeFileName,
                            `${sideSectionData?.candidate?.name} - Resume`
                          )
                        }
                        className="text-primary f-sem-bol cursor-pointer"
                      >
                        Download Resume
                        <img
                          src={downloadImg}
                          alt=""
                          style={{
                            height: "12px",
                            width: "auto",
                            marginLeft: "10px",
                          }}
                        />
                      </td>
                    ) : (
                      "Not Available"
                    )}
                  </tr>
                  <tr className="info-tabel">
                    <td>Job Description</td>
                    {sideSectionData?.position?.jobDescription ? (
                      <td
                        onClick={() =>
                          downloadFile(
                            sideSectionData?.position?.jobDescription,
                            `${sideSectionData?.position?.title} - JD`
                          )
                        }
                        className="text-primary f-sem-bol cursor-pointer"
                      >
                        Download Job Description
                        <img
                          src={downloadImg}
                          alt=""
                          style={{
                            height: "12px",
                            width: "auto",
                            marginLeft: "10px",
                          }}
                        />
                      </td>
                    ) : (
                      "Not Available"
                    )}
                  </tr>
                  <tr className="info-tabel">
                    <td>Time Slots</td>
                    <td>
                      {sideSectionData?.timeSlots
                        ? sideSectionData?.timeSlots
                        : "Not Available"}
                    </td>
                  </tr>
                  <tr className="info-tabel">
                    <td>Feedback System Type</td>
                    {sideSectionData?.reportSystem ? (
                      <td>
                        {sideSectionData?.reportSystem !== "feedback_system"
                          ? "Manual Feedback System"
                          : "Automated Feedback System"}
                      </td>
                    ) : (
                      "Not Available"
                    )}
                  </tr>
                  {sideSectionData?.position?.feedbackFileUploadedFileName && (
                    <tr className="info-tabel">
                      <td>CAR</td>
                      {sideSectionData?.position?.feedbackFile ? (
                        <td
                          onClick={() =>
                            downloadFile(
                              sideSectionData?.position?.feedbackFile,
                              sideSectionData?.position
                                ?.feedbackFileUploadedFileName
                            )
                          }
                          className="text-primary f-sem-bol cursor-pointer"
                        >
                          Download CAR
                          <img
                            alt=""
                            src={downloadImg}
                            style={{
                              height: "12px",
                              width: "auto",
                              marginLeft: "10px",
                            }}
                          />
                        </td>
                      ) : (
                        "Not Available"
                      )}
                    </tr>
                  )}
                  <tr className="info-tabel">
                    <td>Interview Instructions</td>
                    <td>
                      {sideSectionData?.remarks
                        ? sideSectionData?.remarks
                        : "Not Available"}
                    </td>
                  </tr>
                  <tr className="info-tabel">
                    <td>Questions</td>
                    <td>
                      {sideSectionData?.screeningQuestions &&
                      sideSectionData?.screeningQuestions.length > 0
                        ? sideSectionData?.screeningQuestions.map(
                            (qs, index) => (
                              <>
                                <div>
                                  {index + 1}. {qs?.question}
                                </div>
                                <br />
                              </>
                            )
                          )
                        : "Not Available"}
                    </td>
                  </tr>
                  <tr className="info-tabel">
                    <td>Additional Information</td>
                    <td>
                      {sideSectionData?.customField &&
                      sideSectionData?.customField.length > 0
                        ? sideSectionData?.customField.map(
                            (data, index) =>
                              data?.fieldValue && (
                                <li key={index}>
                                  {capitalize(data?.fieldName)}:
                                  {data?.fieldType === "file" ? (
                                    <a
                                      href={data?.fieldValue}
                                      className="text-primary cursor-pointer"
                                      target="_blank"
                                    >
                                      <span className="hyperlink">
                                        View File
                                      </span>
                                    </a>
                                  ) : (
                                    <>{data?.fieldValue}</>
                                  )}
                                </li>
                              )
                          )
                        : "Not Available"}
                    </td>
                  </tr>
                  <tr className="info-tabel">
                    <td>Posted By</td>
                    <td>
                      {sideSectionData?.employer
                        ? capitalize(sideSectionData?.createdBy?.name)
                        : "Not Available"}
                      <br />
                      {moment(sideSectionData?.createdAt).format("Do MMM YYYY")}
                      , {moment(sideSectionData?.createdAt).format("LT")}
                    </td>
                  </tr>
                  {sideSectionData.scheduledBy && (
                    <tr className="info-tabel">
                      <td>scheduled By</td>
                      <td>
                        {sideSectionData?.scheduledBy
                          ? capitalize(sideSectionData?.scheduledBy?.name)
                          : "Not Available"}
                        <br />
                      </td>
                    </tr>
                  )}
                  {sideSectionData.cancelledBy && (
                    <tr className="info-tabel">
                      <td>Cancelled By</td>
                      <td>
                        {sideSectionData?.cancelledBy
                          ? capitalize(sideSectionData?.cancelledBy?.name)
                          : "Not Available"}
                        <br />
                        {moment(sideSectionData?.cancelledAt).format(
                          "Do MMM YYYY"
                        )}
                        , {moment(sideSectionData?.cancelledAt).format("LT")}
                      </td>
                    </tr>
                  )}
                  {sideSectionData.cancelReason && (
                    <tr className="info-tabel">
                      <td>Cancelled Reason</td>
                      <td>
                        {sideSectionData?.cancelReason
                          ? capitalize(sideSectionData?.cancelReason)
                          : "Not Available"}
                        <br />
                        {sideSectionData?.cancelDescription
                          ? capitalize(sideSectionData?.cancelDescription)
                          : "Not Available"}
                      </td>
                    </tr>
                  )}
                  {sideSectionData.acceptedBy && (
                    <tr className="info-tabel">
                      <td>Accepted By</td>
                      <td>
                        {sideSectionData?.acceptedBy?.name
                          ? capitalize(sideSectionData?.acceptedBy?.name)
                          : "Not Available"}
                        <br />
                        {moment(sideSectionData?.acceptedAt).format(
                          "Do MMM YYYY"
                        )}
                        , {moment(sideSectionData?.acceptedAt).format("LT")}
                      </td>
                    </tr>
                  )}
                  {sideSectionData.allotedBy && (
                    <tr className="info-tabel">
                      <td>Coordinator</td>
                      <td>
                        {sideSectionData?.allotedBy?.name
                          ? capitalize(sideSectionData?.allotedBy?.name)
                          : "Not Available"}
                        <br />
                        {moment(sideSectionData?.allotedAt).format(
                          "Do MMM YYYY"
                        )}
                        , {moment(sideSectionData?.allotedAt).format("LT")}
                      </td>
                    </tr>
                  )}
                  <tr className="info-tabel">
                    <td>Calls</td>
                    <td className="d-flex">
                      {sideSectionData?.candidate?.phone && (
                        <>
                          <Button
                            isImage={true}
                            image={CALL_ICON}
                            text="Candidate"
                            className="call_btn"
                            onClick={() => {
                              setIsSideSectionOpen(false);
                              setCallPopUp(true);
                              setInitiateCall({
                                id: sideSectionData?._id,
                                type: "Candidate",
                              });
                            }}
                          />
                        </>
                      )}
                      {sideSectionData?.interviewer?.phone && (
                        <>
                          {checkPermissions(
                            "IncServe",
                            "Call Interviewers",
                            "View"
                          ) ? (
                            <Button
                              isImage={true}
                              image={CALL_ICON}
                              text="Interviewer"
                              className="call_btn interviewer_btn"
                              onClick={() => {
                                setIsSideSectionOpen(false);
                                setCallPopUp(true);
                                setInitiateCall({
                                  id: sideSectionData?._id,
                                  type: "interviewer",
                                });
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </>
                      )}

                      {sideSectionData?.candidate?.phone ||
                      sideSectionData?.interviewer?.phone ? (
                        <Button
                          text="Logs"
                          className="call_btn logs_btn d-center"
                          onClick={() => {
                            setAudioLogs(true);
                            setIsEditable(false);
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                  {sideSectionData?.interviewer && (
                    <tr className="info-tabel">
                      <td>Interviewer</td>
                      <td>
                        {sideSectionData?.interviewer?.code}
                        <br />
                        {sideSectionData?.interviewer?.name}
                      </td>
                    </tr>
                  )}
                  {sideSectionData?.allotedInterviewers && (
                    <tr className="info-tabel">
                      <td>Pay Per Interview</td>
                      <td>
                        {sideSectionData?.allotedInterviewers?.payPerInterview}{" "}
                        {sideSectionData?.allotedInterviewers?.currency}
                      </td>
                    </tr>
                  )}
                  {sideSectionData?.interviewDate && (
                    <tr className="info-tabel">
                      <td>Schedule Date</td>
                      <td>
                        {moment(sideSectionData?.interviewDate).format(
                          "Do MMM YYYY"
                        )}{" "}
                        {sideSectionData?.interviewTime}
                      </td>
                    </tr>
                  )}
                  {sideSectionData?.feedbackId && (
                    <tr className="info-tabel">
                      <td>Feedback</td>
                      <td>{sideSectionData?.feedbackId?.status}</td>
                    </tr>
                  )}
                  {sideSectionData?.mode && (
                    <tr className="info-tabel">
                      <td>Interview Mode</td>
                      <td>
                        {sideSectionData?.mode === "Tele Conferencing"
                          ? "Tele Conferencing"
                          : "Video Conferencing"}
                      </td>
                    </tr>
                  )}
                  {sideSectionData?.platform && (
                    <tr className="info-tabel">
                      <td>Interview Platform</td>
                      <td>
                        {sideSectionData?.platform === "100ms"
                          ? "100 MS"
                          : sideSectionData?.platform === "zoom"
                          ? "Zoom"
                          : "Clan"}
                      </td>
                    </tr>
                  )}
                  {sideSectionData?.interviewOutcome && (
                    <tr className="info-tabel">
                      <td>Interview Outcome</td>
                      <td>
                        {sideSectionData?.interviewOutcome === "L2_selected"
                          ? "L2 selected"
                          : sideSectionData?.interviewOutcome === "L2_rejected"
                          ? "L2 rejected"
                          : sideSectionData?.interviewOutcome ===
                            "position_onhold"
                          ? "position on hold"
                          : sideSectionData?.interviewOutcome ===
                            "candidate_onhold"
                          ? "candidate on hold"
                          : "N/A"}
                      </td>
                    </tr>
                  )}
                  {sideSectionData?.meetInviteDetails?.guestLink && (
                    <tr className="info-tabel">
                      <td>Guest Link</td>
                      <td className="">
                        <Button
                          text="Copy Link"
                          className="call_btn"
                          onClick={() => {
                            const linkToCopy =
                              sideSectionData?.meetInviteDetails?.guestLink;
                            if (linkToCopy) {
                              navigator.clipboard
                                .writeText(linkToCopy)
                                .then(() => {
                                  toast.success("Link copied successfully!");
                                })
                                .catch((error) => {
                                  toast.error(
                                    "Failed to copy link. Please try again."
                                  );
                                });
                            } else {
                              toast.error("Link not available");
                            }
                          }}
                        />
                        <Button
                          text="Copy Code"
                          className="call_btn interviewer_btn"
                          onClick={() => {
                            const linkToCopy =
                              sideSectionData?.meetInviteDetails?.guestCode;
                            if (linkToCopy) {
                              navigator.clipboard
                                .writeText(linkToCopy)
                                .then(() => {
                                  toast.success("Code copied successfully!");
                                })
                                .catch((error) => {
                                  toast.error(
                                    "Failed to copy code. Please try again."
                                  );
                                });
                            } else {
                              toast.error("Code not available");
                            }
                          }}
                        />
                      </td>
                    </tr>
                  )}
                  {sideSectionData?.meetInviteDetails?.recordingLink && (
                    <tr className="info-tabel">
                      <td>Interview Recording</td>
                      <td className="">
                        <Button
                          text="Copy Link"
                          className="call_btn"
                          onClick={() => {
                            const linkToCopy =
                              sideSectionData?.meetInviteDetails?.recordingLink;
                            if (linkToCopy) {
                              navigator.clipboard
                                .writeText(linkToCopy)
                                .then(() => {
                                  toast.success("Link copied successfully!");
                                })
                                .catch((error) => {
                                  toast.error(
                                    "Failed to copy link. Please try again."
                                  );
                                });
                            } else {
                              toast.error("Link not available");
                            }
                          }}
                        />
                        <Button
                          text="View Interview Recording"
                          className="call_btn interviewer_btn"
                          onClick={() => {
                            window.open(
                              sideSectionData?.meetInviteDetails?.recordingLink,
                              "_blank"
                            );
                          }}
                        />
                      </td>
                    </tr>
                  )}
                  {sideSectionData?.meetInviteDetails?.adminLink && (
                    <tr className="info-tabel">
                      <td>Interview Link</td>
                      <td className="">
                        <Button
                          text="Copy Link"
                          className="call_btn"
                          onClick={() => {
                            const linkToCopy =
                              sideSectionData?.meetInviteDetails?.adminLink;
                            if (linkToCopy) {
                              navigator.clipboard
                                .writeText(linkToCopy)
                                .then(() => {
                                  toast.success("Link copied successfully!");
                                })
                                .catch((error) => {
                                  toast.error(
                                    "Failed to copy link. Please try again."
                                  );
                                });
                            } else {
                              toast.error("Link not available");
                            }
                          }}
                        />
                        <Button
                          text="Join Interview"
                          className="call_btn interviewer_btn"
                          onClick={() => {
                            window.open(
                              sideSectionData?.meetInviteDetails?.adminLink,
                              "_blank"
                            );
                          }}
                        />
                      </td>
                    </tr>
                  )}
                  {sideSectionData?.interviewerRating && (
                    <tr className="info-tabel">
                      <td>Interviewer Ratings</td>
                      <td>
                        <tr className="">
                          <td>Interview Coordination</td>
                          <td>
                            {
                              sideSectionData.interviewerRating
                                ?.interviewCoordination
                            }
                          </td>
                        </tr>
                        <tr className="">
                          <td>Interview Platform</td>
                          <td>
                            {
                              sideSectionData.interviewerRating
                                ?.interviewPlatform
                            }
                          </td>
                        </tr>
                        <tr className="">
                          <td>Platform Feedback</td>
                          <td>
                            {
                              sideSectionData.interviewerRating
                                ?.platformFeedback
                            }
                          </td>
                        </tr>
                      </td>
                    </tr>
                  )}
                  {sideSectionData?.candidateRating && (
                    <tr className="info-tabel">
                      <td>
                        <span>
                          candidate Ratings
                          <img
                            src={Pencil}
                            alt="pencil"
                            className="ms-2 cursor-pointer"
                            onClick={() => {
                              setCandidateRatingPopup(true);
                              setIsSideSectionOpen(false);
                              setApproveInterviewData(sideSectionData);
                            }}
                          />
                        </span>
                      </td>
                      <td>
                        <tr className="even-row">
                          <td>interviewRating</td>
                          <td>
                            {sideSectionData?.candidateRating?.interviewRating}
                          </td>
                        </tr>
                        <tr className="">
                          <td>interviewerRating</td>
                          <td>
                            {
                              sideSectionData?.candidateRating
                                ?.interviewerRating
                            }
                          </td>
                        </tr>
                        <tr className="even-row">
                          <td>remarks</td>
                          <td>{sideSectionData?.candidateRating?.remarks}</td>
                        </tr>
                      </td>
                    </tr>
                  )}

                  {sideSectionData?.adminRatings && (
                    <tr className="info-tabel">
                      <td>
                        <span>
                          Admin Ratings
                          <img
                            src={Pencil}
                            alt="pencil"
                            className="ms-2 cursor-pointer"
                            onClick={() => {
                              setInterviewFeedbackPopup(true);
                              setIsSideSectionOpen(false);
                              setApproveInterviewData(sideSectionData);
                            }}
                          />
                        </span>
                      </td>
                      <td>
                        <tr className="even-row">
                          <td>Duration Of Interview</td>
                          <td>
                            {sideSectionData?.adminRatings?.durationRating}
                          </td>
                        </tr>
                        <tr className="">
                          <td>CV Screening and Panel Introduction</td>
                          <td>
                            {sideSectionData?.adminRatings?.cvscreeningRating}
                          </td>
                        </tr>
                        <tr className="even-row">
                          <td>
                            Questions asked on Mandatory and Optional Skills
                          </td>
                          <td>
                            {sideSectionData?.adminRatings?.questionRating}
                          </td>
                        </tr>
                        <tr className="">
                          <td>Coding Challenge</td>
                          <td>{sideSectionData?.adminRatings?.codingRating}</td>
                        </tr>
                        <tr className="">
                          <td>Attitude and Communication</td>
                          <td>
                            {
                              sideSectionData?.adminRatings
                                ?.attitudeAndcommunicationRating
                            }
                          </td>
                        </tr>
                      </td>
                    </tr>
                  )}

                  {sideSectionData?.logs &&
                    sideSectionData?.logs?.length > 0 && (
                      <tr className="info-tabel">
                        <td>Interview Logs</td>
                        <td>
                          <div className="d-flex text-align-center">
                            <div className="w-33 logs-header">Action</div>
                            <div className="w-33 logs-header">Action By</div>
                            {type === "cancelled" || type === "rescheduled" ? (
                              <div className="w-33 logs-header">Reason</div>
                            ) : (
                              ""
                            )}
                            <div className="w-33 logs-header">Created On</div>
                          </div>
                          {sideSectionData?.logs.map(
                            (logs, i) =>
                              logs.action !== "accepted" &&
                              logs.action !== "allot" &&
                              logs.action !== "scheduled" && (
                                <div
                                  key={i}
                                  className="d-flex p-2 logs-tabel text-align-center"
                                >
                                  <div className="w-33">
                                    {logs?.action
                                      ? logs.action === "accepted"
                                        ? "Accepted"
                                        : logs.action === "scheduled"
                                        ? "Scheduled"
                                        : logs.action === "rescheduled" ||
                                          logs.action === "reschedule_requested"
                                        ? "Rescheduled"
                                        : logs.action === "feedback_approved"
                                        ? "Feedback Approved"
                                        : logs.action === "admin_ratings"
                                        ? "Admin Ratings"
                                        : logs.action
                                      : "Not Available"}
                                  </div>
                                  <div className="w-33">
                                    {logs?.user?.name
                                      ? logs?.user?.name
                                      : "Not Available"}
                                  </div>
                                  {type === "cancelled" ||
                                  type === "rescheduled" ||
                                  type === "reschedule_requested" ? (
                                    <div className="w-33">
                                      {logs?.reason
                                        ? capitalizeAll(logs?.reason)
                                        : "Not Available"}
                                      <br />
                                      {logs?.description
                                        ? logs?.description
                                        : "Not Available"}
                                    </div>
                                  ) : (
                                    ""
                                  )}{" "}
                                  <div className="w-33">
                                    {moment(logs?.createdAt).format(
                                      "Do MMM YYYY"
                                    )}
                                    <br />
                                    {moment(logs?.createdAt).format("LT")}
                                  </div>
                                </div>
                              )
                          )}
                        </td>
                      </tr>
                    )}
                  {sideSectionAction && sideSectionAction.length > 0 && (
                    <tr className="info-tabel">
                      <td colSpan={2} className="f-sem-bol">
                        <div className="d-flex flex-wrap justify-content-center gap-1 action-btn-container">
                          {sideSectionAction.map(
                            (btn, index) =>
                              btn?.name !== undefined && (
                                <button
                                  key={index}
                                  className="abc-type-2"
                                  onClick={() => {
                                    btn?.action();
                                    setIsSideSectionOpen(false);
                                  }}
                                >
                                  {btn?.name}
                                </button>
                              )
                          )}
                        </div>
                      </td>
                    </tr>
                  )}
                </table>
              )}
            </>
          ) : (
            <>
              <NoRecordFound />
            </>
          )}
        </div>
      </div>
      {addQuestionPopUp && (
        <IncServeAddQuestionPopUp
          setAddQuestionPopUp={setAddQuestionPopUp}
          sideSectionData={sideSectionData}
          status={status}
          currentPage={currentPage}
          entriesLimit={entriesLimit}
          search={search}
          sort={sortBy}
          isFilterBtnData={isFilterBtnData}
          isReportApproved={isReportApproved}
          setIsSideSectionOpen={setIsSideSectionOpen}
        />
      )}
    </div>
  );
};

export default InfoTable;
