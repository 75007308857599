import React, { memo, useMemo, useState } from "react";
import moment from "moment";
import logo from "../../../assets/images/incruiter-logo.svg";
import paid from "../../../assets/images/paid-sticker.svg";
import { numberToWords } from "../../../utils/helper";

const Invoice = memo(({ setViewInvoice, type, invoiceData, inputRef }) => {
  const Rs = (amount) => {
    let words = [];
    words[0] = "Zero";
    words[1] = "One";
    words[2] = "Two";
    words[3] = "Three";
    words[4] = "Four";
    words[5] = "Five";
    words[6] = "Six";
    words[7] = "Seven";
    words[8] = "Eight";
    words[9] = "Nine";
    words[10] = "Ten";
    words[11] = "Eleven";
    words[12] = "Twelve";
    words[13] = "Thirteen";
    words[14] = "Fourteen";
    words[15] = "Fifteen";
    words[16] = "Sixteen";
    words[17] = "Seventeen";
    words[18] = "Eighteen";
    words[19] = "Nineteen";
    words[20] = "Twenty";
    words[30] = "Thirty";
    words[40] = "Forty";
    words[50] = "Fifty";
    words[60] = "Sixty";
    words[70] = "Seventy";
    words[80] = "Eighty";
    words[90] = "Ninety";
    amount = amount.toString();
    let atemp = amount.split(".");
    let number = atemp[0].split(",").join("");
    let n_length = number.length;
    let words_string = "";
    if (n_length <= 9) {
      let n_array = [0, 0, 0, 0, 0, 0, 0, 0, 0];
      let received_n_array = [];
      for (let i = 0; i < n_length; i++) {
        received_n_array[i] = number.substr(i, 1);
      }
      for (let i = 9 - n_length, j = 0; i < 9; i++, j++) {
        n_array[i] = received_n_array[j];
      }
      for (let i = 0, j = 1; i < 9; i++, j++) {
        if (i === 0 || i === 2 || i === 4 || i === 7) {
          if (n_array[i] === 1) {
            n_array[j] = 10 + parseInt(n_array[j]);
            n_array[i] = 0;
          }
        }
      }
      let value = "";
      for (let i = 0; i < 9; i++) {
        if (i === 0 || i === 2 || i === 4 || i === 7) {
          value = n_array[i] * 10;
        } else {
          value = n_array[i];
        }
        if (value !== 0) {
          words_string += words[value] + " ";
        }
        if (
          (i === 1 && value !== 0) ||
          (i === 0 && value !== 0 && n_array[i + 1] === 0)
        ) {
          words_string += "Crores ";
        }
        if (
          (i === 3 && value !== 0) ||
          (i === 2 && value !== 0 && n_array[i + 1] === 0)
        ) {
          words_string += "Lakhs ";
        }
        if (
          (i === 5 && value !== 0) ||
          (i === 4 && value !== 0 && n_array[i + 1] === 0)
        ) {
          words_string += "Thousand ";
        }
        if (
          i === 6 &&
          value !== 0 &&
          n_array[i + 1] !== 0 &&
          n_array[i + 2] !== 0
        ) {
          words_string += "Hundred and ";
        } else if (i === 6 && value !== 0) {
          words_string += "Hundred ";
        }
      }
      words_string = words_string.split(" ").join(" ");
    }
    return words_string;
  };
  const RsPaise = (n) => {
    let op;
    let nums = n?.toString().split(".");
    let whole = Rs(nums[0]);
    if (nums[1] === null) nums[1] = 0;
    if (nums[1]?.length === 1) nums[1] = nums[1] + "0";
    if (nums[1]?.length > 2) {
      nums[1] = nums[1].substring(2, nums[1].length - 1);
    }
    if (nums.length === 2) {
      if (nums[0] <= 9) {
        nums[0] = nums[0] * 10;
      }
      let fraction = Rs(nums[1]);
      if (whole === "" && fraction === "") {
        op = "Zero Only";
      }
      if (whole === "" && fraction !== "") {
        op = fraction + "Paise Only";
      }
      if (whole !== "" && fraction === "") {
        op = whole + " Rupees Only";
      }
      if (whole !== "" && fraction !== "") {
        op = whole + " Rupees and " + fraction + " Paise Only";
      }
      return op;
    }
  };

  return (
    <div className="interviewer-invoice-container" ref={inputRef}>
      <div className="invoice" style={{ top: "50px" }}>
        <div className="invoice-main-content">
          <div className="d-flex justify-content-center f-30 my-3 f-bol invoice-title">
            INVOICE
          </div>
          <div className="company-details d-flex justify-content-between">
            <div className="part-1 f-14">
              <p className="company-name f-16 f-bol">{invoiceData?.name}</p>
              <p className="company-address f-12 f-reg">
                {invoiceData?.address?.address}
                <br />
                {invoiceData?.address?.city}
                <br />
                {invoiceData?.address?.state}
                <br />
                {invoiceData?.address?.pincode}
              </p>
            </div>{" "}
            <div className="part-1 f-14 d-flex justify-content-between">
              <div className="part-title">
                <div className="d-flex f-12">Interviewer ID</div>
                <div className="d-flex f-12">Interviewer Name</div>
                <div className="d-flex f-12">Invoice Month</div>
                <div className="d-flex f-12">Total Interviews</div>
                <div className="d-flex f-12">Conducted Interviews</div>
                <div className="d-flex f-12">No Shows</div>
              </div>
              <div className="part-title">
                <div className="d-flex f-12">
                  : {invoiceData?.code ? invoiceData?.code : "Not Available"}
                </div>
                <div className="d-flex f-12">
                  : {invoiceData?.name ? invoiceData?.name : "Not Available"}
                </div>
                <div className="d-flex f-12">
                  :{" "}
                  {invoiceData &&
                  invoiceData?.data &&
                  invoiceData?.data[0]?.month
                    ? invoiceData?.data[0]?.month
                    : "Not Available"}
                </div>
                <div className="d-flex f-12">
                  :{" "}
                  {invoiceData &&
                  invoiceData?.data &&
                  invoiceData?.data[0]?.totalInterviews
                    ? invoiceData?.data[0]?.totalInterviews
                    : "Not Available"}
                </div>
                <div className="d-flex f-12">
                  :
                  {invoiceData &&
                  invoiceData?.data &&
                  invoiceData?.data[0]?.conductedInterviews
                    ? invoiceData?.data[0]?.conductedInterviews
                    : "Not Available"}{" "}
                </div>
                <div className="d-flex f-12">
                  :{" "}
                  {invoiceData &&
                  invoiceData?.data &&
                  invoiceData?.data[0]?.noshowInterviews
                    ? invoiceData?.data[0]?.noshowInterviews
                    : "Not Available"}{" "}
                </div>
              </div>
            </div>
            <div className="part-2 f-14 d-flex flex-column justify-content-between">
              <div>
                <div className="divart-title f-med f-12">Invoice number</div>
                <div className="f-sem-bol text-dark">
                  #{invoiceData?.invoice}
                </div>
              </div>
              <div>
                <div className="divart-title f-med f-12">Invoice Date</div>
                <div className="f-sem-bol text-dark">
                  {moment(invoiceData?.createdAt).format("ll")}
                </div>
              </div>
            </div>
          </div>
          <table className="product-details w-100 f-12">
            <thead className="p-3">
              <tr className="invoice-table-header tr-border">
                <th className="f-10 p-2">NO.</th>
                <th className="f-10 text-center">
                  INTERVIEW <br /> ID
                </th>
                <th className="text-center f-10">
                  CANDIDATE <br /> NAME
                </th>
                <th className="text-center f-10">
                  DATE OF <br /> INTERVIEW
                </th>
                <th className="text-center f-10">FEEDBACK</th>
                <th className="text-center f-10">
                  PAY PER <br /> INTERVIEW
                </th>
              </tr>
            </thead>

            <tbody>
              {invoiceData &&
                invoiceData?.data &&
                invoiceData?.data[0]?.interviews?.length > 0 &&
                invoiceData?.data[0]?.interviews.map((itm, index) => (
                  <tr className="f-sem-bol f-14  tr-border" key={index}>
                    <td className="f-med f-12 text-center p-2">{index + 1}</td>
                    <td className="f-med f-12 text-center">{itm?.code}</td>
                    <td className="f-med f-12 text-center">
                      {itm?.candidateName}
                    </td>
                    <td className="f-med f-12 text-center">
                      {moment(itm?.interviewDate).format("Do MMM YYYY")}
                      <br />
                      {
                        new Date(itm?.interviewTime)
                          .toLocaleString()
                          .split(", ")[1]
                      }
                    </td>
                    <td className="f-med f-12 text-center">
                      {itm?.status === "very_poor"
                        ? "Very poor"
                        : itm?.status === "good"
                        ? "Good"
                        : itm?.status === "poor"
                        ? "Poor"
                        : itm?.status === "average"
                        ? "Average"
                        : itm?.status === "above_average"
                        ? "Above Average"
                        : itm?.status === "very_good"
                        ? "Very Good"
                        : itm?.status === "excellent"
                        ? "Excellent"
                        : ""}
                    </td>
                    <td className="f-med f-12 text-center">
                      {itm?.payPerInterview}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div className="total-container d-flex justify-content-between align-items-end tr-border">
            <span className="f-12 f-sem-bol">Total Amount (In words)</span>
            <table className="f-14 f-sem-bol">
              <tbody>
                <tr>
                  <td className="f-12 f-med">Total</td>
                  <td className="text-end f-16 f-sem-bol">
                    INR
                    {invoiceData?.data
                      ? invoiceData?.data.length > 0
                        ? invoiceData?.data[0]?.totalPayPerInterview === 0 ||
                          invoiceData?.data[0]?.totalPayPerInterview === null
                          ? 0.0
                          : invoiceData?.data[0]?.totalPayPerInterview?.toFixed(
                              2
                            )
                        : 0.0
                      : 0.0}
                  </td>{" "}
                </tr>
              </tbody>
            </table>
          </div>
          <div className="f-14 f-sem-bol">
            {invoiceData?.data && invoiceData?.data.length > 0
              ? numberToWords(invoiceData?.data[0]?.totalPayPerInterview)
              : "Zero Only"}
          </div>
          <div className="d-flex justify-content-center f-30 my-3 f-bol invoice-title">
            THANK YOU
          </div>
        </div>
        <div className="d-flex align-items-end flex-column">
          {/* <p className="title f-10 f-bol">
            For Incruiter India Private Limited
          </p> */}
          <p className="auth f-10 f-med">Authorized Signatory</p>
        </div>
        <div className="invoice-footer">
          <div className="f-10 f-med text-center">
            This is a Computer Generated Invoice
          </div>
          <div className="tr-border"></div>
          <div className="additional-info f-12 f-med text-center my-1">
            080-471-87100 | www.incruiter.com | contact@incruiter.com
          </div>
        </div>
      </div>
    </div>
  );
});

export default Invoice;
