import React, { useEffect } from "react";
import Select from "react-select";

const SearchSelect = ({
  name,
  value,
  onChange,
  labelClass,
  inputClass,
  errorClass,
  error,
  label,
  isRequired,
  options,
  isMulti,
  defaultValue,
  isDisabled,
  placeholder,
}) => {
  const customStyles = {
    singleValue: (provided, state) => ({
      ...provided,
      backgroundColor: state.isDisabled ? "#E7E7E7" : provided.backgroundColor,
      color: state.isDisabled ? "#626262" : provided.color,
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: state.isDisabled ? "#E7E7E7" : provided.backgroundColor,
      color: state.isDisabled ? "#626262" : provided.color,
    }),
  };
  return (
    <>
      {label && (
        <label htmlFor={name} className={`c-label ${labelClass}`}>
          {label} {isRequired && <span className="asterisk f-12">&#42;</span>}
        </label>
      )}
      <div className={`position-relative${isDisabled && " cursor-na"}`}>
        <Select
          className={`${inputClass} ${error ? "error" : ""}`}
          isMulti={isMulti}
          classNamePrefix="select"
          isSearchable={true}
          options={options}
          name={name}
          defaultValue={defaultValue}
          isDisabled={isDisabled}
          value={options && options.find((itm) => itm.value === value)}
          onChange={onChange}
          maxMenuHeight={200}
          isRequired={isRequired}
          placeholder={placeholder}
          styles={customStyles}
          // menuPlacement="auto"
        />
      </div>
      {error && <div className={`invalid ${errorClass}`}>{error}</div>}
    </>
  );
};

export default SearchSelect;
