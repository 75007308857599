import {
  GET_CREDIT_DESCRIPTION,
  GET_CREDIT_DESCRIPTION_FAILURE,
  GET_CREDIT_DESCRIPTION_SUCCESS,
} from "../action/types";

const INIT_STATE = {
  loading: "false",
  creditDescriptionList: null,
  error: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CREDIT_DESCRIPTION:
      return { ...state, loading: true };
    case GET_CREDIT_DESCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        creditDescriptionList: action.payload,
      };
    case GET_CREDIT_DESCRIPTION_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};
